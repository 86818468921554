import React, { useEffect } from 'react';
import withRouter from '../utils/withRouter';
import { HStack, SimpleGrid, Skeleton } from '@chakra-ui/react';
import { MedalAchievement } from '@vectopus/atlas-icons-react';
import { Badge } from '../components/Achievements/Badge';
import activityService from '../services/activity-service';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar';

const Badges = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [badges, setBadges] = React.useState([]);

  useEffect(() => {
    async function fetAllBadges() {
      const response = await activityService.getBadges();
      setBadges(response.data.badges);
      setIsLoading(false);
    }
    fetAllBadges();
  }, []);

  return (
    <>
      <LayoutWithSidebar title="Badges">
        <Skeleton isLoaded={!isLoading} rounded={'lg'} minHeight={'48'}>
          <HStack pb={6}>
            <MedalAchievement className="w-6 h-6 text-gray-700" />
            <h1 className="text-gray-700 text-2xl">All Badges</h1>
          </HStack>
          <SimpleGrid columns={[1, 2, 2, 3]} spacing={10}>
            {badges.map(badge => (
              <Badge key={badge.id} badge={badge} showProgress={false} />
            ))}
          </SimpleGrid>
        </Skeleton>
      </LayoutWithSidebar>
    </>
  );
};

export default withRouter(Badges);
