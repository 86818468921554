import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Button,
  Text,
  useToast,
  FormHelperText,
  FormControl,
  Input,
} from '@chakra-ui/react';
import MentorService from '../services/mentor-service';
import TextInput from './TextInput';
import { TagsInput } from 'react-tag-input-component';
import { BeatLoader } from 'react-spinners';
import { isSupported, setup } from '@loomhq/record-sdk';
import { Formik } from 'formik';
import * as Yup from 'yup';

const EditMentorProfile = ({ mentor, username }) => {
  const RECORD_LOOM_BUTTON_ID = 'record-loom-button';
  const LOOM_PUBLIC_APP_ID = '7b1de6ea-3cf3-45ed-a8a8-f1fb62c334b4';

  const validationSchema = Yup.object().shape({
    professionalPitch: Yup.string()
      .required('Please add your bio to introduce yourself.')
      .label('Professional pitch')

      .max(1000),
    funFact: Yup.string().label('Fun fact'),
    location: Yup.string().required().label('Location'),
    currentRole: Yup.string()
      .required('Please provide your current role')
      .label('Current role'),
    company: Yup.string()
      .required('Please provide the where you currently work')
      .label('Company'),

    topics: Yup.array()
      .of(Yup.string())
      .min(1, 'Please add at least one topic')
      .max(10, 'You can add up to 10 topics')
      .required("Topics can't be empty"),
    linkedIn: Yup.string().url().required('Please enter a valid LinkedIn URL'),
    twitter: Yup.string().label('Twitter'),
    calendarLink: Yup.string()
      .label('Calendar link')
      .required('Please add a calendar link'),
    personalWebsite: Yup.string().label('Personal website'),
    github: Yup.string().label('Github'),
    skills: Yup.array()
      .of(Yup.string())
      .min(1, 'Please ad at least one skill')
      .max(10, 'You can add up to 10 skills')
      .required(),
    introVideoLink: Yup.string().label('Intro video link'),
    numberOfOpenSpots: Yup.number(),
  });

  const toast = useToast();
  const [values, setValues] = useState({
    professionalPitch: mentor.pitch,
    industry: mentor.industry,
    currentRole: mentor.current_role,
    yearsOfExperience: mentor.years_of_experience,
    company: mentor.company,
    linkedIn: mentor.linkedin,
    twitter: mentor.twitter,
    personalWebsite: mentor.website,
    github: mentor.github,
    funFact: mentor.fun_fact,
    calendarLink: mentor.calendly_booking_link,
    location: mentor.location,
    pricePerSession: mentor.price_per_session,
    percentForCharity: mentor.percentage_for_charity,
    skills: mentor.skills.map(skill => skill.name),
    topics: mentor.topics,
    introVideoLink: mentor.intro_video,
    numberOfOpenSpots: mentor.number_of_open_spots,
  });

  const handleMentorProfileUpdate = async values => {
    const payload = {
      pitch: values.professionalPitch,
      industry: values.industry,
      current_role: values.currentRole,
      company: values.company,
      linkedin: values.linkedIn,
      twitter: values.twitter,
      website: values.personalWebsite,
      github: values.github,
      fun_fact: values.funFact,
      calendly_booking_link: values.calendarLink,
      location: values.location,
      topics: values.topics,
      skill_names: values.skills,
      intro_video: values.introVideoLink,
      number_of_open_spots: values.numberOfOpenSpots,
    };

    try {
      const response = await MentorService.updateMentorProfile(
        username,
        payload
      );
      if (response.status === 200) {
        toast({
          title: 'Update successful!',
          description: 'Your mentor profile has been updated',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (err) {
      toast({
        title: 'Error!',
        description: 'Unable to update your profile, please try again',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleMentorProfileUpdate(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          useEffect(() => {
            async function setupLoom() {
              const { supported, error } = await isSupported();

              if (!supported) {
                console.warn(`Error setting up Loom: ${error}`);
                return;
              }

              const button = document.getElementById(RECORD_LOOM_BUTTON_ID);

              if (!button) {
                return;
              }

              const { configureButton } = await setup({
                publicAppId: LOOM_PUBLIC_APP_ID,
                config: {
                  defaultRecordingType: 'cam',
                },
              });

              const sdkButton = configureButton({ element: button });

              sdkButton.on('insert-click', async video => {
                setFieldValue('introVideoLink', video.sharedUrl);
              });
            }

            setupLoom();
          }, []);

          return (
            <form method="post" onSubmit={handleSubmit}>
              <Flex
                p="4"
                h={['100%', '100%', '100%']}
                w={['100%', '100%', '100%', '100%']}
                direction="column"
              >
                <Flex direction="column">
                  <h3 className="text-lg font-semibold text-gray-700 mb-4">
                    Skills and topics
                  </h3>
                  <hr className="mb-4" />
                  <Box w={['95%', '97%', '97%', 'auto']} mb={'8'}>
                    <FormControl isRequired>
                      <label
                        htmlFor={'skills'}
                        className="block text-sm font-medium pb-1"
                      >
                        Skills
                        <span className="ml-1 text-gray-700">*</span>
                      </label>
                      <TagsInput
                        id="skills"
                        value={values.skills}
                        name="skills"
                        placeHolder="Add a skill"
                        classNames={{
                          tag: 'rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2',
                          input:
                            'block w-full px-3.5 py-2 text-gray-900 ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6',
                        }}
                        onChange={newValue => {
                          setFieldValue('skills', newValue);
                        }}
                      />

                      <FormHelperText fontSize={'sm'} textColor={'gray.600'}>
                        Add up to 10 skills that you are proficient in (e.g.
                        JavaScript, Frontend Development, Communication, etc.)
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  <Box w={['95%', '97%', '97%', 'auto']} mb={'8'}>
                    <FormControl isRequired>
                      <label
                        htmlFor={'topics'}
                        className="block text-sm font-medium pb-1"
                      >
                        Topics
                        <span className="ml-1 text-gray-500">*</span>
                      </label>
                      <TagsInput
                        value={values.topics}
                        name="topics"
                        placeHolder="Add a topic"
                        classNames={{
                          tag: 'rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2',
                          input:
                            'block w-full px-3.5 py-2 text-gray-900 ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6',
                        }}
                        onChange={newValue => {
                          setFieldValue('topics', newValue);
                        }}
                      />

                      <FormHelperText fontSize={'sm'} textColor={'gray.600'}>
                        Add up to 10 topics that you like to talk about (e.g.
                        Getting started in tech, Growing your career, etc.)
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  <h3 className="text-lg font-semibold text-gray-700 mb-4">
                    About you
                  </h3>
                  <hr className="mb-4" />

                  {/* Professional Pitch */}
                  <Box w={['95%', '97%', '97%', 'auto']}>
                    <TextInput
                      title="About"
                      name={'professionalPitch'}
                      element="textArea"
                      onChangeFunction={handleChange}
                      isRequired={true}
                      value={values.professionalPitch}
                      placeholder="Tell us about your professional experience"
                      showError={
                        errors.professionalPitch && touched.professionalPitch
                      }
                      errorMessage="A good bio helps mentees know you better"
                      helperText={
                        'A good bio helps mentees know you better. Share about yourself in the first person. Max 1000 characters'
                      }
                    />
                  </Box>

                  {/* Fun Fact */}
                  <Box w={['95%', '97%', '97%', 'auto']} mt="20px">
                    <TextInput
                      title="Your fun fact"
                      name={'funFact'}
                      element="textArea"
                      onChangeFunction={handleChange}
                      onBlurFunction={handleBlur}
                      value={values.funFact}
                      placeholder="Like the first job you wanted as a kid :)"
                      showError={errors.funFact && touched.funFact}
                      errorMessage={errors.funFact && touched.funFact}
                      helperText={
                        'A fun fact is a great way to connect with your mentees. Max 1000 characters'
                      }
                    />
                  </Box>
                  <Box w={['95%', '97%', '97%', 'auto']} mt="20px">
                    <FormControl className="xl:mr-4" w={'100%'}>
                      <label
                        htmlFor={'introVideoLink'}
                        className="block text-sm font-medium pb-1"
                      >
                        Intro video link
                      </label>
                      <Input
                        name="introVideoLink"
                        value={values.introVideoLink}
                        onChange={handleChange}
                        bgColor={'white'}
                        fontSize={'sm'}
                        onBlur={handleBlur}
                        placeholder="e.g. https://www.youtube.com/watch?v=yourvideo"
                      ></Input>
                      <Text
                        borderRadius="md"
                        fontSize="sm"
                        mt="1"
                        textColor="red.500"
                      >
                        {errors.introVideoLink && touched.introVideoLink
                          ? errors.introVideoLink
                          : null}
                      </Text>
                      <FormHelperText fontSize={'sm'} textColor={'gray.600'}>
                        <a
                          id={RECORD_LOOM_BUTTON_ID}
                          className="text-orange-600 hover:underline cursor-pointer"
                        >
                          Record a loom
                        </a>{' '}
                        introducing yourself to your mentees.
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Flex>

                <h3 className="text-lg font-semibold text-gray-700 mt-8 mb-4">
                  Professional
                </h3>
                <hr className="mb-4" />

                <Flex
                  direction={['column', 'column', 'column', 'row']}
                  mt="20px"
                  justifyContent="space-between"
                  w={['93%', '95%', '95%', 'auto']}
                >
                  <Box className="w-full md:mr-4">
                    <TextInput
                      title="Current role"
                      name={'currentRole'}
                      type="text"
                      onChangeFunction={handleChange}
                      onBlurFunction={handleBlur}
                      placeholder="e.g. Software Engineer"
                      isRequired={true}
                      value={values.currentRole}
                      addMarginTop={true}
                    />
                  </Box>
                  <Box className="w-full">
                    <TextInput
                      value={values.company}
                      name={'company'}
                      title="Company"
                      type="text"
                      onChangeFunction={handleChange}
                      onBlurFunction={handleBlur}
                      placeholder="e.g Google"
                      isRequired={true}
                    />
                  </Box>
                </Flex>

                {/* Location */}

                <Flex
                  direction={['column', 'column', 'column', 'row']}
                  mt="20px"
                  justifyContent="space-between"
                  w={['93%', '95%', '95%', 'auto']}
                >
                  <TextInput
                    value={values.location}
                    title="Location"
                    name={'location'}
                    type="text"
                    onChangeFunction={handleChange}
                    onBlurFunction={handleBlur}
                    placeholder="Lagos, Nigeria"
                    addMarginTop={true}
                    showError={errors.location && touched.location}
                    errorMessage="Your Location is required."
                  />
                </Flex>

                <h3 className="text-lg font-semibold text-gray-700 mt-8 mb-4">
                  Availability
                </h3>
                <hr className="mb-4" />

                <Flex
                  direction={['column', 'column', 'column', 'row']}
                  mt="20px"
                  justifyContent="space-between"
                  w={['93%', '95%', '95%', 'auto']}
                >
                  <Box className="w-full md:mr-4">
                    <TextInput
                      title="Bookable calendar link"
                      type="url"
                      name={'calendarLink'}
                      onChangeFunction={handleChange}
                      onBlurFunction={handleBlur}
                      value={values.calendarLink}
                      placeholder="https://calendly.com/yourprofilename"
                      showError={errors.calendarLink && touched.calendarLink}
                      errorMessage="Please provide a valid url."
                      isRequired={true}
                      helperText={
                        'This is the link mentees will use to book a session with you. You can use Calendly, Cal.com, etc.'
                      }
                    />
                  </Box>
                  <Box className="w-full">
                    <TextInput
                      title="Number of open spots"
                      type="number"
                      name={'numberOfOpenSpots'}
                      onChangeFunction={handleChange}
                      onBlurFunction={handleBlur}
                      value={values.numberOfOpenSpots}
                      placeholder="e.g. 5"
                      showError={errors.numberOfOpenSpots}
                      errorMessage="Please provide a valid number."
                      isRequired={true}
                      helperText={
                        'This is the number of mentees that can book a mentorship with you in a month.'
                      }
                    />
                  </Box>
                </Flex>

                <h3 className="text-lg font-semibold text-gray-700 mt-8 mb-4">
                  Social links
                </h3>
                <hr className="mb-4" />

                <Flex
                  direction={['column', 'column', 'column', 'row']}
                  mt="20px"
                  justifyContent="space-between"
                  w={['93%', '95%', '95%', 'auto']}
                >
                  <Box width={'100%'} className="md:mr-4">
                    <TextInput
                      title="LinkedIn URL"
                      type="url"
                      name={'linkedIn'}
                      onChangeFunction={handleChange}
                      onBlurFunction={handleBlur}
                      isRequired={true}
                      value={values.linkedIn}
                      placeholder="https://www.linkedin.com/in/yourname/"
                      showError={errors.linkedIn && touched.linkedIn}
                      errorMessage="Please provide a valid url."
                    />
                  </Box>
                  <Box width={'100%'}>
                    <TextInput
                      title="Personal website (optional)"
                      type="url"
                      name={'personalWebsite'}
                      onChangeFunction={handleChange}
                      onBlurFunction={handleBlur}
                      value={values.personalWebsite}
                      placeholder="https://www.yourname.com"
                      showError={
                        errors.personalWebsite && touched.personalWebsite
                      }
                      errorMessage="Please provide a valid url."
                    />
                  </Box>
                </Flex>

                {/* Personal website and Github */}

                <Flex
                  direction={['column', 'column', 'column', 'row']}
                  mt="20px"
                  justifyContent="space-between"
                  w={['93%', '95%', '95%', 'auto']}
                >
                  <Box className="w-full md:mr-4">
                    <TextInput
                      title="Github link (optional)"
                      type="text"
                      name={'github'}
                      onChangeFunction={handleChange}
                      onBlurFunction={handleBlur}
                      value={values.github}
                      errorMessage={errors.github && touched.github}
                      placeholder="Github link"
                      addMarginTop={true}
                    />
                  </Box>
                  <Box className="w-full">
                    <TextInput
                      title="Twitter Handle (optional)"
                      type="text"
                      name={'twitter'}
                      onChangeFunction={handleChange}
                      onBlurFunction={handleBlur}
                      value={values.twitter}
                      placeholder="username"
                      addMarginTop={true}
                      showError={errors.twitter && touched.twitter}
                      errorMessage="Check your twitter handle."
                      helperText={
                        'Omit the @ symbol. This will be used to tag you on twitter when we share your profile.'
                      }
                    />
                  </Box>
                </Flex>

                <Flex
                  mx={2}
                  my="20px"
                  justifyContent={['center', 'flex-start']}
                >
                  <Button
                    _focus={{ outline: 'none' }}
                    bgColor="brand.darkBlue"
                    textColor={'white'}
                    rounded={'lg'}
                    size="md"
                    type="submit"
                    _hover={{ shadow: 'md' }}
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                    spinner={<BeatLoader size={8} color={'white'} />}
                  >
                    Save changes
                  </Button>
                </Flex>
              </Flex>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default EditMentorProfile;
