import React from 'react';
import { Tag } from '@chakra-ui/react';

const DifficultyLabel = ({ difficulty }) => {
  switch (difficulty?.toLowerCase()) {
    case 'easy':
      return (
        <Tag
          fontSize={'xs'}
          rounded={'lg'}
          colorScheme={'green'}
          className="capitalize"
        >
          {difficulty}
        </Tag>
      );
    case 'intermediate' || 'medium' || 'moderate' || 'Intermediate' || 'Medium':
      return (
        <Tag
          fontSize={'xs'}
          rounded={'lg'}
          colorScheme={'orange'}
          className="capitalize"
        >
          Medium
        </Tag>
      );
    case 'hard':
      return (
        <Tag
          fontSize={'xs'}
          rounded={'lg'}
          colorScheme={'red'}
          className="capitalize"
        >
          {difficulty}
        </Tag>
      );
    case 'Poor':
      return (
        <Tag
          fontSize={'xs'}
          rounded={'lg'}
          colorScheme={'orange'}
          className="capitalize"
        >
          {difficulty}
        </Tag>
      );
    case 'Terrible':
      return (
        <Tag
          fontSize={'xs'}
          rounded={'lg'}
          colorScheme={'red'}
          className="capitalize"
        >
          {difficulty}
        </Tag>
      );
    default:
      return (
        <Tag
          fontSize={'xs'}
          rounded={'lg'}
          className="capitalize"
          colorScheme={'gray'}
        >
          {difficulty}
        </Tag>
      );
  }
};

export default DifficultyLabel;
