import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Text,
  Button,
  Skeleton,
  HStack,
  Heading,
  Avatar,
  Box,
  Divider,
  LinkBox,
  LinkOverlay,
} from '@chakra-ui/react';
import moment from 'moment';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline';
import { FaQuoteLeft } from 'react-icons/fa';
import { StarIcon } from '@heroicons/react/24/solid';


export const SubmissionItem = ({
  submission,
  index,
  showProjectName = true,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <LinkBox
        _hover={{
          boxShadow: 'md',
          cursor: 'pointer',
        }}
        rounded={'lg'}
        border="1px"
        borderColor={'gray.100'}
        width={'100%'}
        p="4"
        flexDirection="column"
      >
        <Flex className="justify-between pb-4 w-full">
          <HStack className="w-8/12">
            <Avatar
              size="sm"
              src={submission.submitter.image}
              name={`${submission.submitter.fullname}`}
            />
            <div className="flex flex-col">
              <HStack>
                <a
                  className="font-semibold text-sm text-gray-700 hover:underline"
                  href={`/profile/${submission.submitter.username}`}
                >
                  {submission.submitter.fullname}
                </a>

                <div className="items-center text-xs inline-flex font-bold leading-sm px-2 py-0 rounded-full bg-white text-gray-700 border">
                  <StarIcon className="h-3 w-3 mr-1 text-orange-400" />
                  <Text fontSize="xs" className="text-gray-600 font-semibold">
                    {submission.submitter.total_points}
                  </Text>
                </div>
              </HStack>
              <div className="text-gray-600 text-xs w-48 truncate">
                {submission.submitter.bio}
              </div>
            </div>
          </HStack>

          <HStack className="w-4/12 justify-end">
            <Text textColor={'gray.500'} fontSize="xs">
              {moment(`${submission.updated_at}`.split('T')[0])
                .utc()
                .format('MMM D, YYYY')}
            </Text>
          </HStack>
        </Flex>

        <LinkOverlay href={`/submissions/${submission.id}`}>
          <Flex flexDirection={'column'}>
            <FaQuoteLeft className="h-4 w-4 text-orange-500" />
            <Box pt={2} height={'20'}>
              <Text
                textColor={'gray.700'}
                noOfLines={3}
                fontSize="md"
                className="italic"
              >
                "{submission.additional_information}"
              </Text>
            </Box>
          </Flex>
        </LinkOverlay>
        <Divider />
        {showProjectName && (
          <Flex
            className="ring-1 ring-gray-300 rounded-lg p-2"
            flexDirection={'column'}
            textColor={'gray.700'}
            bg={'gray.50'}
          >
            <LinkOverlay href={`/submissions/${submission.id}`}>
              <Heading fontSize={'md'} as="h4" className="mb-2">
                {submission.challenge?.title}
              </Heading>
            </LinkOverlay>
            <HStack className="text-xs" alignContent={'center'}>
              <Text>Project</Text>
              <span>&bull;</span>
              <Text>{submission.challenge?.track}</Text>
            </HStack>
          </Flex>
        )}
        <Flex
          className="inset-x-0 bottom-0"
          pt={4}
          justifyContent="space-between"
          alignItems="center"
        >
          {submission.comments.length > 0 ? (
            <HStack>
              <ChatBubbleLeftEllipsisIcon className="h-4 w-4 text-gray-500" />
              <Text textColor={'gray.500'} fontSize="sm">
                {submission.comments.length}{' '}
                {submission.comments.length === 1 ? 'comment' : 'comments'}
              </Text>
            </HStack>
          ) : (
            <Text textColor={'gray.500'} fontSize="sm">
              Give the first feedback
            </Text>
          )}
          <Button
            size={'sm'}
            rounded={'full'}
            variant={'outline'}
            rightIcon={<ChevronRightIcon className="h-4 w-4" />}
            onClick={event => {
              event.preventDefault();
              navigate(`/submissions/${submission.id}`);
            }}
          >
            View Submission
          </Button>
        </Flex>
      </LinkBox>
    </>
  );
};

export const SubmissionItemSkeleton = () => {
  return <Skeleton></Skeleton>;
};
