import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { extendTheme, ChakraProvider } from '@chakra-ui/react';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LogSnagProvider } from '@logsnag/react';
// import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';

import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import SetNewPassword from './pages/SetNewPassword';
import EditUserProfile from './pages/EditUserProfile';
import Skills from './pages/Skills';
import Mentors from './pages/Mentors';
import Mentor from './pages/Mentor';
import AuthGate from './components/AuthGate';
import AlreadyAuthorized from './components/AlreadyAuthorized';
import NewsletterConfirmation from './pages/NewsletterSubscription';
import PageNotFound from './pages/404';
import Challenge from './pages/Challenge';
import Notifications from './pages/Notifications';
import Squad from './pages/Squad';
import ContributeProject from './pages/ContributeProject';
import CreateSquadProject from './pages/CreateSquadProject';
import JoinHackathonSquad from './pages/JoinHackathonSquad';
import SubmissionDetail from './pages/SubmissionDetail';
import Profile from './pages/Profile';
import Bookings from './pages/Bookings';
import BookingDetail from './pages/BookingDetail';
import Discussions from './pages/Discussions';
import Stories from './pages/Stories';
import StoryDetail from './pages/StoryDetail';
import DiscussionDetail from './pages/DiscussionDetail';
import Pathfinder from './pages/Pathfinder';
import Messages from './pages/Messages';
import Jobs from './pages/Jobs';
import Landing from './pages/Landing';
import MentorLanding from './pages/MentorLanding';
import Onboarding from './pages/Onboarding';
import LearnerOnboarding from './pages/LearnerOnboarding';
import AccountVerficationRequired from './pages/AccountVerificationRequired';
import MentorOnboarding from './pages/MentorOnboarding';
import MentorsDashboard from './pages/MentorsDashboard';
import Inbox from './pages/Messages';
import SkillDetail from './pages/SkillDetail';
import ProfileSwitchHandler from './pages/ProfileSwitchHandler';
import Logout from './pages/Logout';
import CheckoutSuccess from './pages/CheckoutSuccess';
import CheckoutCancelled from './pages/CheckoutCancelled';
import Projects from './pages/Projects';
import EditChallengeSubmission from './pages/EditChallengeSubmission';
import Mentorships from './pages/Mentorships';
import MentorshipDetail from './pages/MentorshipDetail';
import Employers from './pages/Employers';
import Membership from './pages/Membership';
import Bounties from './pages/Bounties';
import Badges from './pages/Badges';
import Bounty from './pages/Bounty';
import CreateBounty from './pages/CreateBounty';
import EditBounty from './pages/EditBounty';
import Workshops from './pages/Workshops';

const firebaseConfig = {
  apiKey: 'AIzaSyCe1_FYYc4Ve32SFCpO1Pv-yBHYA0a_Boo',
  authDomain: 'bloostack-dad5b.firebaseapp.com',
  projectId: 'bloostack-dad5b',
  storageBucket: 'bloostack-dad5b.appspot.com',
  messagingSenderId: '771478312138',
  appId: '1:771478312138:web:3103ff9bac927a4d319033',
  measurementId: 'G-SXZN3HVG8S',
};

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const theme = extendTheme({
  colors: {
    brand: {
      darkBlue: '#152340',
      lightBlue: '#51def5',
      orange: '#da8f67',
      gray: '#2d3748',
      darkOrange: '#2d3748',
    },
  },
  fonts: {
    heading:
      "'Avenir', Arial, Helvetica,sans-serif, 'Open Sans',Baskerville,'Times New Roman','Helvetica Neue'",
    body: '"Avenir", sans-serif',
  },
  components: {
    Steps,
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: '#152340',
        },
      },
    },
  },
});

const queryClient = new QueryClient();

const ThemedApp = () => (
  <LogSnagProvider token="b8da77d8f9b93a70b41baea82b552b68" project="bloostack">
    <GoogleOAuthProvider clientId="198094606033-5set0i1gu8iu584k96e0e9lvm373v8io.apps.googleusercontent.com">
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<Landing />} />
              <Route
                exact
                path="/login"
                element={<AlreadyAuthorized Component={Login} />}
              />
              <Route
                exact
                path="/get-started"
                element={<AlreadyAuthorized Component={Register} />}
              />
              <Route
                exact
                path="/forgot-password"
                element={<AlreadyAuthorized Component={ForgotPassword} />}
              />
              <Route exact path="/set-password" element={<SetNewPassword />} />
              <Route
                exact
                path={'/home'}
                element={<AuthGate Component={Dashboard} />}
              />
              <Route
                exact
                path={'/skills/:trackId/lessons'}
                element={<SkillDetail />}
              />
              <Route
                exact
                path={'/skills/:trackId'}
                element={<SkillDetail />}
              />
              <Route exact path={'/skills'} element={<Skills />} />
              <Route exaact path="/employers" element={<Employers />} />
              <Route exact path="/community" element={<Discussions />} />
              <Route exact path="/posts" element={<Discussions />} />
              <Route exact path="/posts/:slug" element={<DiscussionDetail />} />
              <Route exact path="/stories" element={<Stories />} />
              <Route exact path="/stories/:slug" element={<StoryDetail />} />
              <Route exact path="/projects" element={<Projects />} />
              <Route exact path="/membership" element={<Membership />} />
              <Route exact path="/jobs" element={<Jobs />} />
              <Route exact path="/workshops" element={<Workshops />} />
              <Route
                exact
                path="/projects/contribute"
                element={<AuthGate Component={ContributeProject} />}
              />
              <Route
                exact
                path="/projects/:challengeId"
                element={<Challenge />}
              />
              <Route
                exact
                path="/challenges/:challengeId/submit"
                element={<EditChallengeSubmission />}
              />
              <Route exact path={'/mentors'} element={<Mentors />} />
              <Route exact path={'/mentor/:username'} element={<Mentor />} />
              <Route
                exaact
                path="/learning-paths"
                element={<Navigate replace to="/skills" />}
              />
              <Route
                exact
                path="/settings"
                element={<AuthGate Component={EditUserProfile} />}
              />
              <Route
                exact
                path="/notifications"
                element={<AuthGate Component={Notifications} />}
              />
              <Route exact path="/bounties" element={<Bounties />} />
              <Route
                exact
                path="/amas"
                element={<AuthGate Component={Inbox} />}
              />
              <Route
                exact
                path="/bounties/create"
                element={<AuthGate Component={CreateBounty} />}
              />
              <Route exact path="/bounties/:slug" element={<Bounty />} />
              <Route
                exact
                path="/bounties/:slug/edit"
                element={<AuthGate Component={EditBounty} />}
              />
              <Route
                exact
                path="/hackathons/squad/:squadSlug"
                element={<AuthGate Component={Squad} />}
              />
              <Route
                exact
                path="/hackathons/squad/:invitationCode/join"
                element={<AuthGate Component={JoinHackathonSquad} />}
              />
              <Route
                exact
                path="/start-project"
                element={<AuthGate Component={CreateSquadProject} />}
              />
              <Route
                exact
                path="/submissions/:submissionsId"
                element={<AuthGate Component={SubmissionDetail} />}
              />
              <Route
                exact
                path="/verify_account"
                element={<AuthGate Component={AccountVerficationRequired} />}
              />
              <Route
                exact
                path="/onboarding"
                element={<AuthGate Component={Onboarding} />}
              />
              <Route
                exact
                path="/onboarding/learner"
                element={<AuthGate Component={LearnerOnboarding} />}
              />
              <Route
                exact
                path="/onboarding/mentor"
                element={<AuthGate Component={MentorOnboarding} />}
              />
              <Route
                exact
                path="/confirm_subscription"
                element={<NewsletterConfirmation />}
              />
              <Route exact path={'/profile/:username'} element={<Profile />} />
              <Route
                exact
                path="/bookings"
                element={<AuthGate Component={Bookings} />}
              />
              <Route
                exact
                path="/bookings/:hashCode"
                element={<AuthGate Component={BookingDetail} />}
              />
              <Route exact path="/quiz" element={<Pathfinder />} />
              {/* <Route exact path="/about" element={<About />} /> */}

              <Route exact path="/messages" element={<Messages />} />

              <Route exact path="/mentor" element={<MentorLanding />} />
              <Route
                exact
                path="/m/home"
                element={<AuthGate Component={MentorsDashboard} />}
              />
              <Route
                exact
                path="/mentorships"
                element={<AuthGate Component={Mentorships} />}
              />
              <Route
                exact
                path="/mentorships/:mentorshipId"
                element={<AuthGate Component={MentorshipDetail} />}
              />
              <Route
                exact
                path="/profiles/:profile/switch"
                element={<ProfileSwitchHandler />}
              />
              <Route exact path="/logout" element={<Logout />} />
              {/* Checkout */}
              <Route
                exact
                path="/checkout/success"
                element={<AuthGate Component={CheckoutSuccess} />}
              />
              <Route
                exact
                path="/checkout/cancelled"
                element={<AuthGate Component={CheckoutCancelled} />}
              />

              <Route exact path="/badges" element={<Badges />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        </ChakraProvider>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  </LogSnagProvider>
);

export default ThemedApp;
