import React from 'react';
import { Button } from '@chakra-ui/react';

export default function PrimaryButtonFilter({
  children,
  onClick,
  isActive,
  value,
}) {
  return (
    <Button
      _focus={{ outline: 'none' }}
      name="track"
      size={'sm'}
      value={value}
      variant={isActive ? 'solid' : 'outline'}
      colorScheme="gray"
      bgColor={isActive ? 'orange.500' : 'white'}
      rounded="lg"
      textColor={isActive ? 'white' : 'gray.600'}
      onClick={() => onClick('track', value)}
      _hover={{
        variant: 'solid',
        shadow: 'sm',
        bgColor: isActive ? 'orange.400' : 'gray.100',
      }}
    >
      {children}
    </Button>
  );
}
