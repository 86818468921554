import React from 'react';

import {
  Avatar,
  Flex,
  Box,
  Text,
  HStack,
  LinkOverlay,
  LinkBox,
  Button,
} from '@chakra-ui/react';
import {
  ChatBubbleBottomCenterIcon,
  EyeIcon,
  HandThumbUpIcon,
} from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/24/solid';
import { ExpandableText } from '../ExpandableText';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const DiscussionItem = ({ discussion, upVoteFn }) => {
  const { comments, slug, upvotes, views } = discussion;
  const navigate = useNavigate();

  return (
    <>
      <LinkBox
        p={['10px', '20px']}
        color="#718096"
        className="shadow-lg shadow-black/5 ring-1 ring-slate-700/10 hover:ring-orange-300"
        rounded="lg"
        flexDirection={'column'}
      >
        <Flex
          justify={'flex-start'}
          alignItems={'center'}
          pr="10px"
          pb={4}
          fontSize="15px"
        >
          <Box pr={2}>
            <Avatar
              src={discussion.author.image}
              name={discussion.author.fullname}
            />
          </Box>
          <div className="flex flex-col">
            <HStack spacing={1}>
              <Text fontSize="md" className="text-gray-600 font-semibold pr-2">
                {discussion.author.fullname}{' '}
              </Text>
              <div className="items-center text-xs inline-flex font-bold leading-sm px-2 py-0 rounded-full bg-white text-gray-700 border">
                <StarIcon className="h-3 w-3 mr-1 text-orange-400" />
                <Text fontSize="xs" className="text-gray-600 font-semibold">
                  {discussion.author.total_points}
                </Text>
              </div>
            </HStack>

            <p className="text-xs">
              Posted in{' '}
              <span className="text-orange-500">{discussion.guild.name}</span>{' '}
              {moment(discussion.created_at).fromNow()}
            </p>
          </div>
        </Flex>
        <Flex
          w="100%"
          justifyContent={['flex-start', 'space-between']}
          flexDirection={'column'}
          pt={2}
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Box
              justifyContent={['center', 'center', 'center', 'flex-start']}
              flexDirection="column"
            >
              <LinkOverlay href={`/posts/${slug}`}>
                <Text
                  fontWeight="semibold"
                  color="gray.700"
                  pb={2}
                  fontSize="lg"
                >
                  {discussion.title}
                </Text>
              </LinkOverlay>
              <ExpandableText limit={3}>{discussion.body}</ExpandableText>
            </Box>
          </Flex>
          <HStack mt={4} spacing={2}>
            <Button
              leftIcon={
                <HandThumbUpIcon
                  className="h-4 w-4 text-gray-500"
                  aria-label="Upvote"
                />
              }
              variant={'ghost'}
              fontWeight={'normal'}
              className="focus:outline-none focus:ring-2 focus:ring-orange-300 focus:ring-offset-2 focus:ring-offset-slate-700"
              size={'sm'}
              _hover={{ bg: 'gray.50' }}
              _focus={{ outline: 'none' }}
              onClick={() => upVoteFn(discussion.slug)}
              rounded={'full'}
            >
              {upvotes} upvotes
            </Button>
            <Button
              leftIcon={
                <EyeIcon
                  className="h-4 w-4 text-gray-500"
                  aria-label="Upvote"
                />
              }
              size={'sm'}
              variant={'ghost'}
              fontWeight={'normal'}
              _hover={{ bg: 'gray.50' }}
              onClick={() => navigate(`/posts/${slug}`)}
              rounded={'full'}
            >
              {views} views{' '}
            </Button>
            <Button
              leftIcon={
                <ChatBubbleBottomCenterIcon
                  className="h-4 w-4 text-gray-500"
                  aria-label="Upvote"
                />
              }
              size={'sm'}
              variant={'ghost'}
              fontWeight={'normal'}
              _hover={{ bg: 'gray.50' }}
              onClick={() => navigate(`/posts/${slug}`)}
              rounded={'full'}
            >
              {comments.length} {comments.length == 1 ? 'comment' : 'comments'}
            </Button>
          </HStack>
        </Flex>
      </LinkBox>
    </>
  );
};

export default DiscussionItem;
