import React from 'react';
import { Flex, Text, LinkBox, LinkOverlay, Link } from '@chakra-ui/react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

const AvailableTracks = ({ item }) => {
  const { title, description, slug, image } = item;

  return (
    <LinkBox
      mb="24px"
      flexDirection={'column'}
      w="100%"
      p="24px 32px 32px"
      shadow="sm"
      border={'1px solid #e2e8f0'}
      justifyContent="space-between"
      _hover={{ shadow: 'lg' }}
      rounded="lg"
      cursor="pointer"
    >
      <div>
        <div style={{ marginBottom: '24px' }}>
          <img
            src={image}
            width="64px"
            height={'64px'}
            alt="android-development"
          />
        </div>
        <Flex flexDirection={'column'}>
          <LinkOverlay href={`/skills/${slug}`}>
            <Text
              fontSize="lg"
              fontWeight="bold"
              mb="12px"
              lineHeight="32px"
              letterSpacing="0.01em"
              textColor={'gray.700'}
            >
              {' '}
              {title}{' '}
            </Text>
          </LinkOverlay>
          <Text
            fontSize="md"
            fontWeight="normal"
            color="gray.600"
            noOfLines={3}
          >
            {description}
          </Text>
        </Flex>
      </div>

      <Flex alignItems={'flex-end'} mt="24px">
        <a
          className="inline-flex justify-center rounded-full text-sm font-semibold py-2 px-4 bg-slate-800 text-white hover:bg-slate-600 items-center transition duration-150 ease-in-out ring-inset ring-0 ring-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-800"
          href={`/skills/${slug}`}
        >
          Learn more{' '}
          <ChevronRightIcon
            aria-hidden="true"
            className="hidden text-slate-200 w-4 h-4 sm:inline justify-center align-middle ml-2"
          />
        </a>
      </Flex>
    </LinkBox>
  );
};

export default AvailableTracks;
