import React, { useState, useEffect } from 'react';
import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import MentorGrid from '../components/MentorGrid.js';
import MentorService from '../services/mentor-service';
import { Flex, useToast } from '@chakra-ui/react';
import SEO from '../components/Common/SEO.js';
import { Mixpanel } from '../utils/Mixpanel.js';
import withRouter from '../utils/withRouter.js';
import splitbee from '@splitbee/web';

const Mentors = () => {
  Mixpanel.track('Viewed Mentors Page');
  splitbee.track('Viewed Mentors Page');
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [mentorsList, setMentorsList] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    async function getListOfMentors() {
      try {
        setIsLoading(true);
        const response = await MentorService.getMentorList();
        setMentorsList(response.data.results);
      } catch (err) {
        toast({
          title: 'Oops!',
          description: 'Unable to fetch coaches, please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    }
    getListOfMentors();
  }, [toast]);

  const handleFilterByRole = event => {
    console.log(event.target.value);
    event.preventDefault();
    const role = event.target.value;
    if (role !== '') {
      const filteredMentors = mentorsList.filter(item =>
        item.current_role.contains(role)
      );
      setMentorsList(filteredMentors);
    } else {
      setMentorsList(mentorsList);
    }
  };

  return (
    <>
      <SEO
        title="Find a mentor. Learn from industry leaders | Techstarta"
        url="https://techstarta.com/mentors"
        description={`Find a mentor. Learn from industry leaders.`}
      />
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar
          title="Mentors"
          url={'https://techstarta.com/mentors'}
          hideBecomeAMentorButton={true}
        />
        {/* Header */}
        <div className="px-4 md:px-10 mx-auto w-full">
          <div className="flex flex-wrap mt-4">
            <div className="w-full  mb-12 xl:mb-0 ">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 px-4 border-0">
                  <Flex
                    w="auto"
                    mt={['50px', '50px', '50px', '0px']}
                    justifyContent={[
                      'center',
                      'center',
                      'center',
                      'space-between',
                    ]}
                    flexDirection={['column', 'column', 'column', 'row']}
                    mb="10px"
                  >
                    <Flex
                      justifyContent="center"
                      alignContent="center"
                      color="gray.700"
                      flexDirection="column"
                    >
                      <h1 className="text-3xl md:text-4xl text-gray-800 font-bold leading-tight tracking-tight text-left mb-4">
                        Industry experts. Not influencers.
                      </h1>
                      <h2 className="md:w-8/12 w-full text-gray-700 font-medium text-left mb-4 text-xl">
                        Learn directly from experienced industry experts who are
                        doing the job you want to do — not just talking about it
                      </h2>
                    </Flex>
                  </Flex>
                  <MentorGrid mentorsList={mentorsList} isLoading={isLoading} />
                </div>
              </div>
            </div>
          </div>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(Mentors);
