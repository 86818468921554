import React from 'react';
import { Flex, Text, Avatar, Skeleton, Td, Tr, Stack } from '@chakra-ui/react';
import { WinnerTrophy } from '@vectopus/atlas-icons-react';

export const TrackLeaderboardItem = ({ member, position }) => {
  const getRank = position => {
    if (position === 1 && member.total_earned_points) {
      return (
        <div className="flex items-center">
          <WinnerTrophy className="w-5 h-5 text-yellow-600" />
        </div>
      );
    }

    if (position === 2 && member.total_earned_points) {
      return (
        <div className="flex items-center">
          <WinnerTrophy className="w-5 h-5 text-gray-600" />
        </div>
      );
    }

    if (position === 3 && member.total_earned_points) {
      return (
        <div className="flex items-center">
          <WinnerTrophy className="w-5 h-5 text-yellow-800" />
        </div>
      );
    }

    return (
      <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
        Unranked
      </span>
    );
  };

  return (
    <Tr className="hover:bg-gray-50" height={'2'}>
      <Td>{getRank(position)}</Td>
      <Td>
        <Flex alignItems="center">
          <Avatar
            mr="10px"
            size={'sm'}
            name={`${member.profile.fullname.toUpperCase()} `}
            src={member.profile.image}
          />
          <Stack color="#2d3748" spacing={0}>
            <strong className="capitalize text-sm">
              {`${member.profile.fullname} `}{' '}
            </strong>
            <Text as={'span'} fontSize="sm" color="#718096">
              <a
                className="hover:underline"
                href={`/profile/${member.profile.username}`}
              >
                {member.profile.username}
              </a>
            </Text>
          </Stack>
        </Flex>
      </Td>
      <Td>
        <Flex color="#718096" textAlign="center">
          <Text
            className="font-semibold text-sm text-gray-700"
            textColor={'brand.darkBlue'}
          >
            {member.total_earned_points}P
          </Text>
        </Flex>
      </Td>
    </Tr>
  );
};

export const TrackLeaderboardItemSkeleton = ({ user, position }) => {
  return (
    <>
      <Tr>
        <Td>
          <Skeleton w="30%" h="20px"></Skeleton>
        </Td>
        <Td>
          <Flex alignItems="center" w="100%">
            <Skeleton
              minWidth="48px"
              minHeight="48px"
              w="48px"
              h="48px"
              rounded="full"
            ></Skeleton>
            <Skeleton w="100%" h="20px" ml="10px"></Skeleton>
          </Flex>
        </Td>
        <Td>
          <Skeleton w="100%" h="20px"></Skeleton>
        </Td>
      </Tr>
    </>
  );
};
