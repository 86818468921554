import React from 'react';
import moment from 'moment';
import { Avatar, Box, Button, Flex, HStack, Text } from '@chakra-ui/react';
import MarkdownToHtml from '../ReactMarkdownItem';
import { HandThumbUpIcon } from '@heroicons/react/24/outline';

const CommentBox = ({ comment, upvoteFn }) => {
  return (
    <>
      <Flex p={['10px', '10px', '10px', '20px']}>
        <Flex>
          <Avatar
            size="sm"
            name={comment.author.fullname}
            src={comment.author.image}
          />
          <div>
            <Box
              className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10"
              marginX="10px"
              padding="10px"
              rounded="lg"
              width={['90%', '95%', '95%', '100%']}
            >
              <div className="font-semibold text-sm leading-relaxed">
                {comment.author.fullname}
              </div>
              <div className="text-base leading-snug md:leading-normal pt-5">
                <MarkdownToHtml input={comment.text} />
              </div>
            </Box>
            <HStack mx="10px" my="1">
              <Button
                onClick={event => {
                  event.preventDefault();
                  upvoteFn(comment.id);
                }}
                leftIcon={<HandThumbUpIcon className="w-4 h-4" />}
                size="xs"
                variant="ghost"
              >
                Upvote ({comment.num_vote_up})
              </Button>
              <Text paddingX="10px" fontSize="xs" color="gray.600">
                {moment(comment.created_at).fromNow()}
              </Text>
            </HStack>
          </div>
        </Flex>
      </Flex>
    </>
  );
};

export default CommentBox;
