export const convertMsToTime = (prevTime, currentTime) => {
    const timeResult = Math.floor(currentTime - prevTime)
    const seconds = (timeResult / 1000).toFixed(0);
    const minutes = (timeResult / (1000 * 60)).toFixed(0);
    const hours = (timeResult / (1000 * 60 * 60)).toFixed(0);
    const days = (timeResult / (1000 * 60 * 60 * 24)).toFixed(0);

    if (seconds < 60) {
        if (seconds === "1") {
            return `${seconds  } sec ago`
        }
        return `${seconds  } secs ago`
    }
    if (minutes < 60) {
        if (minutes === "1") {
            return `${minutes  } min ago`
        }
        return `${minutes  } mins ago`
    }
    if (hours < 24) {
        if (hours === "1") {
            return `${hours  } hr ago`
        }
        return `${hours  } hrs ago`
    }
    if (hours >= 24) {
        if (days === "1") {
            return `${days  } day ago`
        }
        return `${days  } days ago`
    }
}
