import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  useToast,
  Button,
  Skeleton,
  Text,
  HStack,
  Flex,
  Link,
  Avatar,
  ListItem,
  UnorderedList,
  Image,
  Accordion,
  AccordionItem,
  Box,
  AccordionIcon,
  AccordionPanel,
  AccordionButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  SimpleGrid,
  LinkBox,
  Heading,
  LinkOverlay,
  Divider,
  AvatarGroup,
  VStack,
} from '@chakra-ui/react';
import splitbee from '@splitbee/web';
import TrackService from '../services/track-service';
import AuthService from '../services/auth-service';
import ProfileService from '../services/profile-service';
import { Mixpanel } from '../utils/Mixpanel';
import withRouter from '../utils/withRouter.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import {
  BookOpenIcon,
  BookmarkSquareIcon,
  BriefcaseIcon,
  ChatBubbleLeftRightIcon,
  CheckBadgeIcon,
  ChevronRightIcon,
  ClockIcon,
  PuzzlePieceIcon,
  Square3Stack3DIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';

import MarkdownToHtml from '../components/ReactMarkdownItem';
import Resources from '../components/Track/Resources';
import {
  ChallengeCard,
  ChallengeCardSkeleton,
} from '../components/ChallengeCard';
import submissionsService from '../services/submissions-service';
import { ExpandableText } from '../components/ExpandableText';
import {
  ArrowUpChart,
  InfoCircle,
  Library,
  MedalAchievement,
  WeightsGym,
  WinningPlacesPeople,
} from '@vectopus/atlas-icons-react';
import TrackLabel from '../components/Track/TrackLabel';
import SubmissionStatus from '../components/Submission/SubmissionStatus';
import { TrackLeaderboard } from '../components/Leaderboard/TrackLeaderBoard';
import authService from '../services/auth-service';
import profileService from '../services/profile-service';
import { BadgesGrid } from '../components/Achievements/BadgesGrid';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar';
import MentorGrid from '../components/MentorGrid.js';

const SkillDetail = () => {
  splitbee.track('Skill Detail Page');
  Mixpanel.track('Skill Detail Page');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { trackId: slug } = useParams();
  const [currentTrack, setCurrentTrack] = useState('');
  const [hasJoinedTrack, setHasJoinedTrack] = useState(null);
  const [title, setTitle] = useState(
    'Learn in-demand tech skills | Techstarta'
  );
  const [profile, setProfile] = useState('');
  const user = JSON.parse(AuthService.getCurrentUser());
  const username = user ? user.username : null;
  const profileSlug = profile && profile.tracks[0]?.slug;

  const [projects, setProjects] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [trackLeaderboard, setTrackLeaderboard] = useState([]);
  const [badges, setBadges] = useState([]);

  useEffect(() => {
    async function getCurrentTrack() {
      try {
        const response = await TrackService.getTrack(slug);
        setCurrentTrack(response.track);
        setHasJoinedTrack(response.track.hasJoined);
        setTitle(
          `Learning Guides & Projects for ${response.track.title} | Techstarta`
        );
        Mixpanel.track('Viewed track', { track: response.track.title });
      } catch (err) {
        Mixpanel.track('Failed View track');
        toast({
          title: 'Oops!',
          description: 'Unable to fetch track. Please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
    getCurrentTrack();
  }, [slug, toast]);

  useEffect(() => {
    async function fetchProfile() {
      if (username) {
        const response = await ProfileService.getProfile(username);
        setProfile(response.data.profile);
      }
    }
    fetchProfile();
  }, [username]);

  useEffect(() => {
    async function fetchProjects() {
      try {
        setIsLoading(true);
        const response = await TrackService.fetchAllChallenges(slug);
        setProjects(response.challenges);
      } catch (err) {
        toast({
          title: 'Something went wrong!',
          description: 'Unable to show projects, please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    }
    fetchProjects();
  }, [toast]);

  useEffect(() => {
    async function fetchSubmissions() {
      if (username) {
        const response = await submissionsService.listSubmissions(username);
        if (response.status === 200) {
          setSubmissions(
            response.data.submissions.filter(
              submission => submission.challenge.track_slug === slug
            )
          );
        }
      }
    }
    fetchSubmissions();
  }, [username]);

  useEffect(() => {
    async function fetchBadges() {
      if (username) {
        const response = await profileService.getProfileBadges(username);
        if (response.status === 200) {
          setBadges(response.data.badges);
        }
      }
    }
    fetchBadges();
  }, [username]);

  const joinTrack = async event => {
    event.preventDefault();
    try {
      const username = user.username;
      const response = await TrackService.joinTrack(slug, username);
      if (response === 200) {
        toast({
          title: 'Bravo!',
          description: "You're on a journey to success!",
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setHasJoinedTrack(true);
        await AuthService.fetchCurrentUser();
        navigate(`/skills/${slug}`);
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        toast({
          title: 'Session expired!',
          description: 'You need login again to view this page',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        navigate('/login');
      } else {
        toast({
          title: 'Login required',
          description: 'You need to login to save your progress',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        navigate('/login');
      }
    }
  };

  const leaveTrack = async event => {
    event.preventDefault();
    setIsAlertDialogOpen(false);
    try {
      const user = JSON.parse(AuthService.getCurrentUser());
      const username = user.username;
      const response = await TrackService.deleteTrack(profileSlug, username);
      if (response.status === 200) {
        toast({
          title: 'Done.',
          description: 'You joined this track',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        setHasJoinedTrack(false);
        await AuthService.fetchCurrentUser();
        navigate(`/skills/${slug}`);
      }
    } catch (err) {
      if (err.status === 403) {
        toast({
          title: 'Login required',
          description: 'Please login and then try again',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Something went wrong.',
          description:
            'Unable to remove you from this track, please try again later ',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleTabsChange = async index => {
    setTabIndex(index);
    if (index === 4 && trackLeaderboard.length === 0) {
      await handleFetchLeaderboard();
    }
  };

  const handleFetchLeaderboard = async () => {
    try {
      const response = await TrackService.getTrackLeaderboard(slug);
      setTrackLeaderboard(response.ranking.results);
    } catch (err) {
      toast({
        title: 'Something went wrong!',
        description: 'Unable to fetch leaderboard, please try again later',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <LayoutWithSidebar title={title} pageTitle={'Career Paths'}>
        <div className="mx-auto w-full">
          <div className="flex flex-wrap px-2">
            <div className="relative w-full">
              <Button
                size={'sm'}
                className="mt-4 md:mt-0"
                rounded={'lg'}
                variant="outline"
                colorScheme={'gray'}
                leftIcon={
                  <FontAwesomeIcon
                    icon={faArrowLeftLong}
                    style={{ marginRight: '4px' }}
                  />
                }
                marginBottom="12px"
                cursor={'pointer'}
                _active={{ outlineColor: 'gray.500' }}
                _focus={{ outlineColor: 'gray.500' }}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <div>
                <Tabs
                  isLazy
                  pt={4}
                  variant={'line'}
                  colorScheme="orange"
                  index={tabIndex}
                  onChange={handleTabsChange}
                >
                  <Skeleton isLoaded={currentTrack ? true : false}>
                    <Flex
                      flexDirection={['column', 'column', 'column', 'row']}
                      justifyContent="space-between"
                    >
                      <Flex flexDirection={'column'} width={'100%'}>
                        <Image
                          h={'16'}
                          w={'16'}
                          className="hidden md:block"
                          src={currentTrack.image}
                          alt={currentTrack.title}
                        />
                        <Flex>
                          <h1 className="capitalize text-2xl text-gray-700 font-bold mt-2 pb-2">
                            {currentTrack.title}
                          </h1>
                        </Flex>
                        <Text
                          fontSize="md"
                          lineHeight="6"
                          textColor={'gray.600'}
                          fontWeight="450"
                        >
                          {currentTrack.description}
                        </Text>

                        {currentTrack?.mentors?.length > 0 && (
                          <Flex
                            py={4}
                            flexDirection={'column'}
                            justifyContent={'flex-end'}
                            className="hidden md:block"
                          >
                            <AvatarGroup size={'sm'} max={5}>
                              {currentTrack.mentors.map((mentor, index) => (
                                <Avatar
                                  key={index}
                                  name={
                                    mentor.firstname + ' ' + mentor.lastname
                                  }
                                  src={authService.buildImageUrl(mentor.image)}
                                  textColor={'white'}
                                  bg="brand.darkBlue"
                                  borderColor="white"
                                  shadow="md"
                                  _hover={{ shadow: 'lg' }}
                                />
                              ))}
                            </AvatarGroup>
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              color="gray.600"
                              mt={2}
                            >
                              {currentTrack.mentors.length} Expert Mentors{' '}
                              <span className="px-2">&bull;</span>{' '}
                              {currentTrack.learners_count} Learners
                            </Text>
                          </Flex>
                        )}

                        {!hasJoinedTrack && (
                          <div className="flex w-full mt-5">
                            <Button
                              shadow="md"
                              _hover={{ shadow: 'lg' }}
                              _focus={{ outline: 'none' }}
                              bg="brand.darkBlue"
                              rounded={'full'}
                              textColor={'white'}
                              onClick={event => joinTrack(event)}
                              rightIcon={<UserPlusIcon className="w-4 h-4" />}
                            >
                              Join this track
                            </Button>
                          </div>
                        )}
                      </Flex>
                      <Flex
                        className="shadow-lg shadow-black/5 ring-1 ring-slate-700/10 rounded-lg p-6 text-gray-800"
                        width={['100%', '100%', '100%', '40%']}
                      >
                        {currentTrack && (
                          <VStack alignItems={'flex-start'}>
                            <Text
                              fontSize={'md'}
                              pb={4}
                              fontWeight={'semibold'}
                            >
                              What's included in this path?{' '}
                            </Text>
                            <HStack>
                              <Square3Stack3DIcon className="w-5 h-5 mr-1 text-orange-600" />
                              <p className="text-md text-gray-700 flex items-center mt-2 md:mt-0">
                                Handpicked resources
                              </p>
                            </HStack>
                            <HStack>
                              <PuzzlePieceIcon className="w-5 h-5 mr-1 text-orange-600" />
                              <p className="text-md text-gray-700 flex items-center mt-2 md:mt-0 mr-4">
                                Build real-world projects
                              </p>
                            </HStack>
                            <HStack>
                              <ClockIcon className="w-5 h-5 mr-1 text-orange-600" />
                              <p className="text-md text-gray-700 flex items-center mt-2 md:mt-0">
                                Learn at your own pace
                              </p>
                            </HStack>
                            <HStack>
                              <CheckBadgeIcon className="w-5 h-5 mr-1 text-orange-600" />
                              <p className="text-md text-gray-700 flex items-center mt-2 md:mt-0">
                                Earn badges & recognition
                              </p>
                            </HStack>
                            <HStack>
                              <ChatBubbleLeftRightIcon className="w-5 h-5 mr-1 text-orange-600" />
                              <p className="text-md text-gray-700 flex items-center mt-2 md:mt-0">
                                Get mentored by experts
                              </p>
                            </HStack>
                          </VStack>
                        )}
                      </Flex>
                    </Flex>
                    <Flex overflow={'scroll'} className="w-full">
                      <TabList pt={4} width={'100%'}>
                        <Tab
                          _focus={{ outline: 'none' }}
                          _selected={{
                            fontWeight: 'semibold',
                            borderBottomColor: 'orange.500',
                          }}
                        >
                          <HStack>
                            <BookOpenIcon className="h-4 w-4 text-orange-500" />
                            <Text className="text-[0.95rem]">Overview</Text>
                          </HStack>
                        </Tab>
                        {currentTrack?.hasJoined && (
                          <Tab
                            _focus={{ outline: 'none' }}
                            _selected={{
                              fontWeight: 'semibold',
                              borderBottomColor: 'orange.500',
                            }}
                          >
                            <HStack>
                              <ArrowUpChart className="h-4 w-4 text-orange-500" />
                              <Text className="text-[0.95rem]">Progress</Text>
                            </HStack>
                          </Tab>
                        )}
                        <Tab
                          _focus={{ outline: 'none' }}
                          _selected={{
                            fontWeight: 'semibold',
                            borderBottomColor: 'orange.500',
                          }}
                        >
                          <HStack>
                            <WeightsGym className="h-4 w-4 text-orange-500" />
                            <Text className="text-[0.95rem]">Projects</Text>
                          </HStack>
                        </Tab>
                        <Tab
                          _focus={{ outline: 'none' }}
                          _selected={{
                            fontWeight: 'semibold',
                            borderBottomColor: 'orange.500',
                          }}
                        >
                          <HStack>
                            <Library className="h-4 w-4 text-orange-500" />
                            <Text className="text-[0.95rem]">Library</Text>
                          </HStack>
                        </Tab>
                        <Tab
                          _focus={{ outline: 'none' }}
                          _selected={{
                            fontWeight: 'semibold',
                            borderBottomColor: 'orange.500',
                          }}
                          isDisabled={!currentTrack?.hasJoined}
                          id="leaderboard"
                        >
                          <HStack>
                            <WinningPlacesPeople className="h-4 w-4 text-orange-500" />
                            <Text className="text-[0.95rem]">Leaderboard</Text>
                          </HStack>
                        </Tab>
                      </TabList>
                    </Flex>
                  </Skeleton>

                  <TabPanels>
                    <TabPanel px="-4">
                      <div className="flex flex-wrap">
                        {currentTrack.about && (
                          <div className="w-full md:w-8/12 md:pr-6 mt-6">
                            <Flex flexDirection={'column'}>
                              <Text
                                fontSize={'2xl'}
                                className="text-md text-gray-800 font-bold"
                                mb="16px"
                              >
                                What does {currentTrack.title} entail?
                              </Text>
                              <ExpandableText limit={10}>
                                {currentTrack.about}
                              </ExpandableText>
                            </Flex>
                          </div>
                        )}
                        {currentTrack && currentTrack.tools.length > 0 && (
                          <div className="md:w-4/12 sm:pb-16 sm:mt-6">
                            <Flex
                              flexDirection={'column'}
                              rounded={'lg'}
                              width="100%"
                              border={'1px'}
                              borderColor="gray.100"
                              p={4}
                            >
                              <Text
                                fontSize={'lg'}
                                className="text-md text-gray-800 font-bold"
                                mb="16px"
                              >
                                {currentTrack.title} tools
                              </Text>
                              <UnorderedList>
                                {currentTrack.tools.map(tool => (
                                  <ListItem
                                    key={tool.name}
                                    alignItems="center"
                                    mb="10px"
                                  >
                                    <Link
                                      fontSize="sm"
                                      className="text-orange-600 hover:underline"
                                      textDecoration={'underline'}
                                      target="blank"
                                      href={tool.link}
                                      isExternal
                                    >
                                      {tool.name}
                                    </Link>
                                  </ListItem>
                                ))}
                              </UnorderedList>
                            </Flex>
                          </div>
                        )}
                      </div>
                      {currentTrack && currentTrack.mentors.length > 0 && (
                        <Flex
                          flexDirection={'column'}
                          rounded={'lg'}
                          width="100%"
                          mt={8}
                        >
                          <h2 className="text-2xl text-gray-700 font-bold">
                            Get hands-on mentorship on {currentTrack.title}
                          </h2>
                          <p className="text-lg text-gray-600">
                            Learn from vetted experts who have worked at top
                            tech companies.
                          </p>
                          {currentTrack?.mentors?.length > 0 && (
                            <MentorGrid
                              isLoading={isLoading}
                              columns={3}
                              mentorsList={currentTrack.mentors}
                            />
                          )}
                        </Flex>
                      )}
                    </TabPanel>
                    {/* Progress Tab */}
                    {currentTrack?.hasJoined && (
                      <TabPanel minHeight={'52'} px="-4">
                        <div className="flex flex-wrap">
                          <div className="w-full">
                            {user && submissions.length > 0 && (
                              <Skeleton isLoaded={!isLoading} rounded={'lg'}>
                                <Flex pt={6} pb={12} flexDirection={'column'}>
                                  <HStack pb={6}>
                                    <WeightsGym className="h-6 w-6 text-gray-600" />
                                    <Heading
                                      color="#2d3748"
                                      size="md"
                                      justifyContent="space-between"
                                    >
                                      My Projects
                                    </Heading>
                                  </HStack>

                                  {submissions.length > 0 && (
                                    <SimpleGrid
                                      spacing={4}
                                      columns={[1, 2, 2, 2]}
                                    >
                                      {submissions.map(submission => (
                                        <LinkBox
                                          w="100%"
                                          key={submission.id}
                                          justifyContent={'space-between'}
                                          alignItems="center"
                                        >
                                          <Flex
                                            key={submission.id}
                                            justifyContent={'space-between'}
                                            alignItems="center"
                                            p="10px"
                                            mt="10px"
                                            rounded="lg"
                                            cursor={'pointer'}
                                            border={'1px'}
                                            borderColor={'gray.200'}
                                            _hover={{
                                              shadow: 'md',
                                            }}
                                          >
                                            <Flex alignItems="center">
                                              <Image
                                                src={submission.challenge.image}
                                                alt={submission.challenge.title}
                                                w="50px"
                                                h="50px"
                                                objectFit={'cover'}
                                                rounded="lg"
                                              />
                                              <Flex
                                                flexDirection="column"
                                                ml="10px"
                                                justifyContent="center"
                                              >
                                                <LinkOverlay
                                                  href={`/projects/${submission.challenge.slug}`}
                                                >
                                                  <Text
                                                    fontSize="sm"
                                                    fontWeight="bold"
                                                    color="#2d3748"
                                                  >
                                                    {submission.challenge.title}
                                                  </Text>
                                                </LinkOverlay>
                                                <HStack spacing={2} py={2}>
                                                  <TrackLabel
                                                    track={
                                                      submission.challenge.track
                                                    }
                                                  />
                                                  <SubmissionStatus
                                                    status={submission.status}
                                                  />
                                                </HStack>
                                              </Flex>
                                            </Flex>

                                            <Flex alignItems={'center'}>
                                              <ChevronRightIcon className="h-6 w-6 text-gray-600" />
                                            </Flex>
                                          </Flex>
                                        </LinkBox>
                                      ))}
                                    </SimpleGrid>
                                  )}
                                </Flex>
                              </Skeleton>
                            )}
                            <Skeleton isLoaded={!isLoading} rounded={'lg'}>
                              {submissions.length === 0 && (
                                <Flex
                                  alignItems="center"
                                  flexDirection="column"
                                >
                                  <Box py={6}>
                                    <WeightsGym
                                      className="h-12 w-12 text-gray-600"
                                      aria-hidden="true"
                                    />
                                  </Box>
                                  <Text
                                    fontSize="md"
                                    fontWeight="bold"
                                    color="#2d3748"
                                  >
                                    It's time to do something awesome!
                                  </Text>
                                  <Box py={6}>
                                    <Button
                                      bgColor={'brand.darkBlue'}
                                      rounded={'full'}
                                      _hover={{ shadow: 'md' }}
                                      textColor={'white'}
                                      onClick={() => {
                                        setTabIndex(2);
                                      }}
                                    >
                                      Explore Projects
                                    </Button>
                                  </Box>
                                </Flex>
                              )}
                            </Skeleton>
                            {user && badges.length > 0 && (
                              <Skeleton isLoaded={!isLoading} rounded={'lg'}>
                                <Flex pt={6} pb={12} flexDirection={'column'}>
                                  <HStack pb={6}>
                                    <MedalAchievement className="h-6 w-6 text-gray-600" />
                                    <Heading
                                      color="#2d3748"
                                      size="md"
                                      justifyContent="space-between"
                                    >
                                      My Achievements
                                    </Heading>
                                  </HStack>

                                  {badges.length > 0 && (
                                    <BadgesGrid badges={badges} />
                                  )}
                                </Flex>
                              </Skeleton>
                            )}
                          </div>
                          <div className="w-full md:w-4/12"></div>
                        </div>
                      </TabPanel>
                    )}

                    {/* Projects Tab */}
                    <TabPanel px="-4">
                      <Box py={6}>
                        <HStack>
                          <BriefcaseIcon className="w-6 h-6 text-gray-600" />
                          <Text
                            as={'h2'}
                            textColor="gray.700"
                            fontWeight="semibold"
                            fontSize="2xl"
                          >
                            Hands-on projects to improve your{' '}
                            {currentTrack.title} skills
                          </Text>
                        </HStack>
                        <Text pt={2} fontSize={'md'} textColor={'gray.700'}>
                          Build your portfolio with real-world projects curated
                          by our{' '}
                          <a
                            className="text-orange-600 hover:underline"
                            href="/mentors"
                          >
                            our expert mentors
                          </a>
                          .
                        </Text>
                      </Box>
                      {isLoading && (
                        <SimpleGrid
                          mt="25px"
                          columns={[1, 2, 2, 3]}
                          justifyContent="space-evenly"
                          spacing="8"
                          mb="20px"
                          w="100%"
                        >
                          <>
                            <ChallengeCardSkeleton />
                            <ChallengeCardSkeleton />
                            <ChallengeCardSkeleton />
                          </>
                        </SimpleGrid>
                      )}
                      <SimpleGrid
                        mt="4"
                        columns={[1, 2, 2, 3]}
                        justifyContent="space-evenly"
                        spacing="8"
                        mb="20px"
                        w="100%"
                      >
                        {projects.map((challenge, index) => (
                          <ChallengeCard
                            challengeDetails={challenge}
                            key={index}
                          />
                        ))}
                      </SimpleGrid>
                      {projects.length === 0 && !isLoading && (
                        <Flex
                          alignItems="center"
                          flexDirection="column"
                          mt="20px"
                        >
                          <Box py={6}>
                            <BriefcaseIcon
                              className="h-12 w-12 text-gray-600"
                              aria-hidden="true"
                            />
                          </Box>
                          <Text fontSize="md" fontWeight="bold" color="#2d3748">
                            Projects are coming soon!
                          </Text>
                        </Flex>
                      )}
                    </TabPanel>

                    {/* Learning Guides Tab */}
                    <TabPanel px="-4">
                      <div className="rounded-lg border border-gray-100 mt-6">
                        <Box p={4}>
                          <HStack>
                            <BookmarkSquareIcon className="w-6 h-6 text-gray-600" />
                            <Text
                              textColor="gray.700"
                              fontWeight="semibold"
                              fontSize="xl"
                            >
                              Top Courses & Resources for {currentTrack.title}
                            </Text>
                          </HStack>
                          <Text fontSize={'md'} textColor={'gray.700'}>
                            Explore these curated collections of high-quality
                            learning resources curated by{' '}
                            <a
                              className="text-orange-600 hover:underline"
                              href="/mentors"
                            >
                              our expert mentors
                            </a>
                            .
                          </Text>
                        </Box>
                        <Skeleton
                          isLoaded={currentTrack ? true : false}
                          rounded={'lg'}
                        >
                          <Box border={1} borderColor="gray.100">
                            <Accordion
                              border={1}
                              borderColor="gray.100"
                              mt="10px"
                              _focus={{ outline: 'none' }}
                              rounded="lg"
                              defaultIndex={[0]}
                              allowToggle
                            >
                              {currentTrack &&
                                currentTrack.lessons.map((lesson, index) => (
                                  <AccordionItem key={index}>
                                    <h2>
                                      <AccordionButton
                                        height={'12'}
                                        w="100%"
                                        _expanded={{
                                          background: 'brand.darkBlue',
                                          textColor: 'white',
                                        }}
                                        textColor={'gray.700'}
                                        justifyItems={'space-between'}
                                        justifyContent={'space-between'}
                                        _focus={{ outline: 'none' }}
                                      >
                                        <Flex
                                          alignItems={'flex-start'}
                                          flexDirection={'column'}
                                          width="75%"
                                        >
                                          <h3 className="font-semibold">
                                            {lesson.title}
                                          </h3>
                                        </Flex>
                                        <AccordionIcon />
                                      </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                      <MarkdownToHtml
                                        input={lesson.explainer}
                                        classname="text-sm text-gray-700 pt-6"
                                      />
                                      {lesson.resources.length > 0 && (
                                        <Flex direction={'column'} py={8}>
                                          <HStack pb={4}>
                                            <Square3Stack3DIcon className="h-5 w-5 text-gray-600" />
                                            <Text
                                              textColor={'gray.600'}
                                              fontWeight="semibold"
                                            >
                                              Recommended resources
                                            </Text>
                                          </HStack>
                                          <Resources
                                            resources={lesson.resources}
                                          />
                                        </Flex>
                                      )}
                                    </AccordionPanel>
                                  </AccordionItem>
                                ))}
                            </Accordion>
                          </Box>
                        </Skeleton>
                      </div>
                      <Flex
                        mt={8}
                        p={4}
                        flexDirection={'column'}
                        className="rounded-lg border border-gray-100"
                      >
                        <HStack>
                          <Text
                            textColor="gray.700"
                            fontWeight="semibold"
                            fontSize="xl"
                          >
                            Feedback
                          </Text>
                        </HStack>
                        <Box>
                          <Text fontSize={'sm'} textColor={'gray.700'}>
                            To report an issue or suggest a resource that should
                            be added to this page or you want to request a new
                            learning path, please{' '}
                            <Link
                              href={'https://forms.gle/rsf39W8gE26z4jmT9'}
                              textColor="brand.darkBlue"
                              fontWeight="semibold"
                            >
                              fill this form
                            </Link>
                          </Text>
                        </Box>
                      </Flex>
                    </TabPanel>

                    {/* Leaderboard Tab */}
                    <TabPanel px="-4">
                      <TrackLeaderboard ranking={trackLeaderboard} />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </LayoutWithSidebar>
    </>
  );
};

export default withRouter(SkillDetail);
