import React, { useEffect, useState } from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import DashboardNavbar from '../components/DashboardNavbar.js';
import { useParams } from 'react-router-dom';
import {
  MentorProfile,
  MentorProfileSkeleton,
} from '../components/MentorProfile';
import { Mixpanel } from '../utils/Mixpanel.js';
import withRouter from '../utils/withRouter.js';
import SEO from '../components/Common/SEO.js';
import splitbee from '@splitbee/web';
import authService from '../services/auth-service.js';

const Mentor = () => {
  Mixpanel.track('Mentor Detail Page');
  const toast = useToast();
  const username = useParams().username;
  const [mentor, setMentor] = useState('');
  const [profile, setProfile] = useState({});
  splitbee.track('Mentor Detail Page', { username: username });
  const [isLoading, setIsLoading] = useState(true);

  const getMentorDetails = async () => {
    try {
      const response = await authService.getProfile(username);
      setProfile(response);
      setMentor(response.mentor);
    } catch (err) {
      toast({
        title: 'Something went wrong.',
        description: `Unable to get mentor details.`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getMentorDetails();
  }, [username, toast]);

  return (
    <>
      <SEO
        title={`Get mentored by ${
          mentor
            ? mentor.firstname + ' ' + mentor.lastname
            : 'an industry expert'
        } | Techstarta`}
        description={`Get mentored by ${
          mentor
            ? mentor.firstname + ' ' + mentor.lastname
            : 'an industry expert'
        }`}
        url={`https://www.techstarta.com/mentor/${username}`}
      />
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar
          title={mentor ? mentor.firstname : 'Mentor'}
          hideBecomeAMentorButton={true}
        />
        {/* Header */}
        <div className="px-4 md:px-10 mx-auto w-full py-8">
          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 xl:mb-0 px-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="w-full rounded-t mb-0 border-0">
                  {mentor ? (
                    <Flex rounded="lg" direction="column" width={'100%'}>
                      <MentorProfile
                        mentor={mentor}
                        profile={profile}
                        isLoading={isLoading}
                      />
                    </Flex>
                  ) : (
                    <Flex
                      shadow="md"
                      rounded="lg"
                      direction="column"
                      width={'100%'}
                    >
                      <MentorProfileSkeleton />
                    </Flex>
                  )}
                </div>
              </div>
            </div>
          </div>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(Mentor);
