import React, { useState } from 'react';
import { Flex, Button, Skeleton, Box } from '@chakra-ui/react';
import splitbee from '@splitbee/web';
import { useFormik } from 'formik';
import TextInput from './TextInput';

export const SubmissionForm = ({
  submission,
  slug,
  submitProjectFn,
  updateProjectFn,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const submissionForm = useFormik({
    initialValues: {
      work_link: submission?.work_link,
      additional_information: submission?.additional_information,
      id: submission?.id,
      status: submission?.status,
      documentation_link: submission?.documentation_link,
      challenge_slug: slug,
    },
    onSubmit: values => {
      setIsLoading(true);
      if (values.id) {
        updateProjectFn(values);
      } else {
        submitProjectFn(values);
      }
      setIsLoading(false);
      splitbee.track('Submitted Project');
    },
  });

  return (
    <>
      <form onSubmit={submissionForm.handleSubmit}>
        <Flex flexDirection="column">
          <input
            type="hidden"
            name="challenge_slug"
            value={submissionForm.values.challenge_slug}
          />
          <input type="hidden" name="id" value={submissionForm.values.id} />
          {/* Notes */}
          <Flex mb="6">
            <TextInput
              title={'Description'}
              value={submissionForm.values.additional_information}
              name={'additional_information'}
              element="textArea"
              showError={submissionForm.errors.additional_information}
              placeholder="What did you learn? What was challenging? What questions do your have?"
              helperText={
                'Briefly summarize your solution so reviewers understand what they will see when they access your project.'
              }
              isRequired={true}
              errorMessage={'Please enter a description'}
              onChangeFunction={submissionForm.handleChange}
              onBlurFunction={submissionForm.handleBlur}
            />
          </Flex>

          {/* Solution Link or Github */}
          <Flex mb={6}>
            <TextInput
              title={'Link to your project'}
              value={submissionForm.values.work_link}
              name={'work_link'}
              onChangeFunction={submissionForm.handleChange}
              onBlurFunction={submissionForm.handleBlur}
              type="url"
              placeholder="Link to your solution"
              showError={submissionForm.errors.work_link}
              helperText={
                'Provide a link to your full solution hosted on GitHub, Figma, Google Drive, etc.'
              }
              isRequired={true}
              errorMessage={'That does not look like a valid url'}
            />
          </Flex>

          <Flex mb={6}>
            <TextInput
              title={'Additional link'}
              value={submissionForm.values.documentation_link}
              name={'documentation_link'}
              isRequired={false}
              onChangeFunction={submissionForm.handleChange}
              onBlurFunction={submissionForm.handleBlur}
              type="url"
              placeholder="Additional link"
              showError={submissionForm.errors.documentation_link}
              helperText={
                'Include any other relevant links, such as: Live site URL, supplementary documents, design prototype etc.'
              }
              errorMessage={'That does not look like a valid url'}
            />
          </Flex>
          <Flex align={'center'} justify={'space-between'}>
            <Box className="mr-auto w-9/12">
              <label
                htmlFor={'status'}
                className="block text-sm font-medium pb-1"
              >
                Open for review
              </label>
              <span className="text-sm leading-normal text-gray-700">
                This would make your project public and allow reviewers to see
                your project
              </span>
            </Box>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                name="status"
                type="checkbox"
                value={'open_for_review'}
                checked={submissionForm.values.status === 'open_for_review'}
                onChange={() => {
                  submissionForm.setFieldValue(
                    'status',
                    submissionForm.values.status === 'open_for_review'
                      ? 'work_in_progress'
                      : 'open_for_review'
                  );
                }}
                onBlur={submissionForm.handleBlur}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-orange-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-500"></div>
            </label>
          </Flex>

          {/* Submit */}
          <Flex mt="20px">
            <Button
              _focus={{ outline: 'none' }}
              rounded={'lg'}
              bgColor="brand.darkBlue"
              textColor={'white'}
              onClick={submissionForm.submitForm}
              type="button"
              isLoading={isLoading}
              loadingText={'Submitting...'}
              _hover={{
                bgColor: 'brand.darkBlue',
                textColor: 'white',
                opacity: 0.8,
                cursor: 'pointer',
                outline: 'none',
                shadow: 'small',
              }}
              w={['30%', '20%']}
            >
              Submit
            </Button>
          </Flex>
        </Flex>
      </form>
    </>
  );
};

export const SubmissionFormSkeleton = () => {
  return (
    <Flex
      w="100%"
      mt={['40px', '40px', '40px', '0px']}
      p={['10px', '10px', '10px', '20px']}
      shadow="lg"
      flexDirection="column"
    >
      <Flex mx="10px">
        <Skeleton w="100%" h="50px"></Skeleton>
      </Flex>
      <Flex mt="20px" flexDirection="column">
        {/* Solution Link or Github */}
        <Flex mt="20px" mx="10px" flexDirection="column">
          <Skeleton w="100%" h="20px"></Skeleton>
          <Skeleton mt="5px" w="100%" h="40px"></Skeleton>
        </Flex>

        {/* Notes */}
        <Flex mt="20px" mx="10px" flexDirection="column">
          <Skeleton w="100%" h="20px"></Skeleton>
          <Skeleton mt="5px" w="100%" h="60px"></Skeleton>
        </Flex>

        {/* Submit */}
        <Flex mt="20px" mx="10px">
          <Skeleton w={['30%', '20%']} h="40px"></Skeleton>
        </Flex>
      </Flex>
    </Flex>
  );
};
