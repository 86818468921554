import React from 'react';
import {
  Flex,
  Text,
  Button,
  useToast,
  Box,
  Heading,
  Show,
  Skeleton,
  HStack,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
} from '@chakra-ui/react';
import hackathonService from '../services/hackathon-service.js';
import { LightBulbIcon } from '@heroicons/react/24/outline';
import authService from '../services/auth-service.js';
import { useQuery } from '@tanstack/react-query';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar.js';
import { WrenchScrewdriverIcon } from '@heroicons/react/24/solid';

const Workshops = () => {
  const toast = useToast();
  const { isLoading, error, data } = useQuery({
    queryKey: ['workshops'],
    queryFn: hackathonService.getHackathons,
  });

  const currentUser = JSON.parse(authService.getCurrentUser());

  return (
    <LayoutWithSidebar title={'Workshops'}>
      <Flex w="auto" flexDirection={'column'} mb="10px">
        <Flex justifyContent={'space-between'}>
          <Flex
            flexDirection={'column'}
            width={['100%', '100%', '100%', '80%']}
          >
            <Text>
              <span className="text-orange-600 uppercase text-xs font-semibold">
                Beta
              </span>
            </Text>
            <Heading textColor={'gray.700'} as={'h2'} textAlign="left" pb={4}>
              Expert-led Workshops
            </Heading>
            <Text textAlign="left" textColor={'gray.700'} fontSize="md">
              Learn from experts in software development, product management,
              data science, and product design.
              <br /> Our workshops are designed to help you build your skills
              and advance your career.
            </Text>
            <Text
              pt={4}
              textAlign="left"
              textColor={'gray.700'}
              fontSize="md"
            ></Text>
          </Flex>
          <Show above="sm">
            <WrenchScrewdriverIcon className="h-28 w-28 text-orange-500" />
          </Show>
        </Flex>
        <Box pt={4}>
          <a href="mailto:hello@techstarta.com" className="mr-4">
            <Button
              size="md"
              variant="solid"
              borderRadius="full"
              bgColor={'brand.darkBlue'}
              textColor={'white'}
              width={'auto'}
              _focus={{ outline: '#127881' }}
              _hover={{
                variant: 'outline',
                shadow: 'sm',
                outlineColor: 'brand.darkBlue',
              }}
              _active={{ outlineColor: 'gray.100' }}
            >
              Host a Workshop
            </Button>
          </a>
          {!currentUser && (
            <a href="/get-started?ref=workshops">
              <Button
                size="sm"
                variant="solid"
                bgColor={'brand.darkBlue'}
                textColor={'white'}
                borderRadius="full"
                width={'auto'}
                outlineColor={'gray.300'}
                _focus={{ outline: '#127881' }}
                _hover={{
                  variant: 'outline',
                  shadow: 'sm',
                  outlineColor: 'orange.500',
                }}
                _active={{ outlineColor: 'gray.100' }}
              >
                Sign up to get notified
              </Button>
            </a>
          )}
        </Box>
      </Flex>
      <Skeleton isLoaded={true} rounded={'lg'}>
        <Flex py={12} flexDirection={'column'}>
          <HStack pb={6}>
            <LightBulbIcon className="h-6 w-6 text-gray-600" />
            <Heading color="#2d3748" size="md" justifyContent="space-between">
              Upcoming Workshops
            </Heading>
          </HStack>

          <Box>
            <Text>
              We are currently cooking up some hands-on workshops for you to
              join. Check back soon!
            </Text>
            <Text pt={4}>
              If you are interested in hosting a workshop, please email us at{' '}
              <a
                className="underline text-orange-600"
                href="mailto:hello@techstarta.com"
              >
                hello@techstarta.com
              </a>
              .
            </Text>
          </Box>
          {/* <SimpleGrid columns={[1, 2, 2, 3]}>
            {data?.hackathons?.map(hackathon => (
              <LinkBox
                w="100%"
                key={hackathon.id}
                justifyContent={'space-between'}
                alignItems="center"
              >
                <Flex
                  key={hackathon.id}
                  justifyContent={'space-between'}
                  alignItems="center"
                  p="10px"
                  mt="10px"
                  rounded="lg"
                  cursor={'pointer'}
                  border={'1px'}
                  borderColor={'gray.200'}
                  _hover={{
                    shadow: 'md',
                  }}
                >
                  <Flex alignItems="center">
                    {hackathon.image ? (
                      <Image
                        src={club.image}
                        alt={club.name}
                        w="50px"
                        h="50px"
                        objectFit={'cover'}
                        rounded="lg"
                      />
                    ) : (
                      <TrophyStarReward className="h-10 w-10 text-orange-500" />
                    )}
                    <Flex
                      flexDirection="column"
                      ml="10px"
                      justifyContent="center"
                    >
                      <LinkOverlay href={`/hacakthon/${hackathon.slug}`}>
                        <Text fontSize="sm" fontWeight="bold" color="#2d3748">
                          {hackathon.name}
                        </Text>
                      </LinkOverlay>
                    </Flex>
                  </Flex>
                </Flex>
              </LinkBox>
            ))}
          </SimpleGrid> */}
        </Flex>
      </Skeleton>
    </LayoutWithSidebar>
  );
};

export default Workshops;
