import React from 'react';
import { Flex, Text, Box, Button, Link, HStack } from '@chakra-ui/react';
import { MedalAchievement } from '@vectopus/atlas-icons-react';

export function SkillWithProgress({ track }) {
  return (
    <Flex
      border={'1px'}
      borderColor="gray.200"
      shadow={'sm'}
      rounded={'lg'}
      key={track.track.slug}
      size="lg"
      direction={'column'}
      _hover={{ shadow: 'md' }}
      mb="2"
    >
      <Box p={4}>
        <Text lineHeight={'4'} fontWeight="semibold" textColor={'gray.600'}>
          {track.track.title}
        </Text>
      </Box>

      <Flex p={4} justifyContent="space-between" alignItems={'center'}>
        <HStack>
          <MedalAchievement className="h-5 w-5 text-orange-600" />
          <Text lineHeight={'4'} textColor={'gray.600'}>
            {track.total_earned_points} points
          </Text>
        </HStack>
        <Button
          as={Link}
          href={`/skills/${track.track.slug}`}
          _hover={{ shadow: 'md', textDecoration: 'none' }}
          textColor={'white'}
          bgColor={'brand.darkBlue'}
          rounded={'full'}
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  );
}
