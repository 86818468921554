import React, { useState, useEffect } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import AuthService from '../services/auth-service';
import EditMentorProfile from '../components/EditMentorProfile';
import UpdateUserProfile from '../components/UpdateUserProfile';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar.js';
import profileService from '../services/profile-service.js';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import { UserHierarchy } from '@vectopus/atlas-icons-react';

const EditUserProfile = () => {
  const user = JSON.parse(AuthService.getCurrentUser());
  const { username } = user;

  const [profile, setProfile] = useState('');

  useEffect(() => {
    async function getProfile() {
      try {
        const response = await profileService.getProfile(username);
        if (response.status === 200) {
          setProfile(response.data.profile);
        }
      } catch (err) {
        setProfile(null);
      }
    }
    getProfile();
  }, [username]);

  return (
    <LayoutWithSidebar title={'Settings'}>
      <Tabs
        className="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden"
        color="gray.700"
        mt={['50px', '50px', '50px', '0px']}
        w={['100%', '100%', '100%', '70%']}
        colorScheme="orange"
      >
        <TabList>
          <Tab
            id="basicProfile"
            className="font-medium text-sm hover:bg-slate-50"
            _focus={{ outline: 'none' }}
            _selected={{ color: 'white', bg: 'orange.500' }}
          >
            <UserCircleIcon className="w-4 h-4 mr-2 inline-block" />
            Basic profile
          </Tab>
          {profile?.mentor ? (
            <Tab
              id="mentorProfile"
              className="font-medium text-sm hover:bg-slate-50"
              _focus={{ outline: 'none' }}
              _selected={{ color: 'white', bg: 'orange.500' }}
            >
              <UserHierarchy className="w-4 h-4 mr-2 inline-block" />
              Mentor profile
            </Tab>
          ) : null}
        </TabList>

        <TabPanels>
          <TabPanel id="basicProfile" boxShadow="sm" rounded={'lg'}>
            <UpdateUserProfile />
          </TabPanel>
          <TabPanel id="mentorProfile" boxShadow="sm" rounded={'lg'}>
            {profile?.mentor ? (
              <EditMentorProfile mentor={profile.mentor} username={username} />
            ) : null}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </LayoutWithSidebar>
  );
};

export default EditUserProfile;
