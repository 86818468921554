import React from 'react';
import DiscussionItem from '../Discussion/DiscussionItem';
import { DiscussionListSkeleton } from './DiscussionSkeleton';
import { Stack, Text } from '@chakra-ui/react';

const DiscussionList = ({ isLoading, discussions, upVoteFn }) => {
  return (
    <>
      {isLoading ? (
        <DiscussionListSkeleton />
      ) : (
        <Stack orientation="vertical" spacing={4}>
          {discussions.map(discussion => (
            <DiscussionItem
              key={discussion.slug}
              discussion={discussion}
              upVoteFn={upVoteFn ? () => upVoteFn(discussion.slug) : undefined}
            />
          ))}
          {discussions.length === 0 && (
            <Text className="text-center text-gray-500 text-sm font-medium leading-6 mt-10 mb-10">
              Published posts will appear here
            </Text>
          )}
        </Stack>
      )}
    </>
  );
};

export default DiscussionList;
