import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Text, Button } from '@chakra-ui/react';

const AvailableTrack = ({ item, noAuth, trackActionFn }) => {
  const navigate = useNavigate();
  const { title, description, slug, hasJoined } = item;

  const viewTrack = () => {
    navigate(`/skills/${slug}`);
  };

  return (
    <>
      <Flex
        bgColor={hasJoined ? 'blue.50' : 'white'}
        mb="15px"
        flexWrap="wrap"
        w="100%"
        p="20px"
        shadow="md"
        alignItems="center"
        justifyContent="space-between"
        rounded="md"
      >
        <Flex
          color="#2d3748"
          fontWeight="bold"
          w={['100%', '100%', '100%', '100%']}
          flexDirection="column"
        >
          <Flex alignItems="center" pb="10px" justifyContent="space-between">
            <Text cursor="pointer" onClick={viewTrack}>
              {' '}
              {title}{' '}
            </Text>
          </Flex>
          <Text fontSize="14px" fontWeight="normal" color="#718096">
            {' '}
            {description}{' '}
          </Text>
        </Flex>

        <Flex
          w={'100%'}
          flexDirection="row"
          justifyContent={'space-between'}
          pt="10px"
        >
          {hasJoined ? (
            <Button
              onClick={viewTrack}
              fontSize="sm"
              shadow="md"
              _hover={{ shadow: 'lg' }}
              rounded="9999px"
              _focus={{ outline: 'none' }}
              textColor="white"
              bg="brand.darkBlue"
            >
              Continue learning{' '}
              <i
                style={{ paddingLeft: '5px' }}
                className="fa fa-arrow-right"
              ></i>
            </Button>
          ) : (
            <Button
              fontSize="sm"
              shadow="md"
              _hover={{ shadow: 'lg' }}
              rounded="9999px"
              _focus={{ outline: 'none' }}
              colorScheme="orange"
              onClick={trackActionFn}
            >
              Join learning path{' '}
              <i style={{ paddingLeft: '5px' }} className="fa fa-user-plus"></i>
            </Button>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default AvailableTrack;
