import React, { useState, useEffect } from 'react';
import { Flex, Box, Spinner, Heading, useToast } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import TeamService from '../services/hackathon-service';

const JoinHackathonSquad = () => {
  const navigate = useNavigate();
  const invitationCode = useParams().invitationCode;
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function joinTeam() {
      setIsLoading(true);
      try {
        const response = await TeamService.joinTeam(invitationCode);
        if (response.status === 201) {
          toast({
            title: 'Great!',
            description: "You've successfully joined this team.",
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          setIsLoading(false);
          navigate(`/teams/${response.data.team_member.team_slug}`);
        }
      } catch (err) {
        toast({
          title: 'Oops!',
          description: 'Unable to join Team, please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }
    joinTeam();
  }, [invitationCode, toast, navigate]);

  return (
    <Flex bg="#1a202c" w="100%" h="100vh" align="center" justify="center">
      <Flex
        rounded="lg"
        direction="column"
        w={['90%', '70%', '60%', '50%']}
        h="auto"
        bg="white"
        align="center"
        justify="center"
      >
        <Box pt="3">
          <Heading color="#2d3748" as="h1">
            {' '}
            Welcome!{' '}
          </Heading>
        </Box>
        <Box pt="2" pb="3">
          <Heading color="#718096" size="sm" as="h4">
            {' '}
            Wait while we add you to this team.{' '}
          </Heading>
        </Box>
        <Box pt="2" pb="4">
          <Spinner
            display={isLoading ? 'block' : 'none'}
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="orange.500"
            size="xl"
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default JoinHackathonSquad;
