import React from 'react';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import MentorshipOpportunityImage from '../images/pexels-christina-morillo-1181391.webp';
import HeroBackground from '../images/pexels-christina-morillo-1181414.webp';
import {
  Stack,
  Image,
  Center,
  Container,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  UnorderedList,
  ListItem,
  Text,
  Link,
  Flex,
  Heading,
  SimpleGrid,
  WrapItem,
  Wrap,
} from '@chakra-ui/react';

import AuthService from '../services/auth-service';
import {
  ArrowRightIcon,
  ChartBarIcon,
  LightBulbIcon,
  RocketLaunchIcon,
  UserCircleIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import SEO from '../components/Common/SEO.js';
const MentorLanding = () => {
  const currentUser = JSON.parse(AuthService.getCurrentUser());

  const Feature = ({ title, text, icon }) => {
    return (
      <Stack
        shadow={'sm'}
        border={'1px solid'}
        borderColor={'gray.100'}
        p={4}
        rounded={'lg'}
      >
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          color={'white'}
          rounded={'full'}
          bg={'gray.50'}
          mb={1}
        >
          {icon}
        </Flex>
        <Text color={'gray.700'} fontWeight={600}>
          {title}
        </Text>
        <Text color={'gray.700'}>{text}</Text>
      </Stack>
    );
  };

  const MotionHeading = motion(Heading);
  return (
    <>
      <SEO title="Become a mentor | Techstarta" />
      <Navbar transparent={false} currentUser={currentUser} showMenu={true} />
      <Container
        px={['2', '20', '20', '20']}
        pt={['10', '20', '20', '20']}
        pb={['10', '20', '20', '20']}
        maxW={'95%'}
      >
        <Flex
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 20 }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Flex
            textAlign={['center', 'center', 'center', 'start']}
            flexDirection={'column'}
            flex={1}
          >
            <MotionHeading
              as="h1"
              initial={{ opacity: 0 }}
              animate={{ opacity: 2 }}
              lineHeight={1.1}
              fontWeight={'bold'}
              fontSize={{ base: '6xl', sm: '6xl', lg: '70' }}
              pb={6}
            >
              <Text
                as={'span'}
                className="text-slate-800"
                position={'relative'}
              >
                Shape the future of aspiring tech professionals
              </Text>
            </MotionHeading>
            <Text pb={6} className="text-slate-800" as={'h3'} fontSize={'xl'}>
              Mentorship at Techstarta is different from the traditional
              approaches. Join our community and empower career switchers
              through hands-on projects and personalized guidance.
            </Text>
            {currentUser ? (
              <Stack
                spacing={{ base: 4, sm: 4 }}
                direction={{ base: 'column', sm: 'row' }}
                alignItems={'center'}
              >
                <a href="/m/home">
                  <Button
                    variant={'outline'}
                    rounded={'full'}
                    size={'lg'}
                    fontSize={'md'}
                    leftIcon={<UserCircleIcon className="w-4 h-4" />}
                  >
                    {`Continue as ${currentUser.firstname}`}
                  </Button>
                </a>
                <Link fontSize={'md'} href="/logout">
                  Login in as a different user
                </Link>
              </Stack>
            ) : (
              <Stack
                alignItems={'center'}
                justifyItems={'center'}
                spacing={{ base: 4, sm: 4 }}
                pb={6}
                direction={{ base: 'column', sm: 'row' }}
              >
                <a href="/get-started">
                  <Button
                    width={['80', '50', '50', '40']}
                    rounded={'full'}
                    size={'lg'}
                    px={6}
                    colorScheme={'orange'}
                    fontWeight={'semibold'}
                    bg={'brand.darkBlue'}
                    _hover={{ shadow: 'lg' }}
                  >
                    Apply now
                  </Button>
                </a>
              </Stack>
            )}
          </Flex>
          <Flex
            flex={1}
            justify={'center'}
            alignItems={'center'}
            position={'relative'}
          >
            <Box
              position={'relative'}
              rounded={'2xl'}
              width={'full'}
              overflow={'hidden'}
            >
              <Image
                alt={'Hero Image'}
                objectFit={'cover'}
                w={'100%'}
                h={'100%'}
                src={HeroBackground}
              />
            </Box>
          </Flex>
        </Flex>

        <Container p={['5', '20', '20', '20']} maxW={'7xl'}>
          <Center pt={8} width="100%">
            <p className="text-sm text-gray-500 uppercase font-semibold">
              Where our mentors work
            </p>
          </Center>
          <Center
            width={'100%'}
            pt="8"
            pb={8}
            alignItems="center"
            justifyItems={'center'}
          >
            <Wrap
              spacing="80px"
              width="100%"
              py={4}
              justify={'center'}
              align={'center'}
            >
              <WrapItem>
                <Image
                  alt="GetYourGuide"
                  height="40px"
                  width="auto"
                  src={
                    'https://res.cloudinary.com/techstarta/image/upload/v1621080284/grayscale/image_wvguy5.png'
                  }
                ></Image>
              </WrapItem>
              <WrapItem>
                <Image
                  alt="Delivery Hero"
                  height="40px"
                  width="auto"
                  src={
                    'https://res.cloudinary.com/techstarta/image/upload/v1621085669/grayscale/image_8_urb8y9.png'
                  }
                ></Image>
              </WrapItem>
              <WrapItem>
                <Image
                  alt="Lieferando"
                  height="40px"
                  width="auto"
                  src={
                    'https://res.cloudinary.com/techstarta/image/upload/v1621085083/grayscale/image_7_xsojxd.png'
                  }
                ></Image>
              </WrapItem>

              <WrapItem>
                <Image
                  alt="Andela"
                  height="40px"
                  width="auto"
                  src={
                    'https://res.cloudinary.com/techstarta/image/upload/v1621080505/grayscale/image_1_hmrml8.png'
                  }
                ></Image>
              </WrapItem>
              <WrapItem>
                <Image
                  alt="Brass"
                  height="40px"
                  width="auto"
                  src={
                    '//logo.clearbit.com/trybrass.com?size=80&greyscale=true'
                  }
                ></Image>
              </WrapItem>
              <WrapItem>
                <Image
                  alt="YellowCard"
                  height="40px"
                  width="auto"
                  src={
                    '//logo.clearbit.com/yellowcard.io?size=80&greyscale=true'
                  }
                ></Image>
              </WrapItem>
              <WrapItem>
                <Image
                  alt="Sennder"
                  height="40px"
                  width="auto"
                  src={'//logo.clearbit.com/sennder.com?size=80&greyscale=true'}
                ></Image>
              </WrapItem>
            </Wrap>
          </Center>
        </Container>
        <Box width="full" px={8} py="20" rounded={'xl'}>
          <Center pb={12} flexDirection="column">
            <Heading as="h2" className="text-slate-700">
              Why mentor at Techstarta?
            </Heading>
            <Text fontSize={'md'} textAlign={'center'}>
              Mentoring at Techstarta is hands-on and personalized.
              <br /> Share your knowledge and expertise with your mentees and
              help them grow.
            </Text>
          </Center>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} pb={12}>
            <Feature
              icon={<LightBulbIcon className="text-orange-500 h-10 w-10" />}
              title="Solidify your knowledge"
              text="Sharing your knowledge with others is a great way to
                      solidify your own understanding and learn new things."
            />

            <Feature
              icon={<ChartBarIcon className="text-orange-500 h-10 w-10" />}
              title={'Grow your soft skills'}
              text="Mentoring is a great way to develop your soft skills, such
                      as communication, leadership, and empathy."
            />
            <Feature
              icon={<UsersIcon className="text-orange-500 h-10 w-10" />}
              title={'Connect with like-minded people'}
              text="Techstarta is a community of young techies and industry
                      experts who are passionate about helping each other
                      grow."
            />
          </SimpleGrid>
          <Center pt={4}>
            <Link href="/get-started">
              <Button
                className="text-xs font-semibold"
                shadow="sm"
                rounded="full"
                _hover={{ bg: 'brand.darkBlue', shadow: 'md' }}
                variant="solid"
                colorScheme="white"
                bgColor="brand.darkBlue"
                rightIcon={<ArrowRightIcon className="h-4 w-4 " />}
              >
                Join as a mentor
              </Button>
            </Link>
          </Center>
        </Box>
        <section className="relative py-10">
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="Mentorship opportunities"
                  className="max-w-full rounded-lg shadow-lg"
                  src={MentorshipOpportunityImage}
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-orange-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-orange-200">
                    <RocketLaunchIcon className="h-8 w-8" />
                  </div>
                  <h3 className="text-3xl font-semibold">
                    Mentorship Opportunities
                  </h3>
                  <p className="mt-4 text-md leading-relaxed text-gray-600">
                    We are looking for mentors who are passionate about
                    supporting the next generation technologists. We are
                    currently looking for mentors in the following areas:
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-orange-600 bg-orange-200 mr-3">
                            <i className="fas fa-code"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                            Software Engineering
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-orange-600 bg-orange-200 mr-3">
                            <i className="fab fa-product-hunt"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Product Management</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-orange-600 bg-orange-200 mr-3">
                            <i className="fa fa-images"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Product Design</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-orange-600 bg-orange-200 mr-3">
                            <i className="fas fa-desktop"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Data Analysis</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-orange-600 bg-orange-200 mr-3">
                            <i className="fas fa-money-check-alt"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                            Business Development
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="mt-6">
                    <a href="/get-started">
                      <Button
                        width={['80', '50', '50', '40']}
                        rounded={'full'}
                        size={'lg'}
                        px={6}
                        colorScheme={'orange'}
                        fontWeight={'semibold'}
                        bg={'brand.darkBlue'}
                        _hover={{ shadow: 'lg' }}
                      >
                        Apply now
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-20 pb-20">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-10">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold">
                  Frequently Asked Questions
                </h2>
              </div>
            </div>
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-7/12 px-4">
                <Accordion defaultIndex={[0]} allowMultiple>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={'semibold'}
                        >
                          What do I need to have to be a mentor?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text pb={2}>
                        To be a mentor, you must meet the following criteria:
                      </Text>
                      <UnorderedList>
                        <ListItem>
                          Relevant experience in the industry for at least 5
                          years
                        </ListItem>
                        <ListItem>
                          Must have a proven track record of success in your
                          field
                        </ListItem>
                        <ListItem>
                          Passionate about impacting the growth of others and
                          sharing your expertise
                        </ListItem>

                        <ListItem>
                          Ability to listen actively, empathize, and put
                          themselves in their mentees shoes.
                        </ListItem>
                        <ListItem>
                          <a
                            className="underline text-orange-500"
                            href="https://coa.stanford.edu/publications/intellectual-humility-mindset-and-learning"
                          >
                            Intellectual humility
                          </a>{' '}
                          is a fundamental value we uphold, and it is an
                          essential trait we expect all our mentors to embody.
                        </ListItem>
                      </UnorderedList>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={'semibold'}
                        >
                          How does mentoring work on Techstarta
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <UnorderedList>
                        <ListItem>
                          Mentors participate in one-on-one mentorship programs,
                          conducting individual sessions.
                        </ListItem>
                        <ListItem>
                          Mentors have the option to lead workshops
                          independently or collaborate with fellow mentors to
                          organize sessions.
                        </ListItem>
                        <ListItem>
                          Mentors contribute to the community by sharing their
                          expertise through articles, resources, and
                          discussions.
                        </ListItem>
                        <ListItem>
                          Mentors create projects for mentees to undertake. See
                          sample projects{' '}
                          <a
                            className="underline text-orange-500"
                            href="/projects"
                          >
                            here
                          </a>
                        </ListItem>
                        <ListItem>
                          Mentors can participate in team-based mentorship
                          programs.
                        </ListItem>
                        <ListItem>
                          Mentors offer constructive feedback to their mentees
                          on their projects.
                        </ListItem>
                      </UnorderedList>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={'semibold'}
                        >
                          Do I get paid for mentoring?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text>
                        Yes, you do get paid as a mentor. However, please note
                        that for every paid mentorship session conducted through
                        Techstarta, the platform charges a 20% platform fee. As
                        a mentor, you receive 80% of the amount paid by the
                        mentee for your valuable guidance and expertise.
                      </Text>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={'semibold'}
                        >
                          How else can I contribute to Techstarta?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text>
                        Provide feedback and suggestions on how we can improve
                        the platform and the mentorship experience. We are
                        always looking for ways to improve the platform and
                        provide a better experience for our mentors and mentees.
                      </Text>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                <Flex
                  pt={16}
                  alignItems="center"
                  justifyContent={'center'}
                  flexDirection="column"
                >
                  <Text
                    fontWeight={'semibold'}
                    fontSize="2xl"
                    textColor={'gray.700'}
                  >
                    You have different question?
                  </Text>
                  <Text textColor={'gray.700'}>
                    Email us at &nbsp;
                    <a href="mailto:hello@techstarta.com" className="underline">
                      hello@techstarta.com
                    </a>
                  </Text>
                </Flex>
              </div>
            </div>
          </div>
        </section>
      </Container>
      <Footer />
    </>
  );
};

export default MentorLanding;
