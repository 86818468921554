import React from 'react';
import { Flex } from '@chakra-ui/react';
import { CreateProjectForm } from '../components/CreateProjectForm.js';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar.js';
import withRouter from '../utils/withRouter.js';

const ContributeProject = () => {
  return (
    <>
      <LayoutWithSidebar title={'Contribute project'} pageTitle={'Projects'}>
        <Flex w={['100%', '100%', '100%', '70%']} justifyContent="center">
          <CreateProjectForm />
        </Flex>
      </LayoutWithSidebar>
    </>
  );
};

export default withRouter(ContributeProject);
