import React from 'react';
import { Heading, HStack, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';

const ProjectReference = ({ project }) => {
  return (
    <LinkBox
      className="ring-1 ring-gray-300 rounded-lg p-2"
      flexDirection={'column'}
      textColor={'gray.700'}
      bg={'gray.50'}
    >
      <LinkOverlay href={`/projects/${project.slug}`}>
        <Heading fontSize={'md'} as="h4" className="mb-2">
          {project?.title}
        </Heading>
      </LinkOverlay>
      <HStack className="text-xs" alignContent={'center'}>
        <Text>Project</Text>
        <span>&bull;</span>
        <Text>{project?.track}</Text>
      </HStack>
    </LinkBox>
  );
};

export default ProjectReference;
