import {API_BASE_URL, connection} from "./index"
import AuthService from "./auth-service";

const listStories = async () => {
    return await connection.get(API_BASE_URL + "stories");
}

const postComment = async (storySlug, payload) => {
    const token = AuthService.getAuthToken();
    return await connection.post(API_BASE_URL + "stories/" + storySlug + "/comments", {comment: payload},
        {
            headers: {
                "Authorization": `token ${token}`
            }
        });
}

const upvoteStory = async (slug) => {
    const token = AuthService.getAuthToken();
    return await connection.post(API_BASE_URL + "stories/" + slug + "/vote", {},
        {
            headers: {
                "Authorization": `token ${token}`
            }
        });
}

const upvoteComment = async (commentId) => {
    const token = AuthService.getAuthToken();
    return await connection.post(API_BASE_URL + "comments/" + commentId + "/vote", {},
        {
            headers: {
                "Authorization": `token ${token}`
            }
        });
}

const getStoryDetail = async (storySlug) => {
    return await connection.get(API_BASE_URL + "stories/" + storySlug);
}

const StoryService = { listStories, postComment, getStoryDetail, upvoteStory, upvoteComment }

export default StoryService;