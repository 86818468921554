import React, { useState, useEffect } from 'react';
import { Flex, Text, Button, Skeleton, useToast } from '@chakra-ui/react';
import TextInput from './TextInput';
import Validator from '../utils/validator';
import TeamService from '../services/hackathon-service';
import { useNavigate } from 'react-router-dom';

export const CreateHackathonProjectForm = ({ team, editTeam }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const [teamName, setTeamName] = useState('');
  const [about, setAbout] = useState('');
  const [website, setWebsite] = useState('');

  useEffect(() => {
    if (team) {
      setTeamName(team.name);
      setAbout(team.description);
      setWebsite(team.website);
    }
  }, [team]);

  const [showTeamNameError, setShowTeamNameError] = useState(false);
  const [showAboutError, setShowAboutError] = useState(false);
  const [showWebsiteError, setShowWebsiteError] = useState(false);

  const validateSubmission = isError => {
    const isNameValid = Validator.isValueValid(teamName);
    const isAboutValid = Validator.isValueValid(about);
    const isWebsiteValid = Validator.isUrlValid(website);

    setShowTeamNameError(!isNameValid);
    setShowAboutError(!isAboutValid);
    setShowWebsiteError(!isWebsiteValid);

    if (!isNameValid || !isAboutValid || !isWebsiteValid) {
      isError = true;
    }

    return isError;
  };

  const submitTeam = async () => {
    let isError = false;

    isError = validateSubmission(isError);

    if (isError) {
      return;
    }

    const payload = {
      name: teamName,
      description: about,
      website,
    };

    try {
      const response = await TeamService.createTeam(payload);
      if (response.status === 201) {
        toast({
          title: 'Great!',
          description: 'Your team created successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate(`/teams/${response.data.team.slug}`);
      }
    } catch (error) {
      toast({
        title: 'Ooops!',
        description: 'Unable to create team. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const updateTeam = async () => {
    let isError = false;

    isError = validateSubmission(isError);

    if (isError) {
      return;
    }

    const payload = {
      name: teamName,
      description: about,
      website,
    };

    try {
      const response = await TeamService.updateTeam(payload, team.slug);
      if (response.status === 200) {
        toast({
          title: 'Great!',
          description: 'Your team updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate(`/teams/${response.data.team.slug}`);
      }
    } catch (error) {
      toast({
        title: 'Ooops!',
        description: 'Unable to update team. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      w={
        editTeam
          ? ['100%', '100%', '90%', '80%']
          : ['100%', '100%', '90%', '60%']
      }
      mt={editTeam ? '0px' : ['40px', '40px', '40px', '0px']}
      p={['10px', '10px', '10px', '20px']}
      shadow={editTeam ? 'none' : 'lg'}
      flexDirection="column"
    >
      {editTeam ? null : (
        <>
          <Flex mx="10px" alignItems="center">
            <Text fontSize="25px" ml="10px" color="#2d3748" fontWeight="bold">
              {' '}
              Create Team{' '}
            </Text>
          </Flex>
          <Flex mt="5px" mx="10px">
            <Text fontSize="md" color="#718096" fontWeight="bold">
              {' '}
              Create a team for your friends, colleagues or Bootcamp.{' '}
            </Text>
          </Flex>
        </>
      )}
      <Flex mt="10px" flexDirection="column">
        {/*Team name */}
        <Flex>
          <TextInput
            value={teamName || ''}
            onChangeFunction={event => setTeamName(event.target.value)}
            title="Name"
            type="text"
            placeholder="Enter team name"
            showError={showTeamNameError}
            errorMessage={'Please provide a valid name'}
          />
        </Flex>

        {/* About */}
        <Flex mt="20px">
          <TextInput
            value={about || ''}
            title="About"
            element="textArea"
            placeholder="About your team"
            onChangeFunction={event => setAbout(event.target.value)}
            showError={showAboutError}
            errorMessage={'Please provide a description'}
          />
        </Flex>

        {/* Website */}
        <Flex mt="20px">
          <TextInput
            value={website || ''}
            onChangeFunction={event => setWebsite(event.target.value)}
            title="Website"
            type="url"
            placeholder="Your team website (optional)"
            showError={showWebsiteError}
            errorMessage={'Please provide a valid url'}
          />
        </Flex>

        {/* Submit */}
        <Flex mt="20px" mx="10px">
          <Button
            _focus={{ outline: 'none' }}
            colorScheme="orange"
            onClick={editTeam ? updateTeam : submitTeam}
          >
            {' '}
            {editTeam ? 'Save Changes' : 'Create team'}{' '}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const CreateTeamFormSkeleton = () => {
  return (
    <Flex
      w={['100%', '100%', '90%', '60%']}
      mt={['40px', '40px', '40px', '0px']}
      p={['10px', '10px', '10px', '20px']}
      shadow="lg"
      flexDirection="column"
    >
      <Flex mx="10px">
        <Skeleton w="100%" h="50px"></Skeleton>
      </Flex>
      <Flex mt="20px" flexDirection="column">
        {/* Team Name */}
        <Flex mx="10px" flexDirection="column">
          <Skeleton w="100%" h="20px"></Skeleton>
          <Skeleton mt="5px" w="100%" h="40px"></Skeleton>
        </Flex>

        {/* About */}
        <Flex mt="20px" mx="10px" flexDirection="column">
          <Skeleton w="100%" h="20px"></Skeleton>
          <Skeleton mt="5px" w="100%" h="40px"></Skeleton>
        </Flex>

        {/* Website */}
        <Flex mt="20px" mx="10px" flexDirection="column">
          <Skeleton w="100%" h="20px"></Skeleton>
          <Skeleton mt="5px" w="100%" h="40px"></Skeleton>
        </Flex>

        {/* Submit */}
        <Flex mt="20px" mx="10px">
          <Skeleton w={['30%', '20%']} h="40px"></Skeleton>
        </Flex>
      </Flex>
    </Flex>
  );
};
