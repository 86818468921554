export const groupBy = (array, property) => {
    const groupedArray = array.reduce((acc, item) => {
        const key = item[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc;
    }   
    , {});  
    return groupedArray;
}
