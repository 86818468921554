import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import { Clock, User } from '@vectopus/atlas-icons-react';

const PublicBountyApplicationCard = ({ application }) => {
  const { opportunity } = application;
  const { applicant } = application;
  return (
    <LinkBox
      href={`/profile/${applicant.username}`}
      p={4}
      className="ring-1 ring-slate-200 rounded-lg hover:ring-slate-300"
    >
      <Flex justify={'space-between'} className="bottom-0">
        <HStack>
          <Avatar
            bgColor={'orange.500'}
            size={'sm'}
            name={applicant.fullname}
            src={applicant.image}
          />
          <Stack verticalAlign={'true'} spacing={0.2}>
            <Text className="text-sm text-gray-500 font-[500]">
              {applicant.username}
            </Text>
            <Text className="text-xs text-gray-400 font-[500]">
              Applied {moment(application.created_at).fromNow()}
            </Text>
          </Stack>
        </HStack>
        <HStack>
          {opportunity.status === 'open' &&
          application.status === 'accepted' &&
          opportunity.author.username == user.username ? null : (
            <Button rounded={'full'} colorScheme="orange" disabled>
              Accept application
            </Button>
          )}
        </HStack>
      </Flex>
      <Flex className="my-4" flexDirection={'column'}>
        <Text className="text-sm">{application.message}</Text>
      </Flex>
    </LinkBox>
  );
};

export default PublicBountyApplicationCard;
