import React from 'react';
import { SimpleGrid, Box, Text, Flex } from '@chakra-ui/react';
import { ChallengeCard, ChallengeCardSkeleton } from './ChallengeCard';

const ChallengesGrid = ({ isLoading, projects }) => {
  return (
    <div>
      <SimpleGrid
        mt="25px"
        columns={[1, 2, 2, 3]}
        justifyContent="space-evenly"
        spacing="8"
        mb="20px"
        w="100%"
      >
        {isLoading && (
          <>
            <ChallengeCardSkeleton />
            <ChallengeCardSkeleton />
            <ChallengeCardSkeleton />
          </>
        )}
      </SimpleGrid>

      {!isLoading &&
        projects &&
        Object.entries(projects).map(([key, value]) => (
          <Flex flexDirection={'column'} pb={6} key={key}>
            <Text
              color="gray.600"
              fontSize={'lg'}
              className="capitalize font-semibold"
            >
              {key} projects
            </Text>
            <SimpleGrid
              mt="4"
              columns={[1, 2, 2, 3]}
              justifyContent="space-evenly"
              spacing="8"
              mb="20px"
              w="100%"
            >
              {value.map((challenge, index) => (
                <ChallengeCard challengeDetails={challenge} key={index} />
              ))}
            </SimpleGrid>
          </Flex>
        ))}

      {!isLoading && !projects && (
        <Box mx={4} borderRadius="5px" p="20px" w="100%">
          <Text fontWeight="semibold" color="#718096">
            We couldn't find any projects that match your search.
          </Text>
        </Box>
      )}
    </div>
  );
};

export default ChallengesGrid;
