import { useEffect, useState } from "react";

const useClipboard = () => {

    const [hasCopied, setHasCopied] = useState(false)

    useEffect(
        () => {
            setTimeout(() => setHasCopied(false), 3000)
        }, [hasCopied]
    )

    const copyToClipboard = (id) => {
        const copyText = document.getElementById(id).innerText;
        const textArea = document.createElement("textarea")
        document.body.appendChild(textArea)
        textArea.value = copyText
        textArea.setAttribute('readonly', '');
        textArea.style.position = 'absolute';
        textArea.style.left = '-9999px';
        document.body.appendChild(textArea);
        textArea.select();
        textArea.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(textArea);
        setHasCopied(true)
    }

    return { hasCopied, copyToClipboard }
}

export default useClipboard;
