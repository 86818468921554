import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Text,
  Button,
  Skeleton,
  Heading,
  Divider,
  Box,
  useToast,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import TextInput from './TextInput';
import TrackService from '../services/track-service';
import { BeatLoader } from 'react-spinners';
import { Formik } from 'formik';
import * as Yup from 'yup';

export const CreateProjectForm = () => {
  const toast = useToast();
  const router = useNavigate();

  const [trackList, setTrackList] = useState('');
  useEffect(() => {
    async function getAllTracks() {
      const response = await TrackService.fetchAllTracks();
      setTrackList(response.tracks);
    }
    getAllTracks();
  }, []);

  const ProjectSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    summary: Yup.string().min(2, 'Too Short!').max(150, 'Too Long!'),
    description: Yup.string().required('Required'),
    submission_requirements: Yup.string(),
    track_slug: Yup.string().required('Required'),
    difficulty_level: Yup.string().required('Required'),
    what_you_will_learn: Yup.string().required('Required'),
    instructions: Yup.string(),
  });

  const createChallenge = async values => {
    try {
      const response = await TrackService.createChallenge(values);
      if (response === 201) {
        toast({
          title: 'Tada!',
          description: 'Project created successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        router('/projects');
      }
    } catch (err) {
      toast({
        title: 'Oops!',
        description: 'Unable to create project, please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Flex
        mt={['40px', '40px', '0px']}
        p="20px"
        shadow="md"
        h={['100%', '100%', '100%']}
        w={['100%', '100%', '100%', '100%']}
        rounded={'lg'}
        direction="column"
      >
        <Flex flexDirection={'column'} mt={['10px']}>
          <Heading size="lg" color="#2d3748">
            Create a Guided Project
          </Heading>
          <Text>
            Practice projects helps our community members to learn and grow.
          </Text>
        </Flex>
        <Divider py={2} />
        <Formik
          initialValues={{
            title: '',
            summary: '',
            description: '',
            what_you_will_learn: '',
            track_slug: '',
            difficulty_level: '',
            submission_requirements: '',
            instructions: '',
          }}
          validationSchema={ProjectSchema}
          onSubmit={async (values, { setSubmitting }) => {
            await createChallenge(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form method="post" onSubmit={handleSubmit}>
              <Flex mt="20px" direction="column">
                <Flex
                  boxSizing="border-box"
                  direction={['column', 'column', 'column', 'row']}
                  w={['93%', '95%', '95%', 'auto']}
                >
                  <TextInput
                    title="Title"
                    name="title"
                    element={'input'}
                    onChangeFunction={handleChange}
                    value={values.title}
                    onBlur={handleBlur}
                    placeholder="Give this project a title"
                    showError={errors.title && touched.title}
                    errorMessage={errors.title}
                  />
                </Flex>

                <Box w={['95%', '97%', '97%', 'auto']} mt="20px">
                  <TextInput
                    title="Project Summary"
                    name={'summary'}
                    element="textArea"
                    onChangeFunction={handleChange}
                    onBlurFunction={handleBlur}
                    value={values.summary}
                    placeholder="Give a short summary of the project"
                    showError={errors.summary && touched.summary}
                    errorMessage={errors.summary}
                  />
                </Box>

                {/* Description */}
                <Box w={['95%', '97%', '97%', 'auto']} mt="20px">
                  <TextInput
                    title="Description"
                    name={'description'}
                    element="textArea"
                    onChangeFunction={handleChange}
                    onBlurFunction={handleBlur}
                    value={values.description}
                    placeholder="Describe the project"
                    showError={errors.description && touched.description}
                    errorMessage="Please provide a description."
                  />
                </Box>

                <Box w={['95%', '97%', '97%', 'auto']} mt="20px">
                  <TextInput
                    title="What skills will be practiced?"
                    name={'what_you_will_learn'}
                    element="textArea"
                    onChangeFunction={handleChange}
                    onBlurFunction={handleBlur}
                    value={values.what_you_will_learn}
                    placeholder="List the skills that will be practiced"
                    showError={
                      errors.what_you_will_learn && touched.what_you_will_learn
                    }
                    errorMessage="Please add the skills that will be practiced."
                  />
                </Box>

                <Box w={['95%', '97%', '97%', 'auto']} mt="20px">
                  <TextInput
                    name="submission_requirements"
                    title="Requirements & Recommendations"
                    element="textArea"
                    onChangeFunction={handleChange}
                    onBlurFunction={handleBlur}
                    value={values.submission_requirements}
                    placeholder="Outline the steps to complete this project"
                    showError={
                      errors.submission_requirements &&
                      touched.submission_requirements
                    }
                  />
                </Box>

                <Flex
                  direction={['column', 'column', 'column', 'row']}
                  mt="20px"
                  justifyContent="space-between"
                  w={['93%', '95%', '95%', 'auto']}
                >
                  <Box
                    mt={['20px', '20px', '20px', '0px']}
                    w={['100%', '100%']}
                    mx="10px"
                    mb="20px"
                  >
                    <FormLabel color="#2d3748" mb="5px">
                      Track
                    </FormLabel>
                    <Select
                      w="100%"
                      focusBorderColor="#dd6b20"
                      name="track_slug"
                      color="#2d3748"
                      placeholder="Choose a track for this project"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.track_slug}
                      fontSize={['sm', 'md']}
                    >
                      {trackList
                        ? trackList.map((track, index) => (
                            <option key={index} value={track.slug}>
                              {track.title}
                            </option>
                          ))
                        : null}
                    </Select>
                  </Box>
                  <Box
                    mt={['20px', '20px', '20px', '0px']}
                    w={['100%', '100%']}
                    mx="10px"
                  >
                    <FormLabel color="#2d3748" mb="5px">
                      Difficulty Level
                    </FormLabel>
                    <Select
                      name="difficulty_level"
                      focusBorderColor="#dd6b20"
                      color="#2d3748"
                      placeholder="Select the difficulty level for this project"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.difficulty_level}
                      fontSize={['sm', 'md']}
                    >
                      <option value="Easy">Easy </option>
                      <option value="Intermediate">Intermediate </option>
                      <option value="Hard">Hard</option>
                    </Select>
                    {touched.difficulty_level && errors.difficulty_level && (
                      <Text
                        borderRadius="md"
                        fontSize="sm"
                        mt="1"
                        textColor="red.500"
                      >
                        {errors.difficulty_level}
                      </Text>
                    )}
                  </Box>
                </Flex>

                <Box w={['95%', '97%', '97%', 'auto']} mt="20px">
                  <TextInput
                    title="References"
                    element="textArea"
                    name={'instructions'}
                    onBlurFunction={handleBlur}
                    onChangeFunction={handleChange}
                    value={values.instructions}
                    placeholder="References to help the project"
                    helperText="Add links to resources that will help the project"
                    showError={errors.instructions && touched.instructions}
                  />
                </Box>

                <Flex
                  mx="10px"
                  my="20px"
                  justifyContent={['center', 'flex-end']}
                >
                  <Button
                    _focus={{ outline: 'none' }}
                    colorScheme="orange"
                    isLoading={isSubmitting}
                    loadingText="Saving..."
                    size="md"
                    type="submit"
                    disabled={isSubmitting}
                    spinner={<BeatLoader size={8} color="white" />}
                  >
                    Save project
                  </Button>
                </Flex>
              </Flex>
            </form>
          )}
        </Formik>
      </Flex>
    </>
  );
};

export const CreateChallengeFormSkeleton = () => {
  return (
    <Flex
      p="20px"
      boxShadow="md"
      h={['100%', '100%', '100%']}
      w={['100%', '100%', '100%', '100%']}
      direction="column"
    >
      <Flex alignItems="center" mt={['10px']}>
        <Skeleton w="100%" h="50px"></Skeleton>
      </Flex>
      <Divider />
      <Flex mt="20px" direction="column">
        <Flex
          direction={['column', 'column', 'column', 'row']}
          justifyContent="space-between"
        >
          <Box w={['100%', '100%']} mx="10px">
            <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
            <Skeleton mt="5px" w="100%" h="30px"></Skeleton>
          </Box>

          <Box
            w={['100%', '100%']}
            mt={['20px', '20px', '20px', '0px']}
            mx="10px"
          >
            <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
            <Skeleton mt="5px" w="100%" h="30px"></Skeleton>
          </Box>
        </Flex>

        {/* Description */}
        <Box mx={['0px', '0px', '0px', '10px']} mt="20px">
          <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
          <Skeleton mt="5px" w="100%" h="60px"></Skeleton>
        </Box>

        {/* Instructions */}
        <Box mx={['0px', '0px', '0px', '10px']} mt="20px">
          <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
          <Skeleton mt="5px" w="100%" h="60px"></Skeleton>
        </Box>

        {/* Start and Submission dates */}

        <Flex
          direction={['column', 'column', 'column', 'row']}
          mt="20px"
          justifyContent="space-between"
        >
          <Box w={['100%', '100%']} mx={['0px', '0px', '0px', '10px']}>
            <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
            <Skeleton mt="5px" w="100%" h="30px"></Skeleton>
          </Box>
          <Box
            w={['100%', '100%']}
            mt={['20px', '20px', '20px', '0px']}
            mx={['0px', '0px', '0px', '10px']}
          >
            <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
            <Skeleton mt="5px" w="100%" h="30px"></Skeleton>
          </Box>
        </Flex>

        {/* Start and Submission Time */}

        <Flex
          direction={['column', 'column', 'column', 'row']}
          mt="20px"
          justifyContent="space-between"
        >
          <Box w={['100%', '100%']} mx={['0px', '0px', '0px', '10px']}>
            <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
            <Skeleton mt="5px" w="100%" h="30px"></Skeleton>
          </Box>
          <Box
            w={['100%', '100%']}
            mt={['20px', '20px', '20px', '0px']}
            mx={['0px', '0px', '0px', '10px']}
          >
            <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
            <Skeleton mt="5px" w="100%" h="30px"></Skeleton>
          </Box>
        </Flex>

        {/* Inspiration Link */}

        <Flex
          direction={['column', 'column', 'column', 'row']}
          mt="20px"
          justifyContent="space-between"
        >
          <Box w={['100%', '100%']} mx={['0px', '0px', '0px', '10px']}>
            <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
            <Skeleton mt="5px" w="100%" h="30px"></Skeleton>
          </Box>
          <Box
            w={['100%', '100%']}
            mt={['20px', '20px', '20px', '0px']}
            mx={['0px', '0px', '0px', '10px']}
          >
            <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
            <Skeleton mt="5px" w="100%" h="30px"></Skeleton>
          </Box>
        </Flex>

        <Box mx={['0px', '0px', '0px', '10px']} mt="20px">
          <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
          <Skeleton mt="5px" w="100%" h="60px"></Skeleton>
        </Box>

        {/* prize summary */}

        <Box mx={['0px', '0px', '0px', '10px']} mt="20px">
          <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
          <Skeleton mt="5px" w="100%" h="60px"></Skeleton>
        </Box>

        {/* prize description */}

        <Flex mx="20px" my="20px" justifyContent={['center', 'flex-end']}>
          <Skeleton w={['40%', '20%']} mt="5px" h="40px">
            <Button></Button>
          </Skeleton>
        </Flex>
      </Flex>
    </Flex>
  );
};
