import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar';
import withRouter from '../utils/withRouter.js';

const CheckoutSuccess = () => {
  const navigate = useNavigate();

  const handleManageBilling = event => {
    event.preventDefault();
    window.open(
      'https://billing.stripe.com/p/login/test_eVa02J62gbyY05afYY',
      '_blank',
      'noopener,noreferrer'
    );
  };

  return (
    <>
      <LayoutWithSidebar title={'Manage billing'}>
        <Flex
          shadow={'sm'}
          rounded={'lg'}
          minHeight={'52'}
          justify={'center'}
          alignItems="center"
          flexDirection="column"
        >
          <Text fontSize={'2xl'} fontWeight="semibold" textColor={'gray.700'}>
            Thank you for your subscription!
          </Text>
          <Text fontSize={'sm'} textColor="gray.700">
            Your subscription confirmation has been sent to your email.
          </Text>
          <VStack spacing={'2'} pt="5">
            <Button
              bgColor={'brand.darkBlue'}
              textColor="white"
              _hover={{ shadow: 'md' }}
              variant={'solid'}
              w={'56'}
              rightIcon={<ArrowRightIcon className="w-4 h-4" />}
              onClick={() => navigate('/home')}
            >
              Continue Learning
            </Button>
            <Button onClick={handleManageBilling} variant={'outline'} w={'56'}>
              Manage Billing
            </Button>
          </VStack>
        </Flex>
      </LayoutWithSidebar>
    </>
  );
};

export default withRouter(CheckoutSuccess);
