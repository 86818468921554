import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Link,
  SimpleGrid,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import withRouter from '../utils/withRouter.js';
import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import TrackList from '../components/TrackList';
import SEO from '../components/Common/SEO.js';
import choice from '../images/choice.png';
import profileService from '../services/profile-service.js';
import { SkillWithProgress } from '../components/Skill/SkillWithProgress.js';
import { AcademicCapIcon } from '@heroicons/react/24/outline';

const Skills = () => {
  const currrentUser = JSON.parse(localStorage.getItem('user'));
  const [userTracks, setUserTracks] = useState([]);
  const [username, setUsername] = useState(currrentUser?.username);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchProfile() {
      if (username) {
        const response = await profileService.getProfile(username);
        setUserTracks(response.data.profile?.tracks);
      }
      setLoading(false);
    }
    fetchProfile();
  }, [username]);

  return (
    <>
      <SEO
        title="Explore Career Paths in Tech | Techstarta"
        url="https://techstarta.com/skills"
        description="Get skilled in product management, product design, software engineering, AI, and Business."
      />

      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar
          title="Career Paths"
          url={'https://techstarta.com/skills'}
          hideBecomeAMentorButton={true}
        />
        {/* Header */}
        <div className="px-2 md:px-10 mx-auto w-full">
          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 xl:mb-0 px-4">
              <div className="relative flex flex-col min-w-0 mt-10">
                <Skeleton isLoaded={!loading} rounded={'lg'}>
                  {userTracks?.length > 0 ? (
                    <div>
                      <HStack>
                        <AcademicCapIcon className="w-6 h-6 text-orange-500" />
                        <Text
                          fontSize="xl"
                          fontWeight="bold"
                          textColor={'gray.700'}
                        >
                          Your Career Paths
                        </Text>
                      </HStack>
                      <Text fontSize="md" textColor={'gray.600'}>
                        Complete hands-on projects to demonstrate your skills,
                        earn points and get expert feedback.
                      </Text>

                      <SimpleGrid mt={8} columns={[1, 2, 2, 3]} spacing={4}>
                        {userTracks.map((track, index) => (
                          <SkillWithProgress key={index} track={track} />
                        ))}
                      </SimpleGrid>
                    </div>
                  ) : (
                    <Box
                      border="1px solid"
                      borderColor="gray.200"
                      rounded="lg"
                      p="32px 40px 48px"
                      style={{ background: 'rgb(251, 251, 249)' }}
                      mb={'4'}
                      shadow="sm"
                    >
                      <Flex justifyContent={'space-between'}>
                        <Box>
                          <h1 className="text-3xl md:text-4xl text-gray-800 font-bold leading-tight tracking-tight text-left mb-4">
                            Explore Career Paths
                          </h1>
                          <Text mt={'4'} fontSize={'18px'}>
                            Not sure where to begin? Take a 2-minute quiz to
                            find out which path fits you best.
                          </Text>
                          <Box mt="4">
                            <Link _hover={{ shadow: 'md' }} href="/quiz">
                              <Button
                                _hover={{ shadow: 'md' }}
                                rounded={'full'}
                                bgColor={'brand.darkBlue'}
                                fontSize="sm"
                                textColor="white"
                              >
                                Take Quiz
                              </Button>
                            </Link>
                          </Box>
                        </Box>
                        <Box>
                          <Image
                            src={choice}
                            alt="Find your tech career fit"
                            w={'20'}
                          />
                        </Box>
                      </Flex>
                    </Box>
                  )}
                </Skeleton>

                <TrackList />
              </div>
            </div>
          </div>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(Skills);
