import React from 'react';
import { SubmissionItem, SubmissionItemSkeleton } from '../SubmissionItem';
import { Flex, VStack } from '@chakra-ui/react';
import { ListBulletIcon } from '@heroicons/react/24/outline';

export const ProjectSubmissions = ({ projectSubmissions }) => {
  const submissions = projectSubmissions.filter(
    item => item.status === 'open_for_review' || item.status === 'graded'
  );
  return (
    <Flex w={'100%'} flexDirection="column">
      {submissions ? (
        <VStack spacing={4} width={'100%'}>
          {submissions.map((submission, index) => {
            return (
              <SubmissionItem
                key={index}
                submission={submission}
                showProjectName={false}
                index={index}
              />
            );
          })}
        </VStack>
      ) : (
        <>
          <SubmissionItemSkeleton />
          <SubmissionItemSkeleton />
          <SubmissionItemSkeleton />
          <SubmissionItemSkeleton />
          <SubmissionItemSkeleton />
        </>
      )}
      {submissions.length === 0 && (
        <div className="flex flex-col items-center justify-center">
          <ListBulletIcon className="w-6 h-6 text-gray-600" />
          <h3 className="text-md  text-gray-600">
            Submitted solutions will appear here
          </h3>
        </div>
      )}
    </Flex>
  );
};
