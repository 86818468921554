import React, { useState } from 'react';
import { Flex, Text, Input, Divider, Icon } from '@chakra-ui/react';
import NotificationList from '../components/NotificationList';
import { getDate } from '../utils/currentDate';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar.js';

const Notifications = () => {
  const today = getDate(true);
  const [numberOfNotifications, setNumberOfNotifications] = useState('0');

  return (
    <>
      <LayoutWithSidebar title={'Notifications'} pageTitle={'Notifications'}>
        <Flex w="100%" flexDirection="column">
          <Flex
            mt={['50px', '50px', '50px', '0px']}
            mb="20px"
            w="100%"
            justifyContent="space-between"
          >
            <Flex alignItems="center">
              <Text color="#718096" pl="5px">
                {' '}
                ({numberOfNotifications}){' '}
              </Text>
            </Flex>
            <Flex color="#718096">
              <Input type="date" value={today} />
            </Flex>
          </Flex>
          <Flex mb="20px" flexDirection="column">
            <Flex mb="20px" flexDirection="column">
              <Text mb="20px" fontWeight="bold" color="#2d3748">
                {' '}
                Today{' '}
              </Text>
              <NotificationList
                setNumberOfNotifications={setNumberOfNotifications}
                notificationsPage={true}
              />
            </Flex>
          </Flex>
          <Flex justifyContent="flex-end">
            <Flex color="#718096" shadow="md" p="10px" alignItems="center">
              <Flex>
                <Icon name="chevron-left" size="24px" />
                <Divider
                  border="1px solid"
                  borderColor="#ed8936"
                  orientation="vertical"
                />
                <Icon color="#2d3748" name="chevron-right" size="24px" />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </LayoutWithSidebar>
    </>
  );
};

export default Notifications;
