import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
    Flex,
    Text,
    Button,
    Image,
    useToast
} from "@chakra-ui/react";
import DashboardNavbar from "../components/DashboardNavbar.js";
import Sidebar from "../components/Sidebar.js";
import DashboardFooter from "../components/DashboardFooter.js";
import BookingService from "../services/booking-service";
import BookingList from "../components/Booking/BookingList.js";
import authService from "../services/auth-service.js";

const Bookings = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const [numOfBookings, setNumOfBookings] = useState("")
    const [bookings, setBookings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchBookings() {
          try {
            setIsLoading(true)
            const response = await BookingService.listBookings();
            setBookings(response.bookings);
            setNumOfBookings(response.bookings.length)
          } catch (error) {
            switch (error.status) {
                case 401:
                    navigate("/login");
                    break;
                case 403:
                    toast({ 
                        title: "Oops!",
                        description: "Please login to view this page",
                        status: "error"});
                    authService.logout();
                    navigate("/login"); 
                    break;
                default:
                    toast({ 
                        title: "Oops!",
                        description: "Unable to fetch bookings, please try again later",
                        status: "error"});
                    break;
            }
          }
          setIsLoading(false)
        }
        fetchBookings();
      }, [toast]);

    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-white">
                <DashboardNavbar title="Bookings" hideBecomeAMentorButton={true} />
                {/* Header */}
                <div className="relative bg-white md:pt-32 pb-16"></div>
                <div className="px-2 md:px-10 mx-auto w-full -m-24">
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full  mb-12 xl:mb-0">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                                <div className="rounded-t mb-0 px-2 border-0">
                                    <Flex mt={["50px", "50px", "50px", "20px"]} p={["10px", "10px", "10px", "20px"]} flexDirection="column">
                                        <Flex pb="20px" justifyContent="space-between" direction={["column", "row"]} alignItems="center" mx="10px">
                                            <Flex alignItems="center">
                                                <Image boxSize="30px" alt="solution-logo" src="https://res.cloudinary.com/techstarta/image/upload/v1630065163/calendar_gh0xnl.png" />
                                                <Text fontSize="20px" ml="10px" color="#2d3748" fontWeight="semibold" > Your bookings </Text>
                                            </Flex>
                                            <Flex>
                                                <Text fontSize="sm" ml="10px" color="#718096" fontWeight="bold" > {numOfBookings ? numOfBookings : "0"} Bookings </Text>
                                            </Flex>
                                        </Flex>
                                        <BookingList bookings={bookings} />
                                        <Flex p={["10px", "10px", "10px", "10px"]} mt="20px">
                                            <Link to="/mentors" >
                                                <Button _focus={{ outline: "none" }} _hover={{ backgroundColor: "teal.100" }} variant="outline" color="brand.darkBlue" rounded="full"> Book a mentoring session</Button>
                                            </Link>
                                        </Flex>
                                    </Flex>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DashboardFooter title="Bookings" />
                </div>
            </div>
        </>
    )
}

export default Bookings;
