import React from 'react';
import { Avatar, Box, Flex, Link, Text } from '@chakra-ui/react';
import GradeBadge from './GradeBadge';
import MarkdownToHtml from '../ReactMarkdownItem';

const GradeBox = ({ assignedMentor, grade, comment }) => {
  return (
    <Flex flexDirection={'column'}>
      <Flex alignItems={'center'} pb={2}>
        <Avatar
          mr={2}
          size="sm"
          name={assignedMentor.firstname + ' ' + assignedMentor.lastname}
          src={assignedMentor.image}
          bgColor="brand.darkBlue"
          textColor={'white'}
        />
        <Link
          href={`/mentor/${assignedMentor.username}`}
          textColor={'orange.600'}
          fontWeight={'light'}
          fontSize={'sm'}
          pr="2"
        >
          {assignedMentor.firstname + ' ' + assignedMentor.lastname}
        </Link>
        <Text fontSize={'sm'} pr={1}>
          graded this work as{' '}
        </Text>
        <GradeBadge grade={grade} />
      </Flex>
      <Box
        rounded={'md'}
        border={'1px'}
        py={2}
        px={4}
        fontSize={'sm'}
        borderColor="gray.100"
        _hover={{ shadow: 'sm' }}
        bg={'blue.50'}
        minHeight={'16'}
      >
        <MarkdownToHtml input={comment} classname={'text-sm'} />
      </Box>
    </Flex>
  );
};

export default GradeBox;
