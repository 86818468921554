import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import moment from 'moment';
import { Clock } from '@vectopus/atlas-icons-react';

const BountyApplicationCard = ({ application }) => {
  const { opportunity } = application;
  return (
    <LinkBox
      href={`/bounties/${opportunity.slug}`}
      p={4}
      className="ring-1 ring-slate-200 rounded-lg hover:ring-slate-300"
    >
      <Flex justify={'space-between'}>
        <Box>
          <Text className="text-orange-500 font-[500] text-lg">
            ${opportunity.amount_in_usd}
          </Text>
        </Box>
        <HStack>
          <Clock className="h-4 w-4 text-gray-500" />
          <Text className="text-sm">
            Due {moment(opportunity.target_due_date).fromNow()}
          </Text>
        </HStack>
      </Flex>
      <Flex className="my-4" flexDirection={'column'}>
        <LinkOverlay href={`/bounties/${opportunity.slug}`}>
          <Text className="text-md font-semibold">{opportunity.title}</Text>
        </LinkOverlay>
        <Text className="text-sm">{opportunity.summary}</Text>
      </Flex>
      <Flex justify={'space-between'} className="bottom-0">
        <HStack>
          <Avatar
            bgColor={'orange.500'}
            size={'sm'}
            name={opportunity.author.fullname}
            src={opportunity.author.image}
          />
          <Stack verticalAlign={'true'} spacing={0.2}>
            <Text className="text-sm text-gray-500 font-[500]">
              {opportunity.author.username}
            </Text>
            <Text className="text-xs text-gray-400 font-[500]">
              Posted {moment(opportunity.created_at).fromNow()}
            </Text>
          </Stack>
        </HStack>
        <HStack>
          <Button rounded={'full'} variant={'outline'} size={'sm'}>
            <LinkOverlay href={`/bounties/${opportunity.slug}`}>
              View
            </LinkOverlay>
          </Button>
          {application.status === 'accepted' ? (
            <Button size={'sm'} rounded={'full'} colorScheme="orange">
              Submit work
            </Button>
          ) : (
            <Button
              size={'sm'}
              rounded={'full'}
              colorScheme="orange"
              isDisabled
            >
              Application {application.status}
            </Button>
          )}
        </HStack>
      </Flex>
    </LinkBox>
  );
};

export default BountyApplicationCard;
