import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Text, Button, Image } from '@chakra-ui/react';

import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import { HomeIcon } from '@heroicons/react/20/solid';

const PageNotFound = () => {
  const navigate = useNavigate();
  const handleHomeRouting = () => {
    navigate('/');
  };

  return (
    <>
      <Flex
        w="100%"
        h="100%"
        flexDirection="column"
        bg="#1a202c"
        minHeight="100%"
        dw
        position="static"
        overflow="auto"
        backgroundColor="white"
        backgroundSize="100% auto"
        backgroundRepeat="repeat"
        backgroundPosition="center"
      >
        <Navbar transparent={false} showMenu={true} />
        <Flex
          w="auto"
          minHeight="calc(100vh - 100px)"
          justifyContent="center"
          alignContent="center"
        >
          <Flex
            m="30px"
            mt="100px"
            rounded="5px"
            w={['100%', '80%', '60%', '40%']}
            alignItems="center"
            justifyContent="center"
          >
            <Flex w={['100%', '80%', '60%', '70%']} flexDirection="column">
              <main>
                <section className="w-full h-full">
                  <div className="container mx-auto h-full">
                    <div className="flex content-center items-center justify-center h-full">
                      <div className="w-full">
                        <div className="relative flex flex-col min-w-0 break-words w-full rounded-lg bg-white border-0">
                          <Flex
                            w="100%"
                            h="100%"
                            align="center"
                            justify="center"
                            flexDirection="column"
                          >
                            <Flex pt="10" pb="3" flexDirection="row" w="100%">
                            </Flex>
                            <Flex
                              flexDirection="column"
                              w="100%"
                              textAlign="center"
                            >
                              <Text
                                fontSize="28px"
                                fontWeight="1px"
                                color="gray.600"
                              >
                                Sorry, we can't seem to find the page you are
                                looking for.
                              </Text>
                            </Flex>
                            <Flex
                              pt="2"
                              pb="5"
                              flexDirection="column"
                              alignItems="center"
                            >
                              <Button
                                leftIcon={<HomeIcon className='w-4 h-4' />}
                                onClick={handleHomeRouting}
                                bgColor="brand.darkBlue"
                                textColor={'white'}
                                _hover={{ outline: 'none', shadow: 'md' }}
                                _active={{ outline: 'brand.lightBlue' }}
                                size="md"
                                textTransform="uppercase"
                                fontSize="12px"
                              >
                                {' '}
                                Techstarta Home{' '}
                              </Button>
                            </Flex>
                          </Flex>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </Flex>
          </Flex>
        </Flex>
        <Footer />
      </Flex>
    </>
  );
};

export default PageNotFound;
