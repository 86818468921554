import React, { useState } from 'react';
import Navbar from '../components/Navbar.js';
import AuthService from '../services/auth-service';
import { useToast, Button, Flex, Text } from '@chakra-ui/react';
import Validator from '../utils/validator';
import SEO from '../components/Common/SEO.js';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [showEmailError, setShowEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async event => {
    event.preventDefault();
    let isError = false;

    const isEmailValid = Validator.isEmailValid(email);

    if (!isEmailValid) {
      isError = true;
    }

    setShowEmailError(!isEmailValid);

    if (isError) {
      return;
    }

    const payload = { email: email };
    try {
      setIsLoading(true);
      const response = await AuthService.requestUserPasswordReset(payload);
      if (response === 201) {
        toast({
          title: 'Boom!',
          description:
            "We've sent you an email. Check your email to change your password.",
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (err) {
      if (err) {
        toast({
          title: 'Error',
          description: err.data.password_reset.detail,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <SEO
        title={'Reset your password'}
        url={'https://techstarta.com/forgot-password'}
      />
      <Flex
        w="100%"
        h="100%"
        bg="#1a202c"
        position="static"
        overflow="auto"
        backgroundColor="white"
        backgroundSize="100% auto"
        backgroundRepeat="repeat"
        backgroundPosition="center"
      >
        <Navbar transparent={false} showMenu={true} />
        <Flex
          w="auto"
          minHeight="calc(100vh - 350px)"
          justifyContent="center"
          alignContent="center"
        >
          <Flex
            rounded="5px"
            w={['100%', '80%', '60%', '50%']}
            alignItems="center"
            justifyContent="center"
          >
            <Flex w={['100%', '80%', '60%', '70%']} flexDirection="column">
              <main>
                <section className="absolute w-full h-full">
                  <div
                    className="absolute top-0 w-full h-full bg-gray-900"
                    style={{
                      backgroundColor: 'white',
                      backgroundSize: '100%',
                      backgroundRepeat: 'no-repeat',
                    }}
                  ></div>
                  <div className="container mx-auto px-4 h-full">
                    <div className="flex content-center items-center justify-center h-full">
                      <div className="w-full lg:w-4/12 md:w-5/12 px-4">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-2">
                          <div className="rounded-t mb-0 px-6 py-6">
                            <div className="text-center mb-3">
                              <h6 className="text-gray-700 text-sm font-bold uppercase">
                                Reset Your Password
                              </h6>
                            </div>
                          </div>
                          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                            <div className="text-gray-700 text-left mb-3 font-normal">
                              <small>
                                Please provide the email address you used when
                                you signed up for your Techstarta account.
                              </small>
                            </div>
                            <form onSubmit={handleSubmit}>
                              <div className="relative w-full mb-3">
                                <label
                                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                  for="grid-password"
                                >
                                  Email
                                </label>
                                <input
                                  type="email"
                                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                  placeholder="name@domain.com"
                                  style={{ transition: 'all .15s ease' }}
                                  onChange={event =>
                                    setEmail(event.target.value)
                                  }
                                />
                                <Text
                                  borderRadius="md"
                                  fontSize="sm"
                                  style={{
                                    display: showEmailError ? 'block' : 'none',
                                  }}
                                  mt="1"
                                  textColor={'red.500'}
                                >
                                  A valid email address must be provided.
                                </Text>
                              </div>
                              <div className="text-center mt-6">
                                <Button
                                  backgroundColor="brand.orange"
                                  color="white"
                                  fontSize="small"
                                  _hover={{
                                    shadow: 'lg',
                                    backgroundColor: 'orange.500',
                                  }}
                                  style={{ transition: 'all .15s ease' }}
                                  onClick={handleSubmit}
                                  isLoading={isLoading}
                                  loadingText="Seding..."
                                  width="100%"
                                  textTransform="uppercase"
                                  type="submit"
                                >
                                  Send email
                                </Button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
