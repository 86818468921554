import React, { useState, useEffect } from "react";
import NotificationService from "../services/notification-service"
import { NotificationItem, NotificationItemSkeleton } from "./NotificationItem"
import {
    Flex,
    Text,
} from "@chakra-ui/react";

const NotificationList = ({ setNumberOfNotifications, notificationsPage }) => {

    const [notifications, setNotifications] = useState("")

    useEffect(
        () => {
            async function getNotifications() {
                try {
                    const response = await NotificationService.getNotifications()
                    if (response.status === 200) {
                        setNotifications(response.data.notifications)
                    }
                } catch (err) {
                    setNotifications("")
                }
            }
            getNotifications()
        }, []
    )

    useEffect(
        () => {
            if (notificationsPage) {
                setNumberOfNotifications(notifications.length)
            }
        }, [setNumberOfNotifications, notifications, notificationsPage]
    )

    return (
        <>

            {/* Notifications loading state */}
            {
                !notifications && <NotificationItemSkeleton notificationsPage={notificationsPage} />
            }

            {/* IF no notifications exist */}
            {
                notifications && notifications.length < 1 && <Flex w="100%">
                    <Text fontSize={'xs'} fontWeight="normal" color="gray.500"> Your notifications will appear here. </Text>
                </Flex>
            }

            {/* If there are notifications on notifications page */}
            {
                notifications && notifications.length > 0 && notificationsPage && notifications.map((notification, index) => <NotificationItem notification={notification} notificationsPage={notificationsPage} key={index} />)
            }

            {/* If there are notifications on dashboardpage */}

            {
                notifications && notifications.length > 0 && !notificationsPage && notifications.filter((notification, index) => index < 5).map((notification, index) => <NotificationItem notification={notification} notificationsPage={notificationsPage} key={index} />)
            }
        </>
    )
}

export default NotificationList;
