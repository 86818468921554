import { connection, API_BASE_URL } from './index';

const getProfile = async username => {
  return await connection.get(API_BASE_URL + `profiles/${username}`);
};

const getProfileBadges = async username => {
  return await connection.get(API_BASE_URL + `profiles/${username}/badges`);
};

export default { getProfile, getProfileBadges };
