import React from 'react';
import { Box, Flex, Progress, Text } from '@chakra-ui/react';
import { StarBadgeAward } from '@vectopus/atlas-icons-react';
import moment from 'moment';

export const Badge = ({ badge, showProgress = true }) => {
  return (
    <Box
      w="100%"
      key={badge.id}
      className="shadow-lg rounded-lg shadow-black/5 ring-1 ring-slate-700/10 hover:ring-orange-400"
      justifyContent={'space-between'}
      alignItems="center"
    >
      <Flex
        key={badge.id}
        alignItems="center"
        p="10px"
        mt="10px"
        rounded="lg"
        cursor={'pointer'}
      >
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          color={'white'}
          rounded={'full'}
          bg={'gray.50'}
          mb={1}
        >
          <StarBadgeAward className="h-12 w-12 text-orange-500 " />
        </Flex>
        <Flex alignItems="center">
          <Flex flexDirection="column" ml="10px" justifyContent="center">
            <Text fontSize="md" fontWeight="bold" color="#2d3748">
              {badge.badge_name}
            </Text>
            <Text fontSize="sm" pb={2} textColor={'gray.700'}>
              {badge.description}
            </Text>
            {showProgress && (
              <Box>
                {badge.badge_action_count >= badge.required_action_count ? (
                  <Text className="text-xs text-gray-500">
                    {moment(badge.date_earned).format('MMM D, YYYY')}
                  </Text>
                ) : (
                  <Progress
                    size={'sm'}
                    rounded={'lg'}
                    color="teal"
                    value={
                      (badge.badge_action_count / badge.required_action_count) *
                      100
                    }
                  />
                )}
              </Box>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
