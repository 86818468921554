import { Box, Flex, Link, SimpleGrid } from '@chakra-ui/react';
import { Badge } from './Badge';

export const BadgesGrid = ({ badges, columns = [1, 2, 2, 3] }) => {
  return (
    <Flex flexDirection={'column'}>
      <SimpleGrid columns={columns} spacing={8} pb={4}>
        {badges.map(badge => (
          <Badge key={badge.badge_name} badge={badge} />
        ))}
      </SimpleGrid>
      <Box>
        <Link href="/badges" fontSize={'sm'}>
          See all badges
        </Link>
      </Box>
    </Flex>
  );
};
