import React from "react";
import { Text, Flex } from "@chakra-ui/react";
import { BookingItem, BookingItemSkeleton } from "./BookingItem";

const BookingList = ({ bookings }) => {

    return (
        <>
            {
                bookings && bookings.length > 0 && bookings.map(booking => <BookingItem booking={booking} key={booking.hash_code} />)
            }

            {
                bookings && bookings.length < 1 && <Flex w="100%" p="15px">
                <Text color="#718096"> You don't have any booking yet.</Text>
            </Flex>
            }

            {
                !bookings && <BookingItemSkeleton />
            }
        </>
    )
}

export default BookingList;