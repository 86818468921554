import React from 'react';
import { Flex, Box, Heading, Text, Button } from '@chakra-ui/react';
import { FeedbackFish } from '@feedback-fish/react';
import { HandsGestureStar } from '@vectopus/atlas-icons-react';

export default function FeedbackBox({ user }) {
  return (
    <Flex
      flexDirection="column"
      w={['100%', '100%', '100%', '100%']}
      className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10 hover:ring-gray-300 rounded-lg"
      p={4}
    >
      <Flex justifyContent="space-between">
        <Box>
          <Heading fontSize="normal" color="gray.700" fontWeight="semibold">
            Share your feedback
          </Heading>
        </Box>
      </Flex>
      <Text fontSize={'md'} py={'2'}>
        Let us know what you think about the platform. Report a bug or request a
        feature.
      </Text>
      <Flex w="100%" mt="4">
        <FeedbackFish projectId="31db9b73671d16" userId={user.email}>
          <Button
            _hover={{ shadow: 'md' }}
            variant={'solid'}
            size="sm"
            textColor={'white'}
            bgColor={'gray.700'}
            leftIcon={<HandsGestureStar className="h-5 w-5 text-white" />}
          >
            Give feedback
          </Button>
        </FeedbackFish>
      </Flex>
    </Flex>
  );
}
