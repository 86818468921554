import { API_BASE_URL, connection } from './index';
import AuthService from './auth-service';

const listSubmissions = async (username = null, status = 'graded') => {
  if (username !== null) {
    return await connection.get(
      API_BASE_URL + 'submissions?username=' + username
    );
  }

  const token = AuthService.getAuthToken();

  if (!token) {
    return await connection.get(API_BASE_URL + `submissions`);
  }

  return await connection.get(API_BASE_URL + `submissions`, {
    headers: {
      Authorization: `token ${token}`,
    },
  });
};

const fetchSubmission = async id => {
  return await connection.get(API_BASE_URL + 'submissions/' + id);
};

const postChallengeSubmission = async payload => {
  const token = AuthService.getAuthToken();
  return await connection.post(
    API_BASE_URL + 'submissions',
    {
      submission: payload,
    },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
};

const updateSubmission = async (id, payload) => {
  const token = AuthService.getAuthToken();
  return await connection.put(
    API_BASE_URL + `submissions/${id}`,
    {
      submission: payload,
    },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
};

const submitComment = async payload => {
  const token = AuthService.getAuthToken();

  return await connection.post(
    API_BASE_URL + 'comments',
    {
      comment: payload,
    },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
};

const submitGrading = async payload => {
  const token = AuthService.getAuthToken();
  return await connection.patch(
    API_BASE_URL + `submissions/${payload.submissionId}/grade`,
    {
      submission: {
        grade: payload.grade,
        grade_comment: payload.gradeComment,
      },
    },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
};

const requestMentorFeedback = async payload => {
  const token = AuthService.getAuthToken();
  return await connection.patch(
    API_BASE_URL + `submissions/${payload.submissionId}/request_feedback`,
    {
      feedback_request: {
        mentor_id: payload.mentorId,
      },
    },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
};

export default {
  makeSubmission: postChallengeSubmission,
  listSubmissions,
  updateSubmission,
  getSubmission: fetchSubmission,
  submitComment,
  submitGrading,
  requestMentorFeedback,
};
