import React from 'react';
import withRouter from '../utils/withRouter';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar';
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { BeatLoader } from 'react-spinners';
import { useMutation } from '@tanstack/react-query';
import opportunity from '../services/opportunity';

const CreateBounty = () => {
  const descriptionTemplate = `## Problem Description



## Acceptance Criteria



## Technical Details



## Timelines / Milestones`;

  const BountySchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    summary: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    target_due_date: Yup.date().required('Required'),
    amount_in_usd: Yup.number().required('Required'),
    application_deadline: Yup.date().required('Required'),
  });

  const mutation = useMutation(newBounty => {
    return opportunity.createOpportunity(newBounty);
  });

  const toast = useToast();

  return (
    <LayoutWithSidebar title="New Bounty">
      <Flex w="auto" flexDirection={'column'} mb="10px">
        <Flex justifyContent={'space-between'}>
          <Flex
            flexDirection={'column'}
            width={['100%', '100%', '100%', '80%']}
          >
            <Text>
              <span className="text-orange-600 uppercase text-xs font-semibold">
                Beta
              </span>
            </Text>
            <Heading textColor={'gray.700'} as={'h2'} textAlign="left" pb={4}>
              Post a new bounty
            </Heading>
            <Text
              textAlign="left"
              textColor={'gray.600'}
              fontSize="xl"
              fontWeight={'light'}
            >
              Work with vetted talents to transform your ideas into reality.
            </Text>
            <Text
              pt={4}
              textAlign="left"
              textColor={'gray.700'}
              fontSize="md"
            ></Text>
          </Flex>
        </Flex>
        <Flex width={'66%'}>
          <Formik
            initialValues={{
              title: '',
              summary: '',
              target_due_date: '',
              amount_in_usd: '',
              description: descriptionTemplate,
              application_deadline: '',
            }}
            validationSchema={BountySchema}
            onSubmit={async (values, actions) => {
              await mutation.mutateAsync(values);
              actions.setSubmitting(false);
              toast({
                title: 'Bounty created',
                description: 'Your bounty has been created.',
                status: 'success',
                duration: 9000,
                isClosable: true,
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <form method="post" className="w-full" onSubmit={handleSubmit}>
                <FormControl className="py-4" isRequired>
                  <FormLabel>Bounty title</FormLabel>
                  <Input
                    type="text"
                    name="title"
                    placeholder="Give your bounty a descriptive title"
                    required={true}
                    value={values.title}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ transition: 'all .15s ease' }}
                  />
                  <Text fontSize="sm" mt="1" textColor="red.500">
                    {errors.title && touched.title ? errors.title : null}
                  </Text>
                </FormControl>
                <FormControl className="py-4" isRequired>
                  <FormLabel>Bounty summary</FormLabel>
                  <Input
                    type="text"
                    name="summary"
                    value={values.summary}
                    onChange={handleChange}
                    placeholder="Describe your bounty in a few sentences"
                  />
                  <Text fontSize="sm" mt="1" textColor="red.500">
                    {errors.summary && touched.summary ? errors.summary : null}
                  </Text>
                </FormControl>

                <FormControl className="py-4" isRequired>
                  <FormLabel>Target completion date</FormLabel>
                  <Input
                    type="date"
                    name="target_due_date"
                    value={values.target_due_date}
                    onChange={handleChange}
                    placeholder="When should this bounty be completed by?"
                  />
                  <Text fontSize="sm" mt="1" textColor="red.500">
                    {errors.target_due_date && touched.target_due_date
                      ? errors.target_due_date
                      : null}
                  </Text>
                </FormControl>
                <FormControl className="py-4" isRequired>
                  <FormLabel>Amount</FormLabel>
                  <InputGroup>
                    <InputLeftAddon children="USD $" />
                    <Input
                      type="number"
                      name="amount_in_usd"
                      value={values.amount_in_usd}
                      onChange={handleChange}
                      placeholder="How much do you want to pay for this bounty?"
                    />
                  </InputGroup>
                  <Text fontSize="sm" mt="1" textColor="red.500">
                    {errors.amount && touched.amount ? errors.amount : null}
                  </Text>
                </FormControl>
                <FormControl className="py-4" isRequired>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    className="ring-1 ring-gray-300 focus:ring-2 focus:ring-orange-500 border-orange-500 shadow-slate-700"
                    value={values.description}
                    name="description"
                    onChange={handleChange}
                    minHeight={'250px'}
                  />

                  {errors.description && touched.description ? (
                    <Text fontSize="sm" mt="1" textColor="red.500">
                      {errors.description}
                    </Text>
                  ) : (
                    <FormHelperText>Markdown is supported. </FormHelperText>
                  )}
                </FormControl>
                <FormControl className="py-4" isRequired>
                  <FormLabel>Application deadline</FormLabel>
                  <Input
                    type="date"
                    name="application_deadline"
                    value={values.application_deadline}
                    onChange={handleChange}
                    placeholder="When should applications be submitted by?"
                  />
                  <Text fontSize="sm" mt="1" textColor="red.500">
                    {errors.application_deadline && touched.application_deadline
                      ? errors.application_deadline
                      : null}
                  </Text>
                </FormControl>
                <div className="py-4">
                  <Button
                    bgColor={'orange.400'}
                    _hover={{ bgColor: 'orange.500' }}
                    textColor={'white'}
                    rounded={'full'}
                    isLoading={isSubmitting}
                    type="submit"
                    spinner={<BeatLoader size={8} color="white" />}
                    disabled={isSubmitting}
                  >
                    Create draft bounty
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </Flex>
      </Flex>
    </LayoutWithSidebar>
  );
};
export default withRouter(CreateBounty);
