import { API_BASE_URL, connection } from './index';
import AuthService from './auth-service';

const getOpportunities = async () => {
  const response = await connection.get(API_BASE_URL + 'opportunities');
  return response.data;
};

const createOpportunity = async payload => {
  const token = AuthService.getAuthToken();
  return await connection.post(
    API_BASE_URL + 'opportunities',
    { opportunity: payload },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
};

const getOpportunity = async opportunityId => {
  const token = AuthService.getAuthToken();
  let response = null;

  if (token === null) {
    response = await connection.get(
      API_BASE_URL + `opportunities/${opportunityId}`
    );
  } else {
    response = await connection.get(
      API_BASE_URL + `opportunities/${opportunityId}`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
  }

  return response.data;
};

const updateOpportunity = async (slug, payload) => {
  const token = AuthService.getAuthToken();
  const response = await connection.put(
    API_BASE_URL + `opportunities/${slug}`,
    { opportunity: payload },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response.data;
};

const getUserOpportunities = async () => {
  const token = AuthService.getAuthToken();
  const response = await connection.get(API_BASE_URL + 'user/opportunities', {
    headers: {
      Authorization: `token ${token}`,
    },
  });
  return response.data;
};

const applyToOpportunity = async (opportunityId, payload) => {
  const token = AuthService.getAuthToken();
  const response = await connection.post(
    API_BASE_URL + `opportunities/${opportunityId}/applications`,
    { application: payload },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response.data;
};

const getUserApplications = async () => {
  const token = AuthService.getAuthToken();
  const response = await connection.get(API_BASE_URL + 'user/applications', {
    headers: {
      Authorization: `token ${token}`,
    },
  });
  return response.data;
};

const getOpportunityApplications = async opportunityId => {
  const token = AuthService.getAuthToken();
  const response = await connection.get(
    API_BASE_URL + `opportunities/${opportunityId}/applications`,
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response.data;
};

export default {
  getOpportunities,
  createOpportunity,
  getOpportunity,
  getUserOpportunities,
  update: updateOpportunity,
  applyToOpportunity,
  getUserApplications,
  getOpportunityApplications,
};
