import React from 'react';
import { Badge } from '@chakra-ui/react';

const GradeBadge = ({ grade }) => {
  switch (grade) {
    case 'Excellent':
      return (
        <Badge
          fontSize={'xs'}
          fontWeight={'semibold'}
          rounded={'lg'}
          colorScheme={'purple'}
        >
          {grade}
        </Badge>
      );
    case 'Good':
      return (
        <Badge
          fontSize={'xs'}
          fontWeight={'semibold'}
          rounded={'lg'}
          colorScheme={'blue'}
        >
          {grade}
        </Badge>
      );
    case 'Average':
      return (
        <Badge
          fontSize={'xs'}
          fontWeight={'semibold'}
          rounded={'lg'}
          colorScheme={'gray'}
        >
          {grade}
        </Badge>
      );
    case 'Poor':
      return (
        <Badge
          fontSize={'xs'}
          fontWeight={'semibold'}
          rounded={'lg'}
          colorScheme={'orange'}
        >
          {grade}
        </Badge>
      );
    case 'Terrible':
      return (
        <Badge
          fontSize={'xs'}
          fontWeight={'semibold'}
          rounded={'lg'}
          colorScheme={'red'}
        >
          {grade}
        </Badge>
      );
  }
};

export default GradeBadge;
