import React from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Box, Text, Badge, Skeleton } from "@chakra-ui/react";

export const BookingItem = ({ booking }) => {
  const navigate = useNavigate();
  const { hash_code, number_of_sessions } = booking;

  const viewBooking = (event) => {
    event.preventDefault();
    navigate(`/bookings/${hash_code}`);
  };

  return (
    <>
      <Flex
        shadow={"md"}
        p={["10px", "20px"]}
        color="#718096"
        mb="10px"
        alignItems="center"
        cursor="pointer"
        onClick={(event) => viewBooking(event)}
      >
        <Flex pr="10px" fontSize="15px">
          <i style={{ color: "#ed8936" }} className="fas fa-calendar-check"></i>
        </Flex>
        <Flex
          w="100%"
          justifyContent={["flex-start", "space-between"]}
          direction={["column", "column", "column", "row"]}
        >
          <Flex px="10px" alignItems="center" justifyContent="space-between">
            <Box
              justifyContent={["center", "center", "center", "flex-start"]}
              flexDirection="column"
            >
              <Text fontWeight="semibold" color="gray.700" fontSize="14px">
                 {booking.learner + ' <> ' + booking.coach}
              </Text>
            </Box>
            <Box>
              <Badge ml={["0px", "10px"]} rounded="full" px="2" colorScheme="teal">
                {}
              </Badge>

            </Box>
          </Flex>
          <Flex mt={["5px", "5px", "5px", "0px"]}>
            <Flex px="10px" color="#718096" textAlign="center">
              <Text fontSize="16px">
                {number_of_sessions} {" "} coaching sessions
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export const BookingItemSkeleton = () => {
  return (
    <>
      <Flex p={"20px"} shadow={"md"} justifyContent="space-between">
        <Skeleton w="5%" h="20px">
          {" "}
        </Skeleton>
        <Skeleton w="70%" h="20px">
          {" "}
        </Skeleton>
        <Skeleton w="20%" h="20px">
          {" "}
        </Skeleton>
      </Flex>
      <Flex p={"20px"} shadow={"md"} mt="10px" justifyContent="space-between">
        <Skeleton w="5%" h="20px">
          {" "}
        </Skeleton>
        <Skeleton w="70%" h="20px">
          {" "}
        </Skeleton>
        <Skeleton w="20%" h="20px">
          {" "}
        </Skeleton>
      </Flex>
    </>
  );
};