export const getDate = (simplifiedDate) => {
    if (simplifiedDate === true) {
        return new Date().toISOString().substr(0, 10)
    }
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const mm = months[today.getMonth()];
    const yyyy = today.getFullYear();

    today = `${dd  } ${  mm  } ${  yyyy}`;
    return today
}
