import React from 'react';
import { Box } from '@chakra-ui/react';

const YoutubeEmbed = ({ videoId }) => {
  return (
    <Box width={'100%'} rounded={'lg'}>
      <iframe
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        width={'100%'}
        style={{ borderRadius: '8px' }}
        height="572px"
      />
    </Box>
  );
};

export default YoutubeEmbed;
