import React, { useState } from 'react';
import MentorService from '../services/mentor-service.js';

import {
  Badge,
  Flex,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  Avatar,
} from '@chakra-ui/react';
import { Mixpanel } from '../utils/Mixpanel.js';
import withRouter from '../utils/withRouter.js';
import splitbee from '@splitbee/web';
import { useQuery } from '@tanstack/react-query';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar.js';
import MentorshipList from '../components/Mentorship/MentorshipList.jsx';
import authService from '../services/auth-service.js';
import MentorshipApplications from '../components/Mentorship/MentorshipApplications.jsx';
import MentorshipRequests from '../components/Mentorship/MentorshipRequests.jsx';

const Mentorships = () => {
  const currentUser = JSON.parse(authService.getCurrentUser());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedMentorshipRequest, setSelectedMentorshipRequest] =
    useState(null);
  const btnRef = React.useRef();
  Mixpanel.track('Mentorships Page');
  splitbee.track('Mentorships Page');
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(true);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const { isLoading, data } = useQuery({
    queryKey: ['mentorships'],
    queryFn: MentorService.getMentorships,
    refetchOnWindowFocus: false,
  });

  const { data: mentorshipApplications } = useQuery({
    queryKey: ['mentorshipApplications'],
    queryFn: MentorService.getMentorshipApplications,
  });

  const { data: mentorshipRequests } = useQuery({
    queryKey: ['mentorshipRequests'],
    queryFn: MentorService.getMentorshipRequests,
  });

  const handleAcceptMentorship = async mentorshipId => {
    try {
      setIsSubmitting(true);
      await MentorService.updateMentorshipRequest(mentorshipId, 'accept');
      toast({
        title: 'Success!',
        description: 'Mentorship request accepted.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: 'Oops!',
        description:
          'Unable to accept mentorship request, please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsSubmitting(false);
  };

  const handleRejectMentorship = async mentorshipId => {
    try {
      setIsSubmitting(true);
      await MentorService.updateMentorshipRequest(mentorshipId, 'reject');
      toast({
        title: 'Mentorship request rejected',
        description: 'You have rejected the mentorship request.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: 'Oops!',
        description:
          'Unable to reject mentorship request, please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsSubmitting(false);
  };

  const handleViewMentorshipRequest = mentorshipRequest => {
    if (mentorshipRequest) {
      setSelectedMentorshipRequest(mentorshipRequest);
      onOpen();
    }
  };

  return (
    <>
      <LayoutWithSidebar title={'Mentorships'}>
        <Drawer
          isOpen={isOpen}
          placement="right"
          size={'md'}
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader className="text-2xl font-semibold leading-normal text-gray-800">
              Mentorship request
            </DrawerHeader>

            <DrawerBody>
              <div className="px-4 sm:px-0">
                <h3 className="text-base font-semibold leading-7 text-gray-900">
                  Applicant Information
                </h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                  Personal details and application.
                </p>
              </div>
              <div className="mt-6 border-t border-gray-100">
                <dl className="pt-4">
                  <Avatar
                    size="lg"
                    name={selectedMentorshipRequest?.mentee?.fullname}
                    src={selectedMentorshipRequest?.mentee?.image}
                    className="mx-auto h-20 w-20 rounded-full"
                  />
                </dl>
                <dl className="divide-y divide-gray-100">
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Full name
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {selectedMentorshipRequest?.mentee?.fullname}
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Career track
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {selectedMentorshipRequest?.track.title}
                    </dd>
                  </div>

                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Mentorship goal
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {selectedMentorshipRequest?.goal}
                    </dd>
                  </div>
                </dl>
              </div>
            </DrawerBody>

            <DrawerFooter>
              <div className="space-x-4">
                <button
                  onClick={() => {
                    handleRejectMentorship(
                      selectedMentorshipRequest?.mentorship_id
                    );
                    onClose();
                  }}
                  className="inline-flex items-center px-3 py-2 border border-gray-500 text-sm leading-4 font-medium rounded-md shadow-sm text-gray-800 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  mr={3}
                >
                  Decline
                </button>
                <button
                  onClick={() => {
                    handleAcceptMentorship(
                      selectedMentorshipRequest?.mentorship_id
                    );
                    onClose();
                  }}
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                >
                  Accept
                </button>
              </div>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
        <Flex
          width={['100%', '100%', '66%', '66%']}
          pt={4}
          flexDirection="column"
        >
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-2xl font-semibold leading-normal text-gray-800">
                Manage your mentorships
              </h3>
              <p className="text-sm mb-4 font-normal leading-normal text-gray-500 pb-4">
                All your mentorships, applications and requests will appear
                here.
              </p>
            </div>
            <div>
              <button
                type="button"
                onClick={() => (window.location.href = '/mentors')}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
                Find a mentor
              </button>
            </div>
          </div>
          <div className="ring-1 ring-inset rounded-lg ring-gray-200 shadow-sm">
            <Tabs variant={'line'} _focus={{ outline: 'none' }}>
              <TabList h={12}>
                <Tab
                  _focus={{ outline: 'none' }}
                  _active={{ textColor: 'gray.500' }}
                  _selected={{
                    fontWeight: 'medium',
                    borderBottomColor: 'orange.500',
                  }}
                  key={'active'}
                >
                  <HStack className="align-middle justify-center text-sm">
                    <Text> Active </Text>
                    <Badge rounded={'full'}>{data?.results.length}</Badge>
                  </HStack>
                </Tab>
                {currentUser?.is_mentor ? (
                  <Tab
                    _focus={{ outline: 'none' }}
                    _active={{ textColor: 'gray.500' }}
                    _selected={{
                      fontWeight: 'medium',
                      borderBottomColor: 'orange.500',
                    }}
                    key={'pending'}
                  >
                    <HStack className="align-middle justify-center text-sm">
                      <Text> Needs approval </Text>
                      <Badge rounded={'full'}>
                        {mentorshipRequests?.results.length}
                      </Badge>
                    </HStack>
                  </Tab>
                ) : null}
                <Tab
                  _focus={{ outline: 'none' }}
                  _active={{ textColor: 'gray.500' }}
                  _selected={{
                    fontWeight: 'medium',
                    borderBottomColor: 'orange.500',
                  }}
                  key={'needsApproval'}
                >
                  <HStack className="align-middle justify-center text-sm">
                    <Text>Applications</Text>
                  </HStack>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel px="-4">
                  {data?.results?.length > 0 ? (
                    <MentorshipList mentorships={data.results} />
                  ) : (
                    <div className="p-4">
                      <p className="text-sm mb-4 font-normal leading-normal text-gray-500">
                        Your mentorships will appear here.
                      </p>
                      <button
                        type="button"
                        onClick={() => (window.location.href = '/mentors')}
                        className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                      >
                        Find a mentor
                      </button>
                    </div>
                  )}
                </TabPanel>
                {currentUser?.is_mentor ? (
                  <TabPanel px="-4">
                    {mentorshipRequests?.results.length > 0 ? (
                      <MentorshipRequests
                        mentorshipRequests={mentorshipRequests.results}
                        handleViewRequest={handleViewMentorshipRequest}
                        handleAcceptMentorship={handleAcceptMentorship}
                        handleRejectMentorship={handleRejectMentorship}
                        isSubmitting={isSubmitting}
                      />
                    ) : (
                      <p className="text-sm mb-4 font-normal leading-normal p-4 text-gray-500">
                        Mentorship requests sent to you will appear here.
                      </p>
                    )}
                  </TabPanel>
                ) : null}
                <TabPanel px="-4">
                  {mentorshipApplications?.results.length > 0 && !isLoading ? (
                    <MentorshipApplications
                      applications={mentorshipApplications.results}
                    />
                  ) : (
                    <p className="text-sm mb-4 font-normal leading-normal p-4 text-gray-500">
                      Your mentorship applications will appear here.
                    </p>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </Flex>
      </LayoutWithSidebar>
    </>
  );
};

export default withRouter(Mentorships);
