import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Link,
  Text,
  useToast,
  List,
  ListItem,
  ListIcon,
  HStack,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  FocusLock,
  PopoverArrow,
  PopoverCloseButton,
  useDisclosure,
  PopoverBody,
  Skeleton,
  Heading,
  LinkBox,
  SimpleGrid,
  LinkOverlay,
  Alert,
  CloseButton,
  InputGroup,
  InputRightElement,
  useClipboard,
  Input,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import FeedbackBox from '../components/FeedbackBox.js';

import { CheckCircleIcon } from '@heroicons/react/20/solid';
import {
  InformationCircleIcon,
  LightBulbIcon,
  QuestionMarkCircleIcon,
  RocketLaunchIcon,
} from '@heroicons/react/24/outline';
import { DollarBill, PuzzlePiece } from '@vectopus/atlas-icons-react';
import DashboardProfileCard from '../components/Common/DashboardProfileCard.js';
import authService from '../services/auth-service.js';
import {
  EmailIcon,
  EmailShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import TextInput from '../components/TextInput.js';

export default function MentorDashboard({ user }) {
  const title = 'Check out my Techstarta profile';
  const link = `techstarta.com/mentor/${user.username}`;
  const { hasCopied, onCopy } = useClipboard(link);

  const [isLoading, setIsLoading] = useState(true);
  const { firstname, username, is_mentor } = user;
  const [mentor, setMentor] = useState({
    achievements: { feedbacks_given: 0 },
  });
  const [profile, setProfile] = useState({});
  const toast = useToast();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    onOpen: onOpenProfileProgress,
    onClose: onCloseProfileProgress,
    isOpen: isProfileProgressVisible,
  } = useDisclosure({
    defaultIsOpen: true,
  });

  const {
    onOpen: openShareProfileModal,
    onClose: closeShareProfileModal,
    isOpen: isShareProfileModalOpen,
  } = useDisclosure();

  const concatenateSpecializations = tracks => {
    const specializations = tracks.filter(track => track.role == 'mentor');
    if (specializations.length == 1) {
      return specializations[0].track.title;
    }
    if (specializations.length == 2) {
      return (
        specializations[0].track.title + ' & ' + specializations[1].track.title
      );
    }

    let result = '';
    for (let i = 0; i < specializations.length - 1; i++) {
      result += specializations[i].track.title + ', ';
    }
    result += ' & ' + specializations[specializations.length - 1].track.title;

    return result;
  };

  const [shareProfile, setShareProfile] = useState('');

  const getProfile = async () => {
    try {
      setIsLoading(true);
      const response = await authService.getLoggedInProfile(username);
      setProfile(response);
      setMentor(response.mentor);
      const specializations = concatenateSpecializations(response.tracks);
      setShareProfile(`Hi there! 
As a Techstarta mentor, I'm excited to share my expertise in ${specializations}.

Join me on Techstarta to level up on any of the skills and topics listed on my profile. ✨`);
    } catch (err) {
      toast({
        title: 'Something went wrong',
        description: `We are unable to fetch some details. Please try again later.`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getProfile();
  }, [username, toast]);

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar
          title="Home"
          isMentor={is_mentor}
          hideBecomeAMentorButton={false}
        />
        {/* Header */}
        <div className="px-2 md:px-10 mx-auto w-full py-8">
          <div className="flex flex-wrap mt-4">
            <div className="w-full xl:w-8/12 mb-12 xl:mb-0">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 md:px-4 border-0">
                  <Modal
                    isOpen={isShareProfileModalOpen}
                    onClose={closeShareProfileModal}
                    size={'xl'}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Share your profile</ModalHeader>
                      <ModalCloseButton className="hover:bg-orange-500 focus:outline-none hover:text-white" />
                      <ModalBody>
                        <Box pb={4}>
                          <TextInput
                            element={'textArea'}
                            name={'shareProfile'}
                            value={shareProfile}
                            onChangeFunction={e =>
                              setShareProfile(e.target.value)
                            }
                          />
                        </Box>
                        <HStack spacing={2} pb="4">
                          <LinkedinShareButton
                            title={title}
                            summary={shareProfile}
                            url={link}
                            source={link}
                            className="focus:outline-none"
                          >
                            <LinkedinIcon size={32} round={true} />
                          </LinkedinShareButton>
                          <TwitterShareButton
                            title={shareProfile}
                            url={link}
                            via="techstarta"
                            hashtags={['techstarta']}
                            className="focus:outline-none"
                          >
                            <TwitterIcon size={32} round={true} />
                          </TwitterShareButton>
                          <WhatsappShareButton
                            className="focus:outline-none"
                            title={shareProfile}
                            url={link}
                          >
                            <WhatsappIcon size={32} round={true} />
                          </WhatsappShareButton>
                          <EmailShareButton
                            className="focus:outline-none"
                            subject={title}
                            body={shareProfile}
                          >
                            <EmailIcon size={32} round={true} />
                          </EmailShareButton>
                        </HStack>
                        <Text
                          textColor={'gray.600'}
                          fontSize={'sm'}
                          fontWeight="semibold"
                        >
                          or copy link
                        </Text>
                        <Box py={4}>
                          <InputGroup size="md">
                            <Input
                              pr="4.5rem"
                              type={'text'}
                              bgColor="gray.50"
                              fontSize={'sm'}
                              className="text-gray-700 font-medium"
                              readOnly={true}
                              value={link}
                            />
                            <InputRightElement width="4.5rem">
                              <Button h="1.75rem" size="sm" onClick={onCopy}>
                                {hasCopied ? 'Copied' : 'Copy'}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                        </Box>
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                  <Flex
                    rounded={'lg'}
                    flexDirection="column"
                    bgColor="white"
                    p={2}
                    mb={4}
                    alignContent={'center'}
                  >
                    <Flex color="brand.gray" flexDirection={'column'}>
                      <Heading as={'h1'}>Welcome, {`${firstname}!`}</Heading>
                      <Text
                        fontSize={'md'}
                        textColor={'gray.700'}
                        fontWeight="normal"
                      >
                        Shape the future of tech by sharing your knowledge and
                        experience.
                      </Text>
                    </Flex>
                  </Flex>
                  <Skeleton isLoaded={!isLoading} rounded={'lg'}>
                    {mentor?.status == 'approved' ? (
                      <Flex
                        bg={'white'}
                        key={1}
                        p={4}
                        rounded={'lg'}
                        className="flex flex-col md:flex md:flex-row md:items-center md:justify-between shadow-sm shadow-black/5 ring-1 ring-slate-700/10 hover:ring-gray-300"
                        mb={6}
                      >
                        <Box pb={2}>
                          <Text fontWeight={600}>
                            Your mentor profile is live! 🎉
                          </Text>
                          <Text color={'gray.700'} className="text-sm">
                            Share your profile with others and start accepting
                            mentorship requests.
                          </Text>
                        </Box>
                        <Button
                          onClick={openShareProfileModal}
                          variant={'outline'}
                          textColor={'white'}
                          _hover={{
                            textColor: 'white',
                          }}
                          className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                          size="md"
                          leftIcon={
                            <RocketLaunchIcon className="w-5 h-5 text-white" />
                          }
                          ml={[0, 0, 0, 'auto']}
                        >
                          Share
                        </Button>
                      </Flex>
                    ) : (
                      <Skeleton isLoaded={!isLoading} rounded={'lg'}>
                        {(!profile?.image || !mentor?.calendly_booking_link) &&
                        isProfileProgressVisible ? (
                          <Alert
                            rounded={'lg'}
                            status="info"
                            alignItems={'start'}
                            variant={'subtle'}
                            justifyContent={'space-between'}
                            p={6}
                            mb={4}
                            shadow={'sm'}
                          >
                            <Box>
                              <Text
                                fontSize={'lg'}
                                textColor={'gray.700'}
                                fontWeight={'semibold'}
                              >
                                You're almost set{' '}
                              </Text>
                              <List spacing={3} my={4}>
                                <ListItem>
                                  <HStack>
                                    <ListIcon
                                      as={() =>
                                        profile?.image ? (
                                          <CheckCircleIcon className="w-5 h-5 text-orange-500" />
                                        ) : (
                                          <QuestionMarkCircleIcon className="w-5 h-5 text-orange-500" />
                                        )
                                      }
                                    />
                                    <Text
                                      className={
                                        profile?.image ? 'line-through' : ''
                                      }
                                    >
                                      Update your profile picture
                                    </Text>
                                  </HStack>
                                </ListItem>
                                <ListItem>
                                  <HStack>
                                    <ListIcon
                                      as={() =>
                                        mentor?.calendly_booking_link ? (
                                          <CheckCircleIcon className="w-5 h-5 text-orange-500" />
                                        ) : (
                                          <QuestionMarkCircleIcon className="w-5 h-5 text-orange-500" />
                                        )
                                      }
                                    />
                                    <Text
                                      className={
                                        mentor?.calendly_booking_link
                                          ? 'line-through'
                                          : ''
                                      }
                                    >
                                      Add your calendar link
                                    </Text>
                                    <Popover
                                      isOpen={isOpen}
                                      onOpen={onOpen}
                                      onClose={onClose}
                                      placement="right"
                                      closeOnBlur={false}
                                    >
                                      <PopoverTrigger>
                                        <IconButton
                                          aria-label="info"
                                          _active={{ outline: 'none' }}
                                          _focus={{ outline: 'none' }}
                                          variant={'link'}
                                          icon={
                                            <InformationCircleIcon className="w-5 h-5 text-orange-500" />
                                          }
                                        />
                                      </PopoverTrigger>
                                      <PopoverContent p={5}>
                                        <FocusLock
                                          returnFocus
                                          persistentFocus={false}
                                        >
                                          <PopoverArrow />
                                          <PopoverCloseButton />
                                        </FocusLock>
                                        <PopoverBody>
                                          <Text fontSize="sm">
                                            Your future mentees will be able to
                                            book meetings with you using Cal.com
                                            or Calendly. You can create a
                                            Cal.com account{' '}
                                            <Link
                                              textColor={'brand.darkBlue'}
                                              fontWeight={'semibold'}
                                              size={'sm'}
                                              variant={'outline'}
                                              className="underline"
                                              width="40"
                                              fontSize="sm"
                                              href="https://cal.com/"
                                              isExternal
                                            >
                                              here
                                            </Link>
                                          </Text>
                                        </PopoverBody>
                                      </PopoverContent>
                                    </Popover>
                                  </HStack>
                                </ListItem>
                                <ListItem>
                                  <HStack>
                                    <ListIcon
                                      as={() =>
                                        profile?.skills?.length > 0 ? (
                                          <CheckCircleIcon className="w-5 h-5 text-orange-500" />
                                        ) : (
                                          <QuestionMarkCircleIcon className="w-5 h-5 text-orange-500" />
                                        )
                                      }
                                    />
                                    <Text
                                      className={
                                        profile?.skills?.length > 0
                                          ? 'line-through'
                                          : ''
                                      }
                                    >
                                      Add your skills
                                    </Text>
                                  </HStack>
                                </ListItem>
                                <ListItem>
                                  <HStack>
                                    <ListIcon
                                      as={() =>
                                        mentor?.topics?.length > 0 ? (
                                          <CheckCircleIcon className="w-5 h-5 text-orange-500" />
                                        ) : (
                                          <QuestionMarkCircleIcon className="w-5 h-5 text-orange-500" />
                                        )
                                      }
                                    />
                                    <Text
                                      className={
                                        mentor?.topics?.length > 0
                                          ? 'line-through'
                                          : ''
                                      }
                                    >
                                      Add your mentoring topics
                                    </Text>
                                  </HStack>
                                </ListItem>
                                <ListItem>
                                  <HStack>
                                    <ListIcon
                                      as={() =>
                                        mentor?.status === 'pending' ? (
                                          <CheckCircleIcon className="w-5 h-5 text-orange-500" />
                                        ) : (
                                          <QuestionMarkCircleIcon className="w-5 h-5 text-orange-500" />
                                        )
                                      }
                                    />
                                    <Text
                                      className={
                                        mentor?.status === 'approved'
                                          ? 'line-through'
                                          : ''
                                      }
                                    >
                                      Your mentor application is pending
                                      approval
                                    </Text>
                                  </HStack>
                                </ListItem>
                              </List>

                              <a
                                className="text-gray-700 underline hover:text-gray-600"
                                href="/settings"
                              >
                                Complete profile setup
                              </a>
                            </Box>
                            <CloseButton onClick={onCloseProfileProgress} />
                          </Alert>
                        ) : null}
                      </Skeleton>
                    )}
                  </Skeleton>

                  <HStack margin={'24px 0'}>
                    <RocketLaunchIcon className="w-6 h-6 text-gray-600" />
                    <Text
                      fontSize="lg"
                      fontWeight="bold"
                      textColor={'gray.700'}
                    >
                      What would you like to do today?
                    </Text>
                  </HStack>

                  <SimpleGrid
                    spacing={4}
                    columns={[1, 2, 2, 2]}
                    justifyContent="space-evenly"
                  >
                    <LinkBox key={'gotoCommunity'}>
                      <Box
                        className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10 hover:ring-gray-300"
                        rounded={'lg'}
                        bgColor="white"
                        p={4}
                      >
                        <Flex
                          w={16}
                          h={16}
                          align={'center'}
                          justify={'center'}
                          color={'white'}
                          rounded={'full'}
                          bg={'gray.50'}
                          mb={1}
                        >
                          <LightBulbIcon className="w-8 h-8 text-orange-500" />
                        </Flex>
                        <Flex justifyContent={'space-between'}>
                          <Box>
                            <LinkOverlay href="/community">
                              <Text fontSize={'lg'} fontWeight="semibold">
                                Share your insights
                              </Text>
                            </LinkOverlay>
                            <Text
                              py={2}
                              fontWeight="base"
                              fontSize={'md'}
                              className="text-gray-700"
                            >
                              Help others grow by sharing your knowledge and
                              experience.
                            </Text>
                          </Box>
                        </Flex>
                      </Box>
                    </LinkBox>
                    {/* <LinkBox key={'learn'}>
                      <Box
                        className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10 hover:ring-gray-300"
                        rounded={'lg'}
                        bgColor="white"
                        p={4}
                      >
                        <Flex
                          w={16}
                          h={16}
                          align={'center'}
                          justify={'center'}
                          color={'white'}
                          rounded={'full'}
                          bg={'gray.50'}
                          mb={1}
                        >
                          <Tools className="w-8 h-8 text-orange-600" />
                        </Flex>
                        <Flex justifyContent={'space-between'}>
                          <Box>
                            <LinkOverlay href="/community">
                              <Text fontSize={'lg'} fontWeight="semibold">
                                Host a workshop
                              </Text>
                            </LinkOverlay>
                            <Text
                              py={2}
                              fontWeight="base"
                              fontSize={'md'}
                              className="text-gray-700"
                            >
                              Host a workshop to help others learn and grow.
                            </Text>
                          </Box>
                        </Flex>
                      </Box>
                    </LinkBox> */}
                    <LinkBox key={'postBounty'}>
                      <Box
                        className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10 hover:ring-gray-300"
                        rounded={'lg'}
                        bgColor="white"
                        p={4}
                      >
                        <Flex
                          w={16}
                          h={16}
                          align={'center'}
                          justify={'center'}
                          color={'white'}
                          rounded={'full'}
                          bg={'gray.50'}
                          mb={1}
                        >
                          <DollarBill className="w-8 h-8 text-orange-500" />
                        </Flex>
                        <Flex justifyContent={'space-between'}>
                          <Box>
                            <LinkOverlay href="/bounties/create">
                              <Text fontSize={'lg'} fontWeight="semibold">
                                Post a bounty
                              </Text>
                            </LinkOverlay>
                            <Text
                              py={2}
                              fontWeight="base"
                              fontSize={'md'}
                              className="text-gray-700"
                            >
                              Work with top talents on your projects and pay
                              them for their contributions.
                            </Text>
                          </Box>
                        </Flex>
                      </Box>
                    </LinkBox>
                    <LinkBox key={'contributeProject'}>
                      <Box
                        className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10 hover:ring-gray-300"
                        rounded={'lg'}
                        bgColor="white"
                        p={4}
                      >
                        <Flex
                          w={16}
                          h={16}
                          align={'center'}
                          justify={'center'}
                          color={'white'}
                          rounded={'full'}
                          bg={'gray.50'}
                          mb={1}
                        >
                          <PuzzlePiece className="w-8 h-8 text-orange-500" />
                        </Flex>
                        <Flex justifyContent={'space-between'}>
                          <Box>
                            <LinkOverlay href="/projects/contribute">
                              <Text fontSize={'lg'} fontWeight="semibold">
                                Contribute a project
                              </Text>
                            </LinkOverlay>
                            <Text
                              py={2}
                              fontWeight="base"
                              fontSize={'md'}
                              className="text-gray-700"
                            >
                              Real-world projects help newbies learn and grow.
                            </Text>
                          </Box>
                        </Flex>
                      </Box>
                    </LinkBox>
                    <FeedbackBox user={user} />
                  </SimpleGrid>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-4/12 px-4 pb-12">
              <Skeleton isLoaded={!isLoading} rounded={'lg'}>
                {profile ? (
                  <DashboardProfileCard profile={profile} isMentor={true} />
                ) : null}
              </Skeleton>
            </div>
            <Flex width="100%" flexWrap="wrap">
              <DashboardFooter />
            </Flex>
          </div>
        </div>
      </div>
    </>
  );
}
