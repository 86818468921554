import React from 'react';
import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/react';
import splitbee from '@splitbee/web';
import './styles/compiled-theme.min.css';
import './index.css';
import * as serviceWorker from './serviceWorker';

import '@fortawesome/fontawesome-free/css/all.min.css';
import ThemedApp from './App';
import { HelmetProvider } from 'react-helmet-async';
import authService from './services/auth-service';

// Sentry.init({
//   dsn: 'https://2aa7ce3d5b0e4b90b44755bcf2383912@o435092.ingest.sentry.io/5392943',
// });

splitbee.init({
  token: '3RD6BLDFG9VW',
});

const currentUser = JSON.parse(authService.getCurrentUser());

const helmetContext = {};
const container = document.getElementById('root');

if (document.getElementById('root')) {
  const root = createRoot(container);
  root.render(
    <HelmetProvider context={helmetContext}>
      <ThemedApp />
    </HelmetProvider>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
