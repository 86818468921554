import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Flex,
  Text,
  HStack,
  Heading,
  SimpleGrid,
} from '@chakra-ui/react';
import { FaQuoteLeft } from 'react-icons/fa';

const EmptyStar = () => (
  <svg
    className="w-4 h-4 text-gray-300 dark:text-gray-500"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 22 20"
  >
    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
  </svg>
);

const FilledStar = () => (
  <svg
    className="w-4 h-4 text-yellow-400"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 22 20"
  >
    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
  </svg>
);

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([
    {
      id: 1,
      name: 'Marnin Audu',
      track: 'Frontend Development',
      avatarUrl:
        'https://storage.googleapis.com/talentmine/profiles/DevfestPicture.jpg',
      description:
        'The well curated projects which provide just enough information for you to be able to build your project on your own without needing a step by step guide. Techstarta provides very good experience with the projects especially, and I would like to share that with others.',
    },
    {
      id: 2,
      name: 'Elizabeth Ogunmola',
      track: 'Backend Development',
      description:
        "The simplified approach to learning and starting out in tech is so great. It's so easy to navigate and yeah the best choice for every beginner.",
    },
  ]);

  return (
    <section id="testimonials" className="testimonials">
      <Flex className="container" flexDirection={'column'}>
        <div className="section-title">
          <Text className="uppercase text-xs font-semibold text-orange-500 tracking-wide py-2">
            Testimonials
          </Text>
          <Heading as={'h2'} className="text-slate-700 pb-6">
            What people are saying
          </Heading>
        </div>

        <div className="owl-carousel testimonials-carousel">
          <SimpleGrid columns={[1, 1, 1, 2]} gap={6}>
            {testimonials.map(testimonial => (
              <Box
                key={testimonial.id}
                className="pointer-events-auto rounded-lg bg-white p-4 text-[0.8125rem] leading-5 shadow-xl shadow-black/5 hover:bg-stone-50 ring-1 ring-slate-700/10"
                p={4}
                textColor={'gray.600'}
                minHeight={'16'}
              >
                <div className="flex items-center space-x-1 pb-4">
                  <FilledStar />
                  <FilledStar />
                  <FilledStar />
                  <FilledStar />
                  <FilledStar />
                </div>
                <Box pb={2}>
                  <FaQuoteLeft className="quote-sign-left" />
                </Box>
                <p className="font-medium text-gray-700 text-lg py-4">
                  "{testimonial.description}"
                </p>
                <HStack alignItems={'center'}>
                  <Avatar
                    src={testimonial.avatarUrl}
                    className="testimonial-img"
                    bgColor={'gray.700'}
                    name={testimonial.name}
                  />
                  <Box>
                    <h3 className="text-md font-semibold">
                      {testimonial.name}
                    </h3>
                    <h4 className="text-xm">{testimonial.track} Mentee</h4>
                  </Box>
                </HStack>
              </Box>
            ))}
          </SimpleGrid>
        </div>
      </Flex>
    </section>
  );
};

export default Testimonials;
