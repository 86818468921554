import { connection, API_BASE_URL } from "./index"

const createNewsletterSubscription = async (payload) => {
  const response = await connection.post(API_BASE_URL + "newsletters", {
    subscriber: payload,
  });
  return response.status;
};

const confirmNewsletterSubscription = async (payload) => {
  const response = await connection.put(API_BASE_URL + "newsletter/confirm", {
    subscriber: payload,
  });
  return response.status;
};

const registerForWebinar = async (payload) => {
  const response = await connection.post(API_BASE_URL + "webinars/register", payload);
  return response.status;
}

const deleteNewsletterSubscription = async (payload) => {
  const response = await connection.delete(API_BASE_URL + "newsletters", {
    subscriber: payload,
  });
  return response.status;
};

export default {
  createNewsletterSubscription,
  deleteNewsletterSubscription,
  confirmNewsletterSubscription,
  registerForWebinar,
};
