import { Box, Button } from '@chakra-ui/react';
import { memo, useState } from 'react';
import MarkdownToHtml from './ReactMarkdownItem';

export const ExpandableText = memo(({ children, limit = 700 }) => {
  const text = children;
  const [isExpanded, setIsExpanded] = useState(false);

  if (text.length <= limit) {
    return (
      <Box>
        <MarkdownToHtml input={text} classname={'text-justify'} />
      </Box>
    );
  }

  return (
    <Box>
      <Box noOfLines={isExpanded ? 100 : limit}>
        <MarkdownToHtml input={text} classname={'text-justify'} />
      </Box>

      <Button
        _focus={{ outline: 'none' }}
        variant="link"
        colorScheme="orange"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? 'Show less' : 'Read more'}
      </Button>
    </Box>
  );
});
