import React from 'react';
import { Badge, Tag } from '@chakra-ui/react';

const TrackLabel = ({ track }) => {
  switch (track) {
    case 'User Interface and Experience Design':
      return (
        <Tag fontSize={'xs'} rounded={'lg'} colorScheme={'purple'}>
          UI/UX
        </Tag>
      );

    default:
      return (
        <Tag fontSize={'xs'} rounded={'lg'} colorScheme={'gray'}>
          {track}
        </Tag>
      );
  }
};

export default TrackLabel;
