import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Heading,
  HStack,
  Button,
  SimpleGrid,
  LinkBox,
  LinkOverlay,
  Divider,
  Tag,
  Link,
  Skeleton,
} from '@chakra-ui/react';
import splitbee from '@splitbee/web';
import { useQuery } from '@tanstack/react-query';

import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import AuthService from '../services/auth-service';
import { Mixpanel } from '../utils/Mixpanel';

import {
  AcademicCapIcon,
  BoltIcon,
  BriefcaseIcon,
  ChatBubbleLeftRightIcon,
  RocketLaunchIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@chakra-ui/icons';
import submissionsService from '../services/submissions-service.js';
import SubmissionStatus from '../components/Submission/SubmissionStatus.jsx';
import subscriptionService from '../services/subscription-service.js';
import { MedalAchievement } from '@vectopus/atlas-icons-react';
import mentorService from '../services/mentor-service.js';
import { MentorItem, MentorItemSkeleton } from '../components/MentorItem.js';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DashboardProfileCard from '../components/Common/DashboardProfileCard.js';
import { useNavigate } from 'react-router-dom';

export default function Dashboard({ user }) {
  const [isLoading, setIsLoading] = useState(true);
  const { email, firstname, is_mentor } = user;
  const [careerPaths, setCareerPaths] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [profile, setProfile] = useState({});
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  Mixpanel.track('Home Page', { email: email });
  splitbee.track('Home Page', { email: email });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    padding: '0 20px',
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const navigate = useNavigate();

  const {
    isLoading: isMentorshipsLoading,
    error: errorFetchingMentorships,
    data: mentorshipData,
  } = useQuery({
    queryKey: ['mentorships', 'mentee'],
    queryFn: () => mentorService.getMentorships(),
    enabled: !isLoading,
  });

  const {
    isLoading: isMentorsLoading,
    error: errorFetchingMentors,
    data: mentorData,
  } = useQuery({
    queryKey: ['mentors', careerPaths[0]?.track.slug],
    queryFn: () => mentorService.getMentorList([careerPaths[0]?.track.slug]),
    enabled: !isLoading,
  });

  useEffect(() => {
    async function fetchLoggedInProfile() {
      const response = await AuthService.getLoggedInProfile(user.username);
      setProfile(response);
      setCareerPaths(response.tracks);
      setIsLoading(false);
    }
    fetchLoggedInProfile();
  }, []);

  useEffect(() => {
    async function fetchSubmissions() {
      if (user) {
        const response = await submissionsService.listSubmissions(
          user.username
        );
        if (response.status === 200) {
          setSubmissions(response.data.submissions);
        }
      }
    }
    fetchSubmissions();
  }, [user?.username]);

  const SUBSCRIBTION_PLAN_PRO = 'price_1NdTNWEyLuaj2xNpikFKZIXQ';

  const goToCheckout = async priceId => {
    setCheckoutLoading(true);
    Mixpanel.track('Pro Subscription Clicked', user);

    const payload = { price_id: priceId };
    if (user) {
      payload.customer_email = user.email;
    }
    const response = await subscriptionService.getCheckoutUrl(payload);
    setCheckoutLoading(false);
    const checkoutSessionUrl = response.checkout.url;
    window.open(checkoutSessionUrl, '_blank', 'noopener noreferrer');
  };

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar
          title="Home"
          isMentor={is_mentor}
          hideBecomeAMentorButton={false}
        />
        {/* Header */}
        <div className="px-2 md:px-10 mx-auto w-full md:py-8">
          <div className="flex flex-wrap mt-4">
            <div className="w-full xl:w-8/12 mb-12 xl:mb-0 md:px-2">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 md:px-2 border-0">
                  <Flex flexDirection="column">
                    <Flex
                      rounded={'lg'}
                      bgColor="white"
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      mb={4}
                    >
                      <Box w="95%">
                        <Text fontSize={'2xl'} fontWeight="semibold">
                          Welcome, {`${firstname}!`}
                        </Text>
                        <Text
                          fontWeight="base"
                          fontSize={'md'}
                          className="text-gray-700"
                        >
                          Get ahead in your tech career with personalized
                          mentorship with vetted industry experts
                        </Text>
                      </Box>
                    </Flex>

                    {/* Let's get you started */}

                    <HStack margin={'24px 0'}>
                      <UsersIcon className="w-6 h-6 text-gray-600" />
                      <Text
                        fontSize="lg"
                        fontWeight="bold"
                        textColor={'gray.700'}
                      >
                        Recommended mentors for you
                      </Text>
                    </HStack>

                    <div style={{ margin: '0 -7px' }} className="pb-8">
                      <Slider {...settings}>
                        {mentorData?.data?.results.map(mentor => (
                          <div key={mentor.user_id} className="p-2">
                            <MentorItem mentor={mentor} />
                          </div>
                        ))}
                      </Slider>
                      {isMentorsLoading && (
                        <SimpleGrid px={2} columns={[1, 2, 2, 2]} spacing={4}>
                          <MentorItemSkeleton />
                          <MentorItemSkeleton />
                        </SimpleGrid>
                      )}
                    </div>

                    {/* Next steps */}

                    <HStack margin={'24px 0'}>
                      <RocketLaunchIcon className="w-6 h-6 text-gray-600" />
                      <Text
                        fontSize="lg"
                        fontWeight="bold"
                        textColor={'gray.700'}
                      >
                        What would you like to do next?
                      </Text>
                    </HStack>

                    <SimpleGrid
                      spacing={4}
                      columns={[1, 2, 2, 2]}
                      justifyContent="space-evenly"
                    >
                      <LinkBox key={'portfolioBuilding'}>
                        <Box
                          className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10 hover:ring-gray-300"
                          rounded={'lg'}
                          bgColor="white"
                          p={4}
                        >
                          <Flex
                            w={16}
                            h={16}
                            align={'center'}
                            justify={'center'}
                            color={'white'}
                            rounded={'full'}
                            bg={'gray.50'}
                            mb={1}
                          >
                            <AcademicCapIcon className="w-8 h-8 text-orange-600" />
                          </Flex>
                          <Flex justifyContent={'space-between'}>
                            <Box>
                              <LinkOverlay href="/skills">
                                <Text fontSize={'lg'} fontWeight="semibold">
                                  Build your portfolio
                                </Text>
                              </LinkOverlay>
                              <Text
                                py={2}
                                fontWeight="base"
                                fontSize={'md'}
                                className="text-gray-700"
                              >
                                Build your portfolio with projects that
                                demonstrate your skills.
                              </Text>
                            </Box>
                          </Flex>
                        </Box>
                      </LinkBox>
                      <LinkBox key={'learn'}>
                        <Box
                          className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10 hover:ring-gray-300"
                          rounded={'lg'}
                          bgColor="white"
                          p={4}
                        >
                          <Flex
                            w={16}
                            h={16}
                            align={'center'}
                            justify={'center'}
                            color={'white'}
                            rounded={'full'}
                            bg={'gray.50'}
                            mb={1}
                          >
                            <ChatBubbleLeftRightIcon className="w-8 h-8 text-orange-600" />
                          </Flex>
                          <Flex justifyContent={'space-between'}>
                            <Box>
                              <LinkOverlay href="/community">
                                <Text fontSize={'lg'} fontWeight="semibold">
                                  Ask a question to our community
                                </Text>
                              </LinkOverlay>
                              <Text
                                py={2}
                                fontWeight="base"
                                fontSize={'md'}
                                className="text-gray-700"
                              >
                                Learn from others or share your knowledge with
                                the community.
                              </Text>
                            </Box>
                          </Flex>
                        </Box>
                      </LinkBox>
                    </SimpleGrid>

                    {/* Jump back in */}
                    <Skeleton
                      mt={'8'}
                      rounded={'lg'}
                      isLoaded={!isLoading}
                      pb={6}
                    >
                      {submissions.length > 0 && (
                        <Flex
                          flexDirection={'column'}
                          p={4}
                          className="rounded-lg shadow-sm shadow-black/5 ring-1 ring-slate-700/10"
                        >
                          <Flex mb="20px" flexDirection={'column'}>
                            <Flex justify={'space-between'}>
                              <HStack
                                pb={2}
                                color="#2d3748"
                                alignItems={'center'}
                                fontWeight="bold"
                              >
                                <BriefcaseIcon className="w-6 h-6 text-gray-600" />
                                <Heading
                                  fontSize="lg"
                                  color="gray.700"
                                  fontWeight="bold"
                                >
                                  Current Projects
                                </Heading>
                              </HStack>
                              {/* <Link href="/projects">
                                <a>
                                  <Text
                                    fontSize={'sm'}
                                    fontWeight="semibold"
                                    textColor={'gray.600'}
                                  >
                                    View all
                                  </Text>
                                </a>
                              </Link> */}
                            </Flex>
                            <Text fontSize={'sm'} textColor={'gray.600'}>
                              {submissions.length}{' '}
                              {`${'project'}${
                                submissions.length > 1 ? 's' : ''
                              }`}
                            </Text>
                          </Flex>
                          <SimpleGrid spacing={'8'} columns={[1, 1, 1, 1]}>
                            {submissions.map(submission => (
                              <LinkBox key={submission.id} w={'100%'}>
                                <Flex
                                  shadow={'sm'}
                                  border={'1px'}
                                  borderColor="gray.100"
                                  key={submission.id}
                                  w="100%"
                                  flexDirection={['column', 'row', 'row']}
                                  rounded="lg"
                                  justify={'space-between'}
                                  align={['flex-start', 'center', 'center']}
                                  p={2}
                                  cursor={'pointer'}
                                  className="hover:shadow-sm hover:ring-1 hover:ring-gray-200"
                                >
                                  <Flex
                                    minWidth={'50%'}
                                    className="my-4 md:my-0"
                                    flexDirection="column"
                                    justifyContent="center"
                                  >
                                    <LinkOverlay
                                      href={`/projects/${submission.challenge.slug}`}
                                    >
                                      <Text
                                        pb={2}
                                        fontSize="sm"
                                        fontWeight="bold"
                                        color="#2d3748"
                                        noOfLines={2}
                                      >
                                        {submission.challenge.title}
                                      </Text>
                                    </LinkOverlay>
                                    <HStack>
                                      <SubmissionStatus
                                        status={submission.status}
                                      />
                                      <Tag
                                        width={'max-content'}
                                        variant={'outline'}
                                        borderColor={'gray.200'}
                                        fontSize={'xs'}
                                        textColor={'gray.600'}
                                        bgColor={'white'}
                                      >
                                        {submission.challenge.track}
                                      </Tag>
                                    </HStack>
                                  </Flex>
                                  <Flex
                                    alignItems="center"
                                    minWidth={'10%'}
                                    justifyContent="flex-end"
                                  >
                                    <Button
                                      as={Link}
                                      href={`/projects/${submission.challenge.slug}`}
                                      size="xs"
                                      variant={'link'}
                                      rightIcon={
                                        <ChevronRightIcon className="h-6 w-6 text-gray-600" />
                                      }
                                    >
                                      <span>View</span>
                                    </Button>
                                  </Flex>
                                </Flex>
                              </LinkBox>
                            ))}
                          </SimpleGrid>
                        </Flex>
                      )}
                    </Skeleton>

                    <Skeleton isLoaded={!isLoading} rounded={'lg'}>
                      {careerPaths?.length > 0 && (
                        <Flex
                          flexDirection={'column'}
                          mt={4}
                          p={4}
                          className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10 rounded-lg"
                        >
                          <Flex mb="20px" flexDirection={'column'}>
                            <HStack
                              pb={2}
                              color="#2d3748"
                              justifyItems={'center'}
                              fontWeight="bold"
                            >
                              <AcademicCapIcon className="w-6 h-6 text-gray-600" />
                              <Heading
                                fontSize="lg"
                                color="gray.700"
                                fontWeight="bold"
                              >
                                Your Career Paths
                              </Heading>
                            </HStack>
                          </Flex>
                          <SimpleGrid columns={[1, 2, 2, 2]} spacing={4}>
                            {careerPaths.map((track, index) => (
                              <LinkBox
                                width={'100%'}
                                border={'1px'}
                                borderColor="gray.200"
                                rounded={'lg'}
                                key={track.track.slug}
                                size="lg"
                                direction={'column'}
                                _hover={{ shadow: 'md', rounded: 'lg' }}
                                mb="2"
                              >
                                <Box p={4}>
                                  <LinkOverlay
                                    href={`/skills/${track.track.slug}`}
                                  >
                                    <Text
                                      lineHeight={'4'}
                                      fontWeight="semibold"
                                      fontSize={'md'}
                                      textColor={'gray.600'}
                                    >
                                      {track.track.title}
                                    </Text>
                                  </LinkOverlay>
                                </Box>
                                <Divider />
                                <Flex
                                  p={4}
                                  justifyContent="space-between"
                                  alignItems={'center'}
                                  bgColor={'gray.50'}
                                >
                                  <HStack>
                                    <MedalAchievement className="h-5 w-5 text-orange-600" />
                                    <Text
                                      lineHeight={'4'}
                                      textColor={'gray.600'}
                                    >
                                      {track.total_earned_points} points
                                    </Text>
                                  </HStack>

                                  <Button
                                    as={Link}
                                    href={`/skills/${track.track.slug}`}
                                    _hover={{ shadow: 'md' }}
                                    bgColor={'gray.700'}
                                    textColor="white"
                                    size={'sm'}
                                    rounded={'full'}
                                  >
                                    Resume
                                  </Button>
                                </Flex>
                              </LinkBox>
                            ))}
                          </SimpleGrid>
                        </Flex>
                      )}
                    </Skeleton>
                  </Flex>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-4/12 md:px-4 pb-12">
              <Skeleton isLoaded={!isLoading} rounded={'lg'}>
                <DashboardProfileCard profile={profile} />
              </Skeleton>
              <Flex
                my={6}
                flexDirection="column"
                w={['100%', '100%', '100%', '100%']}
                rounded="lg"
                bgColor={'brand.darkBlue'}
                className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10"
                p={4}
              >
                <Flex justifyContent="space-between" mb="20px">
                  <Box>
                    <Heading
                      pb={1}
                      fontSize="lg"
                      color="gray.50"
                      fontWeight="semibold"
                    >
                      Get personalized mentorship
                    </Heading>
                  </Box>
                </Flex>
                <ul
                  role="list"
                  className="grid grid-cols-1 gap-4 text-sm leading-6 text-white"
                >
                  <li className="flex gap-x-3">
                    <svg
                      className="h-6 w-5 flex-none text-orange-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Unlimited access to real-world projects
                  </li>

                  <li className="flex gap-x-3">
                    <svg
                      className="h-6 w-5 flex-none text-orange-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Create your skill-based shareable portfolio
                  </li>
                  <li className="flex gap-x-3">
                    <svg
                      className="h-6 w-5 flex-none text-orange-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Get expert feedback on every project submission
                  </li>
                  <li className="flex gap-x-3">
                    <svg
                      className="h-6 w-5 flex-none text-orange-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Exclusive access to paid projects and internships
                  </li>
                </ul>
                <Flex w="100%" mt="4">
                  <Button
                    size="md"
                    rounded={'full'}
                    variant={'solid'}
                    bgColor={'white'}
                    onClick={() => {
                      splitbee.track('Upgrade membership', {
                        location: 'Dashboard',
                      });
                      navigate('/membership');
                    }}
                    _active={{
                      outlineColor: 'orange.500',
                    }}
                    _focus={{
                      outlineColor: 'orange.500',
                    }}
                    _hover={{ shadow: 'lg' }}
                    isLoading={checkoutLoading}
                    textColor={'gray.700'}
                    leftIcon={<BoltIcon className="h-4 w-4 text-orange-500" />}
                  >
                    Upgrade membership
                  </Button>
                </Flex>
              </Flex>
            </div>

            <Flex width="100%" flexWrap="wrap">
              <DashboardFooter />
            </Flex>
          </div>
        </div>
      </div>
    </>
  );
}
