import React, { useState } from 'react';
import {
  Flex,
  Text,
  Button,
  useToast,
  Box,
  Heading,
  Show,
  Skeleton,
  HStack,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link,
} from '@chakra-ui/react';
import {
  ArrowUpTrayIcon,
  ListBulletIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { MoneyBagDollar } from '@vectopus/atlas-icons-react';
import { useQuery } from '@tanstack/react-query';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar.js';
import opportunity from '../services/opportunity.js';
import withRouter from '../utils/withRouter.js';
import BountyCard from '../components/Bounty/BountyCard.jsx';
import moment from 'moment';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import BountyApplicationCard from '../components/Bounty/BountyApplicationCard.jsx';

const Bounties = () => {
  const toast = useToast();
  const [tabIndex, setTabIndex] = useState(0);
  const [userOpportunities, setUserOpportunities] = useState([]);
  const [applications, setApplications] = useState([]);
  const [isApplicationsLoading, setIsApplicationsLoading] = useState(false);

  const { isLoading, error, data } = useQuery({
    queryKey: ['bounties'],
    queryFn: opportunity.getOpportunities,
  });

  const fetchUserOpportunties = useQuery({
    queryKey: ['user-opportunities'],
    queryFn: opportunity.getUserOpportunities,
    enabled: false,
  });

  const handleTabsChange = async index => {
    setTabIndex(index);
    if (index === 1) {
      try {
        const { data } = await fetchUserOpportunties.refetch();
        setUserOpportunities(data.results);
      } catch (err) {
        toast({
          title: 'Oops!',
          description: 'Unable to fetch bounties, please try again later',
          status: 'error',
        });
      }
    }

    if (index === 2) {
      setIsApplicationsLoading(true);
      try {
        const { applications } = await opportunity.getUserApplications();
        setApplications(applications);
      } catch (err) {
        toast({
          title: 'Oops!',
          description: 'Unable to fetch applications, please try again later',
          status: 'error',
        });
      }
      setIsApplicationsLoading(false);
    }
  };

  const publishOpportunity = async slug => {
    try {
      const response = await opportunity.publishOpportunity(slug);
      toast({
        title: 'Success!',
        description: 'Bounty published successfully',
        status: 'success',
      });
    } catch (err) {
      toast({
        title: 'Oops!',
        description: 'Unable to publish bounty, please try again later',
        status: 'error',
      });
    }
  };

  return (
    <LayoutWithSidebar title={'Bounties'}>
      <Flex w="auto" flexDirection={'column'} mb="10px">
        <Flex justifyContent={'space-between'}>
          <Flex
            flexDirection={'column'}
            width={['100%', '100%', '100%', '80%']}
          >
            <Text>
              <span className="text-orange-600 uppercase text-xs font-semibold">
                Beta
              </span>
            </Text>
            <Heading textColor={'gray.700'} as={'h2'} textAlign="left" pb={4}>
              Bounties
            </Heading>
            <Text
              textAlign="left"
              textColor={'gray.600'}
              fontSize="xl"
              className="font-normal"
            >
              Bring your ideas to life with top talents on the Techstarta
              community.
            </Text>
          </Flex>
          <Show above="sm">
            <MoneyBagDollar className="h-28 w-28 text-orange-500" />
          </Show>
        </Flex>
        <Box pt={4}>
          <a href="/bounties/create" className="mr-4">
            <Button
              size="md"
              variant="solid"
              borderRadius="full"
              bgColor={'brand.darkBlue'}
              textColor={'white'}
              width={'auto'}
              _focus={{ outline: '#127881' }}
              _hover={{
                variant: 'outline',
                shadow: 'sm',
                outlineColor: 'brand.darkBlue',
              }}
              _active={{ outlineColor: 'gray.100' }}
            >
              Post a bounty
            </Button>
          </a>
        </Box>
      </Flex>
      <Tabs
        index={tabIndex}
        variant={'line'}
        colorScheme="orange"
        _focus={{ outline: 'none' }}
        onChange={handleTabsChange}
      >
        <TabList>
          <Tab
            _selected={{
              fontWeight: 'semibold',
              borderBottomColor: 'orange.500',
            }}
            _focus={{ outline: 'none' }}
          >
            <HStack>
              <ListBulletIcon className="h-4 w-4 text-orange-500" />
              <Text fontSize={'sm'}>All Bounties</Text>
            </HStack>
          </Tab>
          <Tab
            _selected={{
              fontWeight: 'semibold',
              borderBottomColor: 'orange.500',
            }}
            _focus={{ outline: 'none' }}
          >
            <HStack>
              <ArrowUpTrayIcon className="h-4 w-4 text-orange-500" />
              <Text fontSize={'sm'}>Posted Bounties</Text>
            </HStack>
          </Tab>

          <Tab
            _selected={{
              fontWeight: 'semibold',
              borderBottomColor: 'orange.500',
            }}
            _focus={{ outline: 'none' }}
          >
            <HStack>
              <UserIcon className="h-4 w-4 text-orange-500" />
              <Text fontSize={'sm'}>Assigned Bounties</Text>
            </HStack>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel mx={'-4'}>
            <SimpleGrid columns={[1, 1, 2, 3]} spacing={4}>
              {isLoading && <BountiesGridSkeleton />}
              {data?.results.map((opportunity, index) => (
                <BountyCard key={index} opportunity={opportunity} />
              ))}
            </SimpleGrid>
          </TabPanel>
          <TabPanel mx={'-4'} verticalAlign={'true'}>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Bounty</Th>
                    <Th>Application Deadline</Th>
                    <Th>Status</Th>
                    <Th>Applications</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {userOpportunities.length == 0 && <BountiesTableSkeleton />}
                  {userOpportunities.map((opportunity, index) => (
                    <Tr key={index}>
                      <Td>
                        <LinkBox className="hover:text-orange-600 hover:underline">
                          <LinkOverlay href={`/bounties/${opportunity.slug}`}>
                            {opportunity.title}
                          </LinkOverlay>
                        </LinkBox>
                      </Td>
                      <Td>{moment(opportunity?.target_due_date).fromNow()}</Td>
                      <Td>{opportunity.status}</Td>
                      <Td>{opportunity?.application_count}</Td>
                      <Td>
                        <Menu>
                          <MenuButton
                            variant={'outline'}
                            rounded={'full'}
                            as={Button}
                          >
                            <EllipsisVerticalIcon className="h-5 w-5 text-gray-600" />
                          </MenuButton>
                          <MenuList>
                            <Link href={`/bounties/${opportunity.slug}/edit`}>
                              <MenuItem
                                _hover={{
                                  bgColor: 'gray.100',
                                  outline: 'none',
                                }}
                                _focus={{ outline: 'none' }}
                                _active={{ outline: 'none' }}
                              >
                                Edit
                              </MenuItem>
                            </Link>
                            <Link
                              href={`/bounties/${opportunity.slug}?preview=true`}
                            >
                              <MenuItem
                                _hover={{
                                  bgColor: 'gray.100',
                                  outline: 'none',
                                }}
                                _focus={{ outline: 'none' }}
                                _active={{ outline: 'none' }}
                              >
                                Preview
                              </MenuItem>
                            </Link>
                            <MenuItem
                              _hover={{ bgColor: 'gray.100', outline: 'none' }}
                              _focus={{ outline: 'none' }}
                              _active={{ outline: 'none' }}
                            >
                              Publish
                            </MenuItem>
                            <Link
                              href={`/bounties/${opportunity.slug}?tab=applications`}
                            >
                              <MenuItem
                                _hover={{
                                  bgColor: 'gray.100',
                                  outline: 'none',
                                }}
                                _focus={{ outline: 'none' }}
                                _active={{ outline: 'none' }}
                              >
                                Manage applications
                              </MenuItem>
                            </Link>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel mx={'-4'}>
            {isApplicationsLoading && (
              <SimpleGrid columns={1} spacing={4}>
                <BountiesGridSkeleton />
              </SimpleGrid>
            )}
            {!isApplicationsLoading && applications.length == 0 && (
              <Box p="10px">
                <Text fontSize="sm">You have not applied to any bounties.</Text>
              </Box>
            )}
            <SimpleGrid columns={1} spacing={4}>
              {!isApplicationsLoading &&
                applications.map((application, index) => (
                  <BountyApplicationCard application={application} />
                ))}
            </SimpleGrid>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </LayoutWithSidebar>
  );
};

const BountiesTableSkeleton = () => (
  <>
    <BountyRowSkeleton />
    <BountyRowSkeleton />
    <BountyRowSkeleton />
    <BountyRowSkeleton />
  </>
);

const BountyRowSkeleton = () => (
  <Tr>
    <Td>
      <Skeleton height="20px" />
    </Td>
    <Td>
      <Skeleton height="20px" />
    </Td>
    <Td>
      <Skeleton height="20px" />
    </Td>
    <Td>
      <Skeleton height="20px" />
    </Td>
    <Td>
      <Skeleton height="20px" />
    </Td>
  </Tr>
);

const BountiesGridSkeleton = () => (
  <>
    <Skeleton rounded={'lg'} height="44" />
    <Skeleton rounded={'lg'} height="44" />
    <Skeleton rounded={'lg'} height="44" />
    <Skeleton rounded={'lg'} height="44" />
    <Skeleton rounded={'lg'} height="44" />
    <Skeleton rounded={'lg'} height="44" />
  </>
);

export default withRouter(Bounties);
