import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Flex,
  Text,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Textarea,
} from '@chakra-ui/react';
import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import BookingService from '../services/booking-service';
import { PopupWidget } from 'react-calendly';
import BookingDetailSkeleton from '../components/Booking/BookingDetailSkeleton.js';
import { FaCalendarAlt } from 'react-icons/fa';
import withRouter from '../utils/withRouter.js';

const BookingDetail = () => {
  const { hashCode } = useParams();
  const toast = useToast();

  const [booking, setBooking] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [talkingPoints, setTalkingPoints] = useState('');
  const [notes, setNotes] = useState('');

  useEffect(() => {
    async function fetchBookingDetail() {
      try {
        const response = await BookingService.getBooking(hashCode);
        setBooking(response.booking);
        if (response.booking.sessions.length > 0) {
          setNotes(response.booking.sessions[0].notes);
          setTalkingPoints(response.booking.sessions[0].expectations);
        }
      } catch (err) {
        toast({
          title: 'Oops!',
          description:
            'Unable to fetch your booking details, please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
    fetchBookingDetail();
  }, [toast]);

  const CalendlyButton = ({ url, prefill }) => {
    const onClick = () => PopupWidget({ url, prefill });

    return (
      <Button
        leftIcon={<FaCalendarAlt />}
        _focus={{ outline: 'none' }}
        colorScheme="whiteAlpha"
        bg="orange.400"
        _hover={{ shadow: 'md' }}
        onClick={onClick}
      >
        Schedule Meeting
      </Button>
    );
  };

  const handleTalkingPointsOrNoteChanged = async event => {
    event.preventDefault();
    const payload = {
      notes: notes,
      expectations: talkingPoints,
    };
    if (notes.trim() !== '' || talkingPoints.trim() !== '') {
      try {
        setIsSaving(true);
        const response = await BookingService.saveCoachingSession(
          payload,
          hashCode
        );
        toast({
          title: 'All changes saved',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setNotes(response.data.session.notes);
        setTalkingPoints(response.data.session.expectations);
      } catch (err) {
        toast({
          title: 'Changes not saved',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsSaving(false);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar title="Booking Detail" />
        <div className="relative bg-white md:pt-32 pb-16"></div>
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <div className="flex flex-wrap mt-4">
            <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-2">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 px-2 border-0">
                  {booking ? (
                    <Flex
                      shadow="md"
                      rounded="md"
                      mt={['50px', '50px', '50px', '20px']}
                      p={['10px', '10px', '10px', '20px']}
                      flexDirection="column"
                    >
                      <Flex
                        pb="20px"
                        justifyContent="space-between"
                        direction={['column', 'row']}
                        alignItems="center"
                      >
                        <Flex alignItems="center">
                          <Text
                            fontSize="xl"
                            ml="10px"
                            color="#2d3748"
                            fontWeight="normal"
                          >
                            {' '}
                            {booking.learner + ' / ' + booking.coach}{' '}
                          </Text>
                        </Flex>
                      </Flex>

                      <Flex
                        px="10px"
                        flexDirection="column"
                        w={['100%', '100%', '100%', '100%']}
                      >
                        <Flex
                          flexDirection="column"
                          w={['100%', '100%', '100%', '100%']}
                        >
                          <CalendlyButton
                            url={booking.calendar_link}
                            prefill={{
                              email: booking.customer_email,
                              name: booking.learner,
                            }}
                          />
                        </Flex>
                        <FormControl mt="8" id="first-name" isRequired>
                          <FormLabel>What do you need help with?</FormLabel>
                          <Textarea
                            rounded="md"
                            placeholder="Add your talking points here"
                            size="sm"
                            value={talkingPoints}
                            onChange={event =>
                              setTalkingPoints(event.target.value)
                            }
                          />
                        </FormControl>

                        <FormControl id="meeting-notes" mt="8">
                          <FormLabel>Meeting Notes</FormLabel>
                          <Textarea
                            rounded="md"
                            value={notes}
                            placeholder={'Write a note'}
                            size="sm"
                            onChange={event => setNotes(event.target.value)}
                          />
                        </FormControl>
                        <Flex
                          my="8px"
                          justifyContent={['flex-start', 'flex-end']}
                        >
                          <Button
                            _focus={{ outline: 'none' }}
                            onClick={handleTalkingPointsOrNoteChanged}
                            size="sm"
                            isLoading={isSaving}
                            loadingText={'Saving...'}
                            colorScheme="orange"
                            _hover={{ bg: 'orange.300' }}
                            bgColor="orange.400"
                            rounded="md"
                          >
                            Save changes
                          </Button>
                        </Flex>
                      </Flex>
                    </Flex>
                  ) : (
                    <BookingDetailSkeleton />
                  )}
                </div>
              </div>
            </div>
          </div>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(BookingDetail);
