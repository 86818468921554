import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  Flex,
  Text,
  Button,
  useToast,
  Box,
  Heading,
  Skeleton,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  Tag,
  TabPanel,
  Avatar,
  IconButton,
  SkeletonText,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Textarea,
  ModalHeader,
  FormControl,
  FormLabel,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  FormHelperText,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import {
  BookOpenIcon,
  ChatBubbleLeftRightIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { useMutation, useQuery } from '@tanstack/react-query';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar.js';
import opportunity from '../services/opportunity.js';
import moment from 'moment/moment.js';
import MarkdownToHtml from '../components/ReactMarkdownItem.js';
import { PaperAirplane } from '@vectopus/atlas-icons-react';
import withRouter from '../utils/withRouter.js';
import queryString from 'query-string';
import authService from '../services/auth-service.js';
import PublicBountyApplicationCard from '../components/Bounty/PublicBountyApplicationCard.jsx';
import { ShareDropdownButton } from '../components/Common/ShareDropdownButton.js';

const Bounty = () => {
  const { slug } = useParams();
  const location = useLocation().search;
  const queryValues = queryString.parse(location);
  const [tabIndex, setTabIndex] = useState(
    queryValues?.tab === 'applications' ? 1 : 0
  );
  const user = JSON.parse(authService.getCurrentUser());
  const [applications, setApplications] = useState([]);
  const [isApplicationsLoading, setApplicationsLoading] = useState(false);
  const toast = useToast();
  const { isLoading, error, data } = useQuery({
    queryKey: ['bounty', slug],
    queryFn: () => opportunity.getOpportunity(slug),
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [applicationMessage, setApplicationMessage] = useState('');

  const handleTabChange = async index => {
    setTabIndex(index);
    // TODO: Update URL with query params
    if (index === 1) {
      setApplicationsLoading(true);
      const response = await opportunity.getOpportunityApplications(slug);
      setApplications(response.applications);
      setApplicationsLoading(false);
    }

    if (index === 2) {
      // TODO: Fetch discussions
    }
  };

  const mutation = useMutation(bountyApplication => {
    return opportunity.applyToOpportunity(slug, bountyApplication);
  });

  const handleApplication = async () => {
    if (!applicationMessage) {
      toast({
        title: 'Oops!',
        description: 'Please enter an application message',
        status: 'error',
      });
      return;
    }
    try {
      setIsSubmitting(true);
      const values = {
        message: applicationMessage,
      };
      const response = await mutation.mutateAsync(values);
      toast({
        title: 'Success!',
        description: 'Application sent successfully',
        status: 'success',
      });
    } catch (err) {
      toast({
        title: 'Oops!',
        description: 'Unable to send application, please try again later',
        status: 'error',
      });
    }
    setIsSubmitting(false);
  };

  return (
    <LayoutWithSidebar title={'Bounties'}>
      <Flex width={['100%', '66%']} flexDirection={'column'}>
        <Flex w="auto" flexDirection={'column'} mb="10px">
          <Flex justifyContent={'space-between'}>
            <Flex flexDirection={'column'}>
              <Skeleton isLoaded={!isLoading} width={'100%'}>
                <Flex justify={'space-between'}>
                  <Text className="text-orange-500 font-[500] text-xl">
                    Earn ${data?.opportunity.amount_in_usd}
                  </Text>
                  <HStack>
                    <Tag
                      bgColor={'orange.500'}
                      justify={'flex-end'}
                      textColor={'white'}
                      className="text-white font-[500]"
                    >
                      {data?.opportunity.status}
                    </Tag>
                    <span className="text-gray-600 text-sm font-[500]">•</span>
                    <Text
                      justify={'flex-end'}
                      className="text-gray-600 text-sm font-[500]"
                    >
                      due {moment(data?.opportunity.target_due_date).fromNow()}
                    </Text>
                  </HStack>
                </Flex>
              </Skeleton>

              <Skeleton my={4} isLoaded={!isLoading}>
                <Heading textColor={'gray.700'} as={'h2'} textAlign="left">
                  {data?.opportunity.title}
                </Heading>
              </Skeleton>
            </Flex>
          </Flex>
          <Skeleton pt={4} isLoaded={!isLoading}>
            <Flex justify={'space-between'}>
              <HStack direction={'column'}>
                <Avatar
                  bgColor={'orange.500'}
                  size={'sm'}
                  name={data?.opportunity.author.fullname}
                  src={data?.opportunity.author.image}
                />
                <Stack spacing={0}>
                  <Text className="text-sm text-gray-600 font-[500]">
                    {data?.opportunity.author.username}
                  </Text>
                  <Text className="text-xs text-gray-500 font-[500]">
                    posted {moment(data?.opportunity.created_at).fromNow()}
                  </Text>
                </Stack>
              </HStack>
              <HStack>
                <ShareDropdownButton
                  contentTitle={data?.opportunity.title}
                  contentType={'bounty'}
                />
                {/* <IconButton
                  size={'md'}
                  rounded={'full'}
                  icon={<EllipsisVerticalIcon className="w-5 h-5" />}
                /> */}
                <Button
                  width={'28'}
                  variant="solid"
                  borderRadius="full"
                  onClick={onOpen}
                  bgColor={'orange.500'}
                  textColor={'white'}
                  _focus={{ outline: '#127881' }}
                  _hover={{
                    variant: 'outline',
                    shadow: 'sm',
                    bgColor: 'orange.600',
                  }}
                  _active={{ outlineColor: 'gray.100' }}
                >
                  Apply
                </Button>
              </HStack>
            </Flex>
          </Skeleton>
        </Flex>
        <Tabs
          onChange={handleTabChange}
          index={tabIndex}
          variant={'line'}
          colorScheme="orange"
        >
          <TabList>
            <Tab
              _focus={{ outline: 'none' }}
              _selected={{
                fontWeight: 'semibold',
                borderBottomColor: 'orange.500',
              }}
            >
              <HStack>
                <BookOpenIcon className="h-4 w-4 text-orange-500" />
                <Text className="text-[0.95rem]">Details</Text>
              </HStack>
            </Tab>
            <Tab
              _focus={{ outline: 'none' }}
              _selected={{
                fontWeight: 'semibold',
                borderBottomColor: 'orange.500',
              }}
            >
              <HStack>
                <UsersIcon className="h-4 w-4 text-orange-500" />
                <Text className="text-sm">Applications</Text>
              </HStack>
            </Tab>
            <Tab
              _focus={{ outline: 'none' }}
              _selected={{
                fontWeight: 'semibold',
                borderBottomColor: 'orange.500',
              }}
              isDisabled
            >
              <HStack>
                <ChatBubbleLeftRightIcon className="h-4 w-4 text-orange-500" />
                <Text className="text-sm">Discussions</Text>
              </HStack>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel mx={'-4'}>
              <Box py={6}>
                <SkeletonText
                  isLoaded={!isLoading}
                  noOfLines={6}
                  spacing="4"
                  skeletonHeight="4"
                />
                <MarkdownToHtml input={data?.opportunity?.description} />
              </Box>
            </TabPanel>
            <TabPanel mx={'-4'}>
              {isApplicationsLoading && (
                <SimpleGrid columns={1} spacing={4}>
                  <BountyApplicationsSkeleton />
                </SimpleGrid>
              )}
              {!isApplicationsLoading && applications.length == 0 && (
                <Box p="10px">
                  <Text fontSize="sm">
                    Applications to this bounty will appear here.
                  </Text>
                </Box>
              )}
              <SimpleGrid columns={1} spacing={4}>
                {!isApplicationsLoading &&
                  applications.map((application, index) => (
                    <PublicBountyApplicationCard
                      key={index}
                      application={application}
                    />
                  ))}
              </SimpleGrid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply to work on Bounty </ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <Text className="text-sm">
              Your application message will be publicly posted to this Bounty.
              Your contact information will remain private and is only shared
              with the Bounty Poster.
            </Text>
            <Flex mt={4} mb={4} flexDirection={'column'}>
              <Flex className="py-6 w-full">
                <Accordion
                  allowToggle
                  className="ring-1 ring-gray-300 rounded-lg w-full"
                >
                  <AccordionItem rounded={'lg'}>
                    <h2>
                      <AccordionButton className="active:outline-none focus:outline-none">
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={'semibold'}
                        >
                          Complete this Bounty to earn $
                          {data?.opportunity.amount_in_usd}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text className="text-sm">
                        You will be paid in USD via PayPal or Stripe after the
                        Bounty Poster approves your work.
                      </Text>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Flex>
              <FormControl isRequired>
                <FormLabel>Application message</FormLabel>
                <Textarea
                  fontSize={'sm'}
                  name="application_message"
                  value={applicationMessage}
                  onChange={e => setApplicationMessage(e.target.value)}
                  placeholder="Tell us why you're the best person for this bounty. What skills or past work do you have that makes you a good fit?"
                />
                <FormHelperText>
                  Your application message will be publicly posted to this
                  Bounty.
                </FormHelperText>
              </FormControl>
              <Flex className="py-6 w-full">
                <Accordion
                  allowToggle
                  className="ring-1 ring-gray-300 rounded-lg w-full"
                >
                  <AccordionItem rounded={'lg'}>
                    <h2>
                      <AccordionButton className="active:outline-none focus:outline-none">
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={'semibold'}
                        >
                          Showcase your work with your portfolio
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text className="text-sm">
                        Edit your profile and showcase your past work on{' '}
                        {user ? (
                          <a
                            target="_blank"
                            className="text-orange-600 hover:underline"
                            href={`/profile/${user?.username}`}
                          >
                            your profile page
                          </a>
                        ) : (
                          <a
                            target="_blank"
                            className="text-orange-600 hover:underline"
                            href="/login"
                          >
                            your profile page
                          </a>
                        )}
                        .
                      </Text>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Flex>
              <Flex className="pb-6 w-full">
                <Text className="text-sm">
                  By applying, you agree to the{' '}
                  <a href="/terms" className="text-orange-600 hover:underline">
                    terms and conditions
                  </a>
                  . You confirm you'll either produce the artifact yourself or
                  use artifact you have rights to. Rights to the work transfer
                  to the Bounty Poster upon transfer of the amount accrued after
                  acceptance.
                </Text>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              leftIcon={<PaperAirplane className="w-4 h-4" />}
              rounded={'full'}
              colorScheme="orange"
              mr={3}
              onClick={handleApplication}
              isLoading={isSubmitting}
            >
              Send application
            </Button>
            <Button rounded={'full'} variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </LayoutWithSidebar>
  );
};

const BountyApplicationsSkeleton = () => (
  <>
    <Skeleton rounded={'lg'} height="44" />
    <Skeleton rounded={'lg'} height="44" />
    <Skeleton rounded={'lg'} height="44" />
    <Skeleton rounded={'lg'} height="44" />
    <Skeleton rounded={'lg'} height="44" />
    <Skeleton rounded={'lg'} height="44" />
  </>
);

export default withRouter(Bounty);
