import React from 'react';
import AuthService from '../services/auth-service';
import AuthComponent from './AuthComponent.js';
import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  BookOpenIcon,
  MicrophoneIcon,
  PlusCircleIcon,
  Square3Stack3DIcon,
  WrenchIcon,
} from '@heroicons/react/24/outline';
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { BoltIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import useProfile from '../utils/auth/useProfile';
import { ROLES } from '../utils/auth/permission-maps';
import {
  CogIcon,
  CreditCardIcon,
  UserCircleIcon,
} from '@heroicons/react/20/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import {
  Graduate,
  MedalAchievement,
  NewspaperArticle,
  VideoClip,
} from '@vectopus/atlas-icons-react';
import { Formik } from 'formik';
import TextInput from './TextInput.js';
import SelectInput from './Common/SelectInput.js';
import * as Yup from 'yup';
import trackService from '../services/track-service.js';

const navigation = [];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const DashboardNavbar = ({ title, url }) => {
  const user = JSON.parse(AuthService.getCurrentUser());
  const navigate = useNavigate();
  const { getProfile } = useProfile();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const userNavigation = user
    ? [
        {
          name: 'Your profile',
          href:
            getProfile() === ROLES.mentor
              ? `/mentor/${user.username}`
              : `/profile/${user.username}`,
          icon: <UserCircleIcon className="w-4 h-4" />,
        },
        {
          name: 'Account settings',
          href: '/settings',
          icon: <CogIcon className="w-4 h-4" />,
        },
        {
          name: 'Billings',
          href: 'https://billing.stripe.com/p/login/eVaeVJ5NR6qz1gI9AA',
          icon: <CreditCardIcon className="w-4 h-4" />,
        },
        {
          name: 'Logout',
          href: '/logout',
          icon: <FontAwesomeIcon icon={faSignOut}></FontAwesomeIcon>,
        },
      ]
    : [];

  const userContents = [
    {
      name: 'New Resource',
      enabled: true,
      icon: <Square3Stack3DIcon className="w-4 h-4" />,
    },
    {
      name: 'New Achievement',
      enabled: false,
      icon: <MedalAchievement className="w-4 h-4" />,
    },
  ];

  const resourceTypes = [
    {
      name: 'Article',
      icon: <NewspaperArticle className="w-4 h-4" />,
    },
    {
      name: 'Video',
      icon: <VideoClip className="w-4 h-4" />,
    },
    {
      name: 'Book',
      icon: <BookOpenIcon className="w-4 h-4" />,
    },
    {
      name: 'Course',
      icon: <Graduate className="w-4 h-4" />,
    },
    {
      name: 'Podcast',
      icon: <MicrophoneIcon className="w-4 h-4" />,
    },
    {
      name: 'Tool',
      icon: <WrenchIcon className="w-4 h-4" />,
    },
  ];

  const handleShowAddResourceModal = () => {
    if (user && user.is_mentor) {
      onOpen();
    }
  };

  const resourceValidationSchema = Yup.object().shape({
    title: Yup.string().required('Please enter a title'),
    link: Yup.string().required('Please enter a valid URL'),
    summary: Yup.string().required("Don't leave this empty ;)"),
    resourceType: Yup.object().required('Select a resource type'),
    costType: Yup.string().required('Please select free or paid'),
  });

  return (
    <>
      {/* Navbar */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add a resource</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                title: '',
                link: '',
                summary: '',
                resourceType: resourceTypes[0],
                isFeatured: false,
                costType: 'free',
              }}
              validationSchema={resourceValidationSchema}
              onSubmit={async (values, { setSubmitting }) => {
                const payload = {
                  title: values.title,
                  link: values.link,
                  summary: values.summary,
                  resource_type: values.resourceType.name.toLocaleLowerCase(),
                  is_featured: values.isFeatured,
                  cost_type: values.costType,
                };
                const status = await trackService.createResource(payload);
                if (status === 201) {
                  onClose();
                  toast({
                    title: 'Resource added.',
                    description: 'Your resource has been added.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                  });
                } else {
                  toast({
                    title: 'Error',
                    description: 'Something went wrong.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                  });
                }
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
              }) => (
                <form method="post" onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <SelectInput
                      title={'Resource type'}
                      name="resource_type"
                      onChange={option => {
                        setFieldValue('resourceType', option);
                      }}
                      onBlur={handleBlur}
                      selected={values.resourceType}
                      type="text"
                      options={resourceTypes}
                      isRequired={true}
                    />
                    {errors.resourceType &&
                      touched.resourceType &&
                      errors.resourceType}
                  </div>
                  <div className="mb-4">
                    <TextInput
                      name="link"
                      onChangeFunction={handleChange}
                      onBlurFunction={handleBlur}
                      value={values.link}
                      type="text"
                      title="Link"
                      placeholder="Link"
                      helperText={`Add the link to the ${values.resourceType.name.toLocaleLowerCase()}.`}
                      isRequired={true}
                      errorMessage={errors.link && touched.link && errors.link}
                      showError={errors.link && touched.link}
                    />
                  </div>
                  <div className="mb-4">
                    <TextInput
                      name="title"
                      onChangeFunction={handleChange}
                      onBlurFunction={handleBlur}
                      value={values.title}
                      type="text"
                      title="Title"
                      placeholder="Title"
                      isRequired={true}
                      errorMessage={
                        errors.title && touched.title && errors.title
                      }
                      showError={errors.title && touched.title}
                    />
                  </div>

                  <div className="mb-4">
                    <TextInput
                      name="summary"
                      onChangeFunction={handleChange}
                      onBlurFunction={handleBlur}
                      value={values.summary}
                      element={'textArea'}
                      type="textArea"
                      title={`What is this ${values.resourceType.name.toLocaleLowerCase()} about?`}
                      placeholder="A brief summary..."
                      isRequired={true}
                      errorMessage={
                        errors.summary && touched.summary && errors.summary
                      }
                      showError={errors.summary && touched.summary}
                    />
                  </div>

                  <div className="mb-4">
                    <div className="relative flex gap-x-3">
                      <div className="flex h-6 items-center">
                        <input
                          id="isFeatured"
                          name="isFeatured"
                          value={values.isFeatured}
                          checked={values.isFeatured}
                          onChange={() => {
                            setFieldValue('isFeatured', !values.isFeatured);
                          }}
                          onBlur={handleBlur}
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-600"
                        />
                      </div>
                      <div className="text-sm leading-6">
                        <label
                          htmlFor="isFeatured"
                          className="font-medium text-gray-900"
                        >
                          I created or featured in this{' '}
                          {values.resourceType.name.toLocaleLowerCase()}
                        </label>
                        <p className="text-gray-500">
                          If you created this{' '}
                          {values.resourceType.name.toLocaleLowerCase()}, it
                          will be added to your profile.
                        </p>
                      </div>
                    </div>
                  </div>
                  <fieldset>
                    <legend className="text-sm font-medium leading-6 text-gray-900">
                      Is this a free resource?
                    </legend>
                    <div className="mt-2 space-x-4 inline-flex">
                      <div className="flex items-center gap-x-3">
                        <input
                          id="cost-free"
                          name="costType"
                          value={values.costType}
                          checked={values.costType === 'free'}
                          type="radio"
                          onChange={() => {
                            setFieldValue('costType', 'free');
                          }}
                          className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-600"
                        />
                        <label
                          htmlFor="cost-free"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <input
                          id="cost-paid"
                          name="costType"
                          type="radio"
                          value={values.costType}
                          checked={values.costType === 'paid'}
                          onChange={() => {
                            setFieldValue('costType', 'paid');
                          }}
                          className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-600"
                        />
                        <label
                          htmlFor="cost-paid"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </fieldset>
                  <div className="my-4 space-x-2">
                    <button
                      type="submit"
                      className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                    >
                      Add resource
                    </button>
                    <button
                      type="button"
                      onClick={onClose}
                      className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-orange-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Disclosure as="nav" className="bg-white hidden md:block">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 xl:px-12">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <h1 className="text-sm text-gray-700 font-semibold uppercase">
                      {title}
                    </h1>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map(item => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'px-3 py-2 rounded-md text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="hidden md:block">
                  {user ? (
                    <div className="ml-4 flex items-center md:ml-6">
                      {/*  Add resource dropdown */}
                      <div>
                        <Menu as="div" className="relative mr-3">
                          <div>
                            <Menu.Button className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                              <span className="sr-only">Add resource</span>
                              <PlusCircleIcon
                                className="h-8 w-8"
                                aria-hidden="true"
                              />
                            </Menu.Button>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {userContents.map(item => (
                                  <Menu.Item
                                    key={item.name}
                                    disabled={!item.enabled}
                                  >
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        onClick={handleShowAddResourceModal}
                                        className={classNames(
                                          active ? 'bg-gray-100' : '',
                                          'block px-4 py-2 text-sm text-gray-700'
                                        )}
                                      >
                                        <HStack>
                                          {item.icon} <Text>{item.name}</Text>
                                        </HStack>
                                      </a>
                                    )}
                                  </Menu.Item>
                                ))}
                              </Menu.Items>
                            </Transition>
                          </div>
                        </Menu>
                      </div>
                      {/* Resource type dropdown */}
                      <Button
                        onClick={() => navigate('/membership')}
                        leftIcon={
                          <BoltIcon className="w-4 h-4 text-orange-400" />
                        }
                        fontSize="sm"
                        rounded="full"
                        bgColor={'brand.darkBlue'}
                        textColor={'white'}
                        _hover={{ shadow: 'md' }}
                      >
                        Upgrade
                      </Button>

                      {/* Profile dropdown */}
                      <div>
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                              <span className="sr-only">Open user menu</span>
                              {user.image ? (
                                <img
                                  className="h-8 w-8 rounded-full"
                                  src={AuthService.buildImageUrl(user.image)}
                                  alt={user.username}
                                />
                              ) : (
                                <img
                                  className="h-8 w-8 rounded-full"
                                  src="https://res.cloudinary.com/techstarta/image/upload/v1673559875/user_m60knf.png"
                                  alt={user.username}
                                />
                              )}
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {userNavigation.map(item => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <a
                                      href={item.href}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700'
                                      )}
                                    >
                                      <HStack>
                                        {item.icon} <Text>{item.name}</Text>
                                      </HStack>
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  ) : (
                    <div className="hidden md:block items-center">
                      <AuthComponent />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default DashboardNavbar;
