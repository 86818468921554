import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Image,
  Link,
  Skeleton,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import withRouter from '../utils/withRouter.js';
import discussionService from '../services/discussion-service.js';
import { Post, PostSkeleton } from '../components/Discussion/Post.js';
import { SocialShareBox } from '../components/Common/SocialShareBox.js';
import CommentBox from '../components/Common/CommentBox.js';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar.js';
import LoginModal from '../components/Common/LoginModal.js';
import authService from '../services/auth-service.js';

const DiscussionDetail = () => {
  const { slug } = useParams();
  const [discussion, setDiscussion] = useState('');
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [comment, setComment] = useState('');
  const [isCommentValid, setIsCommentValid] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const currentUser = JSON.parse(authService.getCurrentUser());

  useEffect(() => {
    async function getDiscussion() {
      try {
        const response = await discussionService.getDiscussion(slug);
        setDiscussion(response.data.discussion);
        setIsLoading(false);
      } catch (err) {
        toast({
          title: 'Oops!',
          description:
            'Unable to fetch this discussion, please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
    getDiscussion();
  }, []);

  const {
    isLoading: isCommentsLoading,
    error,
    data,
    refetch,
  } = useQuery({
    queryKey: ['comments', discussion?.id],
    queryFn: () => discussionService.getComments('discussion', discussion.id),
    enabled: !isLoading,
  });

  const upvoteReply = async replyId => {
    try {
      const response = await discussionService.upvoteReply(replyId);
      const updatedReplies = replies.map(reply =>
        reply.id === replyId
          ? { ...reply, upvotes: response.data.reply.upvotes }
          : reply
      );
      setReplies(updatedReplies);
    } catch (err) {}
  };

  const postComment = async event => {
    event.preventDefault();
    if (comment.trim() === '') {
      setIsCommentValid(false);
      return;
    }
    const payload = {
      object_id: discussion.id,
      text: comment,
      content_type: 'discussion',
    };
    setIsCommentValid(true);
    try {
      setIsLoading(true);
      await discussionService.submitComment(payload);
      toast({
        title: 'Comment posted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      window.location.reload();
    } catch (err) {
      toast({
        title: 'Failed to post comment',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const handleCommentBoxClick = event => {
    event.preventDefault();
    if (currentUser == null) {
      onOpen();
    }
  };

  const upvoteComment = async commentId => {
    try {
      const response = await discussionService.upvoteComment(commentId);
      refetch();
    } catch (err) {}
  };

  return (
    <>
      <LayoutWithSidebar title={'Community post'}>
        <div className="flex flex-wrap mt-4">
          <div className="w-full xl:w-8/12 mb-12 xl:mb-0">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded-lg">
              <div className="rounded-lg mb-0 border-0 border-gray-100">
                <Box
                  className="shadow-md shadow-black/5 ring-1 ring-slate-700/10"
                  px={2}
                  mx={['10px']}
                  display="flex"
                  flexDirection="column"
                  rounded="lg"
                >
                  {discussion ? (
                    <Post discussion={discussion} />
                  ) : (
                    <PostSkeleton />
                  )}
                  {discussion ? (
                    <Box p={['10px', '10px']} marginTop="20px">
                      <Flex flexDirection={['column', 'column']} border>
                        <Flex marginTop="20px">
                          <Box pr="5px" className="hidden md:block">
                            <Avatar
                              src={
                                currentUser
                                  ? authService.buildImageUrl(currentUser.image)
                                  : ''
                              }
                              name={
                                currentUser
                                  ? currentUser.firstname +
                                    ' ' +
                                    currentUser.lastname
                                  : ''
                              }
                              size="sm"
                            />
                          </Box>
                          <Box w="100%">
                            <Textarea
                              fontSize="sm"
                              placeholder="Write a comment..."
                              value={comment}
                              onChange={event => {
                                event.preventDefault();
                                setIsCommentValid(
                                  event.target.value.trim() !== ''
                                );
                                setComment(event.target.value);
                              }}
                              onClick={event => handleCommentBoxClick(event)}
                            />
                            <Text
                              style={{
                                display: isCommentValid ? 'none' : 'block',
                              }}
                              fontSize="sm"
                              color="red.500"
                            >
                              Comment cannot be empty
                            </Text>
                          </Box>
                        </Flex>
                        <Flex
                          my="10px"
                          justifyContent={['flex-start', 'flex-end']}
                        >
                          <Button
                            _focus={{ outline: 'none' }}
                            bg="orange.500"
                            _hover={{ shadow: 'md' }}
                            onClick={postComment}
                            colorScheme="orange"
                            isLoading={isLoading}
                            loadingText="Posting..."
                            rounded="lg"
                            size="md"
                            fontSize="sm"
                          >
                            Post comment
                          </Button>
                        </Flex>
                      </Flex>
                      <LoginModal
                        isOpen={isOpen}
                        onClose={onClose}
                        redirectTo={location.pathname}
                      />
                      <Flex direction="column">
                        <Text
                          fontWeight="semibold"
                          fontSize="xl"
                          letterSpacing="normal"
                          color="brand.darkBlue"
                        >
                          Comments
                        </Text>
                      </Flex>
                      {!isCommentsLoading && data?.results.length > 0 ? (
                        data?.results.map(comment => (
                          <CommentBox
                            key={comment.id}
                            comment={comment}
                            upvoteFn={upvoteComment}
                          />
                        ))
                      ) : (
                        <Text fontSize="sm" color="gray.500">
                          Comments will appear here
                        </Text>
                      )}
                    </Box>
                  ) : null}
                </Box>
              </div>
            </div>
          </div>
          <div className="w-full xl:w-4/12">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6">
              <div className="flex flex-wrap items-center text-xs px-4">
                <Skeleton isLoaded={!isLoading}>
                  <Flex pb={6}>
                    {discussion?.guild?.track ? (
                      <Box>
                        <Box pb={2}>
                          <Image
                            src={discussion.guild.track?.image}
                            w={10}
                            h={10}
                            alt="Author"
                          />
                        </Box>
                        <Box pb={2}>
                          <Text
                            fontWeight="semibold"
                            fontSize="md"
                            className="text-gray-800"
                          >
                            {discussion.guild.track.title}
                          </Text>
                        </Box>
                        <Box pb={2}>
                          <Text fontSize="xs" color="gray.600">
                            {discussion.guild.track.description}
                          </Text>
                        </Box>
                        <Box>
                          <Link href={`/skills/${discussion.guild.track.slug}`}>
                            <Button
                              variant="outline"
                              colorScheme="black"
                              borderColor={'brand.darkBlue'}
                              _hover={{
                                bg: 'brand.darkBlue',
                                textColor: 'white',
                                shadow: 'md',
                              }}
                              size="sm"
                              mt={2}
                            >
                              Join {discussion.guild.track.member_count} members
                            </Button>
                          </Link>
                        </Box>
                      </Box>
                    ) : null}
                  </Flex>
                </Skeleton>
                {discussion ? (
                  <SocialShareBox
                    contentType="post"
                    contentTitle={discussion.title}
                    boxTitle="Share this post"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </LayoutWithSidebar>
    </>
  );
};

export default withRouter(DiscussionDetail);
