import React from 'react';
import BloostackDarkLogo from '../images/Bloostack_logo_transparent.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faLinkedinIn,
  faMediumM,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons';

export default function Footer() {
  return (
    <>
      <footer className="relative bg-[#152340] pt-16 pb-6 text-white">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-6/12">
              <div className="w-3/4">
                <img
                  src={BloostackDarkLogo}
                  alt="Techstarta"
                  className="h-12 w-12 mx-2 mb-4"
                />
                <h4 className="text-lg font-semibold px-4 mb-2">
                  {/* Bloostack{' '} */}
                  {/*  <span className="text-sm text-gray-100">by</span>{' '}
                  <span>
                    <a
                      type="button"
                      className="inline-flex items-center text-orange-500 text-sm font-semibold shadow-sm hover:text-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:text-orange-500"
                      href="https://twitter.com/intent/user?screen_name=jidesakin_"
                    >
                      Jide Owosakin
                    </a>
                  </span> */}
                </h4>
                <h6 className="text-md font-normal px-4">
                  Bloostack is a platform for running your coaching and
                  mentorship program.
                </h6>
                <div className="mt-6 px-4 pb-4">
                  <a
                    href="https://twitter.com/intent/follow?original_referer=https://bloostack.com&region=follow_link&screen_name=bloostack"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faXTwitter}
                      className="w-5 h-5 mr-3"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/bloostack"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="w-5 h-5 mr-3"
                    />
                  </a>
                  <a
                    href="https://linkedin.com/company/bloostack"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedinIn}
                      className="w-5 h-5 mr-3"
                    />
                  </a>
                  <a
                    href="https://medium.com/bloostack"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faMediumM}
                      className="w-5 h-5 mr-3"
                    />
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="w-full lg:w-6/12">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto pb-6">
                  <span className="block text-gray-400 text-md font-semibold mb-2">
                    Career Hub
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="/skills"
                      >
                        Tech career paths
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="/stories"
                      >
                        Career stories
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="/stories/getting-your-first-job-or-internship-in-tech"
                      >
                        Interview tips
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="/quiz"
                      >
                        Tech career quiz
                      </a>
                    </li>

                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="/community"
                      >
                        Community
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="w-full lg:w-4/12 px-4 ml-auto pb-6">
                  <span className="block text-gray-400 text-md font-semibold mb-2">
                    Mentorship
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="/mentor"
                      >
                        Become a mentor
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="/mentors"
                      >
                        Find a mentor
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="mailto:hello@techstarta.com"
                      >
                        Mentorship for teams
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="w-full lg:w-4/12 px-4 ml-auto pb-6">
                  <span className="block text-gray-400 text-md font-semibold mb-2">
                    Get started
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="/get-started"
                      >
                        Sign up
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="/login"
                      >
                        Login
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="mailto:hello@techstarta.com"
                      >
                        Contact us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
          <hr className="my-6 border-gray-400" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-gray-200 font-semibold py-1">
                © {new Date().getFullYear()}, Bloostack
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
