import React, { useState } from 'react';
import Navbar from '../components/Navbar.js';
import {
  Flex,
  Input,
  useToast,
  InputRightElement,
  InputGroup,
  Button,
  Text,
} from '@chakra-ui/react';
import AuthService from '../services/auth-service';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import Validator from '../utils/validator';
import SEO from '../components/Common/SEO.js';

const SetNewPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showConfirmPasswordError, setShowConfirmPasswordError] =
    useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleConfirmShowPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const navigate = useNavigate();
  const location = useLocation().search;
  const queryValues = queryString.parse(location);
  const toast = useToast();

  const handleSubmit = async event => {
    event.preventDefault();
    let isError = false;

    const isPasswordValid = Validator.isPasswordValid(password);
    const isConfirmPasswordValid = password === confirmPassword;

    if (!isConfirmPasswordValid || !isPasswordValid) {
      isError = true;
    }

    setShowPasswordError(!isPasswordValid);
    setShowConfirmPasswordError(!isConfirmPasswordValid);

    if (isError) {
      return;
    }

    const payload = {
      password: password,
      email: queryValues.email,
      confirmation_number: queryValues.code,
    };
    if (password === confirmPassword) {
      try {
        setIsLoading(true);
        const response = await AuthService.updateUserPassword(payload);
        if (response === 200) {
          toast({
            title: ' Great! ',
            description:
              'Password successfully set, Login with your new password.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          navigate('/login');
        }
      } catch (err) {
        if (err) {
          toast({
            title: ' Error! ',
            description: err.data.password_reset.detail,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }
    } else {
      toast({
        title: 'Invalid! ',
        description: 'Your passwords do not match.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <SEO title={'Techstarta: Password reset'} />
      <Flex
        w="100%"
        h="100%"
        bg="#1a202c"
        position="static"
        overflow="auto"
        backgroundColor="white"
        backgroundSize="100% auto"
        backgroundRepeat="repeat"
        backgroundPosition="center"
      >
        <Navbar transparent={false} />
        <Flex
          w="auto"
          minHeight="calc(100vh - 350px)"
          justifyContent="center"
          alignContent="center"
        >
          <Flex
            rounded="5px"
            w={['100%', '80%', '60%', '50%']}
            alignItems="center"
            justifyContent="center"
          >
            <Flex w={['100%', '80%', '60%', '70%']} flexDirection="column">
              <main>
                <section className="absolute w-full h-full">
                  <div
                    className="absolute top-0 w-full h-full bg-gray-900"
                    style={{
                      backgroundColor: 'white',
                      backgroundSize: '100%',
                      backgroundRepeat: 'no-repeat',
                    }}
                  ></div>
                  <div className="container mx-auto px-4 h-full">
                    <div className="flex content-center items-center justify-center h-full">
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-1">
                          <div className="rounded-t mb-0 px-6 py-6">
                            <div className="text-center mb-3">
                              <h6 className="text-gray-600 text-sm font-semibold uppercase">
                                Password Reset
                              </h6>
                            </div>
                          </div>
                          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                            <div className="text-gray-700 text-center mb-4 font-normal">
                              <small>Enter your new password</small>
                            </div>
                            <form onSubmit={handleSubmit}>
                              <div className="relative w-full mb-3">
                                <label
                                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                  for="grid-password"
                                >
                                  Password
                                </label>
                                <InputGroup size="md">
                                  <Input
                                    pr="4.5rem"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Choose a new password"
                                    onChange={event =>
                                      setPassword(event.target.value)
                                    }
                                  />
                                  <InputRightElement width="4.5rem">
                                    <Button
                                      h="1.3rem"
                                      size="sm"
                                      onClick={handleShowPassword}
                                    >
                                      {showPassword ? 'Hide' : 'Show'}
                                    </Button>
                                  </InputRightElement>
                                </InputGroup>
                                <Text
                                  borderRadius="md"
                                  fontSize="sm"
                                  style={{
                                    display: showPasswordError
                                      ? 'block'
                                      : 'none',
                                  }}
                                  mt="1"
                                  textColor={'red.500'}
                                >
                                  Password must be 8 characters or more.
                                </Text>
                              </div>
                              <div className="relative w-full mb-3 pt-8">
                                <label
                                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                  for="grid-password"
                                >
                                  Confirm Password
                                </label>
                                <InputGroup size="md">
                                  <Input
                                    pr="4.5rem"
                                    type={
                                      showConfirmPassword ? 'text' : 'password'
                                    }
                                    placeholder="Confirm new password"
                                    onChange={event =>
                                      setConfirmPassword(event.target.value)
                                    }
                                  />
                                  <InputRightElement width="4.5rem">
                                    <Button
                                      h="1.3rem"
                                      size="sm"
                                      onClick={handleConfirmShowPassword}
                                    >
                                      {showConfirmPassword ? 'Hide' : 'Show'}
                                    </Button>
                                  </InputRightElement>
                                </InputGroup>
                                <Text
                                  borderRadius="md"
                                  fontSize="sm"
                                  style={{
                                    display: showConfirmPasswordError
                                      ? 'block'
                                      : 'none',
                                  }}
                                  mt="1"
                                  textColor={'red.500'}
                                >
                                  Please enter the same password as above.
                                </Text>
                              </div>
                              <div className="text-center mt-6">
                                <Button
                                  backgroundColor="brand.darkBlue"
                                  color="white"
                                  fontSize="small"
                                  _hover={{
                                    shadow: 'lg',
                                    backgroundColor: 'brand.darkBlue',
                                  }}
                                  style={{ transition: 'all .15s ease' }}
                                  onClick={handleSubmit}
                                  isLoading={isLoading}
                                  loadingText="Submitting..."
                                  width="100%"
                                  textTransform="uppercase"
                                  type="submit"
                                >
                                  Change Password
                                </Button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default SetNewPassword;
