import React from 'react';
import {
  TrackLeaderboardItem,
  TrackLeaderboardItemSkeleton,
} from './TrackLeaderBoardItem';
import { Table, Thead, Tbody, Tr, Th, TableContainer } from '@chakra-ui/react';

export const TrackLeaderboard = ({ ranking }) => {
  return (
    <TableContainer className="shadow-lg rounded-lg shadow-black/5 ring-1 ring-slate-700/10">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Rank</Th>
            <Th>Member</Th>
            <Th>Points</Th>
          </Tr>
        </Thead>
        <Tbody>
          {ranking.length > 0 ? (
            ranking.map((member, index) => (
              <TrackLeaderboardItem
                member={member}
                position={index + 1}
                key={index}
              />
            ))
          ) : (
            <>
              <TrackLeaderboardItemSkeleton />
              <TrackLeaderboardItemSkeleton />
              <TrackLeaderboardItemSkeleton />
              <TrackLeaderboardItemSkeleton />
              <TrackLeaderboardItemSkeleton />
            </>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
