import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Link,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Skeleton,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import Sidebar from '../components/Sidebar';
import DashboardNavbar from '../components/DashboardNavbar';
import DashboardFooter from '../components/DashboardFooter';
import { ProfileSection, ProfileSkeleton } from '../components/Profile.js';
import ProfileService from '../services/profile-service';
import authService from '../services/auth-service';
import queryString from 'query-string';
import submissionsService from '../services/submissions-service';
import withRouter from '../utils/withRouter.js';
import SubmissionStatus from '../components/Submission/SubmissionStatus';
import TrackLabel from '../components/Track/TrackLabel';
import profileService from '../services/profile-service';
import { BadgesGrid } from '../components/Achievements/BadgesGrid';
import { StarLightbulb, Suitcase } from '@vectopus/atlas-icons-react';
import SEO from '../components/Common/SEO';

const Profile = () => {
  const { username } = useParams();
  const [profile, setProfile] = useState(null);
  const [submissions, setSubmissions] = useState([]);
  const user = JSON.parse(authService.getCurrentUser());
  const [badges, setBadges] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const tabs = ['learning', 'portfolio'];

  const groupSubmissionsByTrack = submissions => {
    const tracks = {};
    submissions.forEach(submission => {
      const { track } = submission.challenge;
      if (!tracks[track]) {
        tracks[track] = [];
      }
      tracks[track].push(submission);
    });
    return tracks;
  };

  useEffect(() => {
    async function fetchProfile() {
      const response = await ProfileService.getProfile(username);
      setProfile(response.data.profile);
      if (queryParams.section) {
        setTabIndex(tabs.indexOf(queryParams.section));
      }
    }
    fetchProfile();
  }, [username]);

  useEffect(() => {
    async function fetchSubmissions() {
      const response = await submissionsService.listSubmissions(username);
      setSubmissions(groupSubmissionsByTrack(response.data.submissions));
      setIsLoading(false);
    }
    fetchSubmissions();
  }, [username]);

  useEffect(() => {
    async function fetchBadges() {
      if (username) {
        const response = await profileService.getProfileBadges(username);
        if (response.status === 200) {
          setBadges(response.data.badges);
          setIsLoading(false);
        }
      }
    }
    fetchBadges();
  }, [username]);

  return (
    <>
      <SEO
        title={profile ? `${profile.fullname}` : 'Profile'}
        url={`https://techstarta.com/profile/${username}`}
      />
      <Sidebar />
      <div className="relative md:ml-64 bg-white overflow-x-hidden">
        <DashboardNavbar title="Profile" hideBecomeAMentorButton={true} />
        <div className="md:px-10 mx-auto w-full py-8">
          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 xl:mb-0 md:px-4 sm:px-2">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="w-full xl:w-8/12 rounded-t mb-0 px-2 border-0">
                  {profile ? (
                    <div>
                      <Flex direction="column" width={'100%'}>
                        <ProfileSection
                          profile={profile}
                          currentUser={user}
                          username={username}
                        />
                      </Flex>

                      <Box mt={6} mb={6}>
                        <h3 className="text-gray-500 font-medium pb-2">Bio</h3>
                        {profile.bio ? (
                          <Text>{profile.bio}</Text>
                        ) : (
                          <Text fontSize={'sm'} color="gray.500">
                            No bio yet
                          </Text>
                        )}
                      </Box>
                      <Box>
                        <h3 className="text-gray-500 font-medium pb-2">
                          Career Paths
                        </h3>
                        {profile?.tracks.length > 0 ? (
                          <Wrap>
                            {profile.tracks.map(item => (
                              <WrapItem py={2}>
                                <Link href={`/skills/${item.track.slug}`}>
                                  <Button
                                    bg="brand.darkBlue"
                                    rounded={'full'}
                                    textColor={'white'}
                                    variant="outline"
                                    size={'md'}
                                    key={item.track.title}
                                    leftIcon={
                                      <StarLightbulb className="h-4 w-4" />
                                    }
                                    _active={{ outlineColor: 'brand.darkBlue' }}
                                    _focus={{ outlineColor: 'brand.darkBlue' }}
                                    mr="2"
                                    mb="2"
                                    _hover={{ shadow: 'md' }}
                                  >
                                    {item.track.title}
                                  </Button>
                                </Link>
                              </WrapItem>
                            ))}
                          </Wrap>
                        ) : (
                          <Text fontSize={'sm'} color="gray.500">
                            {profile.firstname} has not joined any career path
                          </Text>
                        )}
                      </Box>

                      <Skeleton isLoaded={!isLoading}>
                        <Flex
                          mt={8}
                          variant={'unstyled'}
                          color="gray.600"
                          flexDirection={'column'}
                        >
                          <h3 className="text-gray-500 font-medium pb-2">
                            Projects
                          </h3>
                          {submissions.length === 0 && (
                            <Flex
                              flexDirection="column"
                              width="100%"
                              alignItems={'center'}
                            >
                              <Box
                                alignSelf={'center'}
                                justifySelf="center"
                                p={4}
                                className="ring-1 ring-gray-500"
                              >
                                <Suitcase className="h-12 w-12 text-gray-600" />
                              </Box>
                              <Text
                                p={4}
                              >{`${profile.firstname}'s projects will appear here`}</Text>
                            </Flex>
                          )}
                          {submissions !== null &&
                            Object.entries(submissions).map(([key, value]) => (
                              <Flex flexDirection={'column'} pb="4" key={key}>
                                <Text textColor={'gray.700'} fontWeight="500">
                                  {key}
                                </Text>
                                <SimpleGrid
                                  spacing={8}
                                  columns={[1, 2, 2, 2]}
                                  my={6}
                                >
                                  {value.length > 0 &&
                                    value.map(submission => (
                                      <LinkBox key={submission.challenge.slug}>
                                        <Flex
                                          className="shadow-lg rounded-lg shadow-black/5 ring-1 ring-slate-700/10 hover:ring-orange-400"
                                          alignItems="center"
                                        >
                                          <Flex p={4} flexDirection={'column'}>
                                            <Flex
                                              pb={2}
                                              justify={'space-between'}
                                            >
                                              <Flex width={'80%'}>
                                                <LinkOverlay
                                                  href={`/submissions/${submission.id}`}
                                                >
                                                  <Text
                                                    className="text-gray-700 font-bold"
                                                    noOfLines={2}
                                                    fontSize="md"
                                                  >
                                                    {submission.challenge.title}
                                                  </Text>
                                                </LinkOverlay>
                                              </Flex>
                                              <Flex justify={'flex-end'}>
                                                <Image
                                                  rounded="lg"
                                                  src={
                                                    submission.challenge.image
                                                  }
                                                  objectFit="cover"
                                                  width={12}
                                                  height={12}
                                                />
                                              </Flex>
                                            </Flex>
                                            <Box pb={4}>
                                              <Text
                                                noOfLines={'2'}
                                                className="text-sm font-medium text-gray-500"
                                              >
                                                {
                                                  submission.challenge
                                                    .description
                                                }
                                              </Text>
                                            </Box>
                                            <Flex
                                              alignItems={'center'}
                                              justifyContent={'space-between'}
                                            >
                                              <HStack>
                                                <TrackLabel
                                                  track={
                                                    submission.challenge.track
                                                  }
                                                />
                                                <SubmissionStatus
                                                  status={submission.status}
                                                />
                                              </HStack>
                                            </Flex>
                                          </Flex>
                                        </Flex>
                                      </LinkBox>
                                    ))}
                                </SimpleGrid>
                              </Flex>
                            ))}
                        </Flex>
                      </Skeleton>
                      {/* Achievements section */}
                      <Skeleton isLoaded={!isLoading} rounded={'lg'}>
                        <Flex pb={12} flexDirection={'column'}>
                          <h3 className="pb-4 text-gray-700 font-medium">
                            Achievements
                          </h3>
                          {badges.length > 0 && (
                            <BadgesGrid
                              badges={badges}
                              columns={[1, 2, 2, 2]}
                            />
                          )}
                        </Flex>
                      </Skeleton>
                    </div>
                  ) : (
                    <ProfileSkeleton />
                  )}
                </div>
              </div>
            </div>
            <Flex width="100%" flexWrap="wrap">
              <DashboardFooter />
            </Flex>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Profile);
