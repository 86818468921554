import React, { useEffect, useState } from 'react';
import { Avatar, Flex, useToast } from '@chakra-ui/react';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar';
import withRouter from '../utils/withRouter';
import discussionService from '../services/discussion-service';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import authService from '../services/auth-service';

const Messages = () => {
  const [message, setMessage] = useState('');
  const [selectedConversation, setSelectedConversation] = useState(null);
  const currentUser = JSON.parse(authService.getCurrentUser());
  const toast = useToast();

  const getOtherParticipant = conversation => {
    return conversation.participants.filter(
      participant => participant.username !== currentUser.username
    )[0];
  };

  const getInitials = fullname => {
    if (!fullname) return '';
    const names = fullname.split(' ');
    const firstName = names[0];
    const lastName = names[1];
    return firstName + ' ' + lastName[0] + '.';
  };

  const { data, refetch, isLoading } = useQuery({
    queryKey: ['conversations'],
    queryFn: () => discussionService.getConverstions(),
  });

  useEffect(() => {
    if (data?.results.length > 0) {
      setSelectedConversation(data.results[0]);
    }
  }, [data]);

  const {
    data: messageData,
    refetch: refetchMessages,
    isLoading: isMessagesLoading,
  } = useQuery({
    queryKey: ['messages', selectedConversation?.id],
    queryFn: () => discussionService.getMessages(selectedConversation?.id),
    enabled: !!selectedConversation?.id,
  });

  const handleSendMessage = async () => {
    const payload = {
      text: message,
      conversation_id: selectedConversation.id,
    };
    try {
      await discussionService.sendMessage(payload);
      setMessage('');
      await refetchMessages();
    } catch (e) {
      toast({
        title: 'Error',
        description: e.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  return (
    <LayoutWithSidebar title={'Messages'} pageTitle={'Messages'}>
      <Flex w={['100%', '100%', '100%', '70%']} justifyContent="start">
        {/* <h1>Messages</h1> */}
      </Flex>
      <div className="flex flex-wrap">
        <div className="w-10/12 md:mr-4">
          <div className="w-full flex rounded-lg ring-1 ring-gray-600/10 h-max">
            <ul role="list" className="divide-y w-6/12 divide-gray-100">
              <div className="flex flex-col p-4">
                <h3 className="text-base font-semibold leading-7 text-gray-900">
                  Messages
                </h3>
              </div>
              <hr />
              {isLoading && (
                <div className="flex flex-col items-center justify-center h-full">
                  <svg
                    className="w-12 h-12 text-gray-300"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                      d="M12 4v16m8-8H4"
                    ></path>
                  </svg>
                  <p className="text-sm font-normal text-gray-400">
                    Loading...
                  </p>
                </div>
              )}

              {data?.results.map(conversation => (
                <li
                  key={conversation.id}
                  className={`flex justify-between gap-x-6 py-5 px-2 hover:bg-gray-50 cursor-pointer ${
                    selectedConversation?.id === conversation.id &&
                    'bg-gray-50 border-orange-500 border-spacing-1'
                  }`}
                  onClick={() => {
                    setSelectedConversation(conversation);
                  }}
                >
                  <div className="flex min-w-0 gap-x-4">
                    {conversation.participants.filter(
                      participant =>
                        participant.username !== currentUser.username
                    ).length > 0 && (
                      <Avatar
                        className="h-12 w-12 flex-none rounded-full bg-gray-50"
                        src={getOtherParticipant(conversation).image}
                        name={getOtherParticipant(conversation).fullname}
                        alt={getOtherParticipant(conversation).fullname}
                      />
                    )}

                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {getOtherParticipant(conversation).fullname}
                      </p>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                        {conversation.last_message.sender.username ===
                          currentUser.username && (
                          <span className="font-semibold">You: </span>
                        )}
                        {conversation.last_message.text}
                      </p>
                    </div>
                  </div>
                  <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p className="text-sm leading-6 text-gray-900">
                      {moment(conversation.last_message.created_at).fromNow()}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
            <div className="w-6/12 md:border-l border-gray-600/10">
              <div className="flex flex-col p-4">
                <h3 className="text-base font-semibold leading-7 text-gray-900">
                  Conversation
                </h3>
              </div>
              <hr />
              <div className="py-4 h-[30rem] overflow-y-auto">
                {messageData?.results.length === 0 && (
                  <div className="flex flex-col items-center justify-center h-full">
                    <svg
                      className="w-12 h-12 text-gray-300"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        d="M12 4v16m8-8H4"
                      ></path>
                    </svg>
                    <p className="text-sm font-normal text-gray-400">
                      No messages yet
                    </p>
                  </div>
                )}
                {messageData?.results.map((message, index) => (
                  <div key={index} className="flex items-start gap-2.5 p-2">
                    <Avatar
                      src={message.sender.image || null}
                      name={message.sender.fullname}
                      size={'sm'}
                    />
                    <div className="flex flex-col w-full max-w-[320px] leading-1.5 p-4 border border-gray-200 rounded-b-xl rounded-r-xl bg-white">
                      <div className="flex items-center space-x-2 rtl:space-x-reverse">
                        <span className="text-sm font-semibold text-gray-900">
                          {getInitials(message.sender.fullname)}
                        </span>
                        <span className="text-sm font-normal text-gray-400">
                          {moment(message.created_at).fromNow()}
                        </span>
                      </div>
                      <p className="text-sm font-normal py-2.5 text-gray-900 ">
                        {message.text}
                      </p>
                      <span className="text-sm font-normal text-gray-500">
                        Delivered
                      </span>
                    </div>
                    {/* <button
                      id="dropdownMenuIconButton"
                      data-dropdown-toggle="dropdownDots"
                      data-dropdown-placement="bottom-start"
                      className="inline-flex self-center items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                      type="button"
                    >
                      <svg
                        className="w-4 h-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 4 15"
                      >
                        <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                      </svg>
                    </button>
                    <div
                      id="dropdownDots"
                      className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-40 dark:bg-gray-700 dark:divide-gray-600"
                    >
                      <ul
                        className="py-2 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownMenuIconButton"
                      >
                        <li>
                          <a
                            href="#"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            Reply
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            Forward
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            Copy
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            Report
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            Delete
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                ))}
              </div>
              <hr />

              <div className="flex flex-col  p-4">
                <div className="flex items-center space-x-2">
                  <textarea
                    rows="3"
                    name="message"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    className="w-full border border-gray-200 rounded-lg focus:outline-none focus:border-gray-300 p-2 focus:ring-2 focus:ring-orange-500"
                    maxLength={1300}
                    placeholder="Write a message..."
                  ></textarea>
                </div>
                <div className="mt-4">
                  <button
                    type="button"
                    disabled={!message}
                    onClick={handleSendMessage}
                    className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-4/12"></div>
      </div>
    </LayoutWithSidebar>
  );
};

export default withRouter(Messages);
