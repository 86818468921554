import React from 'react';
import { Avatar, AvatarGroup } from '@chakra-ui/react';
import moment from 'moment';

const MentorshipApplication = ({ application }) => {
  const { mentor, mentee } = application;
  return (
    <li
      key={application.id}
      className="flex gap-x-6 py-5 hover:bg-gray-50 mx-1 px-4"
    >
      <AvatarGroup size="md" max={2}>
        <Avatar
          className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
          src={mentor.image}
          alt=""
        />
        <Avatar
          className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
          src={mentee.image}
          alt=""
        />
      </AvatarGroup>
      <div className="flex justify-between min-w-0 gap-x-4 w-full">
        <div className="min-w-0 flex-auto">
          <p className="text-sm font-semibold leading-6 text-gray-900">
            {`${mentor.fullname} / ${mentee.fullname}`}
          </p>
          <p className="mt-1 truncate text-xs leading-5 text-gray-500">
            Application sent on{' '}
            {moment(application.created_at).format('MMM DD, YYYY')}
          </p>
        </div>
        <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
          {application.status.toLowerCase() === 'pending' && (
            <>
              <button
                type="button"
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
                Withdraw application
              </button>
            </>
          )}
        </div>
      </div>
    </li>
  );
};

const MentorshipApplications = ({ applications }) => (
  <ul className="divide-y divide-gray-200">
    {applications.map(application => (
      <MentorshipApplication
        key={application.mentorship_id}
        application={application}
      />
    ))}
  </ul>
);

export default MentorshipApplications;
