import React, { useState, useEffect } from 'react';
import {
  Flex,
  Heading,
  Text,
  Stack,
  useToast,
  Link,
  Icon,
  Container,
  Tag,
  WrapItem,
  Wrap,
  Box,
} from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import Navbar from '../components/Navbar.js';
import StoryGrid from '../components/Story/StoryGrid';
import StoryService from '../services/story-service';
import SEO from '../components/Common/SEO.js';
import AuthService from '../services/auth-service';
import Footer from '../components/Footer.js';
import withRouter from '../utils/withRouter.js';

const Stories = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [stories, setStories] = useState([]);
  const currentUser = JSON.parse(AuthService.getCurrentUser());
  const toast = useToast();

  useEffect(() => {
    async function fetchStories() {
      try {
        setIsLoading(true);
        const response = await StoryService.listStories();
        setStories(response.data.stories);
      } catch (err) {
        toast({
          title: 'An error occured',
          description: 'Unable to get stories, please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    }
    fetchStories();
  }, [toast]);

  return (
    <>
      <SEO
        title="Learn from industry experts in tech | Techstarta"
        url="http://techstarta.com/stories"
        description={
          'Insights from industry experts about building a career in tech, gaining experience from mentors, and landing your dream job.'
        }
      />
      <div className="relative bg-white">
        <Navbar transparent={false} currentUser={currentUser} showMenu={true} />
        <Container p={['5', '20', '20', '20']} maxW={'7xl'}>
          <Flex
            className="rounded-lg shadow-xl shadow-black/5 ring-1 ring-slate-700/10 bg-gradient-to-r from-neutral-100 to-orange-300"
            p={['10px', '30px', '40px', '40px']}
            w="auto"
            my={['10px', '10px', '10px', '20px']}
            justifyContent={['center', 'center', 'center', 'space-between']}
            flexDirection={['column', 'column', 'column', 'row']}
            mb="10px"
            rounded="lg"
            mt={[20, 10, 10, 10]}
          >
            <Flex
              justifyContent="center"
              alignContent="center"
              flexDirection="column"
            >
              <h1 className="text-4xl md:text-6xl text-gray-800 font-bold leading-tight tracking-tight text-left mb-4">
                Learn from industry experts in tech
              </h1>
              <Text
                className="text-gray-700 font-medium"
                width={['70%']}
                textAlign="left"
              >
                Insights from industry experts about building a career in tech,
                gaining experience from mentors, and landing your dream job.
              </Text>
              <Wrap py={4}>
                <WrapItem>
                  <Tag
                    colorScheme="cyan"
                    bgColor="blue.100"
                    mb={['5px', '5px', '0px', '0px']}
                    mr={2}
                  >
                    Interview tips
                  </Tag>
                </WrapItem>
                <WrapItem>
                  <Tag
                    colorScheme="orange"
                    bgColor="brown.100"
                    mb={['5px', '5px', '0px', '0px']}
                    mr={2}
                  >
                    Career stories
                  </Tag>
                </WrapItem>
                <WrapItem>
                  <Tag
                    colorScheme="gray"
                    bgColor="gray.100"
                    mb={['5px', '5px', '0px', '0px']}
                    mr={2}
                  >
                    Insider tips
                  </Tag>
                </WrapItem>
              </Wrap>

              <Box pt={2}>
                <Link
                  fontWeight="semibold"
                  href="/mentors"
                  _focus={{ outline: 'none' }}
                >
                  Find a mentor <Icon as={FaSearch}></Icon>
                </Link>
              </Box>
            </Flex>
          </Flex>
          <Flex flexDirection="column">
            <StoryGrid stories={stories} isLoading={isLoading} />
          </Flex>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(Stories);
