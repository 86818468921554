import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  Flex,
  Image,
  Text,
  FormControl,
  Input,
  Stack,
  useToast,
  Button,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  useDisclosure,
  Textarea,
  LinkBox,
  LinkOverlay,
  HStack,
  Avatar,
  Divider,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  SimpleGrid,
} from '@chakra-ui/react';
import {
  FaAddressBook,
  FaAndroid,
  FaChartLine,
  FaCode,
  FaDesktop,
  FaFingerprint,
  FaFire,
  FaLaptopCode,
  FaTrello,
} from 'react-icons/fa';
import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import DiscussionList from '../components/Discussion/DiscussionList.js';
import discussionService from '../services/discussion-service.js';
import authService from '../services/auth-service';

import TechstartaLogo from '../images/Techstarta Logo blue text - png.png';
import SEO from '../components/Common/SEO.js';
import discussionTopics from '../data/discussionTopics.json';
import { Mixpanel } from '../utils/Mixpanel.js';
import withRouter from '../utils/withRouter.js';
import { AddChat, Tag } from '@vectopus/atlas-icons-react';
import splitbee from '@splitbee/web';
import {
  ChatBubbleLeftRightIcon,
  Squares2X2Icon,
} from '@heroicons/react/24/outline';
import submissionsService from '../services/submissions-service.js';
import { useQuery } from '@tanstack/react-query';
import { SubmissionItemSkeleton } from '../components/SubmissionItem.js';
import { ProjectSubmissions } from '../components/Leaderboard/ProjectSubmissions.jsx';

const Discussions = () => {
  Mixpanel.track('Discussions Page');
  const currentUser = JSON.parse(authService.getCurrentUser());
  const [discussionBody, setDiscussionBody] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingDiscussions, setIsFetchingDiscussions] = useState(true);
  const [discussions, setDiscussions] = useState([]);
  const [topics, setTopics] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isLoginModalOpen,
    onOpen: onOpenLoginModal,
    onClose: onCloseLoginModal,
  } = useDisclosure();
  const initialRef = React.useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { topic: category } = queryString.parse(location.search);

  const [title, setTitle] = useState('');
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [isBodyValid, setIsBodyValid] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(category);

  const toast = useToast();

  useEffect(() => {
    async function fetchDiscussions() {
      try {
        setIsFetchingDiscussions(true);
        const response = await discussionService.listDiscussions(category);
        setDiscussions(response.data.discussions);
      } catch (err) {
        toast({
          title: 'Oops!',
          description: 'Unable to load discussions, please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsFetchingDiscussions(false);
    }
    fetchDiscussions();
  }, []);

  useEffect(() => {
    async function fetchTopics() {
      try {
        setIsLoading(true);
        const response = await discussionService.listGuilds();
        setTopics(response.data.guilds);
      } catch (err) {
        toast({
          title: 'Oops!',
          description: 'Unable to fetch topics, please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    }
    fetchTopics();
  }, []);

  const {
    isLoading: isFetchingSubmission,
    error,
    data,
  } = useQuery({
    queryKey: ['submissions'],
    queryFn: async () => {
      return (await submissionsService.listSubmissions(null)).data;
    },
  });

  const postDiscussion = async event => {
    event.preventDefault();
    if (title.trim() === '') {
      setIsTitleValid(false);
      return;
    }

    if (discussionBody.trim() === '') {
      setIsBodyValid(false);
      return;
    }

    setIsTitleValid(true);
    setIsBodyValid(true);
    const payload = {
      title: title,
      body: discussionBody,
      guild_slug: selectedCategory,
    };
    setIsLoading(true);
    const response = await discussionService.postDiscussion(payload);
    setIsLoading(false);
    setDiscussions([response.data.discussion, ...discussions]);
    onClose();
  };

  const handlePostNewDiscussion = event => {
    splitbee.track('Post discussion clicked');
    event.preventDefault();
    if (currentUser === null) {
      onOpenLoginModal();
    } else {
      onOpen();
    }
  };

  const handleUpvoteDiscussion = async slug => {
    try {
      const response = await discussionService.upvoteDiscussion(slug);
      setDiscussions(
        discussions.map(discussion => {
          if (discussion.slug === slug) {
            return response.data.discussion;
          }
          return discussion;
        })
      );
      toast({
        title: 'Post upvoted',
        description: 'You have successfully upvoted this discussion.',
        status: 'success',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    } catch (err) {}
  };

  const Topic = ({ text, icon, iconBg, slug }) => {
    return (
      <LinkBox>
        <Stack
          direction={'row'}
          rounded={'lg'}
          align={'center'}
          p={2}
          _hover={{ bg: 'orange.50', cursor: 'pointer' }}
        >
          <Flex
            w={8}
            h={8}
            align={'center'}
            justify={'center'}
            rounded={'full'}
            bg={iconBg}
          >
            {icon}
          </Flex>
          <LinkOverlay href={`/posts?topic=${slug}`}>
            <Text fontWeight="semibold">{text}</Text>
          </LinkOverlay>
        </Stack>
      </LinkBox>
    );
  };

  return (
    <>
      <SEO
        title="Tech Career Tips & Community Advice Forum | Techstarta"
        description="Discuss what you're learning, the challenges you're attempting to solve – this includes sharing lessons, asking questions, and more."
        url="https://techstarta.com/community"
      />
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar
          title="Community"
          url={'https://techstarta.com/community'}
          hideBecomeAMentorButton={true}
        />
        {/* Header */}
        <div className="px-4 md:px-12 mx-auto w-full md:py-8">
          <Tabs variant="line" colorScheme="orange">
            <TabList>
              <Tab
                _focus={{ outline: 'none' }}
                _selected={{
                  fontWeight: 'semibold',
                  borderBottomColor: 'orange.500',
                }}
              >
                <HStack>
                  <ChatBubbleLeftRightIcon className="h-4 w-4 text-orange-500" />
                  <Text className="text-[0.95rem]">Discussions</Text>
                </HStack>
              </Tab>
              <Tab
                _focus={{ outline: 'none' }}
                _selected={{
                  fontWeight: 'semibold',
                  borderBottomColor: 'orange.500',
                }}
              >
                <HStack>
                  <Squares2X2Icon className="h-4 w-4 text-orange-500" />
                  <Text className="text-[0.95rem]">Project showcase</Text>
                </HStack>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel px={'-4'}>
                <div className="flex flex-wrap mt-4 ">
                  <div className="w-full xl:w-8/12 xl:mb-0 md:pr-4">
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                      <div className="rounded-t mb-0 border-0">
                        <Flex
                          p={['10px', '20px']}
                          color="#718096"
                          mb="10px"
                          className="shadow-lg shadow-black/5 ring-1 ring-slate-700/10 hover:ring-orange-300"
                          rounded="lg"
                          alignItems="center"
                          cursor="pointer"
                          _hover={{ bg: 'gray.50', cursor: 'pointer' }}
                          onClick={event => handlePostNewDiscussion(event)}
                        >
                          <Flex pr="10px" fontSize="15px">
                            <Avatar
                              src={authService.buildImageUrl(
                                currentUser?.image
                              )}
                              name={currentUser?.fullname}
                            ></Avatar>
                          </Flex>
                          <Flex w="100%" justifyContent={'space-between'}>
                            <Flex
                              px="10px"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Text noOfLines={1}>What's on your mind?</Text>
                            </Flex>
                            <Flex my={['5px', '5px', '5px', '0px']}>
                              <button className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                                <AddChat
                                  className="h-5 w-5 mx-2 text-white inline hover:text-orange-500"
                                  aria-label="Comment"
                                />
                                <span className="hidden md:block">
                                  Write a post
                                </span>
                              </button>
                            </Flex>
                          </Flex>
                        </Flex>
                        <h1 className="text-xl font-bold leading-tight text-gray-900 py-6 sm:text-lg sm:truncate">
                          {category
                            ? 'Posts in ' + discussionTopics[category]
                            : 'All posts'}
                        </h1>
                        <DiscussionList
                          isLoading={isFetchingDiscussions}
                          discussions={discussions}
                          upVoteFn={
                            currentUser
                              ? handleUpvoteDiscussion
                              : () => onOpenLoginModal()
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Create post</ModalHeader>
                      <Divider />
                      <ModalCloseButton _focus={{ outline: 'none' }} />
                      <ModalBody>
                        <FormControl>
                          <Input
                            ref={initialRef}
                            placeholder="Title"
                            value={title}
                            maxLength={128}
                            onChange={event => {
                              event.preventDefault();
                              setIsTitleValid(event.target.value.trim() !== '');
                              setTitle(event.target.value);
                            }}
                            isRequired
                          />
                          <Text
                            style={{
                              display: isTitleValid ? 'none' : 'block',
                            }}
                            fontSize="sm"
                            color="red.500"
                          >
                            Give your post a title
                          </Text>
                        </FormControl>

                        <FormControl mt={4}>
                          <Textarea
                            placeholder="What's on your mind? Ask a question, share a lesson, or start a discussion"
                            value={discussionBody}
                            onChange={event => {
                              event.preventDefault();
                              setIsBodyValid(event.target.value.trim() !== '');
                              setDiscussionBody(event.target.value);
                            }}
                            minHeight={'200px'}
                            isRequired={true}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm border focus:outline-none focus:shadow-outline w-full"
                          />
                          <Text
                            style={{
                              display: isBodyValid ? 'none' : 'block',
                            }}
                            fontSize="sm"
                            color="red.500"
                          >
                            Write something
                          </Text>
                        </FormControl>

                        <FormControl mt={4}>
                          <Select
                            size="md"
                            onChange={event => {
                              event.preventDefault();
                              setSelectedCategory(event.target.value);
                            }}
                            value={selectedCategory}
                            placeholder={'Pick a category'}
                          >
                            <option
                              key={'getting-started'}
                              value="getting-started"
                            >
                              Getting started & Intros
                            </option>
                            <option
                              key={'software-development'}
                              value="software-development"
                            >
                              Software Development
                            </option>
                            <option
                              key={'backend-development'}
                              value="backend-development"
                            >
                              Backend Development
                            </option>
                            <option
                              key={'frontend-development'}
                              value="frontend-development"
                            >
                              Frontend Development
                            </option>
                            <option key={'ui-ux-design'} value="ui-ux-design">
                              Product Design
                            </option>
                            <option
                              key={'product-management'}
                              value="product-management"
                            >
                              Product Management
                            </option>
                            <option key={'interviewing'} value="interviewing">
                              Interviewing
                            </option>
                            <option key={'career-growth'} value="career-growth">
                              Career Growth
                            </option>
                            <option key={'other'} value="other">
                              Other
                            </option>
                          </Select>
                        </FormControl>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          loadingText="Posting..."
                          variant="solid"
                          size="md"
                          rounded="full"
                          disabled={!isTitleValid || !isBodyValid}
                          bgColor={'brand.darkBlue'}
                          _hover={{ shadow: 'md' }}
                          textColor={'white'}
                          _focus={{ outline: 'none' }}
                          onClick={postDiscussion}
                        >
                          Publish
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>

                  <Modal isOpen={isLoginModalOpen} onClose={onCloseLoginModal}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Login to continue</ModalHeader>
                      <ModalCloseButton _focus={{ outline: 'none' }} />
                      <ModalBody>
                        <Flex direction="row">
                          <Image
                            src={TechstartaLogo}
                            height="40px"
                            width="auto"
                          />
                        </Flex>
                        <Flex px="4" mt="4">
                          <Text>
                            We are a community of learners, building careers in
                            tech, solving real problems, and sharing knowledge.
                          </Text>
                        </Flex>
                        <Stack px="4" mt="4">
                          <button
                            onClick={() =>
                              navigate(`/login?redirectTo=${location.pathname}`)
                            }
                            className="inline-flex w-full justify-center px-3 py-3 border border-transparent text-md leading-4 font-semibold rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                          >
                            <FaFingerprint className="inline-flex justify-center text-white mr-2" />{' '}
                            <span>Login</span>
                          </button>
                          <button
                            onClick={() => navigate('/get-started')}
                            className="inline-flex w-full justify-center px-3 py-3 border border-transparent text-md leading-4 font-semibold rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-100 focus:outline-none ring-1 ring-gray-600 focus:ring-2 focus:ring-offset-2"
                            leftIcon={<FaFire />}
                          >
                            <FaFire className="inline-flex justify-center text-gray-700 mr-2" />
                            Create new account
                          </button>

                          <Flex pt="8" pb="8">
                            <Text fontSize="sm">
                              We value your privacy and don’t ask for too much.
                            </Text>
                          </Flex>
                        </Stack>
                      </ModalBody>
                    </ModalContent>
                  </Modal>

                  <div className="w-full xl:w-4/12 md:pl-4">
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-sm border border-gray-100 rounded-lg">
                      <div className="rounded-t mb-0 px-4 py-3 border-0">
                        <div className="flex flex-wrap items-center text-sm text-gray-600">
                          <Box
                            className="flex flex-wrap static"
                            display="flex"
                            flexDirection="column"
                          >
                            <HStack className="pb-4">
                              <Tag variant="solid" className="h-5 w-5" />
                              <Text className="text-lg font-bold">Topics</Text>
                            </HStack>
                            <Stack spacing={2}>
                              <Topic
                                icon={
                                  <Box as={FaLaptopCode} color="orange.700" />
                                }
                                iconBg={'orange.100'}
                                text={'Getting Started'}
                                slug={'getting-started'}
                              />
                              <Topic
                                icon={<Box as={FaCode} color="orange.700" />}
                                iconBg={'orange.100'}
                                text={'Backend Development'}
                                slug={'backend-development'}
                              />
                              <Topic
                                icon={<Box as={FaCode} color="orange.700" />}
                                iconBg={'orange.100'}
                                text={'Frontend Development'}
                                slug={'frontend-development'}
                              />
                              <Topic
                                icon={<Box as={FaAndroid} color="orange.700" />}
                                iconBg={'orange.100'}
                                text={'Android Development'}
                                slug={'android-development'}
                              />
                              <Topic
                                icon={<Box as={FaDesktop} color="orange.700" />}
                                iconBg={'orange.100'}
                                text={'Product Design'}
                                slug={'ui-ux-design'}
                              />
                              <Topic
                                icon={<Box as={FaTrello} color="orange.700" />}
                                iconBg={'orange.100'}
                                text={'Product Management'}
                                slug={'product-management'}
                              />
                              <Topic
                                icon={
                                  <Box as={FaAddressBook} color="orange.700" />
                                }
                                iconBg={'orange.100'}
                                text={'Interviewing'}
                                slug={'interviewing'}
                              />
                              <Topic
                                icon={
                                  <Box as={FaChartLine} color="orange.700" />
                                }
                                iconBg={'orange.100'}
                                text={'Career Growth'}
                                slug={'career-growth'}
                              />
                            </Stack>
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel px={'-4'}>
                <div className="flex flex-wrap mt-4 ">
                  <div className="w-full xl:w-8/12 xl:mb-0 md:pr-4">
                    {data?.submissions ? (
                      <ProjectSubmissions
                        projectSubmissions={data?.submissions}
                      />
                    ) : (
                      <SimpleGrid columns={[1, 2, 3]} spacing={4}>
                        <SubmissionItemSkeleton />
                        <SubmissionItemSkeleton />
                        <SubmissionItemSkeleton />
                        <SubmissionItemSkeleton />
                        <SubmissionItemSkeleton />
                        <SubmissionItemSkeleton />
                      </SimpleGrid>
                    )}
                  </div>
                </div>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(Discussions);
