import React, { Fragment } from 'react';
import { useState } from 'react';
import { Dialog, Popover, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  ChartPieIcon,
  ServerStackIcon,
  SparklesIcon,
  TvIcon,
  UserPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Avatar, Button, HStack, Image, Link } from '@chakra-ui/react';

import BloostackDarkLogo from '../images/Bloostack_logo_transparent.png';
import TechstartaLogoWhite from '../images/Bloostack_logo_transparent.png';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/auth-service';
import useProfile from '../utils/auth/useProfile';
import { ROLES } from '../utils/auth/permission-maps';
import { faArrowRight, faHomeUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChevronDownIcon, SquaresPlusIcon } from '@heroicons/react/24/outline';
import { EnvelopeIcon, VariableIcon } from '@heroicons/react/24/solid';
import { Users } from '@vectopus/atlas-icons-react';
import AuthComponent from './AuthComponent';

const callsToAction = [
  { name: 'Find your career fit', href: '/quiz', icon: VariableIcon },
  {
    name: 'Got questions?',
    href: 'mailto:hello@techstarta.com',
    icon: EnvelopeIcon,
  },
];

const navigation = [
  { name: 'Explore career paths', href: '/skills' },
  { name: 'Explore projects', href: '/projects' },
  { name: 'Become a mentor', href: '/mentor' },
  { name: 'Employers', href: '/employers' },
  { name: 'Stories', href: '/stories' },
];

const careerTracks = [
  {
    name: 'Backend Development',
    description: 'Build behind the scenes of the web',
    href: '/skills/backend-development',
    icon: ServerStackIcon,
  },
  {
    name: 'Frontend Development',
    description: 'Build what you see on the web',
    href: '/skills/frontend-development',
    icon: TvIcon,
  },
  {
    name: 'Product Design',
    description: 'Design the look and feel of the web',
    href: '/skills/ui-ux-design',
    icon: SparklesIcon,
  },
  {
    name: 'Product Management',
    description: 'Manage the development of a product',
    href: '/skills/product-management',
    icon: SquaresPlusIcon,
  },
  {
    name: 'Data Analytics',
    description: 'Analyze data to make better decisions',
    href: '/skills/data-analytics',
    icon: ChartPieIcon,
  },
];

const CareerTracks = () => {
  return (
    <Popover className="relative">
      <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 hover:text-gray-600 focus:outline-none">
        <span>Career Paths</span>
        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
          <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4">
              {careerTracks.map(item => (
                <div
                  key={item.name}
                  className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
                >
                  <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                    <item.icon
                      className="h-6 w-6 text-gray-600 group-hover:text-orange-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div>
                    <a href={item.href} className="font-semibold text-gray-900">
                      {item.name}
                      <span className="absolute inset-0" />
                    </a>
                    <p className="mt-1 text-gray-600 font-normal">
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
              {callsToAction.map(item => (
                <a
                  key={item.name}
                  href={item.href}
                  className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                >
                  <item.icon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                  {item.name}
                </a>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

const Mentorship = () => {
  return (
    <Popover className="relative">
      <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 hover:text-gray-600 focus:outline-none">
        <span>Mentorship</span>
        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
          <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4">
              <div
                key={'Get a mentor'}
                className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
              >
                <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                  <Users
                    className="h-6 w-6 text-gray-600 group-hover:text-orange-600"
                    aria-hidden="true"
                  />
                </div>
                <div>
                  <a href="/mentors" className="font-semibold text-gray-900">
                    Find a mentor
                    <span className="absolute inset-0" />
                  </a>
                  <p className="mt-1 text-gray-600 font-normal">
                    Get help from experienced professionals.
                  </p>
                </div>
              </div>
              <div
                key={'Become a mentor'}
                className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
              >
                <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                  <UserPlusIcon
                    className="h-6 w-6 text-gray-600 group-hover:text-orange-600"
                    aria-hidden="true"
                  />
                </div>
                <div>
                  <a href="/mentor" className="font-semibold text-gray-900">
                    Become a mentor
                    <span className="absolute inset-0" />
                  </a>
                  <p className="mt-1 text-gray-600 font-normal">
                    Shape the next generation of tech talent.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default function Navbar(props) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const currentUser = JSON.parse(AuthService.getCurrentUser());
  const { getProfile } = useProfile();
  const defaultHomeUrl = getProfile() === ROLES.mentor ? '/m/home' : '/home';

  const getNavColors = path => {
    const navColors = { default: '', hover: '' };
    const currentPath = window.location.pathname;
    if (currentPath.includes(path)) {
      navColors.default = 'text-orange-500';
      navColors.hover = 'text-orange-600 ';
    } else {
      navColors.default = 'text-gray-800 ';
      navColors.hover = 'text-gray-600 ';
    }

    return navColors;
  };

  return (
    <header className="fixed inset-x-0 bg-white top-0 z-50 shadow-sm">
      <nav
        className="container px-4 py-4 mx-auto flex flex-wrap items-center justify-between"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Techstarta</span>
            <HStack>
              <Image
                src={
                  props.transparent ? TechstartaLogoWhite : BloostackDarkLogo
                }
                alt="Techstarta logo"
                height="8"
                width="auto"
              />
            </HStack>
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-6"></div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {props.currentUser ? (
            ''
          ) : (
            <React.Fragment>
              <AuthComponent />
            </React.Fragment>
          )}

          {props.currentUser && (
            <Button
              onClick={() => navigate(defaultHomeUrl)}
              variant={'outline'}
              rounded={'full'}
              leftIcon={
                <FontAwesomeIcon
                  icon={faHomeUser}
                  className="opacity-75 mr-2 text-sm"
                />
              }
            >
              <Avatar
                size="sm"
                name={currentUser.firstname + ' ' + currentUser.lastname}
                src={AuthService.buildImageUrl(currentUser.image)}
              ></Avatar>
            </Button>
          )}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Techstarta</span>
              <Image
                src={BloostackDarkLogo}
                alt="Techstarta logo"
                height="8"
                width="auto"
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map(item => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                {props.currentUser ? (
                  ''
                ) : (
                  <React.Fragment>
                    <li className="flex items-center px-2 ">
                      <Button
                        rounded="full"
                        _focus={{ outline: 'none' }}
                        size="md"
                        as={Link}
                        href="/login"
                        textColor="gray.700"
                        fontWeight="bold"
                        fontSize="sm"
                        backgroundColor="white"
                        style={{ transition: 'all .15s ease' }}
                        variant="ghost"
                      >
                        Login
                      </Button>
                    </li>
                    <li className="flex items-center px-2 sm:py-2">
                      <Button
                        rounded="full"
                        _focus={{ outline: 'none' }}
                        size="md"
                        as={Link}
                        fontWeight="bold"
                        fontSize="sm"
                        href="/get-started"
                        _hover={{ shadow: 'md' }}
                        colorScheme="orange"
                        bg="brand.darkBlue"
                        style={{ transition: 'all .15s ease' }}
                        variant="solid"
                        rightIcon={
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="opacity-75 mr-2 text-sm"
                          />
                        }
                      >
                        Join the waitlist
                      </Button>
                    </li>
                  </React.Fragment>
                )}

                {props.currentUser && (
                  <Button
                    as={Link}
                    href={defaultHomeUrl}
                    variant={'outline'}
                    rounded={'full'}
                    leftIcon={
                      <FontAwesomeIcon
                        icon={faHomeUser}
                        className="opacity-75 mr-2 text-sm"
                      />
                    }
                  >
                    <Avatar
                      size="sm"
                      name={currentUser.firstname + ' ' + currentUser.lastname}
                      src={AuthService.buildImageUrl(currentUser.image)}
                    ></Avatar>
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
