import React, { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Heading, Stack, Button, Text, Flex } from '@chakra-ui/react';
import withRouter from '../utils/withRouter.js';

import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';

import BuildAndShip from '../images/build-and-ship.jpg';
import { FaArrowDown } from 'react-icons/fa';

const Jobs = () => {
  const navigate = useNavigate();
  const postsRef = useRef();

  const handlePostJob = event => {
    event.preventDefault();
    navigate('/jobs/post');
  };

  const scrollToRef = ref => {
    if (ref) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <>
      <Navbar currentUser={null} showMenu={true} />
      <main>
        <div
          className="relative pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: '75vh',
          }}
        >
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage: `url(${BuildAndShip})`,
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
                <div className=" pr-12">
                  <h1 className="text-white font-semibold text-5xl">
                    Opportunities for early-career tech talents.
                  </h1>
                  <p className="mt-4 text-lg text-gray-300">
                    Post a job and access our growing community early-career
                    tech talents. Or find full-time, part-time and freelance
                    remote-friendly jobs.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: '70px', transform: 'translateZ(0)' }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-gray-300 -mt-42">
          <div className="container relative mx-auto px-4">
            <div className="items-center justify-contents flex flex-wrap">
              <div className="lg:pt-12 pt-6 md:w-9/12 w-full px-4 text-center items-center">
                <Flex flexDirection="column" alignItems="center">
                  <Stack isInline spacing="3">
                    <Button
                      onClick={handlePostJob}
                      variant="solid"
                      backgroundColor="gray.800"
                      color="whitesmoke"
                      _hover={{ boxShadow: 'md' }}
                      _focus={{ outline: 'none' }}
                    >
                      Post a Job
                    </Button>
                    <Button
                      onClick={() => scrollToRef(postsRef)}
                      variant="outline"
                      colorScheme="gray.800"
                      _hover={{ boxShadow: 'md' }}
                      _focus={{ outline: 'none' }}
                      rightIcon={<FaArrowDown />}
                    >
                      View Jobs
                    </Button>
                  </Stack>
                </Flex>
              </div>
            </div>

            <div className="flex flex-wrap items-center mt-20">
              <div
                className="w-full md:w-8/12 px-4  py-10 mr-auto ml-auto"
                ref={postsRef}
              >
                <Flex flexDirection="column" alignItems="center">
                  <Heading color="gray.700" paddingBottom="2">
                    Latest opportunities
                  </Heading>
                  <Text paddingBottom="5">
                    Click on a post to see more details about the role.
                  </Text>
                </Flex>
                <iframe
                  title="Techstarta | Jobs"
                  className="airtable-embed"
                  src="https://airtable.com/embed/shryhxD2l7CtdIXqk?backgroundColor=orange&layout=card&viewControls=on"
                  frameBorder="0"
                  onmousewheel=""
                  width="100%"
                  height="961"
                  style={{
                    background: 'transparent',
                    border: '1px solid #ccc',
                  }}
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: '80px', transform: 'translateZ(0)' }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>

        <section className="pt-20 pb-48">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold">
                  Looking to improve your tech skills and land a Job in tech?
                </h2>
                <p className="text-lg leading-relaxed m-4 text-gray-600">
                  Learn and grow through self-paced learning, real-world
                  challenges and 1-on-1 coaching. Land an internship or
                  entry-level job at a great startup.
                </p>
                <Link to="/get-started">
                  <Button
                    backgroundColor="brand.darkBlue"
                    color="white"
                    _hover={{
                      backgroundColor: 'brand.darkBlue',
                      boxShadow: 'lg',
                    }}
                    _focus={{ outline: 'none' }}
                  >
                    Join for free
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default withRouter(Jobs);
