import React, { useState, useEffect } from 'react';
import {
  Flex,
  Heading,
  Text,
  useToast,
  Button,
  Box,
  HStack,
  Skeleton,
  LinkBox,
  LinkOverlay,
  Link,
  ButtonGroup,
  SimpleGrid,
} from '@chakra-ui/react';
import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import ChallengesGrid from '../components/ChallengesGrid.js';
import TrackService from '../services/track-service.js';
import SEO from '../components/Common/SEO.js';
import { Mixpanel } from '../utils/Mixpanel.js';
import {
  PRIMARY_FILTER_RECOMMENDED,
  PRIMARY_FILTER_ALL,
} from '../constants/index.js';
import withRouter from '../utils/withRouter.js';
import authService from '../services/auth-service.js';
import { BriefcaseIcon, PuzzlePieceIcon } from '@heroicons/react/24/outline';
import submissionsService from '../services/submissions-service.js';
import { ChevronRightIcon } from '@chakra-ui/icons';
import SubmissionStatus from '../components/Submission/SubmissionStatus.jsx';
import TrackLabel from '../components/Track/TrackLabel.js';
import PrimaryButtonFilter from '../components/Track/PrimaryButtonFilter.jsx';
import { UserFolder } from '@vectopus/atlas-icons-react';
import splitbee from '@splitbee/web';

const Projects = () => {
  Mixpanel.track('Viewed Projects Page');
  splitbee.track('Viewed Projects Page');
  const [isLoading, setIsLoading] = useState(true);
  const [isProjectsLoading, setIsProjectsLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const toast = useToast();
  const currentUser = JSON.parse(authService.getCurrentUser());

  const [primaryFilter, setPrimaryFilter] = useState(
    currentUser ? PRIMARY_FILTER_RECOMMENDED : null
  );
  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {
    async function fetchSubmissions() {
      if (currentUser) {
        setIsLoading(true);
        const response = await submissionsService.listSubmissions(
          currentUser.username
        );
        if (response.status === 200) {
          setSubmissions(response.data.submissions);
        }
      }
      setIsLoading(false);
    }
    fetchSubmissions();
  }, [currentUser?.username]);

  useEffect(() => {
    async function getChallengesList() {
      try {
        setIsProjectsLoading(true);
        const response =
          primaryFilter === PRIMARY_FILTER_RECOMMENDED
            ? await TrackService.getRecommendedChallenges()
            : await TrackService.fetchAllChallenges();
        setProjects(groupProjectsByTrack(response.challenges));
      } catch (err) {
        toast({
          title: 'Something went wrong!',
          description: 'Unable to show projects, please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsProjectsLoading(false);
    }
    getChallengesList();
  }, [toast]);

  const groupProjectsByTrack = projects => {
    const tracks = {};
    projects.forEach(project => {
      const { track } = project;
      if (!tracks[track]) {
        tracks[track] = [];
      }
      tracks[track].push(project);
    });
    return tracks;
  };

  const handleFilterChange = async filterValue => {
    setPrimaryFilter(filterValue);
    setIsProjectsLoading(true);
    const response =
      filterValue === PRIMARY_FILTER_RECOMMENDED
        ? await TrackService.getRecommendedChallenges()
        : await TrackService.fetchAllChallenges(filterValue);
    setProjects(groupProjectsByTrack(response.challenges));
    Mixpanel.track('Changed Challenges Filter', {
      filter: filterValue,
    });
    setIsProjectsLoading(false);
  };

  return (
    <>
      <SEO
        title="Learn tech skills by building projects | Techstarta"
        url="http://techstarta.com/projects"
      />
      <Sidebar />
      <div className="relative md:ml-64 bg-white overflow-hidden">
        <DashboardNavbar title="Projects" hideBecomeAMentorButton={true} />
        {/* Header */}
        <div className="md:px-10 mx-auto w-full pt-8 overflow-hidden">
          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 xl:mb-0">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 border-0">
                  <Flex
                    w="auto"
                    flexDirection={['column', 'column', 'row', 'row']}
                    mx={['4', '4']}
                    mb="10px"
                    rounded="lg"
                    justifyContent={['center', 'center', 'space-between']}
                  >
                    <Flex
                      flexDirection={'column'}
                      verticalAlign={'center'}
                      width={['100%', '100%', '100%', '70%']}
                    >
                      <Box pb={'4'}>
                        <PuzzlePieceIcon className="h-12 w-12 text-orange-500" />
                      </Box>
                      <Heading
                        textColor={'gray.700'}
                        as={'h1'}
                        textAlign="left"
                        pb={4}
                      >
                        Learn tech skills by building projects
                      </Heading>
                      <Text
                        textAlign="left"
                        textColor={'gray.600'}
                        fontSize="lg"
                      >
                        Grow your skills by building job-ready projects. Join
                        our community to discuss projects, review solutions, and
                        get feedback from mentors and peers.
                      </Text>
                    </Flex>
                    <Flex
                      justifyContent={['flex-start', 'flex-start', 'flex-end']}
                      width={['100%', '100%', '100%', '30%']}
                      pt={4}
                    >
                      <ButtonGroup>
                        {currentUser ? (
                          <Link href={`/profile/${currentUser?.username}`}>
                            <Button
                              size="md"
                              variant="solid"
                              borderRadius="full"
                              bgColor={'brand.darkBlue'}
                              textColor={'white'}
                              width={'auto'}
                              _focus={{ outline: '#127881' }}
                              _active={{ outlineColor: 'gray.100' }}
                            >
                              Your portfolio
                            </Button>
                          </Link>
                        ) : (
                          <Link href="/login">
                            <Button
                              variant={'outline'}
                              rounded={'full'}
                              bg={'white'}
                              _hover={{
                                bg: 'brand.darkBlue',
                                textColor: 'white',
                              }}
                              shadow={'sm'}
                            >
                              Start building projects
                            </Button>
                          </Link>
                        )}
                      </ButtonGroup>
                    </Flex>
                  </Flex>
                  {/* My Challenges */}
                  {currentUser && submissions.length > 0 && (
                    <Skeleton mx="4" isLoaded={!isLoading} rounded={'lg'}>
                      <Flex py={8} flexDirection={'column'}>
                        <HStack pb={6}>
                          <UserFolder className="h-6 w-6 text-orange-500" />
                          <Heading
                            color="#2d3748"
                            size="md"
                            justifyContent="space-between"
                          >
                            My Projects
                          </Heading>
                        </HStack>

                        {submissions.length > 0 && (
                          <SimpleGrid columns={[1, 2, 2, 2]} spacing={4}>
                            {submissions.map(submission => (
                              <LinkBox
                                w="100%"
                                key={submission.id}
                                justifyContent={'space-between'}
                                alignItems="center"
                              >
                                <Flex
                                  key={submission.id}
                                  justifyContent={'space-between'}
                                  alignItems="center"
                                  p="10px"
                                  mt="10px"
                                  rounded="lg"
                                  cursor={'pointer'}
                                  border={'1px'}
                                  borderColor={'gray.200'}
                                  _hover={{
                                    shadow: 'md',
                                  }}
                                >
                                  <Flex alignItems="center">
                                    <Flex
                                      flexDirection="column"
                                      ml="10px"
                                      justifyContent="center"
                                    >
                                      <LinkOverlay
                                        href={`/projects/${submission.challenge.slug}`}
                                      >
                                        <Text
                                          fontSize="sm"
                                          fontWeight="bold"
                                          color="#2d3748"
                                        >
                                          {submission.challenge.title}
                                        </Text>
                                      </LinkOverlay>
                                      <HStack pt={2} spacing={2}>
                                        <TrackLabel
                                          track={submission.challenge.track}
                                        />
                                        <SubmissionStatus
                                          status={submission.status}
                                        />
                                      </HStack>
                                    </Flex>
                                  </Flex>

                                  <Flex alignItems={'center'}>
                                    <ChevronRightIcon className="h-6 w-6 text-gray-600" />
                                  </Flex>
                                </Flex>
                              </LinkBox>
                            ))}
                          </SimpleGrid>
                        )}
                      </Flex>
                    </Skeleton>
                  )}
                  <Flex mx="4" mt="8" flexDirection="column">
                    <HStack alignItems="center">
                      <BriefcaseIcon className="h-6 w-6 text-orange-500" />
                      <Heading
                        color="#2d3748"
                        size="md"
                        as={'h2'}
                        justifyContent="space-between"
                      >
                        Find your next project
                      </Heading>
                    </HStack>
                    <Flex
                      pt={4}
                      h="12"
                      justifyContent={['flex-start', 'space-between']}
                      overflowX="scroll"
                    >
                      <HStack spacing={2}>
                        {currentUser && (
                          <PrimaryButtonFilter
                            children={'For you'}
                            value={PRIMARY_FILTER_RECOMMENDED}
                            isActive={
                              primaryFilter === PRIMARY_FILTER_RECOMMENDED
                            }
                            onClick={() =>
                              handleFilterChange(PRIMARY_FILTER_RECOMMENDED)
                            }
                          />
                        )}

                        <PrimaryButtonFilter
                          children={'All'}
                          value={PRIMARY_FILTER_ALL}
                          isActive={primaryFilter === null}
                          onClick={() => handleFilterChange(null)}
                        />
                        <PrimaryButtonFilter
                          children={'Backend Development'}
                          value={'backend-development'}
                          isActive={primaryFilter === 'backend-development'}
                          onClick={() =>
                            handleFilterChange('backend-development')
                          }
                        />
                        <PrimaryButtonFilter
                          children={'Frontend Development'}
                          value={'frontend-development'}
                          isActive={primaryFilter === 'frontend-development'}
                          onClick={() =>
                            handleFilterChange('frontend-development')
                          }
                        />
                        <PrimaryButtonFilter
                          children={'Product Management'}
                          value={'product-management'}
                          isActive={primaryFilter === 'product-management'}
                          onClick={() =>
                            handleFilterChange('product-management')
                          }
                        />
                        <PrimaryButtonFilter
                          children={'UI/UX Design'}
                          value={'User Interface and Experience Design'}
                          isActive={primaryFilter === 'ui-ux-design'}
                          onClick={() => handleFilterChange('ui-ux-design')}
                        />
                      </HStack>
                    </Flex>
                    {projects && (
                      <ChallengesGrid
                        projects={projects}
                        isLoading={isProjectsLoading}
                      />
                    )}
                  </Flex>
                </div>
              </div>
            </div>
          </div>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(Projects);
