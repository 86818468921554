import React from 'react';
import {
  Box,
  Flex,
  Text,
  Image,
  Skeleton,
  Stack,
  Button,
  Tag,
  LinkOverlay,
  LinkBox,
  SkeletonText,
} from '@chakra-ui/react';

import emojiFlags from 'emoji-flags';
import { ArrowRight } from '@vectopus/atlas-icons-react';

export const StoryItem = ({ storyDetail }) => {
  const {
    slug,
    title,
    body,
    category,
    featured_image,
    comments,
    upvotes,
    guest_name,
    guest_current_role,
    guest_country_code,
  } = storyDetail;

  return (
    <>
      <LinkBox href={`/stories/${slug}`}>
        <Flex
          className="shadow-lg shadow-black/5 ring-1 ring-slate-700/10 hover:ring-orange-300"
          borderRadius="lg"
          flexDirection="column"
          justifyContent="space-between"
          rounded="lg"
          _hover={{ shadow: 'lg' }}
          cursor="pointer"
          minHeight="100px"
        >
          <Image
            objectFit={'cover'}
            src={featured_image}
            alt={title}
            roundedTop="lg"
            height={'72'}
          />

          <Flex
            color="#2d3748"
            p={['10px', '15px']}
            w={['100%', '100%', '100%', '100%']}
            flexDirection="column"
          >
            <Flex
              w={['100%', '100%', '100%', '100%']}
              pt="10px"
              justifyContent={['center', 'center', 'center', 'center']}
              flexDirection="column"
            >
              <LinkOverlay href={`/stories/${slug}`}>
                <Text cursor="pointer" fontWeight={'bold'} noOfLines="2">
                  {title}
                </Text>
              </LinkOverlay>
              <Box>
                <Text
                  pt={2}
                  fontWeight={'semibold'}
                  textColor={'gray.600'}
                  fontSize={'sm'}
                  noOfLines={1}
                >
                  {guest_name}{' '}
                  {guest_country_code &&
                    emojiFlags.countryCode(guest_country_code).emoji}
                </Text>
                <Text
                  pt={2}
                  textColor={'gray.600'}
                  fontSize={'sm'}
                  noOfLines={1}
                >
                  {guest_current_role}
                </Text>
              </Box>
            </Flex>

            <Flex
              alignItems={'center'}
              justifyItems={'center'}
              alignContent={'center'}
              justifyContent={'space-between'}
            >
              <Stack py="4" spacing="4" direction="row" alignItems="end">
                {category && (
                  <Tag size="sm" rounded="full" colorScheme="orange" px="2">
                    {category.name}
                  </Tag>
                )}
              </Stack>
            </Flex>
            <Box>
              <Button
                textColor={'gray.600'}
                size={'sm'}
                fontSize={'sm'}
                variant={'outline'}
                rightIcon={<ArrowRight className="h-5 w-5 text-gray-600" />}
              >
                Read post
              </Button>
            </Box>
          </Flex>
        </Flex>
      </LinkBox>
    </>
  );
};

export const StoryItemSkeleton = () => {
  return (
    <Flex
      p="15px"
      flexDirection="column"
      rounded="lg"
      justifyContent="space-between"
      className="shadow-lg shadow-black/5 ring-1 ring-slate-700/10 "
      minHeight="100px"
    >
      <Flex w={['100%', '100%', '100%', '100%']} flexDirection="column">
        <Box alignItems="center" pb={['0px', '10px']} flexDirection="column">
          <Skeleton rounded={'lg'} h="48"></Skeleton>
        </Box>
        <Box
          w="100%"
          justifyContent={['center', 'center', 'center', 'center']}
          flexDirection="column"
          py={4}
        >
          <SkeletonText noOfLines={5} mt="5px" h="15px"></SkeletonText>
        </Box>
      </Flex>
      <Flex
        w="100%"
        justifyContent="space-between"
        mt={['10px', '10px', '10px', '40px']}
      >
        <Flex mt={['10px', '10px', '10px', '0px']} color="#718096">
          <Skeleton size="40px"></Skeleton>
        </Flex>
        <Flex direction="column" color="#718096">
          <Skeleton size="40px"></Skeleton>
        </Flex>
      </Flex>
    </Flex>
  );
};
