import React from 'react';
import MentorshipItem from './MentorshipItem';

const MentorshipList = ({ mentorships }) => (
  <ul className="divide-y divide-gray-200">
    {mentorships.map(mentorship => (
      <MentorshipItem key={mentorship.mentorship_id} mentorship={mentorship} />
    ))}
  </ul>
);

export default MentorshipList;
