import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Link,
  Text,
} from '@chakra-ui/react';
import { FaEnvelope, FaLinkedin, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import {
  EmailShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import { EyeIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { HeartIcon } from '@heroicons/react/24/solid';

const DashboardProfileCard = ({ profile, isMentor = false }) => {
  const referralLink = `https://techstarta.com/get-started?invite=${profile.username}`;

  return (
    <Flex
      rounded={'lg'}
      flexDirection={'column'}
      className="ring-1 ring-gray-300/30 p-4 shadow-sm shadow-black/5"
    >
      <Flex>
        <Box>
          <Avatar
            src={profile.image}
            size="lg"
            css={{
              border: '2px solid white',
            }}
          />
        </Box>
        <Flex flexDirection={'column'} ml={4}>
          <Text
            fontSize="lg"
            fontWeight="bold"
            noOfLines={1}
            color={'gray.700'}
          >
            {profile.fullname}
          </Text>
          <Text fontSize="sm" color={'gray.600'}>
            @{profile.username}
          </Text>
          <Text fontSize="sm" color={'gray.600'}>
            {profile.total_points} points
          </Text>
        </Flex>
      </Flex>
      <Divider my={4} />
      <Flex mt={2} align={'flex-start'} flexDirection={'column'}>
        <Button
          fontSize={'sm'}
          pb={2}
          fontWeight="semibold"
          textColor={'gray.600'}
          className="hover:text-orange-500"
          as={Link}
          href={
            isMentor
              ? `/mentor/${profile.username}`
              : `/profile/${profile.username}`
          }
          size="sm"
          variant={'link'}
          leftIcon={<EyeIcon className="h-4 w-4 text-orange-500" />}
        >
          <span>View profile</span>
        </Button>

        <Button
          as={Link}
          href={'/settings'}
          fontSize={'sm'}
          fontWeight="semibold"
          className="hover:text-orange-500"
          textColor={'gray.600'}
          size="sm"
          variant={'link'}
          leftIcon={<PencilSquareIcon className="h-4 w-4 text-orange-500" />}
        >
          <span>Edit profile</span>
        </Button>
      </Flex>
      <Divider my={4} />
      {/* Share Techstarta with your friends */}
      <Flex pt={4} flexDirection="column">
        <Text>
          <span className="font-medium">Share Techstarta</span> with your
          friends <HeartIcon className="h-5 w-5 inline-block text-orange-500" />
        </Text>
        <Flex>
          <HStack spacing={2} py="4" color={'gray.600'}>
            <LinkedinShareButton
              title={'Hi there! Check out Techstarta'}
              summary={
                "I'm building my tech career with Techstarta. You should too!"
              }
              url={referralLink}
              source={referralLink}
            >
              <FaLinkedin className="hover:text-orange-500" size={20} />
            </LinkedinShareButton>
            <TwitterShareButton
              title={
                "Hey there! I'm building my tech career with Techstarta. You should too!"
              }
              url={referralLink}
              via="techstarta"
              hashtags={['SucceedInTech', 'Techstarta']}
            >
              <FaTwitter className="hover:text-orange-500" size={20} />
            </TwitterShareButton>
            <WhatsappShareButton
              title={
                "Hey there! I'm building my tech career with Techstarta. You should too!"
              }
              url={referralLink}
            >
              <FaWhatsapp className="hover:text-orange-500" size={20} />
            </WhatsappShareButton>
            <EmailShareButton
              subject={`Hey there! I'm building my tech career with Techstarta. You should too!`}
              body={referralLink}
            >
              <FaEnvelope className="hover:text-orange-500" size={20} />
            </EmailShareButton>
          </HStack>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DashboardProfileCard;
