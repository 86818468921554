import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar.js";
import FooterSmall from "../components/FooterSmall.js";
import NewsletterService from "../services/newsletter-service"
import { useLocation } from "react-router-dom";
import queryString from "query-string"
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Box,
    Flex,
    Spinner,
    Text
} from "@chakra-ui/react";

const NewsletterConfirmation = () => {

    const [isSubscriptionConfirmed, setSubscriptionConfirmation] = useState(false);
    const [isResponseOk, setResponseOk] = useState("false")
    const location = useLocation().search;
    const queryValues = queryString.parse(location)

    useEffect(
        () => {
            async function confirmSubscription() {
                const payload = {
                    email: queryValues.email,
                    confirmation_code: queryValues.verification_code
                }
                try {
                    const response = await NewsletterService.confirmNewsletterSubscription(payload)
                    if (response === 200) {
                        setResponseOk(true)
                        setSubscriptionConfirmation(true)
                    }
                } catch (err) {
                    if (err) {
                        setResponseOk(false)
                        setSubscriptionConfirmation(true)
                    }
                }
            }
            confirmSubscription()
        }, [queryValues]
    )

    return (
        <Flex
            w="100%"
            h="100%"
            flexDirection="column"
            bg="#1a202c"
            minHeight="100%"
            position="static"
            overflow="auto"
            backgroundImage="url('./images/register_bg_2.png')"
            backgroundSize="100% auto"
            backgroundRepeat="repeat"
            backgroundPosition="center"
        >
            <Navbar transparent showMenu={true} />
            <Flex w="auto" minHeight="calc(100vh - 60px)" justifyContent="center" alignContent="center">
                <Flex m="30px" mt="100px" rounded="5px" w={["100%", "80%", "60%", "40%"]} alignItems="center" justifyContent="center">
                    <Flex w={["100%", "80%", "60%", "70%"]} flexDirection="column">
                        <main>
                            <section className="w-full h-full">
                                <div className="container mx-auto h-full">
                                    <div className="flex content-center items-center justify-center h-full">
                                        <div className="w-full">
                                            <div className="relative flex flex-col min-w-0 break-words w-full  shadow-lg rounded-lg bg-gray-300 border-0">
                                                <div className="rounded-t mb-0 px-6 py-6">
                                                    <div className="text-center mb-3">
                                                        <h6 className="text-gray-600 text-sm uppercase font-bold">
                                                            Welcome Back!
                                                        </h6>
                                                        {
                                                            isSubscriptionConfirmed && isResponseOk && <Alert
                                                                m="3"
                                                                status="success"
                                                                variant="subtle"
                                                                flexDirection="column"
                                                                justifyContent="center"
                                                                textAlign="center"
                                                                height="200px"
                                                            >
                                                                <AlertIcon size="40px" mr={0} />
                                                                <AlertTitle mt={4} mb={1} fontSize="lg">
                                                                    Email confirmed!
                                                                </AlertTitle>
                                                                <AlertDescription maxWidth="sm">
                                                                    Thanks for subscribing to our newsletter.
                                                                </AlertDescription>
                                                            </Alert>
                                                        }
                                                        {
                                                            isSubscriptionConfirmed && !isResponseOk && <Alert
                                                                m="3"
                                                                status="error"
                                                                variant="subtle"
                                                                flexDirection="column"
                                                                justifyContent="center"
                                                                textAlign="center"
                                                                height="200px"
                                                            >
                                                                <AlertIcon size="40px" mr={0} />
                                                                <AlertTitle mt={4} mb={1} fontSize="lg">
                                                                    Email not confirmed!
                                                                </AlertTitle>
                                                                <AlertDescription maxWidth="sm">
                                                                    Kindly try again later.
                                                                </AlertDescription>
                                                            </Alert>
                                                        }
                                                        {
                                                            !isSubscriptionConfirmed && <Flex pt="10px" w="100%" h="100%" align="center" justify="center" >
                                                                <Box rounded="md" w={["100%", "100%", "100%", "80%"]} h="50%" bg="white">
                                                                    <Flex p="20px" align="center" justify="center" >
                                                                        <Spinner size="xl"
                                                                            thickness="4px"
                                                                            speed="0.65s"
                                                                            emptyColor="gray.200"
                                                                            color="orange.500"
                                                                        />
                                                                    </Flex>
                                                                    <Text textAlign="center" color="gray" p="10px" fontSize="sm"> Please wait while we confirm your email... </Text>
                                                                </Box>
                                                            </Flex>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>
                    </Flex>
                </Flex>
            </Flex>
            <FooterSmall />
        </Flex>
    )
}

export default NewsletterConfirmation;
