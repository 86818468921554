import React from 'react';
import {
  Box,
  Flex,
  Text,
  Skeleton,
  Button,
  LinkBox,
  LinkOverlay,
  Link,
  SkeletonText,
  Image,
  HStack,
} from '@chakra-ui/react';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import TrackLabel from './Track/TrackLabel';
import DifficultyLabel from './ChallengeDetail/DifficultyLabel';
import { Users } from '@vectopus/atlas-icons-react';

export const ChallengeCard = ({ challengeDetails }) => {
  const {
    slug,
    title,
    difficulty_level,
    track,
    description,
    image,
    tasks,
    participant_count,
  } = challengeDetails;

  return (
    <>
      <LinkBox className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10 hover:ring-gray-300 rounded-lg">
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          rounded="lg"
          cursor="pointer"
          overflow="hidden"
        >
          <Image
            height={'44'}
            width={'auto'}
            objectFit={'cover'}
            roundedTop={'lg'}
            src={image}
            alt={title}
          />
          <Flex color="#2d3748" w={'100%'} flexDirection="column">
            <Flex
              p={['10px', '15px']}
              pb={['10px', '10px']}
              flexDirection="column"
            >
              <Flex
                w={['100%', '100%', '100%', '100%']}
                pt="10px"
                justifyContent={['center', 'center', 'center', 'center']}
                flexDirection="column"
              >
                <LinkOverlay href={`/projects/${slug}`}>
                  <Text
                    fontWeight={'semibold'}
                    cursor="pointer"
                    textColor={'gray.700'}
                    noOfLines={2}
                  >
                    {title}
                  </Text>
                </LinkOverlay>
              </Flex>
              <Box py={2}>
                <Text
                  overflow="hidden"
                  maxHeight="40px"
                  minHeight="40px"
                  color="gray.700"
                  fontSize="sm"
                  className="line-clamp"
                >
                  {description}
                </Text>
              </Box>
              <Flex
                w={['100%', '100%', '100%', '100%']}
                py={2}
                flexDirection={'column'}
              >
                <HStack spacing={2} alignItems="flex-start">
                  <DifficultyLabel difficulty={difficulty_level} />
                  <TrackLabel track={track} />
                </HStack>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            pt={4}
            pb={4}
            px={4}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            {participant_count > 0 ? (
              <HStack spacing={2}>
                <Users className="w-4 h-4" />
                <Text
                  fontSize={'sm'}
                  fontWeight="semibold"
                  textColor={'gray.600'}
                >
                  {participant_count} participants
                </Text>
              </HStack>
            ) : (
              <Text fontSize={'sm'} textColor={'gray.600'}>
                Try this challenge
              </Text>
            )}
            <Link href={`/projects/${slug}`}>
              <Button
                variant={'outline'}
                fontSize={'sm'}
                _hover={{ bg: 'gray.700', color: 'white' }}
                rightIcon={<ArrowRightIcon className="w-4 h-4" />}
              >
                View Project
              </Button>
            </Link>
          </Flex>
        </Flex>
      </LinkBox>
    </>
  );
};

export const ChallengeCardSkeleton = () => {
  return (
    <Flex
      p="15px"
      flexDirection="column"
      boxShadow="sm"
      justifyContent="space-between"
      minHeight="100px"
      rounded="lg"
    >
      <SkeletonText noOfLines={6} spacing="4" />
      <Flex
        pt={4}
        pb={4}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Skeleton height="10px" w="100%" />
      </Flex>
    </Flex>
  );
};
