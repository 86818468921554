import React, { useEffect } from 'react';
import Cal, { getCalApi } from '@calcom/embed-react';
import { InlineWidget } from 'react-calendly';

const EmbedCal = ({ calLink }) => {
  if (calLink === null || calLink === undefined || calLink === '') {
    return (
      <div className="h-40">
        <h3 className="text-lg font-bold text-center text-gray-800">
          Calendar not available
        </h3>
        <p className="text-md font-medium text-center text-gray-600 mt-2">
          Either the calendar link is invalid or the calendar is not supported.
        </p>
      </div>
    );
  }

  if (calLink.includes('calendly')) {
    return (
      <div>
        <InlineWidget url={calLink} />
      </div>
    );
  }

  if (calLink.includes('cal.com')) {
    const calLinkArray = calLink.split('/');
    const username = calLinkArray[3];
    const calendarName = calLinkArray[4];
    useEffect(() => {
      (async function () {
        const cal = await getCalApi();
        cal('ui', {
          theme: 'light',
          styles: { branding: { brandColor: '#f97316' } },
          hideEventTypeDetails: true,
          layout: 'column_view',
        });
      })();
    }, []);

    return (
      <div className="h-[40rem]">
        <Cal
          namespace={calendarName}
          calLink={`${username}/${calendarName}`}
          style={{ width: '100%', height: '100%', overflow: 'auto' }}
          config={{ layout: 'column_view' }}
        />
      </div>
    );
  }

  if (calLink.includes('calendar.google')) {
    return (
      <div>
        <iframe
          src={calLink}
          style={{ border: '0', width: '100%', height: '100%' }}
          title="Calendar"
        ></iframe>
      </div>
    );
  }

  return (
    <div className="h-40">
      <h3 className="text-lg font-bold text-center text-gray-800">
        Calendar not available
      </h3>
      <p className="text-md font-medium text-center text-gray-600 mt-2">
        Either the calendar link is invalid or the calendar is not supported.
      </p>
    </div>
  );
};

export default EmbedCal;
