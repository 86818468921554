import AuthService from "./auth-service"
import {API_BASE_URL, connection} from "./index"

const getNotifications = async () => {
    const token = AuthService.getAuthToken();
    return await connection.get(API_BASE_URL + "notifications",
        {
            headers: {
                "Authorization": `token ${token}`
            }
        }
    );
}

export default { getNotifications };
