import React from 'react';
import { Link } from 'react-router-dom';

import {
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  HStack,
  Image,
  Text,
  useDisclosure,
  Divider,
} from '@chakra-ui/react';
import TechstartaLogo from '../images/Techstarta Logo blue text - png.png';

import AuthService from '../services/auth-service';
import { FeedbackFish } from '@feedback-fish/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignOut,
  faSignIn,
  faBars,
  faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';
import { faNewspaper } from '@fortawesome/free-regular-svg-icons';
import {
  HomeIcon,
  AcademicCapIcon,
  UsersIcon,
  UserCircleIcon,
  BoltIcon,
  ChatBubbleLeftRightIcon,
  Squares2X2Icon,
  PuzzlePieceIcon,
  UserGroupIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/solid';
import useProfile from '../utils/auth/useProfile';
import { ROLES } from '../utils/auth/permission-maps';
import ProfileSwitch from './ProfileSwitch';

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState('hidden');
  const userData = AuthService.getCurrentUser();
  const user = JSON.parse(userData);
  const { getProfile } = useProfile();
  const defaultHomeUrl = getProfile() === ROLES.mentor ? '/m/home' : '/home';

  const getNavColors = path => {
    const navColors = { default: '', hover: '' };
    const currentPath = window.location.pathname;
    if (currentPath.includes(path) && path !== '/mentors') {
      navColors.default = 'text-orange-500 font-bold';
      navColors.hover = 'text-orange-600 ';
    } else {
      navColors.default = 'text-gray-800 ';
      navColors.hover = 'text-gray-600 ';
    }

    if (path === '/mentors' && currentPath === '/mentors') {
      navColors.default = 'text-orange-500 font-bold';
      navColors.hover = 'text-orange-600 ';
    }

    return navColors;
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <nav
        style={{ background: 'rgb(251, 251, 249)' }}
        className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-md bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
      >
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Logo */}
          <Link
            className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
            to="/home"
          >
            <HStack>
              <Image
                src={TechstartaLogo}
                height="40px"
                width="auto"
                alt="Techstarta"
              />
            </HStack>
          </Link>
          {/* Menu */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={onOpen}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>

          <div
            className={
              'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow-sm absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
              collapseShow
            }
          >
            {user && user.is_mentor ? <ProfileSwitch /> : null}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={`${getNavColors(defaultHomeUrl).default} hover:${
                    getNavColors(defaultHomeUrl).hover
                  } text-sm py-3 font-semibold block`}
                  to={defaultHomeUrl}
                >
                  <HStack>
                    <HomeIcon className="w-5 h-5 text-sm" />
                    <Text>Home</Text>
                  </HStack>
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={`${getNavColors('/mentorships').default} hover:${
                    getNavColors('/mentorships').hover
                  } text-sm py-3 font-semibold block`}
                  to={'/mentorships'}
                >
                  <HStack>
                    <Squares2X2Icon className="w-5 h-5 text-sm" />
                    <Text>Mentorships</Text>
                  </HStack>
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={`${getNavColors('/messages').default} hover:${
                    getNavColors('/messages').hover
                  } text-sm py-3 font-semibold block`}
                  to="/messages"
                >
                  <HStack>
                    <ChatBubbleLeftRightIcon className="w-5 h-5 text-sm" />
                    <Text>Messaging</Text>
                  </HStack>
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={`${getNavColors('/mentors').default} hover:${
                    getNavColors('/mentors').hover
                  } text-sm py-3 font-semibold block`}
                  to="/mentors"
                >
                  <HStack>
                    <UsersIcon className="w-5 h-5" />
                    <Text>Explore</Text>
                  </HStack>
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={`${getNavColors('/workshops').default} hover:${
                    getNavColors('/workshops').hover
                  } text-sm py-3 font-semibold block`}
                  to="/workshops"
                >
                  <HStack>
                    <WrenchScrewdriverIcon className="w-5 h-5" />
                    <Text>Workshops</Text>
                  </HStack>
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={`${getNavColors('/projects').default} hover:${
                    getNavColors('/projects').hover
                  } text-sm py-3 font-semibold block`}
                  to="/projects"
                >
                  <HStack>
                    <PuzzlePieceIcon className="w-5 h-5" />
                    <Text>Projects</Text>
                  </HStack>
                </Link>
              </li>

              {/* <li className="items-center">
                <Link
                  className={`${getNavColors('/bounties').default} hover:${
                    getNavColors('/bounties').hover
                  } text-sm py-3 font-semibold block`}
                  to="/bounties"
                >
                  <HStack>
                    <CurrencyDollarIcon className="w-5 h-5" />
                    <Text>Bounties</Text>
                  </HStack>
                </Link>
              </li> */}
            </ul>
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              More
            </h6>
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={`${getNavColors('/skills').default} hover:${
                    getNavColors('/skills').hover
                  } text-sm py-3 font-semibold block`}
                  to="/skills"
                >
                  <HStack>
                    <AcademicCapIcon className="w-5 h-5" />
                    <Text>Skill guides</Text>
                  </HStack>
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={`${getNavColors('/community').default} hover:${
                    getNavColors('/community').hover
                  } text-sm py-3 font-semibold block`}
                  to="/community"
                >
                  <HStack>
                    <UserGroupIcon className="w-5 h-5" />
                    <Text>Community</Text>
                  </HStack>
                </Link>
              </li>

              <li className="inline-flex">
                <button
                  data-featurebase-feedback
                  to={'#'}
                  className={`${getNavColors('/support').default} hover:${
                    getNavColors('/support').hover
                  } text-sm block py-3 mb-4 no-underline font-semibold focus:outline-none active:outline-none`}
                >
                  <FontAwesomeIcon
                    icon={faThumbsUp}
                    className="mr-2 text-base"
                  />
                  Leave feedback
                </button>
              </li>
            </ul>
            <hr className="my-4 md:min-w-full" />

            {/* <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center">
                <Link
                  className={`${getNavColors('/membership').default} hover:${
                    getNavColors('/membership').hover
                  } text-sm py-3 font-semibold block`}
                  to="/membership"
                >
                  <HStack>
                    <BoltIcon className="h-5 w-5 text-base text-orange-500" />
                    <Text>Upgrade</Text>
                  </HStack>
                </Link>
              </li>
            </ul> */}
            {/* Heading */}
            <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Account
            </h6>
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="inline-flex">
                {user ? (
                  <Link
                    to="/logout"
                    className={`${getNavColors('/logout').default} hover:${
                      getNavColors('/logout').hover
                    } text-sm block mb-4 no-underline font-semibold`}
                  >
                    <FontAwesomeIcon
                      className="text-gray-600 mr-2"
                      icon={faSignOut}
                    />
                    Logout
                  </Link>
                ) : (
                  <Link
                    to="/login"
                    className={`${getNavColors('/login').default} hover:${
                      getNavColors('/login').hover
                    } text-sm block mb-4 no-underline font-semibold`}
                  >
                    <FontAwesomeIcon
                      className="text-gray-600 mr-2 opacity-75 text-base"
                      icon={faSignIn}
                    />
                    Login
                  </Link>
                )}
              </li>
            </ul>
          </div>
          {/* Bottom navigation */}
          <section
            id="bottom-navigation"
            className="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow"
          >
            <div id="tabs" className="flex justify-between p-4">
              <a
                href={defaultHomeUrl}
                className={`w-full focus:text-orange-500 hover:text-orange-500 justify-center inline-block text-center pt-2 pb-1 ${
                  getNavColors(defaultHomeUrl).default
                } hover:${
                  getNavColors(defaultHomeUrl).hover
                } text-sm py-3 font-medium block`}
              >
                <div className="flex flex-col items-center justify-center">
                  <HomeIcon className="w-5 h-5"></HomeIcon>
                  <span className="tab tab-home block text-xs">Home</span>
                </div>
              </a>
              {getProfile() === ROLES.mentor ? null : (
                <>
                  <a
                    href="/skills"
                    className={`w-full focus:text-orange-400 hover:orange-teal-500 justify-center inline-block text-center pt-2 pb-1 ${
                      getNavColors('/skills').default
                    } hover:${
                      getNavColors('/skills').hover
                    } text-sm py-3 font-medium block`}
                  >
                    <div className="flex flex-col items-center justify-center">
                      <AcademicCapIcon className="w-5 h-5"></AcademicCapIcon>
                      <span className="tab tab-home block text-xs">
                        Careers
                      </span>
                    </div>
                  </a>
                </>
              )}
              <a
                href="/mentorships"
                className={`w-full focus:text-orange-400 hover:orange-teal-500 justify-center inline-block text-center pt-2 pb-1 ${
                  getNavColors('/mentorships').default
                } hover:${
                  getNavColors('/mentorships').hover
                } text-sm py-3 font-medium block`}
              >
                <div className="flex flex-col items-center justify-center">
                  <Squares2X2Icon className="w-5 h-5" />
                  <span className="tab tab-home block text-xs">
                    Mentorships
                  </span>
                </div>
              </a>

              <a
                href="/projects"
                className={`w-full focus:text-orange-500 hover:text-orange-500 justify-center inline-block text-center pt-2 pb-1 ${
                  getNavColors('/projects').default
                } hover:${
                  getNavColors('/projects').hover
                } text-sm py-3 font-medium block`}
              >
                <div className="flex flex-col items-center justify-center">
                  <PuzzlePieceIcon className="w-5 h-5"></PuzzlePieceIcon>
                  <span className="tab tab-home block text-xs">Projects</span>
                </div>
              </a>
              <a
                href="/community"
                className={`w-full focus:text-orange-400 hover:text-orange-500 justify-center inline-block text-center pt-2 pb-1 ${
                  getNavColors('/community').default
                } hover:${
                  getNavColors('/community').hover
                } text-sm py-3 font-medium block`}
              >
                <div className="flex flex-col items-center justify-center">
                  <UserGroupIcon className="w-5 h-5" />
                  <span className="tab tab-home block text-xs">Community</span>
                </div>
              </a>
            </div>
          </section>
          {/* End bottom navigation */}
          <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent roundedTopLeft={'2xl'} roundedTopRight="2xl">
              <DrawerCloseButton />
              <DrawerHeader>Menu</DrawerHeader>
              <DrawerBody>
                {user && user.is_mentor ? <ProfileSwitch /> : null}

                <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  <li className="items-center">
                    <Link
                      className={`${getNavColors('/community').default} hover:${
                        getNavColors('/community').hover
                      } text-sm py-3 font-semibold block`}
                      to="/community"
                    >
                      <HStack>
                        <ChatBubbleLeftRightIcon className="w-5 h-5" />
                        <Text>Community</Text>
                      </HStack>
                    </Link>
                  </li>
                  <Divider />
                  <li className="items-center">
                    <Link
                      className={`${getNavColors('/stories').default} hover:${
                        getNavColors('/stories').hover
                      } text-sm py-3 font-semibold block`}
                      to="/stories"
                    >
                      <FontAwesomeIcon
                        icon={faNewspaper}
                        className="mr-2 text-base"
                      />
                      Stories
                    </Link>
                  </li>
                  <li className="items-center">
                    <FeedbackFish
                      projectId="31db9b73671d16"
                      userId={user ? user.email : ''}
                    >
                      <Link
                        to={'#'}
                        className={`${getNavColors('/support').default} hover:${
                          getNavColors('/support').hover
                        } text-sm block mb-4 no-underline font-semibold`}
                      >
                        <i className="fas fa-exclamation-circle opacity-75 mr-2 text-base"></i>
                        Share your feedback
                      </Link>
                    </FeedbackFish>
                  </li>
                </ul>
                <hr className="my-4 md:min-w-full" />

                {/* Heading */}
                <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  Account
                </h6>
                {/* Navigation */}
                <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                  <li className="inline-flex">
                    {user && (
                      <Link
                        to={
                          getProfile() === ROLES.mentor
                            ? `/mentor/${user.username}`
                            : `/profile/${user.username}`
                        }
                        className={`${getNavColors('/logout').default} hover:${
                          getNavColors('/logout').hover
                        } text-sm block mb-4 no-underline font-semibold`}
                      >
                        <HStack>
                          <UserCircleIcon className="w-5 h-5"></UserCircleIcon>
                          <span>Profile</span>
                        </HStack>
                      </Link>
                    )}
                  </li>
                  <li className="inline-flex">
                    {user ? (
                      <Link
                        to="/logout"
                        className={`${getNavColors('/logout').default} hover:${
                          getNavColors('/logout').hover
                        } text-sm block mb-4 no-underline font-semibold`}
                      >
                        <FontAwesomeIcon
                          className="text-gray-600 mr-2"
                          icon={faSignOut}
                        />
                        Logout
                      </Link>
                    ) : (
                      <Link
                        to="/login"
                        className={`${getNavColors('/login').default} hover:${
                          getNavColors('/login').hover
                        } text-sm block mb-4 no-underline font-semibold`}
                      >
                        <FontAwesomeIcon
                          className="text-gray-600 mr-2 opacity-75 text-base"
                          icon={faSignIn}
                        />
                        Login
                      </Link>
                    )}
                  </li>
                </ul>
                <hr className="my-4 md:min-w-full" />

                <Text textColor={'gray.600'}>Follow us</Text>
                <ul className="md:flex-col md:min-w-full flex flex-wrap list-none md:mb-4">
                  <li className="inline-flex mr-4">
                    <a
                      href="https://twitter.com/techstarta"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-500 hover:text-gray-700 text-lg leading-lg"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="inline-flex mr-4">
                    <a
                      href="https://www.facebook.com/techstarta"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-500 hover:text-gray-700 text-lg leading-lg"
                    >
                      <i className="fab fa-facebook-square"></i>
                    </a>
                  </li>
                  <li className="inline-flex mr-4">
                    <a
                      href="https://www.instagram.com/techstarta"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-500 hover:text-gray-700 text-lg leading-lg"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li className="inline-flex mr-4">
                    <a
                      href="https://www.linkedin.com/company/techstarta"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-500 hover:text-gray-700 text-lg leading-lg"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </div>
      </nav>
    </>
  );
}
