import { API_BASE_URL, connection } from './index';
import AuthService from './auth-service';

const getRecentActivities = async () => {
  const token = AuthService.getAuthToken();
  return await connection.get(API_BASE_URL + 'activities', {
    headers: {
      Authorization: `token ${token}`,
    },
  });
};

const getBadges = async () => {
  return await connection.get(API_BASE_URL + 'badges');
};

export default { getRecentActivities, getBadges };
