import { Flex, Stack, Text } from '@chakra-ui/react';

const Feature = ({
  title,
  text,
  icon,
  textColor = 'gray.700',
  bgColor = 'white',
}) => {
  return (
    <Stack
      shadow={'sm'}
      border={'1px solid'}
      borderColor={'gray.100'}
      p={4}
      rounded={'lg'}
    >
      <Flex
        w={16}
        h={16}
        align={'center'}
        justify={'center'}
        color={'white'}
        rounded={'full'}
        bg={'gray.50'}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>
        {title}
      </Text>
      <Text>{text}</Text>
    </Stack>
  );
};

export default Feature;
