import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar';
import withRouter from '../utils/withRouter.js';

const CheckoutCancelled = () => {
  const navigate = useNavigate();

  return (
    <>
      <LayoutWithSidebar title={'Checkout cancelled'}>
        <Flex
          shadow={'sm'}
          rounded={'lg'}
          minHeight={'52'}
          justify={'center'}
          alignItems="center"
          flexDirection="column"
        >
          <Text fontSize={'2xl'} fontWeight="semibold" textColor={'gray.700'}>
            Uhh! Still not convinced?
          </Text>
          <Text fontSize={'sm'} textColor="gray.700">
            Your subscription was not processed.
          </Text>
          <VStack spacing={'2'} pt="5">
            <Button
              textColor="white"
              bgColor="brand.darkBlue"
              _hover={{ shadow: 'md' }}
              variant={'solid'}
              w={'56'}
              rightIcon={<ArrowRightIcon className="w-4 h-4" />}
              onClick={() => navigate('/membership')}
            >
              Upgrade membership
            </Button>
          </VStack>
        </Flex>
      </LayoutWithSidebar>
    </>
  );
};

export default withRouter(CheckoutCancelled);
