import React, { useState } from 'react';
import splitbee from '@splitbee/web';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar';
import { CalendarDaysIcon, PlusIcon } from '@heroicons/react/24/outline';
import {
  Avatar,
  AvatarGroup,
  Box,
  HStack,
  LinkBox,
  LinkOverlay,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Skeleton,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import mentorService from '../services/mentor-service';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import EmbedCal from '../components/Common/EmbedCal';
import {
  Notebook,
  PencilBox,
  TargetArrowBullseye,
} from '@vectopus/atlas-icons-react';
import discussionService from '../services/discussion-service';
import TextInput from '../components/TextInput';
import { useFormik } from 'formik';
import Datepicker from 'react-tailwindcss-datepicker';

const MentorshipDetail = () => {
  splitbee.track('Mentorship Detail');
  const { mentorshipId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEditMentorshipModalOpen,
    onOpen: onOpenMentorshipModal,
    onClose: onCloseMentorshipModal,
  } = useDisclosure();
  const toast = useToast();
  const [message, setMessage] = useState('');

  const getInitials = fullname => {
    if (!fullname) return '';
    const names = fullname.split(' ');
    const firstName = names[0];
    const lastName = names[1];
    return firstName + ' ' + lastName[0] + '.';
  };

  const statusMap = {
    Pending: 'bg-yellow-500',
    Active: 'bg-green-500',
    Completed: 'bg-green-500',
    Inactive: 'bg-red-500',
  };

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ['mentorshipDetail', mentorshipId],
    queryFn: () => mentorService.getMentorshipDetail(mentorshipId),
  });

  const {
    isLoading: isMessageLoading,
    error,
    data: messageList,
    refetch: refetchMessages,
  } = useQuery({
    queryKey: ['comments', mentorshipId],
    queryFn: () => discussionService.getComments('mentorship', mentorshipId),
    enabled: !!mentorshipId,
  });

  const handleSendMessage = async () => {
    const payload = {
      text: message,
      object_id: mentorshipId,
      content_type: 'mentorship',
    };
    try {
      await discussionService.submitComment(payload);
      setMessage('');
      await refetchMessages();
    } catch (e) {
      console.log(e);
    }
  };

  const { mutate: addGoal } = useMutation({
    mutationFn: mentorService.createGoal,
    onSuccess: () => {
      queryClient.invalidateQueries('mentorshipDetail');
      toast({
        icon: <TargetArrowBullseye className="w-5 h-5" />,
        isClosable: true,
        position: 'top-right',
        title: 'Goal added',
        colorScheme: 'gray',
        description: 'A new goal has been added to your mentorship',
      });
      onCloseMentorshipModal();
    },
  });

  const goalForm = useFormik({
    initialValues: {
      title: '',
      description: '',
      mentorship_id: parseInt(mentorshipId),
      target_date: '',
    },
    onSubmit: values => {
      values.target_date = moment(values.target_date).format('YYYY-MM-DD');
      addGoal(values);
    },
  });

  return (
    <LayoutWithSidebar title={'Mentorship'}>
      {/*  Book session modal */}
      <Modal isOpen={isOpen} onClose={onClose} size={'3xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Book a session</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflow={'scroll'}>
            <EmbedCal calLink={data?.mentorship.calendar_link} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isEditMentorshipModalOpen}
        onClose={onCloseMentorshipModal}
        size={'lg'}
      >
        <ModalOverlay />
        <ModalContent overflow={'scroll'} minH={'40rem'}>
          <ModalHeader>Add a goal</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflow={'scroll'}>
            <form onSubmit={goalForm.handleSubmit}>
              <Box w={'100%'} my={'6'}>
                <TextInput
                  name={'title'}
                  placeholder={'Summarize your goal in one sentence'}
                  type={'text'}
                  value={goalForm.values.title}
                  onChangeFunction={goalForm.handleChange}
                  onBlurFunction={goalForm.handleBlur}
                />
              </Box>
              <Box w={'100%'} my={'6'}>
                <TextInput
                  element={'textArea'}
                  placeholder={'Describe your goal'}
                  name={'description'}
                  value={goalForm.values.description}
                  onChangeFunction={goalForm.handleChange}
                  onBlurFunction={goalForm.handleBlur}
                />
              </Box>
              <Box w={'100%'} my={'6'}>
                <Datepicker
                  primaryColor="orange"
                  classNames={{
                    container: 'w-full',
                  }}
                  inputClassName="w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                  useRange={false}
                  popoverDirection="down"
                  value={goalForm.values.target_date}
                  onChange={(value, event) => {
                    goalForm.setFieldValue('target_date', value);
                  }}
                  startFrom={new Date()}
                  asSingle={true}
                  placeholder="Select a target date"
                />
              </Box>
            </form>
          </ModalBody>

          <ModalFooter>
            <button
              type="button"
              onClick={onCloseMentorshipModal}
              className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={goalForm.submitForm}
              className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-gray-800 border border-transparent rounded-md hover:bg-gray-900"
            >
              Save
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <div className="mx-auto w-full">
        <div className="flex flex-wrap mt-4">
          <div className="w-full xl:w-8/12 mb-12 xl:mb-0 md:px-2">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
              <div className="rounded-t mb-0 border-0">
                <div>
                  <div className="justify-between flex flex-wrap">
                    <div className="px-4 sm:px-0 flex flex-wrap items-center">
                      <AvatarGroup>
                        <Avatar
                          name={data?.mentorship.mentor.fullname}
                          src={data?.mentorship.mentor.image}
                          size={'lg'}
                        />
                        <Avatar
                          name={data?.mentorship.mentee.fullname}
                          src={data?.mentorship.mentee.image}
                          size={'lg'}
                        />
                      </AvatarGroup>
                      <Skeleton
                        rounded={'lg'}
                        className="pl-2"
                        isLoaded={!isLoading}
                      >
                        <div className="flex flex-col">
                          <h3 className="text-2xl font-semibold leading-normal text-gray-800">
                            {getInitials(data?.mentorship.mentor.fullname)} &{' '}
                            {getInitials(data?.mentorship.mentee.fullname)}
                          </h3>
                          <p className="text-sm font-light leading-relaxed mt-0 mb-4 text-gray-600">
                            Mentoring since{' '}
                            {moment(data?.mentorship.created_at).format(
                              'MMMM Do, YYYY'
                            )}
                          </p>
                        </div>
                      </Skeleton>
                    </div>
                    <div>
                      <Skeleton isLoaded={!isLoading}>
                        <div className="space-x-2">
                          <div
                            className={`text-xs inline-flex items-center font-medium leading-sm px-4 py-2 rounded-full ${
                              statusMap[data?.mentorship.status]
                            } text-gray-700 border`}
                          >
                            {data?.mentorship.status}
                          </div>
                        </div>
                      </Skeleton>
                    </div>
                  </div>
                  <div className="mt-6 border-t border-gray-100">
                    <dl className="divide-y divide-gray-100">
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Career track
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {data?.mentorship.track.title}
                        </dd>
                      </div>
                      <div className="py-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Quick Actions
                        </h3>
                        <div className="mt-2 max-w-xl w-full">
                          <SimpleGrid
                            spacing={4}
                            columns={[1, 2, 2, 3]}
                            className="w-full"
                          >
                            <LinkBox key={'bookASession'}>
                              <HStack
                                className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10 hover:ring-gray-300"
                                rounded={'lg'}
                                bgColor="white"
                                p={4}
                              >
                                <CalendarDaysIcon className="w-8 h-8 mr-2 text-orange-500" />
                                <LinkOverlay
                                  onClick={() => onOpen()}
                                  className="w-full"
                                  href="#"
                                >
                                  <h3 className="text-md leading-6 font-medium text-gray-900">
                                    Book a session
                                  </h3>
                                </LinkOverlay>
                              </HStack>
                            </LinkBox>
                            <LinkBox key={'takeNote'}>
                              <HStack
                                className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10 hover:ring-gray-300"
                                rounded={'lg'}
                                bgColor="white"
                                p={4}
                              >
                                <Notebook className="w-8 h-8 mr-2 text-gray-300" />
                                <LinkOverlay
                                  onClick={() => {
                                    toast({
                                      title: 'Coming soon',
                                      description:
                                        'This feature is coming soon',
                                      status: 'info',
                                      duration: 5000,
                                      isClosable: true,
                                    });
                                  }}
                                  className="w-full"
                                  href="#"
                                >
                                  <h3 className="text-md leading-6 font-medium text-gray-300">
                                    Take a note
                                  </h3>
                                </LinkOverlay>
                              </HStack>
                            </LinkBox>
                            <LinkBox key={'writeReview'}>
                              <HStack
                                className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10 hover:ring-gray-300"
                                rounded={'lg'}
                                bgColor="white"
                                p={4}
                              >
                                <PencilBox className="w-8 h-8 mr-2 text-gray-300" />
                                <LinkOverlay
                                  onClick={() => {
                                    toast({
                                      title: 'Coming soon',
                                      description:
                                        'This feature is coming soon',
                                      status: 'info',
                                      duration: 5000,
                                      isClosable: true,
                                    });
                                  }}
                                  className="w-full"
                                  href="#"
                                >
                                  <h3 className="text-md leading-6 font-medium text-gray-300">
                                    Write a review
                                  </h3>
                                </LinkOverlay>
                              </HStack>
                            </LinkBox>
                          </SimpleGrid>
                        </div>
                      </div>
                      <div className="py-6">
                        <div className="flex justify-between items-center">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Goals
                          </h3>
                          <button
                            onClick={onOpenMentorshipModal}
                            className="inline-flex items-center px-4 py-2 mt-2 text-sm font-medium text-white bg-gray-800 border border-transparent rounded-md hover:bg-gray-900"
                          >
                            <PlusIcon className="w-4 h-4 inline-flex mr-2" />
                            Add a goal
                          </button>
                        </div>
                        <div className="mt-2 flex w-full">
                          <VStack spacing={4} className="w-full">
                            {data?.mentorship.goals.map(goal => (
                              <LinkBox key={goal.id} className="w-full">
                                <HStack
                                  className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10 hover:ring-gray-300"
                                  rounded={'lg'}
                                  bgColor="white"
                                  p={4}
                                >
                                  <TargetArrowBullseye className="w-8 h-8 mr-2 text-orange-500" />
                                  <LinkOverlay
                                    onClick={() => onOpen()}
                                    className="w-full"
                                    href="#"
                                  >
                                    <h3 className="text-md leading-6 font-medium text-gray-900">
                                      {goal.title}
                                    </h3>
                                  </LinkOverlay>
                                </HStack>
                              </LinkBox>
                            ))}
                          </VStack>
                        </div>
                      </div>

                      {/* <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Attachments
                        </dt>
                        <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <ul
                            role="list"
                            className="divide-y divide-gray-100 rounded-md border border-gray-200"
                          >
                            <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                              <div className="flex w-0 flex-1 items-center">
                                <PaperClipIcon
                                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                  <span className="truncate font-medium">
                                    resume_back_end_developer.pdf
                                  </span>
                                  <span className="flex-shrink-0 text-gray-400">
                                    2.4mb
                                  </span>
                                </div>
                              </div>
                              <div className="ml-4 flex-shrink-0">
                                <a
                                  href="#"
                                  className="font-medium text-indigo-600 hover:text-indigo-500"
                                >
                                  Download
                                </a>
                              </div>
                            </li>
                            <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                              <div className="flex w-0 flex-1 items-center">
                                <PaperClipIcon
                                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                  <span className="truncate font-medium">
                                    coverletter_back_end_developer.pdf
                                  </span>
                                  <span className="flex-shrink-0 text-gray-400">
                                    4.5mb
                                  </span>
                                </div>
                              </div>
                              <div className="ml-4 flex-shrink-0">
                                <a
                                  href="#"
                                  className="font-medium text-indigo-600 hover:text-indigo-500"
                                >
                                  Download
                                </a>
                              </div>
                            </li>
                          </ul>
                        </dd>
                      </div> */}
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full xl:w-4/12 md:px-4 pb-12">
            <div className="rounded-lg ring-1 ring-gray-600/10">
              <div className="flex flex-col p-4">
                <h3 className="text-base font-semibold leading-7 text-gray-900">
                  Conversation
                </h3>
              </div>
              <hr />
              <div className="py-4 h-[30rem] overflow-y-auto">
                {messageList?.results.length === 0 && (
                  <div className="flex flex-col items-center justify-center h-full">
                    <svg
                      className="w-12 h-12 text-gray-300"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        d="M12 4v16m8-8H4"
                      ></path>
                    </svg>
                    <p className="text-sm font-normal text-gray-400">
                      No messages yet
                    </p>
                  </div>
                )}
                {messageList?.results.map((message, index) => (
                  <div key={index} className="flex items-start gap-2.5 p-2">
                    <Avatar
                      src={message.author.image || ''}
                      name={message.author.fullname}
                      size={'sm'}
                    />
                    <div className="flex flex-col w-full max-w-[320px] leading-1.5 p-4 border border-gray-200 rounded-b-xl rounded-r-xl bg-white">
                      <div className="flex items-center space-x-2 rtl:space-x-reverse">
                        <span className="text-sm font-semibold text-gray-900">
                          {getInitials(message.author.fullname)}
                        </span>
                        <span className="text-sm font-normal text-gray-400">
                          {moment(message.created_at).fromNow()}
                        </span>
                      </div>
                      <p className="text-sm font-normal py-2.5 text-gray-900 ">
                        {message.text}
                      </p>
                      <span className="text-sm font-normal text-gray-500">
                        Delivered
                      </span>
                    </div>
                    {/* <button
                      id="dropdownMenuIconButton"
                      data-dropdown-toggle="dropdownDots"
                      data-dropdown-placement="bottom-start"
                      className="inline-flex self-center items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                      type="button"
                    >
                      <svg
                        className="w-4 h-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 4 15"
                      >
                        <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                      </svg>
                    </button>
                    <div
                      id="dropdownDots"
                      className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-40 dark:bg-gray-700 dark:divide-gray-600"
                    >
                      <ul
                        className="py-2 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownMenuIconButton"
                      >
                        <li>
                          <a
                            href="#"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            Reply
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            Forward
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            Copy
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            Report
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            Delete
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                ))}
              </div>
              <hr />

              <div className="flex flex-col  p-4">
                <div className="flex items-center space-x-2">
                  <textarea
                    rows="3"
                    name="message"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    className="w-full border border-gray-200 rounded-lg focus:outline-none focus:border-gray-300 p-2 focus:ring-2 focus:ring-orange-500"
                    maxLength={1300}
                    placeholder="Write a message..."
                  ></textarea>
                </div>
                <div className="mt-4">
                  <button
                    type="button"
                    disabled={!message}
                    onClick={handleSendMessage}
                    className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

export default MentorshipDetail;
