
import React from "react";
import { Flex, Skeleton } from "@chakra-ui/react";

const BookingDetailSkeleton = () => {
    return (
        <>
            <Flex shadow="md" rounded="md" mt={["50px", "50px", "50px", "20px"]} p={["10px", "10px", "10px", "20px"]} flexDirection="column">
                <Flex pb="20px" justifyContent="space-between" direction={["column", "row"]} alignItems="center">
                    <Flex alignItems="center">
                        <Skeleton h="80px" />
                    </Flex>
                </Flex>
                <Flex px="10px" flexDirection="column" w={["100%", "100%", "100%", "60%"]}>
                    <Skeleton h="40px" />
                    <Flex my="10px" justifyContent={["flex-start", "flex-end"]}>
                        <Skeleton h="40px" />
                    </Flex>

                    <Flex mt="8" flexDirection="column" w={["100%", "100%", "100%", "100%"]}>
                        <Skeleton h="40px" />
                    </Flex>
                    <Skeleton h="40px" />

                    <Skeleton h="40px" />

                </Flex>
            </Flex>

        </>
    );
}

export default BookingDetailSkeleton