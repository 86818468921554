import React from "react";
import { Flex, Text, Skeleton } from "@chakra-ui/react"
import { convertMsToTime } from "../utils/convertMsToTime"

export const NotificationItem = ({ notification, notificationsPage, }) => {

    const activityDate = Date.parse(notification.created_at);
    const currentDate = Date.now()

    return (
        <>
            <Flex shadow={notificationsPage ? "md" : "0px"} p={notificationsPage ? ["10px", "20px"] : "0px"} color="#718096" fontSize="sm" mb="10px" alignItems="center">
                {
                    !notification.seen_by_user && notificationsPage && <Flex display={["none", "flex"]} pr="15px" fontSize="10px">
                        <i style={{ color: "#ed8936" }} className="fas fa-circle"></i>
                    </Flex>
                }
                <Flex pr="15px" fontSize="20px">
                    <i style={{ color: "#ed8936" }} className="fas fa-users"></i>
                </Flex>
                <Flex w="100%" justifyContent="space-between">
                    <Flex w={notificationsPage ? ["70%", "auto"] : "80%"} flexDirection="column">
                        <Text color="#2d3748"> {notification.message} </Text>
                        {/* <Text> France Douglas, Rema Warner +5 more </Text> */}
                    </Flex>
                    <Flex w={notificationsPage ? ["25%", "auto"] : "20%"} pl="10px" >
                        <Text>
                            {convertMsToTime(activityDate, currentDate)}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}

export const NotificationItemSkeleton = ({ notificationsPage }) => {
    return (
        <>
            <Flex shadow={notificationsPage ? "md" : "0px"} p={notificationsPage ? "20px" : "0px"} justifyContent="space-between" mb="10px" alignItems="center">
                <Skeleton w="5%" h="20px"> </Skeleton>
                <Skeleton w="70%" h="20px"> </Skeleton>
                <Skeleton w="20%" h="20px"> </Skeleton>
            </Flex>
            <Flex shadow={notificationsPage ? "md" : "0px"} p={notificationsPage ? "20px" : "0px"} justifyContent="space-between" mb="10px" alignItems="center">
                <Skeleton w="5%" h="20px"> </Skeleton>
                <Skeleton w="70%" h="20px"> </Skeleton>
                <Skeleton w="20%" h="20px"> </Skeleton>
            </Flex>
        </>
    )
}
