import { API_BASE_URL, connection } from './index';
import AuthService from './auth-service';

const getHackathons = async () => {
  const token = AuthService.getAuthToken();
  const response = await connection.get(API_BASE_URL + 'hackathons', {
    headers: {
      Authorization: `token ${token}`,
    },
  });
  return response.data;
};

const createTeam = async payload => {
  const token = AuthService.getAuthToken();
  return await connection.post(
    API_BASE_URL + 'teams',
    { team: payload },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
};

const joinTeam = async invitationCode => {
  const token = AuthService.getAuthToken();
  return await connection.post(
    API_BASE_URL + `teams/${invitationCode}/join`,
    {},
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
};

const getTeam = async slug => {
  const token = AuthService.getAuthToken();
  return await connection.get(API_BASE_URL + `teams/${slug}`, {
    headers: {
      Authorization: `token ${token}`,
    },
  });
};

const getTeams = async () => {
  const token = AuthService.getAuthToken();
  const response = await connection.get(API_BASE_URL + 'teams', {
    headers: {
      Authorization: `token ${token}`,
    },
  });
  return response;
};

const updateTeam = async (payload, slug) => {
  const token = AuthService.getAuthToken();
  const response = await connection.put(
    API_BASE_URL + `teams/${slug}`,
    { team: payload },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response;
};

const assignTrack = async (teamSlug, trackSlug) => {
  const token = AuthService.getAuthToken();
  const response = await connection.post(
    API_BASE_URL + `teams/${teamSlug}/tracks/${trackSlug}/assign`,
    {},
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response;
};

const assignChallenge = async (teamSlug, challengeSlug) => {
  const token = AuthService.getAuthToken();
  const response = await connection.post(
    API_BASE_URL + `teams/${teamSlug}/challenges/${challengeSlug}/assign`,
    {},
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response;
};

export default {
  createTeam,
  joinTeam,
  getTeams,
  getTeam,
  updateTeam,
  assignTrack,
  assignChallenge,
  getHackathons,
};
