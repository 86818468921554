import React from 'react';
import { Wrap, WrapItem } from '@chakra-ui/react';

const SkillCloud = ({ skills, max = 4, tagSize = 'sm' }) => {
  const remainingSkillsCount = skills?.length - max;
  skills = skills.slice(0, max);
  return (
    <Wrap>
      {skills.map((skill, index) => (
        <WrapItem key={index}>
          <div className="text-xs inline-flex items-center font-bold leading-sm px-3 py-1 rounded-full bg-white text-gray-700 border">
            {skill}
          </div>
        </WrapItem>
      ))}
      {remainingSkillsCount > 0 && (
        <WrapItem>
          <div className="text-xs inline-flex items-center font-bold leading-sm px-3 py-1 rounded-full bg-white text-gray-700 border">
            +{remainingSkillsCount} more
          </div>
        </WrapItem>
      )}
    </Wrap>
  );
};

export default SkillCloud;
