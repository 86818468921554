import React from 'react';
import { Avatar, Flex, Link, Stack, Text } from '@chakra-ui/react';

export const ContentAuthorCard = ({
  titlePrefix = 'Curated by',
  author,
  profileLink,
}) => {
  return (
    <>
      <Flex pb={4} rounded="md" width="100%" direction="column">
        <Stack direction={'row'} spacing={4} align={'center'}>
          <Avatar name={author.fullname} src={author.image} alt={'Author'} />
          <Stack direction={'column'} spacing={0} fontSize={'sm'}>
            {profileLink ? (
              <Link href={profileLink} fontWeight={600}>
                {author.fullname}
              </Link>
            ) : (
              <Text fontWeight={600}>{author.fullname}</Text>
            )}
            <Text color={'gray.600'} noOfLines={'1'}>
              {author.bio}
            </Text>
          </Stack>
        </Stack>
        {/* {author.date_joined && author.total_points ? (
          <Flex mt="4">
            <Stack direction={'row'}>
              <Stack isInline>
                <Icon color="gray.500" as={FaCalendarAlt}></Icon>
                <Text color={'gray.500'} fontSize="xs">
                  {' '}
                  Joined {moment(author.date_joined).format('MMMM YYYY')}
                </Text>
              </Stack>
              <Text>&bull;</Text>
              <Stack isInline>
                <Text color={'gray.500'} fontSize="xs">
                  {' '}
                  {author.total_points + ' '} points
                </Text>
              </Stack>
            </Stack>
          </Flex>
        ) : null} */}
      </Flex>
    </>
  );
};
