import React from 'react';
import {
  Box,
  SkeletonCircle,
  SkeletonText,
} from '@chakra-ui/react';

const DiscussionItemSkeleton = () => {
  return (
    <>
      <Box
        className="shadow-lg shadow-black/5 ring-1 ring-slate-700/10"
        rounded={'lg'}
        padding="6"
        bg="white"
        my={4}
      >
        <SkeletonCircle size="10" />
        <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
      </Box>
    </>
  );
};

const DiscussionListSkeleton = () => {
  return (
    <>
      <DiscussionItemSkeleton />
      <DiscussionItemSkeleton />
      <DiscussionItemSkeleton />
      <DiscussionItemSkeleton />
    </>
  );
};

export { DiscussionListSkeleton };
