import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';

import withRouter from '../utils/withRouter.js';
import SEO from '../components/Common/SEO.js';
import subscriptionService from '../services/subscription-service.js';
import authService from '../services/auth-service.js';
import { Mixpanel } from '../utils/Mixpanel.js';
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import { ArrowRight } from '@vectopus/atlas-icons-react';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';

const Membership = () => {
  const currentUser = JSON.parse(authService.getCurrentUser());
  const mentorshipPlans = [
    {
      name: 'Lite',
      price: '$19',
      price_id: 'price_1OMZQDEyLuaj2xNpIKsLTFwI',
      description: 'Get hands-on mentorship from an industry expert',
      features: [
        'Hands-on career mentorship',
        'Unlimited Q&A via chat',
        'Access to real-world projects',
        'Access to community',
        '1 Video call per month',
      ],
    },
    {
      name: 'Standard',
      price: '$39',
      description: 'Get hands-on mentorship from an industry expert',
      price_id: 'price_1OMZl4EyLuaj2xNpbh35TrRy',
      features: [
        'Hands-on career mentorship',
        'Unlimited Q&A via chat',
        'Access to real-world projects',
        'Access to community',
        'Resume, LinkedIn & Portfolio review.',
        '2 Video calls per month',
      ],
    },
    {
      name: 'Pro',
      price: '$59',
      description: 'Get hands-on mentorship from an industry expert',
      price_id: 'price_1OMZp9EyLuaj2xNpqZyBdMiz',
      features: [
        'Hands-on career mentorship',
        'Unlimited Q&A via chat',
        'Access to real-world projects',
        'Access to community',
        'Resume, LinkedIn & Portfolio review.',
        '4 Video calls per month',
      ],
    },
  ];

  const SUBSCRIBTION_PLAN_PRO = 'price_1NdTNWEyLuaj2xNpikFKZIXQ';

  const goToCheckout = async priceId => {
    Mixpanel.track('Pro Subscription Clicked', currentUser);

    const payload = { price_id: priceId, mode: 'subscription' };
    if (currentUser) {
      payload.customer_email = currentUser.email;
    }
    const response = await subscriptionService.getCheckoutUrl(payload);
    const checkoutSessionUrl = response.checkout.url;
    window.open(checkoutSessionUrl, '_blank', 'noopener noreferrer');
  };

  return (
    <>
      <SEO
        title="Upgrade membership | Techstarta"
        url="https://techstarta.com/membership"
        description="Become a developer, product manager or a product designer through high-quality learning resources."
      />

      <div className="relative bg-white">
        <Navbar transparent={false} currentUser={currentUser} showMenu={true} />
        <Container p={['5', '20', '20', '20']} maxW={'7xl'}>
          <Flex
            className="shadow-lg shadow-black/5 ring-1 ring-slate-700/10 rounded-lg overflow-hidden"
            style={{ background: 'rgb(251, 251, 249)' }}
            p={['10px', '30px', '40px', '40px']}
            w="auto"
            mt={['20', '20', '20', '10']}
            flexDirection={'column'}
          >
            <Heading
              as={'h2'}
              fontWeight="semibold"
              className="text-gray-800 md:text-5xl"
            >
              Accelerate your career with Hands-on Mentorship
            </Heading>
            <Text py={4} className="text-gray-600 md:text-xl text-md">
              Move your tech career forward with hands-on mentorship with highly
              vetted industry experts.
            </Text>
          </Flex>
          <div className="grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-3 sm:gap-6">
            <div className="mx-auto mt-4 max-w-2xl rounded-lg ring-1 ring-gray-200 sm:mt-10 lg:mx-0 lg:flex lg:max-w-none">
              <div className="p-8 sm:p-10 lg:flex-auto h-full">
                <h3 className="text-xl font-bold tracking-tight text-gray-700">
                  {mentorshipPlans[0].name}
                </h3>
                <h2 className="mt-2 text-3xl font-bold text-gray-900">
                  {mentorshipPlans[0].price}/month
                </h2>
                <p className="mt-6 text-base leading-7 text-gray-600">
                  Get access to limited projects and community.
                </p>
                <div className="mt-6 flex items-center gap-x-4">
                  <h4 className="flex-none text-sm font-semibold leading-6 text-orange-600">
                    What’s included
                  </h4>
                  <div className="h-px flex-auto bg-gray-100"></div>
                </div>
                <div className="min-h-[10rem]">
                  <ul
                    role="list"
                    className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 "
                  >
                    {mentorshipPlans[0].features.map((feature, index) => (
                      <li key={index} className="flex gap-x-3">
                        <svg
                          className="h-6 w-5 flex-none text-orange-600"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clipRule="evenodd"
                          />
                        </svg>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="md:flex p-1 flex-wrap bg-white rounded-full w-full">
                  <a
                    href="#"
                    className={
                      'bottom-0 text-sm py-2 h-full block w-full whitespace-no-wrap rounded text-white font-semibold'
                    }
                  >
                    <Button
                      size={'md'}
                      className="w-full"
                      fontSize={'sm'}
                      bgColor={'brand.darkBlue'}
                      onClick={event => {
                        event.preventDefault();
                        goToCheckout(mentorshipPlans[0].price_id);
                      }}
                      textColor={'white'}
                      rightIcon={<ArrowRight className="h-5 w-5 text-whit" />}
                      variant={'outline'}
                      rounded={'full'}
                      _hover={{ bg: 'brand.darkBlue', shadow: 'lg' }}
                    >
                      Get membership
                    </Button>
                  </a>
                </div>
              </div>
            </div>
            <div className="mx-auto mt-4 max-w-2xl rounded-lg ring-1 ring-orange-500 sm:mt-10 lg:mx-0 lg:flex lg:max-w-none">
              <div className="p-8 sm:p-10 lg:flex-auto h-full">
                <h3 className="text-xl font-bold tracking-tight text-gray-700">
                  {mentorshipPlans[1].name}
                </h3>
                <h2 className="mt-2 text-3xl font-bold text-gray-900">
                  {mentorshipPlans[1].price}/month
                </h2>
                <p className="mt-6 text-base leading-7 text-gray-600">
                  Get access to all projects and expert feedback.
                </p>
                <div className="mt-6 flex items-center gap-x-4">
                  <h4 className="flex-none text-sm font-semibold leading-6 text-orange-600">
                    What’s included
                  </h4>
                  <div className="h-px flex-auto bg-gray-100"></div>
                </div>
                <div className="min-h-[10rem]">
                  <ul
                    role="list"
                    className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600"
                  >
                    {mentorshipPlans[1].features.map((feature, index) => (
                      <li key={index} className="flex gap-x-3">
                        <svg
                          className="h-6 w-5 flex-none text-orange-600"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clipRule="evenodd"
                          />
                        </svg>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="md:flex p-1 right-0 left-0 flex-wrap bottom-0 bg-white rounded-full">
                  <a
                    href="#"
                    className={
                      'bottom-0 text-sm py-2 h-full block w-full whitespace-no-wrap rounded text-white font-semibold'
                    }
                  >
                    <Button
                      size={'md'}
                      className="w-full"
                      fontSize={'sm'}
                      bgColor={'brand.darkBlue'}
                      onClick={event => {
                        event.preventDefault();
                        goToCheckout(mentorshipPlans[1].price_id);
                      }}
                      textColor={'white'}
                      rightIcon={<ArrowRight className="h-5 w-5 text-whit" />}
                      variant={'outline'}
                      rounded={'full'}
                      _hover={{ bg: 'brand.darkBlue', shadow: 'lg' }}
                    >
                      Get membership
                    </Button>
                  </a>
                </div>
              </div>
            </div>

            {/* Pro plan */}
            <div className="mx-auto mt-4 max-w-2xl rounded-lg ring-1 ring-gray-200 sm:mt-10 lg:mx-0 lg:flex lg:max-w-none">
              <div className="p-8 sm:p-10 lg:flex-auto h-full">
                <h3 className="text-xl font-bold tracking-tight text-gray-700">
                  {mentorshipPlans[2].name}
                </h3>
                <h2 className="mt-2 text-3xl font-bold text-gray-900">
                  {mentorshipPlans[2].price}/month
                </h2>
                <p className="mt-6 text-base leading-7 text-gray-600">
                  Elevate your team with hands-on learning and mentorship.
                </p>
                <div className="mt-6 flex items-center gap-x-4">
                  <h4 className="flex-none text-sm font-semibold leading-6 text-orange-600">
                    What’s included
                  </h4>
                  <div className="h-px flex-auto bg-gray-100"></div>
                </div>
                <div className="min-h-[10rem]">
                  <ul
                    role="list"
                    className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600"
                  >
                    {mentorshipPlans[2].features.map((feature, index) => (
                      <li key={index} className="flex gap-x-3">
                        <svg
                          className="h-6 w-5 flex-none text-orange-600"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clipRule="evenodd"
                          />
                        </svg>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="md:flex p-1 flex-wrap bg-white bottom-0 rounded-full">
                  <a
                    href="#"
                    className={
                      'bottom-0 text-sm py-2 h-full block w-full whitespace-no-wrap rounded text-white font-semibold'
                    }
                  >
                    <Button
                      size={'md'}
                      className="w-full"
                      fontSize={'sm'}
                      bgColor={'brand.darkBlue'}
                      onClick={event => {
                        event.preventDefault();
                        goToCheckout(mentorshipPlans[0].price_id);
                      }}
                      textColor={'white'}
                      rightIcon={<ArrowRight className="h-5 w-5 text-whit" />}
                      variant={'outline'}
                      rounded={'full'}
                      _hover={{ bg: 'brand.darkBlue', shadow: 'lg' }}
                    >
                      Get membership
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <Flex flexDirection="column" pt={12} pb={8}>
            <Heading pt={2} className="text-2xl text-gray-700">
              What our members say
            </Heading>
            <Text>
              Techies love the real-world projects and get feedback from
              industry experts.
            </Text>

            <SimpleGrid
              columns={{ md: 1, lg: 2 }}
              spacing={4}
              alignItems="flex-start"
              pt={4}
            >
              <Flex
                flexDirection="column"
                borderRadius="16px"
                style={{ border: '1px solid #e8eaea' }}
                px={4}
                py={4}
              >
                <Text className="pb-2 text-gray-700">
                  "The well curated projects which provide just enough
                  information for you to be able to build your project on your
                  own without needing a step by step guide. Techstarta provides
                  very good experience with the projects especially, and I would
                  like to share that with others."
                </Text>
                <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
                  <Avatar
                    name="Marnin"
                    src="https://storage.googleapis.com/talentmine/profiles/DevfestPicture.jpg"
                  />

                  <Box>
                    <Heading className="text-gray-700" size="sm">
                      Marnin Audu
                    </Heading>
                    <Text className="text-gray-600 text-sm">
                      A Techstarta mentee
                    </Text>
                  </Box>
                </Flex>
              </Flex>

              <Flex
                flexDirection="column"
                borderRadius="16px"
                style={{ border: '1px solid #e8eaea' }}
                px={4}
                py={4}
              >
                <Text pb={2} textColor="gray.600">
                  "The simplified approach to learning and starting out in tech
                  is so great. It's so easy to navigate and yeah the best choice
                  for every beginner."
                </Text>
                <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
                  <Avatar
                    name="Elizabeth Ogunmola"
                    color={'white'}
                    bgColor={'gray.700'}
                  />
                  <Box>
                    <Heading className="text-gray-700" size="sm">
                      Elizabeth Ogunmola
                    </Heading>
                    <Text className="text-gray-600 text-sm">
                      A Techstarta mentee
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </SimpleGrid>
          </Flex>

          <Flex
            bgColor="brand.darkBlue"
            textColor="white"
            justify={['flex-start', 'space-between']}
            pt={6}
            p={8}
            borderRadius="2xl"
            style={{ border: '1px solid #e8eaea' }}
          >
            <Flex flexDirection="column">
              <Heading pt={2} size="md">
                Still have questions?
              </Heading>
              <Text>Talk to someone on the Techstarta team.</Text>
            </Flex>
            <a
              href="https://app.cal.com/jidesakin/techstarta-mentoring-discovery-call"
              target="_blank"
            >
              <Button
                textColor={'white'}
                variant="outline"
                leftIcon={<CalendarDaysIcon className="h-4 w-4" />}
                _hover={{ shadow: 'md' }}
              >
                Book a call
              </Button>
            </a>
          </Flex>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(Membership);
