import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Text,
  Button,
  ButtonGroup,
  Skeleton,
  Flex,
  useToast,
  Divider,
  HStack,
} from '@chakra-ui/react';
import MarkdownToHtml from '../ReactMarkdownItem.js';
import discussionService from '../../services/discussion-service.js';
import moment from 'moment';
import {
  ChatBubbleLeftEllipsisIcon,
  HandThumbUpIcon,
} from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/24/solid';

export const Post = ({ discussion }) => {
  const { title, slug, upvotes, comments } = discussion;
  const [votes, setVotes] = useState(upvotes);
  const toast = useToast();

  const upvoteDiscussion = async event => {
    event.preventDefault();
    try {
      const response = await discussionService.upvoteDiscussion(slug);
      setVotes(response.data.discussion.upvotes);
      toast({
        title: 'Upvote Successful',
        description: 'You have successfully upvoted this discussion.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {}
  };

  return (
    <>
      <Box p={4} display={{ md: 'flex' }}>
        <Box
          mt={{ base: 4, md: 4 }}
          w={{ base: '100%', sm: '100%', md: '100%' }}
        >
          <Flex
            justify={'flex-start'}
            alignItems={'center'}
            pr="10px"
            pb={4}
            fontSize="15px"
          >
            <Box pr={2}>
              <Avatar
                src={discussion.author.image}
                name={discussion.author.fullname}
              />
            </Box>
            <div className="flex flex-col">
              <HStack spacing={1}>
                <Text
                  fontSize="md"
                  className="text-gray-600 font-semibold pr-2"
                >
                  {discussion.author.fullname}{' '}
                </Text>
                <StarIcon className="h-4 w-4 text-orange-400" />
                <Text fontSize="sm" className="text-gray-600 font-semibold">
                  {discussion.author.total_points}
                </Text>
              </HStack>

              <p className="text-xs">
                Posted in{' '}
                <span className="text-orange-500">{discussion.guild.name}</span>
              </p>
            </div>
          </Flex>
          <Flex justify="space-between">
            <h1 className="leading-tight text-3xl md:text-4xl font-bold text-gray-800">
              {title}
            </h1>
          </Flex>

          <Flex
            pt="10px"
            w="100%"
            alignItems={'center'}
            direction={['row', 'row', 'column', 'row']}
            justifyContent="left"
          >
            <HStack spacing={'6'} alignItems={'center'}>
              {
                <Text textColor="gray.600" fontSize="xs">
                  {moment(discussion.created_at).fromNow()}
                </Text>
              }
              {comments && (
                <HStack>
                  <ChatBubbleLeftEllipsisIcon
                    className="h-4 w-4 text-gray-500"
                    aria-label="Comment"
                  />
                  <Text fontSize="xs" textColor={'gray.600'}>
                    {comments.length}{' '}
                    {comments.length > 1 ? 'comments' : 'comment'}
                  </Text>
                </HStack>
              )}
            </HStack>
          </Flex>
        </Box>
      </Box>
      <Divider />
      <Box m={{ base: 4, md: 4 }}>
        <Box fontSize="md" color="gray.800">
          <Flex pb={6}>
            <MarkdownToHtml input={discussion.body} />
          </Flex>

          <Divider p />

          <ButtonGroup pt={4} size="sm" isAttached variant="outline">
            <Button
              onClick={upvoteDiscussion}
              _focus={{ outline: 'none' }}
              rounded="lg"
              aria-label="Upvote"
              rightIcon={
                <HandThumbUpIcon
                  className="h-4 w-4 text-gray-500"
                  aria-label="Upvote"
                />
              }
            >
              Upvote
            </Button>
            <Button rounded="lg" _focus={{ outline: 'none' }} mr="-px">
              {votes}
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </>
  );
};

export const PostSkeleton = () => {
  return (
    <>
      <Box color="gray.500" mt="30px">
        <Box fontSize="60px">
          <Skeleton h="40px"> </Skeleton>
        </Box>
        <Box display="flex" mt="10px" flexDirection="column">
          <Skeleton mt="10px" h="20px">
            {' '}
          </Skeleton>
          <Skeleton mt="10px" h="30px">
            {' '}
          </Skeleton>
        </Box>
      </Box>
      <Box mt="40px">
        <Box mt="40px">
          <Box color="#718096">
            <Skeleton mt="20px" h="20px">
              {' '}
            </Skeleton>
            <Skeleton mt="20px" h="40px">
              {' '}
            </Skeleton>
          </Box>
        </Box>

        <Box mt="40px">
          <Box color="#718096">
            <Box color="#718096">
              <Skeleton mt="20px" h="20px">
                {' '}
              </Skeleton>
              <Skeleton mt="20px" h="40px">
                {' '}
              </Skeleton>
            </Box>
          </Box>
        </Box>
        <Box mt="40px">
          <Skeleton h="30px"> </Skeleton>
          <Box color="#718096">
            <Skeleton mt="20px" h="20px">
              {' '}
            </Skeleton>
            <Skeleton mt="20px" h="40px">
              {' '}
            </Skeleton>
          </Box>
        </Box>
        <Box bg="whitesmoke" mt="40px" p="20px" pt="40px">
          <Skeleton mt="20px" h="30px">
            {' '}
          </Skeleton>
        </Box>
      </Box>
    </>
  );
};
