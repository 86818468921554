import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Avatar,
  Text,
  Box,
  Skeleton,
  Divider,
  Center,
  Show,
  Button,
  HStack,
  Modal,
  ModalOverlay,
  useDisclosure,
  Input,
  useClipboard,
  Tooltip,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  InputGroup,
  InputRightElement,
  Stack,
  Icon,
} from '@chakra-ui/react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import {
  EmailIcon,
  EmailShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { UserIcon } from '@heroicons/react/24/outline';
import { FaLink, FaLinkedinIn, FaTwitter } from 'react-icons/fa';

export const ProfileSection = ({ profile, currentUser, username }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const profileLink = `https://www.techstarta.com/profile/${profile.username}`;
  const { hasCopied, onCopy } = useClipboard(profileLink);
  const shareTitle = 'Check out my Techstarta portfolio';

  return (
    <>
      <Flex
        flexWrap="wrap"
        justify={['center', 'center', 'center', 'space-between']}
      >
        <Box mr={['0px', '10px', '15px', '20px']}>
          <Avatar
            size="xl"
            fontSize="45px"
            borderRadius="50%"
            icon={<UserIcon className="w-28 h-28" />}
            name={profile.fullname}
            src={profile.image}
            shadow="md"
          />
        </Box>

        <Flex
          fontSize="md"
          alignItems={['center', 'center', 'center', 'flex-start']}
          mt={['20px', '10px', '0px']}
          mb={'8'}
          flex={['0 0 100%', '1 0 50%', '1 0 50%', '1 0 33%']}
          direction={['column']}
        >
          <HStack>
            <h1 className="text-[#2d3748] text-3xl md:text-4xl font-[700] leading-tight tracking-tight text-left">
              {profile.fullname}
            </h1>
            {profile.is_mentor && (
              <Tooltip
                background={'transparent'}
                textColor={'gray.700'}
                rounded={'md'}
                label="Verified mentor"
                fontSize={'sm'}
                aria-label="A tooltip"
                placement="top-end"
              >
                <CheckBadgeIcon className="h-5 w-5 inline-flex text-orange-500" />
              </Tooltip>
            )}
          </HStack>
          <Text fontSize={'sm'} textColor={'gray.600'}>
            {'Member since ' +
              moment(profile.member_since).format('MMMM, YYYY')}
          </Text>
          <Stack
            spacing="4"
            isInline
            textAlign="center"
            mb="5px"
            color="#ED8936"
          >
            {profile.linkedin ? (
              <a
                href={profile.linkedin}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Icon as={FaLinkedinIn} />
              </a>
            ) : null}
            {profile.twitter ? (
              <a
                href={
                  profile.twitter.startsWith('http')
                    ? profile.twitter
                    : `https://twitter.com/${profile.twitter}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon as={FaTwitter} />
              </a>
            ) : null}
            {profile.website ? (
              <a
                href={profile.website}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Icon as={FaLink} />
              </a>
            ) : null}
          </Stack>
        </Flex>

        <Show above="sm">
          <Center>
            <Divider orientation="vertical" mr={4} />
          </Center>
        </Show>

        <HStack alignSelf="right" justify={'flex-end'}>
          <Button
            onClick={onOpen}
            size="sm"
            bgColor={'gray.700'}
            _hover={{ shadow: 'md' }}
            color="white"
            rounded={'lg'}
            _focus={{ outlineColor: 'gray' }}
            rightIcon={
              <FontAwesomeIcon
                icon={faShareAlt}
                className="text-white"
                _focus={{ outline: 'none', outlineColor: 'gray' }}
              />
            }
          >
            Share
          </Button>
          {currentUser && username === currentUser.username && (
            <Button
              _active={{ outline: 'none' }}
              onClick={event => {
                event.preventDefault();
                navigate('/settings');
              }}
              size="sm"
              variant={'outline'}
              color="white"
              textColor={'gray.600'}
              rounded={'lg'}
            >
              Edit
            </Button>
          )}
        </HStack>

        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />{' '}
          <ModalContent>
            <ModalHeader className="text-gray-700">Share profile</ModalHeader>
            <ModalCloseButton _focus={{ outline: 'none' }} />
            <ModalBody>
              <HStack spacing={2} pb="4">
                <LinkedinShareButton
                  title={shareTitle}
                  summary="I'm building my portfolio with Techstarta"
                  url={profileLink}
                  source={profileLink}
                >
                  <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>
                <TwitterShareButton
                  title={shareTitle}
                  url={profileLink}
                  via="techstarta"
                  hashtags={['LearnWithTechstarta', 'BuildWithTechstarta']}
                >
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <WhatsappShareButton title={shareTitle} url={profileLink}>
                  <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
                <EmailShareButton subject={shareTitle} body={profileLink}>
                  <EmailIcon size={32} round={true} />
                </EmailShareButton>
              </HStack>
              <Text
                textColor={'gray.600'}
                fontSize={'sm'}
                fontWeight="semibold"
              >
                or copy link
              </Text>
              <Box py={4}>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={'text'}
                    bgColor="gray.100"
                    className="text-gray-700"
                    readOnly={true}
                    value={profileLink}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={onCopy}>
                      {hasCopied ? 'Copied' : 'Copy'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
    </>
  );
};

export const ProfileSkeleton = () => {
  return (
    <>
      <Flex
        p={['10px', '10px', '20px', '20px']}
        color="#718096"
        flexWrap="wrap"
        justify={['center', 'center', 'center', 'space-between']}
      >
        <Box mr={['0px', '10px', '15px', '20px']}>
          <Skeleton
            w="100px"
            h="100px"
            mb="25px"
            rounded="50%"
            shadow="lg"
          ></Skeleton>
          <Skeleton height="20px"></Skeleton>
        </Box>

        <Flex
          fontSize="14px"
          mt={['20px', '10px', '0px']}
          flex={['0 0 100%', '1 0 50%', '1 0 50%', '1 0 33%']}
          direction={['column']}
        >
          <Box mb="25px" textAlign={['center', 'start', 'center', 'start']}>
            <Skeleton mb="10px" height="30px"></Skeleton>
            <Skeleton height="15px"></Skeleton>
          </Box>
          <Box>
            <Skeleton height="20px"></Skeleton>
            <Skeleton mt="10px" height="100px"></Skeleton>
          </Box>
        </Flex>

        <Flex
          flex={['0 0 100%', '0 0 100%', '0 0 100%', '1 0 20%']}
          mt={['20px', '20px', '20px', '0px']}
          ml={['0px', '0px', '0px', '20px']}
          direction={['column']}
        >
          <Box>
            <Skeleton mb="25px" height="50px"></Skeleton>
          </Box>
          <Box>
            <Skeleton mb="5px" height="10px"></Skeleton>
            <Skeleton mb="5px" height="10px"></Skeleton>
            <Skeleton mb="5px" height="10px"></Skeleton>
          </Box>
          <Skeleton my="25px" height="50px"></Skeleton>

          <Skeleton height="50px"></Skeleton>
        </Flex>
      </Flex>
    </>
  );
};
