import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Text,
  Skeleton,
  Button,
  Icon,
  HStack,
  Tooltip,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Modal,
  useDisclosure,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Link,
} from '@chakra-ui/react';
import { BeatLoader } from 'react-spinners';

import AuthService from '../services/auth-service';
import BookingService from '../services/booking-service';
import { Mixpanel } from '../utils/Mixpanel';
import { FaLink, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import InclusionItem from './Membership/InclusionItem';
import {
  CheckBadgeIcon,
  ShareIcon,
  UserIcon,
} from '@heroicons/react/24/outline';

import {
  CheckBadgeIcon as CheckBadgeIconSolid,
  MapPinIcon,
  PlayCircleIcon,
} from '@heroicons/react/24/solid';
import { FireIcon } from '@heroicons/react/20/solid';
import splitbee from '@splitbee/web';
import { oembed } from '@loomhq/loom-embed';

import subscriptionService from '../services/subscription-service';
import SkillCloud from './Common/SkillCloud';
import { ShareModal } from './Common/ShareModal';

export const MentorProfile = ({ mentor, profile, isLoading }) => {
  const profileLink = `https://www.techstarta.com/mentor/${profile.username}`;
  const mentorshipPlans = [
    {
      name: 'Lite',
      price: '$19',
      price_id: 'price_1OMZQDEyLuaj2xNpIKsLTFwI',
      description: 'Get hands-on mentorship from an industry expert',
      features: [
        'Hands-on career mentorship',
        'Unlimited Q&A via chat',
        '1 Video call per month',
      ],
    },
    {
      name: 'Standard',
      price: '$39',
      description: 'Get hands-on mentorship from an industry expert',
      price_id: 'price_1OMZl4EyLuaj2xNpbh35TrRy',
      features: [
        'Hands-on career mentorship',
        'Unlimited Q&A via chat',
        'Resume, LinkedIn & Portfolio review.',
        '2 Video calls per month',
      ],
    },
    {
      name: 'Pro',
      price: '$59',
      description: 'Get hands-on mentorship from an industry expert',
      price_id: 'price_1OMZp9EyLuaj2xNpqZyBdMiz',
      features: [
        'Hands-on career mentorship',
        'Unlimited Q&A via chat',
        'Resume, LinkedIn & Portfolio review.',
        '4 Video calls per month',
      ],
    },
  ];
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [productLoading, setProductLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState(currentUser ? currentUser.email : '');
  const [mentorshipProducts, setMentorshipProducts] = useState([]);
  const productRef = useRef(null);
  const [mentorshipPlan, setMentorshipPlan] = useState(mentorshipPlans[1]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isShareModalOpen,
    onOpen: openShareModal,
    onClose: closeShareModal,
  } = useDisclosure();

  const [embedVideo, setEmbedVideo] = useState(null);

  useEffect(() => {
    const currentUser = JSON.parse(AuthService.getCurrentUser());
    setCurrentUser(currentUser);
    if (currentUser !== null) {
      setName(currentUser.firstname + ' ' + currentUser.lastname);
      setEmail(currentUser.email);
    }
  }, []);

  useEffect(() => {
    async function fetchMentorshipProducts() {
      setProductLoading(true);
      const response = await BookingService.listMentorshipProducts();
      const mentorshipProducts = response.data.products;
      setMentorshipProducts(mentorshipProducts);
      setProductLoading(false);
    }
    fetchMentorshipProducts();
  }, []);

  useEffect(() => {
    if (mentor.intro_video && mentor.intro_video.includes('loom')) {
      oembed(mentor.intro_video).then(embed => {
        setEmbedVideo(embed);
      });
    }
  }, [mentor]);

  const handlePlayIntro = () => {
    if (!mentor.intro_video) {
      return;
    }
    if (embedVideo && embedVideo.type === 'video') {
      onOpen();
      return;
    }

    if (mentor.intro_video.includes('youtube')) {
      const videoId = mentor.intro_video.split('v=')[1];
      const ampersandPosition = videoId.indexOf('&');
      if (ampersandPosition !== -1) {
        const videoId = videoId.substring(0, ampersandPosition);
        setVideoUrl(`https://www.youtube.com/embed/${videoId}`);
        onOpen();
      }
    }
  };

  const goToCheckout = async (priceId, mode) => {
    setCheckoutLoading(true);
    Mixpanel.track('Buy Mentorship Checkout', {
      mentorUsername: mentor.username,
      customerEmail: currentUser?.email,
    });
    splitbee.track('Buy Mentorship Checkout', {
      mentorUsername: mentor.username,
      customerEmail: currentUser?.email,
    });

    const payload = { price_id: priceId, mode: mode };
    if (currentUser) {
      payload.customer_email = currentUser.email;
    }

    try {
      const response = await subscriptionService.getCheckoutUrl(payload);
      if (response?.checkout.url) {
        const checkoutSessionUrl = response.checkout.url;
        window.open(checkoutSessionUrl, '_blank', 'noopener noreferrer');
      }
    } catch (err) {}
    setCheckoutLoading(false);
  };

  return (
    <>
      <div className="relative flex flex-col w-full mx-auto">
        <div className="flex flex-wrap">
          <div className="w-full relative xl:w-8/12">
            <Flex
              flexDirection={'column'}
              rounded={'lg'}
              pr={['2', '4', '4', '4']}
            >
              <Flex
                flexWrap="wrap"
                justify={['center', 'center', 'center', 'space-between']}
                align={['center', 'center', 'center', 'center']}
                pb={4}
              >
                <Box mr={['0px', '10px', '15px', '20px']}>
                  <Avatar
                    size="2xl"
                    fontSize="45px"
                    borderRadius="50%"
                    bgColor={'brand.darkBlue'}
                    icon={<UserIcon className="w-28 h-28" />}
                    name={mentor.firstname + ' ' + mentor.lastname}
                    src={AuthService.buildImageUrl(mentor.image)}
                    shadow="md"
                  />
                </Box>

                <Flex
                  fontSize="md"
                  mt={['20px', '10px', '0px']}
                  flex={['0 0 100%', '1 0 50%', '1 0 50%', '1 0 33%']}
                  direction={['column']}
                >
                  <Box textAlign={['center', 'start', 'center', 'start']}>
                    <HStack alignItems={'center'}>
                      <h1 className="text-[#2d3748] text-3xl md:text-4xl font-[700] leading-tight tracking-tight text-left">
                        {`${mentor.firstname} ${mentor.lastname}`}
                      </h1>
                      <Tooltip
                        background={'transparent'}
                        textColor={'gray.700'}
                        rounded={'md'}
                        label="Verified expert"
                        fontSize={'sm'}
                        aria-label="A tooltip"
                        placement="top-end"
                      >
                        {
                          <CheckBadgeIconSolid className="h-5 w-5 text-orange-500" />
                        }
                      </Tooltip>
                    </HStack>
                    <Text fontWeight={'medium'} textColor={'gray.700'}>
                      {mentor.current_role + ' at ' + mentor.company}
                    </Text>
                    <HStack
                      justify={['center', 'center', 'flex-start', 'flex-start']}
                    >
                      <MapPinIcon className="w-4 h-4 text-orange-500" />
                      <Text textColor={'gray.700'}>{mentor.location}</Text>
                    </HStack>
                    {mentor.intro_video ? (
                      <Box py={2}>
                        <Button
                          onClick={handlePlayIntro}
                          className="ring-1 ring-gray-100 hover:shadow-sm focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 focus:outline-none"
                          leftIcon={
                            <PlayCircleIcon className="h-4 w-4 text-orange-500" />
                          }
                          size={'sm'}
                          rounded={'lg'}
                          variant={'outline'}
                        >
                          Play intro
                        </Button>
                      </Box>
                    ) : null}
                  </Box>
                </Flex>
                <HStack alignSelf="right" justify={'flex-end'}>
                  <Button
                    onClick={openShareModal}
                    size="sm"
                    bgColor={'brand.darkBlue'}
                    _hover={{ shadow: 'md' }}
                    color="white"
                    rounded={'lg'}
                    _focus={{ outlineColor: 'brand.darkBlue' }}
                    leftIcon={<ShareIcon className="w-4 h-4" />}
                  >
                    Share
                  </Button>
                  {currentUser && profile.username === currentUser.username && (
                    <Link href="/settings">
                      <Button
                        _active={{ outline: 'none' }}
                        size="sm"
                        variant={'outline'}
                        color="white"
                        textColor={'gray.600'}
                        rounded={'lg'}
                      >
                        Edit
                      </Button>
                    </Link>
                  )}
                </HStack>
              </Flex>
              <div className="divide-y divide-gray-100 border-gray-100 border-y py-4">
                <HStack className="align-middle text-sm font-medium py-2 text-gray-800">
                  <CheckBadgeIcon className="w-8 h-8 inline-block text-orange-500" />
                  <span>
                    Verified expert in:{' '}
                    {profile?.tracks && profile.tracks.length > 0
                      ? profile?.tracks
                          .filter(track => track.role === 'mentor')
                          .map(track => track.track.title)
                          .join(', ')
                      : 'No skills added yet'}
                  </span>
                </HStack>
              </div>
              <Flex flexDirection={'column'}>
                <Box pt={4}>
                  <h2 className="text-[#2d3748] font-medium leading-tight tracking-tight text-left mb-4">
                    About
                  </h2>
                  <p className="text-gray-700 leading-normal text-base">
                    {mentor.pitch}
                  </p>
                  {mentor.fun_fact ? (
                    <p className="text-gray-700 pt-4 leading-normal text-base">
                      {mentor.fun_fact}
                    </p>
                  ) : null}
                </Box>
                <HStack spacing="2" textAlign="center" my="4">
                  {mentor.linkedin ? (
                    <a
                      href={mentor.linkedin}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Icon as={FaLinkedinIn} />
                    </a>
                  ) : null}
                  {mentor.twitter ? (
                    <a
                      href={
                        mentor.twitter.startsWith('http')
                          ? mentor.twitter
                          : `https://twitter.com/${mentor.twitter}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon as={FaTwitter} />
                    </a>
                  ) : null}
                  {mentor.website ? (
                    <a
                      href={mentor.website}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Icon as={FaLink} />
                    </a>
                  ) : null}
                </HStack>

                <Skeleton isLoaded={!isLoading} rounded={'lg'} mt="8">
                  <Flex flexDirection={'column'}>
                    <Flex rounded={'lg'} flexDirection={'column'}>
                      <h2 className="text-[#2d3748] font-medium leading-tight tracking-tight text-left mb-4">
                        Skills & Expertise
                      </h2>
                      <Flex>
                        {mentor?.skills && mentor.skills.length > 0 ? (
                          <SkillCloud
                            skills={mentor?.skills.map(skill => skill.name)}
                            tagSize="md"
                            max={10}
                          />
                        ) : (
                          <Text fontSize="sm" textColor={'gray.600'}>
                            No skills added yet
                          </Text>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                </Skeleton>
                <Skeleton isLoaded={!isLoading} rounded={'lg'} mt="8">
                  <Flex flexDirection={'column'}>
                    <Flex rounded={'lg'} flexDirection={'column'}>
                      <h2 className="text-[#2d3748] font-medium leading-tight tracking-tight text-left mb-4">
                        Topics
                      </h2>
                      <Flex>
                        {mentor?.topics && mentor.topics.length > 0 ? (
                          <SkillCloud
                            skills={mentor?.topics}
                            max={10}
                            tagSize="md"
                          />
                        ) : (
                          <Text fontSize="sm" textColor={'gray.600'}>
                            No topics added yet
                          </Text>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                </Skeleton>

                <Skeleton isLoaded={!isLoading} rounded={'lg'} my="8">
                  <Flex flexDirection={'column'}>
                    <Flex rounded={'lg'} flexDirection={'column'}>
                      <h2 className="text-[#2d3748] font-medium leading-tight tracking-tight text-left mb-4">
                        Artifacts
                      </h2>
                      <SimpleGrid columns={[1, 2, 2, 2]}>
                        {profile?.featured_resources &&
                        profile.featured_resources.length > 0 ? (
                          profile.featured_resources.map((resource, index) => (
                            <LinkBox className="p-4 ring-1 ring-gray-300/20 ring-inset shadow-sm hover:shadow-md hover:ring-gray-300 rounded-lg">
                              <LinkOverlay href={resource.link} isExternal>
                                <Text
                                  fontSize="md"
                                  className="text-orange-500 font-medium hover:underline"
                                  key={index}
                                >
                                  {resource.title}
                                </Text>
                              </LinkOverlay>
                              <Text
                                className="mt-2 text-sm text-gray-600"
                                key={index}
                              >
                                {resource.summary}
                              </Text>
                            </LinkBox>
                          ))
                        ) : (
                          <Text fontSize="sm" textColor={'gray.600'}>
                            No artifacts added yet
                          </Text>
                        )}
                      </SimpleGrid>
                    </Flex>
                  </Flex>
                </Skeleton>
              </Flex>
            </Flex>
          </div>
          <div className="w-full xl:w-4/12 xl:pl-4">
            <Flex
              rounded={'lg'}
              className="shadow-lg shadow-black/5 ring-1 ring-slate-700/10 xl:fixed xl:top-28 "
              flex={['0 0 100%', '0 0 100%', '0 0 100%', '1 0 32%']}
              direction={['column']}
            >
              <Tabs width={'100%'} align="center" isFitted colorScheme="orange">
                <TabList className="text-gray-700">
                  <Tab className="focus:outline-none hover:bg-gray-50 font-medium">
                    Mentorship plans
                  </Tab>
                  {/* <Tab className="focus:outline-none hover:bg-gray-50 font-medium">
                    Sessions
                  </Tab> */}
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <div className="w-full">
                      <Flex>
                        <div
                          className="inline-flex rounded-md shadow-sm mb-4"
                          role="group"
                        >
                          <button
                            type="button"
                            value={'lite'}
                            onClick={() => {
                              setMentorshipPlan(mentorshipPlans[0]);
                            }}
                            className={`px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-orange-600 focus:outline-none ${
                              mentorshipPlan.name === 'Lite'
                                ? 'bg-orange-50'
                                : 'bg-white'
                            }`}
                          >
                            Lite plan
                          </button>
                          <button
                            type="button"
                            value={'standard'}
                            onClick={() => {
                              setMentorshipPlan(mentorshipPlans[1]);
                            }}
                            className={`px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-orange-600 focus:outline-none ${
                              mentorshipPlan.name === 'Standard'
                                ? 'bg-orange-50'
                                : 'bg-white'
                            }`}
                          >
                            Standard plan
                          </button>
                          <button
                            type="button"
                            value={'pro'}
                            onClick={() => {
                              setMentorshipPlan(mentorshipPlans[2]);
                            }}
                            className={`px-4 py-2 text-sm font-medium text-gray-900 border border-gray-200 rounded-r-lg hover:bg-gray-100 hover:text-orange-600 focus:outline-none ${
                              mentorshipPlan.name === 'Pro'
                                ? 'bg-orange-50'
                                : 'bg-white'
                            }`}
                          >
                            Pro plan
                          </button>
                        </div>
                      </Flex>
                      <Flex
                        align={'flex-start'}
                        flexDirection={'column'}
                        py={2}
                      >
                        <Text
                          fontSize={'3xl'}
                          fontWeight="bold"
                          textColor={'gray.700'}
                        >
                          {mentorshipPlan.price}{' '}
                          <span className="text-base"> per month</span>
                        </Text>
                        <Text
                          fontSize={'md'}
                          pt={2}
                          fontWeight="medium"
                          textColor={'gray.700'}
                        >
                          Get mentored by {mentor.firstname}{' '}
                        </Text>
                      </Flex>
                      <Box textColor={'gray.700'} pb={10}>
                        {mentorshipPlan.features.map((feature, index) => (
                          <InclusionItem key={index} label={feature} />
                        ))}
                      </Box>

                      <Flex flexDirection={'column'} width={'100%'}>
                        <Button
                          onClick={() =>
                            goToCheckout(
                              mentorshipPlan.price_id,
                              'subscription'
                            )
                          }
                          width={'100%'}
                          bgColor={'brand.darkBlue'}
                          textColor={'white'}
                          _hover={{ shadow: 'md' }}
                          _active={{
                            shadow: 'md',
                            outlineColor: 'brand.darkBlue',
                          }}
                          isLoading={checkoutLoading}
                          _focus={{ outlineColor: 'brand.darkBlue' }}
                          spinner={<BeatLoader size={8} color={'white'} />}
                          rounded={'full'}
                        >
                          Apply now
                        </Button>
                        <HStack fontSize={'sm'} py="2" textColor={'gray.600'}>
                          <FireIcon className="w-4 h-4" />
                          <span className="font-medium">
                            5 spots left this month
                          </span>
                        </HStack>
                      </Flex>
                    </div>
                  </TabPanel>
                  {/* <TabPanel>
                    <ProductRadioGroup
                      products={mentorshipProducts}
                      selectedProduct={selectedProduct}
                      onChange={event => {
                        setSelectedProduct(event.target.value);
                      }}
                    />
                    <Flex width={'100%'}>
                      <Button
                        onClick={() => {
                          goToCheckout(selectedProduct, 'payment');
                        }}
                        width={'100%'}
                        bgColor={'brand.darkBlue'}
                        textColor={'white'}
                        _hover={{ shadow: 'md' }}
                        _active={{
                          shadow: 'md',
                          outlineColor: 'brand.darkBlue',
                        }}
                        spinner={<BeatLoader size={8} color={'white'} />}
                        isLoading={checkoutLoading}
                        _focus={{ outlineColor: 'brand.darkBlue' }}
                        rounded={'full'}
                      >
                        Book now
                      </Button>
                    </Flex>
                    <Flex pt={2} width={'100%'}>
                      {mentor.calendly_booking_link ? (
                        <PopupButton
                          className="text-gray-600 w-full font-medium px-4 py-2 rounded-full ring-1 ring-gray-300 hover:shadow-md hover:bg-gray-50"
                          url={mentor.calendly_booking_link}
                          rootElement={document.getElementById('root')}
                          text="Preview availability"
                        />
                      ) : null}
                    </Flex>
                  </TabPanel> */}
                </TabPanels>
              </Tabs>
            </Flex>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Intro video</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {embedVideo && embedVideo.type === 'video' && (
              <div
                className="rounded-lg ring-1 ring-gray-300 overflow-hidden mb-4"
                dangerouslySetInnerHTML={{ __html: embedVideo.html }}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <ShareModal
        modalTitle={'Share profile'}
        title={'Hey there!'}
        body={
          "I'm mentoring techies on Techstarta. Check out my profile and send me a request!"
        }
        isShareModalOpen={isShareModalOpen}
        closeShareModal={closeShareModal}
        link={profileLink}
      />
    </>
  );
};

export const MentorProfileSkeleton = () => {
  return (
    <>
      <Flex
        p={'4'}
        color="#718096"
        flexWrap="wrap"
        justify={['center', 'center', 'center', 'space-between']}
      >
        <Box mr={['0px', '10px', '15px', '20px']}>
          <Skeleton
            w="24"
            h="24"
            mb="25px"
            rounded="50%"
            shadow="sm"
          ></Skeleton>
          <Skeleton height="20px"></Skeleton>
        </Box>

        <Flex
          fontSize="14px"
          mt={['20px', '10px', '0px']}
          flex={['0 0 100%', '1 0 50%', '1 0 50%', '1 0 33%']}
          direction={['column']}
        >
          <Box mb="25px" textAlign={['center', 'start', 'center', 'start']}>
            <Skeleton mb="10px" height="30px"></Skeleton>
            <Skeleton height="15px"></Skeleton>
          </Box>
          <Box>
            <Skeleton height="20px"></Skeleton>
            <Skeleton mt="10px" height="100px"></Skeleton>
          </Box>
        </Flex>

        <Flex
          flex={['0 0 100%', '0 0 100%', '0 0 100%', '1 0 32%']}
          mt={['20px', '20px', '20px', '0px']}
          ml={['0px', '0px', '0px', '20px']}
          direction={['column']}
        >
          <Box>
            <Skeleton mb="25px" height="50px"></Skeleton>
          </Box>
          <Box>
            <Skeleton mb="5px" height="10px"></Skeleton>
            <Skeleton mb="5px" height="10px"></Skeleton>
            <Skeleton mb="5px" height="10px"></Skeleton>
          </Box>
          <Skeleton my="25px" height="50px"></Skeleton>

          <Skeleton height="50px"></Skeleton>
        </Flex>
      </Flex>
    </>
  );
};
