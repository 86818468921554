import React, { useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Text,
  Image,
  IconButton,
  Skeleton,
  Flex,
  Icon,
  Divider,
  Badge,
  Stack,
  SkeletonText,
} from '@chakra-ui/react';
import MarkdownToHtml from '../ReactMarkdownItem';
import moment from 'moment';
import storyService from '../../services/story-service';
import { SocialShareBox } from '../Common/SocialShareBox';

export const DetailedStory = ({ story }) => {
  const { category, upvotes, slug, comments, guest_name, guest_current_role } =
    story;
  const [votes, setVotes] = useState(upvotes);

  const upvoteStory = async event => {
    event.preventDefault();
    try {
      const response = await storyService.upvoteStory(slug);
      setVotes(response.data.story.upvotes);
    } catch (err) {}
  };

  return (
    <>
      <Flex
        p={2}
        pb={4}
        alignItems="center"
        flexDirection={['column', 'column', 'row', 'row']}
      >
        <Box w={'100%'}>
          {category && (
            <Badge size="xs" rounded="full" colorScheme="teal" px="2">
              {category.name}
            </Badge>
          )}
          <h1 className="font-semibold text-gray-800 mt-2 leading-tight text-4xl md:text-5xl">
            {story.title}
          </h1>
          <h2 className="text-xl text-gray-600 mt-2">
            {guest_name}, {guest_current_role}
          </h2>
          <Stack py="4" w="100%" spacing="2" direction="row" align={'center'}>
            <Text textColor="gray.600" fontSize="xs">
              Published on
            </Text>
            {
              <Text textColor="gray.600" fontSize="xs">
                {moment(story.published_date).format('MMM DD, YYYY')}
              </Text>
            }
            {/* <ButtonGroup size="sm" isAttached variant="outline">
              <IconButton
                onClick={upvoteStory}
                aria-label="Upvote"
                _focus={{ outline: 'none' }}
                icon={<FaAngleUp />}
              />
              <Button mr="-px" _focus={{ outline: 'none' }}>
                {votes}
              </Button>
            </ButtonGroup> */}
          </Stack>
          <SocialShareBox
            boxTitle={'Share this story'}
            contentTitle={story.title}
            contentType={'story'}
            showTitle={false}
          />
        </Box>
      </Flex>
      <Flex
        alignContent={'center'}
        flexShrink={0}
        px={2}
        justifyContent={'center'}
        w={'100%'}
      >
        <Image
          borderRadius="lg"
          height={'30rem'}
          objectFit={'cover'}
          width={'full'}
          src={story.featured_image}
          alt={story.title}
        />
      </Flex>
      <Box pt={10} m={{ base: 2, md: 2 }}>
        <Box fontSize="md" color="gray.800">
          <MarkdownToHtml input={story.body} />
        </Box>
      </Box>
    </>
  );
};

export const StoryDetailsSkeleton = () => {
  return (
    <>
      <Box color="gray.500" mt="30px">
        <Box fontSize="60px">
          <Skeleton h="40px">
            <SkeletonText mt="4" noOfLines={1} spacing="4" />
          </Skeleton>
        </Box>
        <Box display="flex" mt="10px" flexDirection="column">
          <SkeletonText mt="4" noOfLines={5} spacing="4" />
        </Box>
      </Box>
      <Box mt="40px">
        <Box mt="40px">
          <Heading color="gray.500" mb="20px" size="sm">
            <SkeletonText mt="4" noOfLines={1} spacing="4" />
          </Heading>
          <Box color="#718096">
            <SkeletonText mt="4" noOfLines={5} spacing="4" />
          </Box>
        </Box>

        <Box mt="40px">
          <Heading color="gray.500" mb="20px" size="sm">
            <SkeletonText mt="4" noOfLines={1} spacing="4" />
          </Heading>
          <Box color="#718096">
            <Box color="#718096">
              <SkeletonText mt="4" noOfLines={5} spacing="4" />
            </Box>
          </Box>
        </Box>
        <Box mt="40px">
          <Heading color="gray.500" mb="20px" size="sm">
            <SkeletonText mt="4" noOfLines={1} spacing="4" />
          </Heading>
          <Skeleton h="30px"> </Skeleton>
          <Box color="#718096">
            <SkeletonText mt="4" noOfLines={5} spacing="4" />
          </Box>
        </Box>
        <Box bg="whitesmoke" mt="40px" p="20px" pt="40px">
          <SkeletonText mt="4" noOfLines={5} spacing="4" />
        </Box>
      </Box>
    </>
  );
};
